<form class="filter-form-sm">
  <div class="cols">
    <div class="col-2">
      <mat-form-field>
        <input matInput #filterCodeInput (keyup)="filter(filterCodeInput.value, 'Code')" spellcheck="false" [placeholder]="'admissionOrders_lblCode' | translate">
        <button mat-button *ngIf="filterCodeInput.value" matSuffix mat-icon-button [title]="'lblClear' | translate"
                (click)="filterCodeInput.value = ''; filter('', 'Code')">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>

    <div class="col-4">
      <mat-form-field>
        <input matInput #filterTitleInput (keyup)="filter(filterTitleInput.value, 'Title')" spellcheck="false" [placeholder]="'admissionOrders_lblTitle' | translate">
        <button mat-button *ngIf="filterTitleInput.value" matSuffix mat-icon-button [title]="'lblClear' | translate"
                (click)="filterTitleInput.value = ''; filter('', 'Title')">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>

    <div class="col-2">
      <mat-form-field>
        <input matInput [matDatepicker]="pickerDateFrom" [(ngModel)]="filterDateFrom" name="filterDateFrom"
               [placeholder]="'share_lblDateFrom' | translate" [title]="'dateFormatHint' | translate">
        <mat-datepicker-toggle matSuffix [for]="pickerDateFrom"></mat-datepicker-toggle>
        <mat-datepicker #pickerDateFrom></mat-datepicker>
      </mat-form-field>
    </div>


    <div class="col-2">
      <mat-form-field>
        <input matInput [matDatepicker]="pickerDateTo" [(ngModel)]="filterDateTo" name="filterDateTo"
               [placeholder]="'share_lblDateTo' | translate" [title]="'dateFormatHint' | translate">
        <mat-datepicker-toggle matSuffix [for]="pickerDateTo"></mat-datepicker-toggle>
        <mat-datepicker #pickerDateTo></mat-datepicker>
      </mat-form-field>
    </div>

    <div class="col-2">
      <div class="mat-align-middle">
        <button type="submit" mat-raised-button color="primary" (click)="filter(null, 'Date')">
          {{'filter' | translate}}
        </button>
      </div>
    </div>
  </div>
</form>

<ngx-datatable #grid
               class="tight"
               [rows]="gridItems"
               [headerHeight]="'auto'"
               [footerHeight]="50"
               [rowHeight]="'auto'"
               [columnMode]="'standard'"
               [limit]="100"
               [externalSorting]="true"
               [loadingIndicator]="gridLoading"
               [messages]="{ emptyMessage: 'gridEmpty' | translate, totalMessage: 'gridTotal' | translate }"
               (sort)="onGridSort($event)">
  <ngx-datatable-row-detail [rowHeight]="'auto'" #detailRow>
    <ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
      <ngx-datatable [rows]="row.Contracts"
                     [headerHeight]="'auto'"
                     [rowHeight]="'auto'"
                     [footerHeight]="50"
                     [columnMode]="'standard'"
                     [messages]="{ emptyMessage: 'gridEmpty' | translate, totalMessage: 'gridTotal' | translate }"
                     [sorts]="[{prop: 'Surname', dir: 'asc'}]">
        <ngx-datatable-column [name]="'admissionApplicationOrders_lblNumber' | translate" prop="Number" [width]="150"></ngx-datatable-column>
        <ngx-datatable-column [name]="'admissionApplicationOrders_lblContractNumber' | translate" prop="ContractNumber" [width]="150"></ngx-datatable-column>
        <ngx-datatable-column [name]="'admissionApplicationOrders_lblName' | translate" prop="Name" [width]="150"></ngx-datatable-column>
        <ngx-datatable-column [name]="'admissionApplicationOrders_lblSurname' | translate" prop="Surname" [width]="150"></ngx-datatable-column>
        <ngx-datatable-column [name]="'admissionApplicationOrders_lblStatus' | translate" prop="Status" [width]="370">
          <ng-template ngx-datatable-cell-template let-row="row">
              {{'applicationStatus_' + row.Status | translate}}
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
    </ng-template>
  </ngx-datatable-row-detail>
  <ngx-datatable-column [width]="20"
                        [resizeable]="false"
                        [sortable]="false"
                        [draggable]="false"
                        [canAutoResize]="false"
                        [flexGrow]="1">
    <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
      <a *ngIf="row.OrderCount > 0" href="javascript:void(0)"
         [class.datatable-icon-right]="!expanded"
         [class.datatable-icon-down]="expanded"
         (click)="toggleGridRow(row)">
      </a>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column [name]="'admissionOrders_lblCode' | translate" prop="Code" [width]="80"></ngx-datatable-column>
  <ngx-datatable-column [name]="'admissionOrders_lblTitle' | translate" prop="Title" [width]="550"></ngx-datatable-column>
  <ngx-datatable-column [name]="'admissionOrders_lblOrderDate' | translate" prop="OrderDate" [width]="100">
    <ng-template ngx-datatable-cell-template let-row="row">
      {{row.OrderDate | appDate}}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column [name]="'admissionOrders_lblProtocolNumber' | translate" prop="ProtocolNumber" [width]="150"></ngx-datatable-column>
  <ngx-datatable-column [name]="'admissionOrders_lblStatus' | translate" prop="Status" [width]="150">
    <ng-template ngx-datatable-cell-template let-row="row">
      {{'enrollmentOrderStatus_' + row.Status | translate}}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column [name]="'admissionOrders_lblDVSDocumentNumber' | translate" prop="DVSDocumentNumber" [width]="150"></ngx-datatable-column>
  <ngx-datatable-column [name]="'admissionOrders_lblOrderCount' | translate" prop="OrderCount" [width]="80"></ngx-datatable-column>
</ngx-datatable>
