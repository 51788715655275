import { BaseEntity } from './BaseEntity';
import { Classifier } from './Classifier';

export enum ApplicationResultType {
    In = 'In',
    InHigher = 'InHigher',
    InLower = 'InLower',
    InManual = 'InManual',
    Out = 'Out',
    OutManual = 'OutManual'
}

export class Program extends BaseEntity {
    Code: string;
    Title: string;
    Faculty: string;
    FacultyId: string;
    Financing: string;
    FinancingCode: string;
    FinancingId: string;
    Location: string;
    LocationId: string;
    Form: string;
    FormId: string;
    Language: string;
    LanguageId: string;
    Speciality: string;
    SpecialityId: string;
    Degree: string;
    PricePerYear: number;
    StartYear: number;
    StudyStartDate: Date;
    Quota: number;
    LengthInYears: number;
    Url: string;
    AllowProlongation: boolean;
    AllowAdmission: boolean;
    AllowApplicantToApply: boolean;
    NotificationCode: string;
    AdmissionDate: Date;
    AdmissionId: number;
    Specializations: ProgramSpecialization[];
    SelectedSpecialization: ProgramSpecialization;
    IsHomines: boolean;
    Universities: Classifier[];
    SelectedUniversity: Classifier;
    MatriculationYear: number;
}

export class ProgramResult {
    Id: number;
    ProgramId: number;
    ApplicationId: number;
    ApplicationNumber: string;
    ApplicationStatus: string;
    ApplicationIsIncomplete: boolean;
    ApplicationPersonVerified: boolean;
    ProgramApplicationId: number;
    Name: string;
    Surname: string;
    PersonCode: string;
    Phone: string;
    Email: string;
    Priority: number;
    ExamsFinished: boolean;
    WeightedResult?: number;
    Result: ApplicationResultType;
    Place?: number;
    NotificationCode: string;
    ContractStatus: string;
    EnrollmentStatus:string;
    StartYear?: number;
    StudiesAtLaterStages: boolean;
    IsForeign: boolean;
    SubmittedByRepresentative: boolean;
    IsLateSubmission: boolean;
    IsContractCreated: boolean;
    IsGraduate: boolean;
}

export class ProgramExam {
    Id: number;
    Code: string;
    ExamCodes: string;
    ProgramId: number;
    ProgramTitle: string;
    Date: Date;
    Description: string;
    Reusable: boolean;
    TypeId: string;
    Type: string;
    Url: string;
    Group: string;
    MinGrade: number;
    MaxGrade: number;
    SortOrder: number;
    Weight: number;
    IsDeciding: number;
    GradeRoundPositions: number;
}

export class ProgramApplicationStatus {
    ProgramApplicationId: number;
    ApplicationId: number;
    ContractStatus: string;
    ContractStatusId: number;
}

export class ProgramSpecialization {
    Id: number;
    SubProgrammCrmId: string;
    SubProgrammName: string;
    SpecializationCrmId: string;
    SpecializationName: string;
}

export class SignedAppsViewModel {
    appNumbers: string[];
}

export class Interviewer extends BaseEntity {
    ProgramId: number;
    SignatureName: string;
    ShortName: string;
    PersonCode: string;
}

export class InterviewPersonnel extends BaseEntity {
    ProgramId: number;
    UserIdentity: string;
    FullName: string;
    PersonCode: string;
}
