import { Component, OnInit, ViewChild } from '@angular/core';
import { AppService } from '../services/app.service';
import { QuizzService } from '../services//quizz.service';
import { Observable, Subject } from 'rxjs';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { ActivatedRoute, Router } from '@angular/router';
import { Classifier } from '../models/classifier';
import { ClassifierService } from '../services/classifier.service';

import { GridComponentBase } from '../GridComponentBase';
import { UserListItem, UserTypes, AccountTypes } from '../models/UserInfo';

@Component({
    selector: 'app-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.css']
})
export class UsersComponent extends GridComponentBase<UserListItem> implements OnInit {
    Universities: Classifier[]=[];
    UserTypes: any = UserTypes;
    AccountTypes: any = AccountTypes;
    constructor(public app: AppService, private classifierService: ClassifierService, private route: ActivatedRoute) {
        super(app);
    }
    @ViewChild(DatatableComponent, { static: true }) grid: DatatableComponent;

    now = new Date();

    filterAccountType: string;
    filterRole: string;

    searchValue: string;

    ngOnInit() {
        if (this.app.auth.isAuthenticated) {
            this.app.setWideLayout();
            this.loadItems();
            this.classifierService.get('University').subscribe(data => {
                this.Universities = data;
            });
        }
    }

    isValidGridItem(item: UserListItem): boolean {
        return !!(item.UserType);
    }

    protected updateGridItem(item: UserListItem): Observable<UserListItem> {
        return this.app.auth.update(item.Id,item);
    }

    protected deleteGridItem(item: UserListItem): Observable<boolean> {
        return this.app.auth.delete(item.Id);
    }

    protected createEmptyObject(): UserListItem {
        let item = new UserListItem();

        return item;
    }
    formatDate(dateValue: string) {
        let dateTime: Date = new Date(dateValue);
        let day = dateTime.getDay().toString();
        if (dateTime.getDay() < 10)
            day = '0' + day;
        let month = (dateTime.getMonth()+1).toString();
        if (dateTime.getMonth() < 9)
            month = '0' + month;
        let year = dateTime.getFullYear().toString();
        if (this.app.translate.currentLang == 'lv')
            return day + '.' + month + '.' + year;
        else
            return year + '-' + month + '-' + day;
    }
    getUniversity(id: string) {
        return this.Universities.find(u => u.Id == id);
    }
    getTranslatedValue(c: Classifier) {
        if (c) {
            if (this.app.translate.currentLang == 'en' && c.ValueEN)
                return c.ValueEN;
            else
                return c.Value;
        }
        else
            return '';
    }
    filter() {
        this.loadItems();
    }

    private loadItems() {
        let loading = this.app.showLoading();
        this.app.auth.findActiveUsers({
            UserType: this.filterRole || '',
            AccountType: this.filterAccountType || '',
            Search: this.searchValue || ''
        }).subscribe(data => {
            this.app.hideLoading(loading);
            this.gridItems = data;
            ///this.tempItems = [...data];
        }, err => this.app.showLoadError(err));
    }
}
