<app-program-content-wrapper [program]="program" [breadcrumbTitle]="admissionTitle"
    [formTitle]="'programInterviews_title' | translate">
    <ng-template let-program="program">

        <form class="filter-form-sm">
            <div style="display: flex; justify-content: space-between; align-items: center;">
                <div style="flex-basis: 150px; margin-right: 20px;">
                    <mat-form-field>
                        <mat-select [placeholder]="'programInterviews_lblDate' | translate" [(ngModel)]="filter.Date"
                            name="filterDate" (selectionChange)="applyFilter()">
                            <mat-option [value]="null"></mat-option>
                            <mat-option [value]="'-'">{{'optionNone' | translate}}</mat-option>
                            <mat-option *ngFor="let d of scheduledDates" [value]="d">{{ d | appDate }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div style="flex-basis: 250px;">
                    <mat-form-field>
                        <mat-select [placeholder]="'programInterviews_lblRoom' | translate" [(ngModel)]="filter.RoomId"
                            name="filterRoom" (selectionChange)="applyFilter()">
                            <mat-option [value]="null"></mat-option>
                            <mat-option [value]="'-'">{{'optionNone' | translate}}</mat-option>
                            <mat-option *ngFor="let sr of scheduledRooms" [value]="sr.Id">{{ sr.Text }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div style="flex-grow: 1;"></div>
                <button type="button" class="btn-inline" mat-raised-button color="primary" (click)="refreshInterviews()">
                    <i class="fa fa-refresh"></i> {{'refresh' | translate }}
                </button>
            </div>
        </form>

        <ngx-datatable class="tight app-scrollable-grid"
        [rows]="gridItems"
            [headerHeight]="30"
            [footerHeight]="50"
            [rowHeight]="30"
            [columnMode]="'force'"
            [scrollbarH]="true"
            [scrollbarV]="true"
            [externalSorting]="true"
            [loadingIndicator]="gridLoading"
            [messages]="{ emptyMessage: 'gridEmpty' | translate, totalMessage: 'gridTotal' | translate }"
            (sort)="onGridSort($event)">
            <ngx-datatable-column cellClass="check-cell" headerClass="check-cell" [width]="32" [minWidth]="32" [maxWidth]="32"
                [resizeable]="false" [canAutoResize]="false" [draggable]="false" [sortable]="false">
                <ng-template ngx-datatable-header-template>
                    <mat-checkbox (change)="selectRows($event)" value="true" [checked]="allRowsSelected()">
                    </mat-checkbox>
                </ng-template>
                <ng-template ngx-datatable-cell-template let-row="row">
                    <mat-checkbox [value]="row.Id" (change)="selectRow(row)" [checked]="rowSelected(row)">
                    </mat-checkbox>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column prop="ApplicationNumber" [name]="'programResults_thApplicationId' | translate" [width]="90" [minWidth]="90" [maxWidth]="90">
                <ng-template ngx-datatable-cell-template let-row="row">
                    <a [routerLink]="['/applications', row.ApplicationNumber] | localize">{{row.ApplicationNumber}}</a>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column prop="Name" [name]="'programResults_lblName' | translate" [width]="150"></ngx-datatable-column>
            <ngx-datatable-column prop="Surname" [name]="'programResults_lblSurname' | translate" [width]="150"></ngx-datatable-column>
            <ngx-datatable-column [name]="' '" [width]="24" [minWidth]="24" [maxWidth]="24"
                [resizeable]="false" [canAutoResize]="false" [sortable]="false">
                <ng-template ngx-datatable-cell-template let-row="row">
                    <i class="fa fa-info-circle icon-btn" (click)="openContacts(row)"></i>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column prop="PersonCode" [name]="'programResults_lblPersonCode' | translate" [width]="125" [minWidth]="125" [maxWidth]="125"></ngx-datatable-column>
            <ngx-datatable-column prop="RoomName" [name]="'programInterviews_lblRoom' | translate" [minWidth]="60" [width]="100"></ngx-datatable-column>
            <ngx-datatable-column prop="InterviewOnlineUrl" [name]="'programInterviews_lblUrl' | translate" [width]="50" [minWidth]="32" [maxWidth]="50">
                <ng-template ngx-datatable-cell-template let-row="row">
                    <a href="{{row.InterviewOnlineUrl}}" target="_blank"
                        [title]="row.InterviewOnlineUrl" *ngIf="row.InterviewOnlineUrl">
                        <i class="fa fa-external-link"></i>
                    </a>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [name]="'programInterviews_lblDate' | translate" prop="InterviewTime" [width]="90" [minWidth]="90" [maxWidth]="90">
                <ng-template ngx-datatable-cell-template let-value="value">
                    {{ value | appDate }}
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [name]="'programInterviews_lblTime' | translate" prop="InterviewTime" [width]="50" [minWidth]="50" [maxWidth]="50">
                <ng-template ngx-datatable-cell-template let-value="value">
                    {{ value | appTime }}
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [name]="' '" [width]="24" [minWidth]="24" [maxWidth]="24" [resizeable]="false" [canAutoResize]="false" [sortable]="false">
                <ng-template ngx-datatable-cell-template let-row="row">
                    <a *ngIf="row.canReschedule && (row.InterviewTime && row.InterviewTime > today)" [routerLink]="[]" (click)="pickTime(row, timepicker)">
                        <i class="fa fa-pencil"></i>
                    </a>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [name]="' '" [width]="24" [minWidth]="24" [maxWidth]="24" [resizeable]="false" [canAutoResize]="false" [sortable]="false">
                <ng-template ngx-datatable-cell-template let-row="row">
                    <a *ngIf="row.ScheduleConflicts.length" [routerLink]="[]" (click)="openConflicts(row.ScheduleConflicts)">
                        <i class="fa fa-exclamation-triangle" style="color:orange;"></i>
                    </a>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [name]="'programInterviews_lblAbsent' | translate" [width]="32" [minWidth]="32" [maxWidth]="32">
                <ng-template ngx-datatable-cell-template let-row="row">
                    <i class="fa fa-check" *ngIf="row.isAbsent"></i>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column *ngFor="let kv of interviewers | keyvalue" [name]="kv.value" [width]="60" [minWidth]="60" [maxWidth]="90" [sortable]="false" headerClass="text-align-center" cellClass="text-align-center">
                <ng-template ngx-datatable-cell-template let-row="row">
                    <a [routerLink]="['/interviews', row.ApplicationExamId, kv.key] | localize">
                        {{interviewStatusText(row.InterviewResults[kv.key])}}
                    </a>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column prop="AverageGrade" [name]="'programInterviews_lblAverageGrade' | translate" [width]="50" [minWidth]="50" [maxWidth]="100"></ngx-datatable-column>
            <ngx-datatable-column [name]="'programInterviews_lblNotification' | translate" [width]="150">
                <ng-template ngx-datatable-cell-template let-row="row">
                    <i *ngIf="row.InvitationMessageCode" class="fa fa-external-link icon-btn"
                        (click)="openNotification(row.ApplicationId, row.ApplicationExamId)"></i>
                    <span style="font-size: smaller;">{{row.InvitationMessageCode}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-footer>
                <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                    let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset">
                    {{'programInterviews_lblTotalCount' | translate}}: <strong>{{rowCount || 0}}</strong>
                </ng-template>
            </ngx-datatable-footer>
        </ngx-datatable>
        <ngx-material-timepicker #timepicker [ESC]="true" [enableKeyboardInput]="true" [format]="24"
            (timeSet)="timePicked($event)"></ngx-material-timepicker>
    </ng-template>
</app-program-content-wrapper>

<ng-container *ngIf="isPlanner">
        <form #planningForm="ngForm" class="block form-in form-inline">
            <header class="block-header">
                <h3 [translate]="'programInterviews_planningActions'"></h3>
            </header>

            <div class="block-body">
                <mat-form-field>
                    <mat-select [placeholder]="'programInterviews_lblPlanningAction' | translate"
                        [(ngModel)]="planningOpts.action" name="planningAction" required>
                        <mat-option *ngFor="let a of planningActions" [value]="a">
                            {{'programInterviews_planningAction_' + a | translate}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field>
                    <mat-select [placeholder]="'programInterviews_lblPlanningTarget' | translate"
                        [(ngModel)]="planningOpts.target" name="planningTarget" required>
                        <mat-option *ngFor="let t of planningTargets" [value]="t">
                            {{'programInterviews_planningTarget_' + t | translate}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field *ngIf="planningOpts.action === 'Set'">
                    <input matInput [(ngModel)]="planningOpts.url" name="planningUrl" [placeholder]="'programInterviews_lblUrl' | translate" required />
                </mat-form-field>
                <mat-form-field *ngIf="planningOpts.action === 'Set'">
                    <input matInput [matDatepicker]="pickerPlanningDate" [(ngModel)]="planningOpts.date"
                        name="planningDate" [placeholder]="'programInterviews_lblDate' | translate" required
                        [min]="today" [max]="env.maxDate" [title]="'dateFormatHint' | translate">
                    <mat-datepicker-toggle matSuffix [for]="pickerPlanningDate"></mat-datepicker-toggle>
                    <mat-datepicker #pickerPlanningDate></mat-datepicker>
                </mat-form-field>
                <mat-form-field *ngIf="planningOpts.action === 'Set'">
                    <mat-select [placeholder]="'programInterviews_lblRoom' | translate"
                        [(ngModel)]="planningOpts.roomId" name="planningRoom" required>
                        <mat-option *ngFor="let a of rooms | keyvalue:compareValues" [value]="a.key">
                            {{a.value}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <button type="button" class="btn-inline" mat-raised-button color="primary" (click)="updateSchedules()"
                    [disabled]="!planningForm.valid || !gridItems.length || planningOpts.target === PlanningTarget[PlanningTarget.Selected] && !selectedRows.length">
                    {{'ok' | translate}}
                </button>
            </div>
        </form>
    <div class="cols">
        <div class="col-7">
            <form #notificationForm="ngForm" class="block form-in form-inline">
                <header class="block-header">
                    <h3 [translate]="'programInterviews_lblCreateOrDeleteEmails'" class="form-title"></h3>
                </header>
                
                <div class="block-body">               
                    <mat-form-field style="min-width: 250px">
                    <mat-select matInput [placeholder]="'programInterviews_lblNotificationTarget' | translate"
                    name="notificationTarget" required [(ngModel)]="notificationOpts.target">
                    <mat-option *ngFor="let t of notificationTargets" [value]="t">
                            {{'programInterviews_notificationTarget_' + t | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                
                <mat-form-field style="min-width: 250px">
                    <input matInput [placeholder]="'programInterviews_lblNotificationCode' | translate" [(ngModel)]="notificationOpts.code"
                    name="notificationCode" required #notifCode
                    [matAutocomplete]="notifCodeAuto.bind(notifCode)"  />
                    <app-message-autocomplete #notifCodeAuto></app-message-autocomplete>
                </mat-form-field>
                
                <button type="submit" class="btn-inline" mat-raised-button color="primary"
                    (click)="createOrDeleteNotification()" [disabled]="!notificationForm.valid" >
                    {{'programInterviews_lblSendEmail' | translate}}
                </button>                
                </div>
            </form>
        </div>
    </div>
    
</ng-container>
