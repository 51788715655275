import { Component, OnInit, Input } from '@angular/core';
import { AppService } from '../../../services/app.service';
import { QuizzService } from '../../../services//quizz.service';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
//import { Moment } from 'moment';
//import { Moment } from '../../../moment';

import { GridComponentBase } from '../../../GridComponentBase';
import { Quizz, QuizzInterval } from '../../../models/Quizz';

@Component({
  selector: 'app-intervals',
  templateUrl: './intervals.component.html',
  styleUrls: ['./intervals.component.css']
})
export class IntervalsComponent extends GridComponentBase<QuizzInterval> implements OnInit {

    constructor(public app: AppService, private service: QuizzService) {
        super(app);
    }
    //quizzid: number;
    private _quizz: Quizz;
    get quizz(): Quizz {
        return this._quizz;
    }
    @Input()
    set quizz(quizz: Quizz) {
        if (quizz && quizz.Id) {
            this._quizz = quizz;
            let loading = this.app.showLoading();
            this.service.getIntervals(this.quizz.Id).subscribe(data => {
                this.data = data;
                this.gridItems = this.getFilteredData(true);
                this.app.hideLoading(loading);
            }, err => this.app.showLoadError(err));
        }
        else
            this._quizz = new Quizz();
    }
    showOnlyActual: boolean = true;
    private data: QuizzInterval[];
    getFilteredData(onlyactual:boolean): QuizzInterval[] {
        if (this.data && onlyactual)
            //let filtered = this.data;
            return this.data.filter(i => {
                if (!i.Id)
                    return true;
                let now = new Date(Date.now());
                let to = this.getDateTime(new Date(i.DateTo), i.TimeTo)
                return to >= now;
            })
        else
            return this.data;
    }
    ngOnInit() {
        
    }

    isValidGridItem(item: QuizzInterval): boolean {
        let notEmpty = !!(item.DateFrom && item.TimeFrom && item.TimeFrom.indexOf(':') > -1 && item.DateTo && item.TimeTo && item.TimeTo.indexOf(':') > -1);
        let isValid = notEmpty;
        if (notEmpty)
            isValid = this.getDateTime(item.DateTo, item.TimeTo) > this.getDateTime(item.DateFrom, item.TimeFrom);
        return isValid;
    }
    protected getDateTime(dateOnly: any, time: string): Date {
        let timeHours = time.split(':')[0];
        if (timeHours.startsWith('0'))
            timeHours = timeHours.substring(1);
        let timeMinutes = time.split(':')[1];
        if (timeMinutes.startsWith('0'))
            timeMinutes = timeMinutes.substring(1);
        if (dateOnly.year)
            return new Date(dateOnly.year(), dateOnly.month(), dateOnly.date(), parseInt(timeHours), parseInt(timeMinutes));
        else if (dateOnly.getFullYear)
            return new Date(dateOnly.getFullYear(), dateOnly.getMonth(), dateOnly.getDate(), parseInt(timeHours), parseInt(timeMinutes));
        else {
            let datecast = new Date(dateOnly);
            return new Date(datecast.getFullYear(), datecast.getMonth(), datecast.getDate(), parseInt(timeHours), parseInt(timeMinutes));
        }
    }
    protected createGridItem(item: QuizzInterval): Observable<QuizzInterval> {
        return this.service.addInterval(this.quizz.Id, this.getDateTime(item.DateFrom, item.TimeFrom), this.getDateTime(item.DateTo, item.TimeTo));
    }

    protected updateGridItem(item: QuizzInterval): Observable<QuizzInterval> {
        return this.service.updateInterval(this.quizz.Id, item.Id, this.getDateTime(item.DateFrom, item.TimeFrom), this.getDateTime(item.DateTo, item.TimeTo));
    }

    protected deleteGridItem(item: QuizzInterval): Observable<boolean> {
        return this.service.deleteInterval(this.quizz.Id,item.Id);
    }

    protected createEmptyObject(): QuizzInterval {
        let item = new QuizzInterval();
        item.DateFrom = new Date(Date.now());
        item.TimeFrom = '00:00';
        item.DateTo = new Date(Date.now());
        item.TimeTo = '23:00';
        return item;
    }
    setShowOnlyActual(event: any) {
        let missing = this.gridItems.filter(d => {
            return (!this.data.find(i => i.Id == d.Id))
        })
        if (missing) {
            for (let i = 0; i < missing.length; i++) {
                this.data.push(missing[i]);
            }
        }
        this.gridItems = this.getFilteredData(event.checked);
    }
    setIsAvailableInIntervalsOnly(event: any) {
        let loading = this.app.showLoading();
        this.service.enableIntervals(this.quizz.Id, event.value).subscribe(r => {
            this.app.hideLoading(loading);
        }, err => this.app.showLoadError(err));
    }
}
