<header class="content-header">
    <nav class="breadcrumb">
        <a [routerLink]="['/applications', item.Number] | localize" class="bc-back">
            {{'applications_edit_titlePrefix' | translate}}
            "{{item.AdmissionTitle}}", {{item.Number}}
        </a>
    </nav>
</header>

<section class="block">
    <header class="block-header">
        <h1 [translate]="'applications_personData'"></h1>
    </header>

    <div class="block-body">
        <div class="cols">
            <div class="col-2">
                <div class="photo-container">
                    <img [src]="photoUrl" *ngIf="item.Id && photoUrl" />
                    <img src="/assets/img/noprofile.png" *ngIf="item.Id && !photoUrl" />
                </div>
            </div>
            <div class="col-9">
                <div class="cols">
                    <div class="col-4">
                        <app-field-display [label]="'applications_lblName' | translate" [value]="item.Name"></app-field-display>
                    </div>

                    <div class="col-4">
                        <app-field-display [label]="'applications_lblSurname' | translate" [value]="item.Surname"></app-field-display>
                    </div>

                    <div class="col-4">
                        <app-field-display [label]="'applications_lblGender' | translate" [value]="item.Gender"></app-field-display>
                    </div>
                </div>
                <div class="cols">
                    <div class="col-4">
                        <app-field-display [label]="'applications_lblBirthdate' | translate" [value]="item.Birthdate | appDate"></app-field-display>
                    </div>

                    <div class="col-4">
                        <app-field-display [label]="'applications_lblPersonCode' | translate" [value]="item.PersonCode"></app-field-display>
                    </div>

                    <div class="col-4">
                        <app-field-display [label]="'applications_lblBirthPlace' | translate" [value]="item.DocBirthPlace"></app-field-display>
                    </div>
                </div>
                <div class="cols">
                    <div class="col-4">
                        <app-field-display [label]="'applications_lblPhone' | translate" [value]="item.Phone"></app-field-display>
                    </div>

                    <div class="col-4">
                        <app-field-display [label]="'applications_lblEmail' | translate" [value]="item.Email"></app-field-display>
                    </div>

                    <div class="col-4" *ngIf="!item.IsForeign">
                        <mat-checkbox [disabled]="true" [checked]="item.SubmittedByRepresentative"><span style="white-space: normal;">{{ 'applications_personSubmittedByRepresentative' | translate }}</span></mat-checkbox>
                    </div>
                </div>
                <ng-container  *ngIf="item.SubmittedByRepresentative">
                    <div class="cols">
                        <div class="col-12">
                            {{'applications_lblpersonSubmitterData' | translate}}
                        </div>
                    </div>
                    <div class="cols">
                        <div class="col-4">
                            <app-field-display [label]="'applications_personRepresentativeName' | translate" [value]="item.RepresentativeName"></app-field-display>
                        </div>

                        <div class="col-4">
                            <app-field-display [label]="'applications_personRepresentativeSurname' | translate" [value]="item.RepresentativeSurname"></app-field-display>
                        </div>

                        <div class="col-4">
                            <app-field-display [label]="'applications_personRepresentativePersonCode' | translate" [value]="item.RepresentativePersonCode"></app-field-display>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</section>

<section class="block" >
    <header class="block-header">
        <h3>{{'applications_personDocument' | translate}}</h3>
    </header>

    <div class="block-body">
        <div class="cols" *ngFor="let doc of personDocuments">
            <div class="col-2" *ngIf="item.IsForeign">
                <app-field-display [label]="'applications_lblCitizenship' | translate" [value]="doc.Citizenship"></app-field-display>
            </div>
            <div class="col-2" *ngIf="!item.IsForeign">
                <app-field-display [label]="'applications_lblDocType' | translate" [value]="'applications_docType_' + doc.Type | translate"></app-field-display>
            </div>
            <div class="col-2">
                <app-field-display [label]="'applications_lblDocNumber' | translate" [value]="doc.Number"></app-field-display>
            </div>
            <div class="col-2">
                <app-field-display [label]="'applications_lblDocDate' | translate" [value]="doc.Date | appDate"></app-field-display>
            </div>
            <div class="col-2">
                <app-field-display [label]="'applications_lblDocExpiry' | translate" [value]="doc.Expiry | appDate"></app-field-display>
            </div>
            <div class="col-3">
                <app-field-display [label]="'applications_lblDocIssuer' | translate" [value]="doc.Issuer"></app-field-display>
            </div>
        </div>

        <ng-container *ngIf="item.IsForeign">
            <div class="help-block">
                {{'applications_otherDocsHint' | translate}}
            </div>
            <app-field-display [label]="'applications_lblOtherDocs' | translate" [value]="aux.OtherDocs"></app-field-display>
        </ng-container>
    </div>
</section>

<section class="block">
    <header class="block-header">
        <h3>{{'applications_declaredAddress' | translate}}</h3>
    </header>

    <div class="block-body">
        <div class="cols">
            <div class="col-4">
                <app-field-display [label]="'applications_lblCountry' | translate" [value]="item.AddrCountry"></app-field-display>
            </div>
        </div>

        <hr />

        <div class="cols">
            <div class="col-4">
                <app-field-display [label]="'applications_lblCounty' | translate" [value]="item.AddrCounty"></app-field-display>
                <app-field-display [label]="'applications_lblCity' | translate" [value]="item.AddrCity"></app-field-display>
                <app-field-display [label]="'applications_lblParish' | translate" [value]="item.AddrParish"></app-field-display>
                <app-field-display [label]="'applications_lblVillage' | translate" [value]="item.AddrVillage"></app-field-display>
            </div>

            <div class="col-4">
                <app-field-display [label]="'applications_lblStreet' | translate" [value]="item.AddrStreet"></app-field-display>
                <app-field-display [label]="'applications_lblHouse' | translate" [value]="item.AddrHouse"></app-field-display>
                <app-field-display [label]="'applications_lblApartment' | translate" [value]="item.AddrApartment"></app-field-display>
                <app-field-display [label]="'applications_lblPostalCode' | translate" [value]="item.AddrIndex"></app-field-display>
            </div>
        </div>
    </div>
</section>

<section class="block" *ngIf="!item.IsForeign">
    <header class="block-header">
        <h3>{{'applications_contactAddress' | translate}}</h3>
    </header>

    <div *ngIf="item.ContIsSameAsAddr" class="block-body">
        {{ 'applications_contactAddressIsSameAsDeclaredAddress' | translate }}
    </div>
    <div *ngIf="!item.ContIsSameAsAddr" class="block-body">
        <div class="cols">
            <div class="col-4">
                <app-field-display [label]="'applications_lblCountry' | translate" [value]="item.ContCountry"></app-field-display>
            </div>
        </div>

        <hr />

        <div class="cols">
            <div class="col-4">
                <app-field-display [label]="'applications_lblCounty' | translate" [value]="item.ContCounty"></app-field-display>
                <app-field-display [label]="'applications_lblCity' | translate" [value]="item.ContCity"></app-field-display>
                <app-field-display [label]="'applications_lblParish' | translate" [value]="item.ContParish"></app-field-display>
                <app-field-display [label]="'applications_lblVillage' | translate" [value]="item.ContVillage"></app-field-display>
            </div>

            <div class="col-4">
                <app-field-display [label]="'applications_lblStreet' | translate" [value]="item.ContStreet"></app-field-display>
                <app-field-display [label]="'applications_lblHouse' | translate" [value]="item.ContHouse"></app-field-display>
                <app-field-display [label]="'applications_lblApartment' | translate" [value]="item.ContApartment"></app-field-display>
                <app-field-display [label]="'applications_lblPostalCode' | translate" [value]="item.ContIndex"></app-field-display>
            </div>
        </div>
    </div>
</section>

<section class="block" *ngIf="item.IsForeign">
    <header class="block-header">
        <h3 [translate]="'applications_languageSkills'"></h3>
    </header>

    <div class="block-body">
        <div class="cols input-row" *ngFor="let n of aux.Languages; let i = index">
            <div class="col-3">
                <app-field-display [label]="'applications_lblLang' | translate" [value]="n.Language"></app-field-display>
            </div>
            <div class="col-3">
                <app-field-display [label]="'applications_lblLangLevel' | translate" [value]="'applications_langLevel_' + n.Level | translate"></app-field-display>
            </div>
            <div class="col-3">
                <app-field-display [label]="'applications_lblLangCert' | translate" [value]="n.Cert"></app-field-display>
            </div>
            <div class="col-2" *ngIf="n.Cert">
                <app-field-display [label]="'applications_lblLangScore' | translate" [value]="n.Score"></app-field-display>
            </div>
        </div>
    </div>
</section>

<section class="block">
    <header class="block-header">
        <h3>{{'applications_eduInstitutions' | translate}}</h3>
    </header>

    <div class="block-body">
        <div class="input-row" *ngFor="let n of educations; let i = index">
            <div class="cols">
                <div class="col-6">
                    <app-field-display [label]="'applications_lblEduInstitution' | translate" [value]="n.Institution"></app-field-display>
                </div>
                <div class="col-2">
                    <app-field-display [label]="'applications_lblEduYearTo' | translate" [value]="n.YearTo"></app-field-display>
                </div>
            </div>
            <div class="cols">
                <div class="col-6">
                    <app-field-display [label]="'applications_lblEduLevel' | translate" [value]="n.Level"></app-field-display>
                </div>
                <div class="col-2" *ngIf="!item.IsForeign">
                    <app-field-display [label]="'applications_lblEduDiplomaNumber' | translate" [value]="n.DiplomaNumber"></app-field-display>
                </div>
                <div class="col-4" *ngIf="isEditor">
                    <mat-checkbox [checked]="n.IsSelected" name="eduIsSeleclted_{{i}}"
                                    [value]="true" class="mat-align-bottom" disabled>
                        {{'applications_lblEduIsSelected' | translate}}
                    </mat-checkbox>
                </div>
            </div>
        </div>
    </div>
</section>

<app-application-notes *ngIf="item.Id"
                       [applicationId]="item.Id"
                       [position]="'PersonData'"
                       [single]="true">
</app-application-notes>
