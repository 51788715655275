import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { Quizz, QuizzGroup, QuizzQuestion, QuizzAnswer, QuizzInterval } from '../models/Quizz';
import { environment as ENV } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class QuizzService {

    constructor(public http: HttpClient) { }

    public get apiUrl(): string { return `${ENV.apiUrl}/quizzes` }
    public get apiGroupUrl(): string { return `${ENV.apiUrl}/quizzgroups` }
    public get apiQuestionUrl(): string { return `${ENV.apiUrl}/quizzquestions` }
    public get apiAnswerUrl(): string { return `${ENV.apiUrl}/quizzanswers` }

    private cache: { [key: string]: any } = {};

    get(): Observable<Quizz[]> {
      if (this.cache['getQuizzes'])
          return of(this.cache['getQuizzes']);

        return this.http.get<Quizz[]>(this.apiUrl).pipe(map(res => {
            this.cache['getQuizzes'] = res;
            return res;
      }));
    }

    getById(Id:number): Observable<Quizz> {
        if (this.cache['getQuizzById_' + Id.toString()])
            return of(this.cache['getQuizzById_' + Id.toString()]);

        return this.http.get<Quizz>(this.apiUrl + '/' + Id.toString()).pipe(map(res => {
            this.cache['getQuizzById_' + Id.toString()] = res;
            return res;
        }));
    }

    getGroupById(Id: number): Observable<QuizzGroup> {
        if (this.cache['getGroupById_' + Id.toString()])
            return of(this.cache['getGroupById_' + Id.toString()]);

        return this.http.get<QuizzGroup>(this.apiGroupUrl + '/' + Id.toString()).pipe(map(res => {
            this.cache['getGroupById_' + Id.toString()] = res;
            return res;
        }));
    }

    getQuestionById(Id: number): Observable<QuizzQuestion> {
        if (this.cache['getQuestionById_' + Id.toString()])
            return of(this.cache['getQuestionById_' + Id.toString()]);

        return this.http.get<QuizzQuestion>(this.apiQuestionUrl + '/' + Id.toString()).pipe(map(res => {
            this.cache['getQuestionById_' + Id.toString()] = res;
            return res;
        }));
    }

    getIntervals(quizzid: number): Observable<QuizzInterval[]> {
        if (this.cache['getIntervals_' + quizzid.toString()])
            return of(this.cache['getIntervals_' + quizzid.toString()]);

        return this.http.get<QuizzInterval[]>(`${this.apiUrl}/${quizzid}/intervals`).pipe(map(res => {
            this.cache['getIntervals_' + quizzid.toString()] = res;
            return res;
        }));
    }

    add(quizz: Quizz): Observable<Quizz> {
        this.clearCache();
        return this.http.post<Quizz>(this.apiUrl, quizz);
    }

    addGroup(quizzId: number,group: QuizzGroup): Observable<QuizzGroup> {
        this.clearCache();
        return this.http.post<QuizzGroup>(this.apiUrl + '/' + quizzId.toString() + '/groups', group);
    }

    addQuestion(quizzGroupId: number, question: QuizzQuestion): Observable<QuizzQuestion> {
        this.clearCache();
        return this.http.post<QuizzQuestion>(this.apiGroupUrl + '/' + quizzGroupId.toString() + '/questions', question);
    }

    addAnswer(quizzQuestionId: number, answer: QuizzAnswer): Observable<QuizzAnswer> {
        this.clearCache();
        return this.http.post<QuizzAnswer>(this.apiQuestionUrl + '/' + quizzQuestionId.toString() + '/answers', answer);
    }

    addInterval(quizzid:number,dateTimeFrom: Date,dateTimeTo:Date): Observable<QuizzInterval> {
        this.clearCache();
        return this.http.post<QuizzInterval>(`${this.apiUrl}/${quizzid}/intervals`, { DateTimeFrom: dateTimeFrom, DateTimeTo: dateTimeTo});
    }

    update(quizz: Quizz): Observable<Quizz> {
        this.clearCache();
        return this.http.put<Quizz>(this.apiUrl + '/' + quizz.Id, quizz);
    }

    updateGroup(group: QuizzGroup): Observable<QuizzGroup> {
        this.clearCache();
        return this.http.put<QuizzGroup>(this.apiGroupUrl + '/' +group.Id.toString(), group);
    }

    updateQuestion(question: QuizzQuestion): Observable<QuizzQuestion> {
        this.clearCache();
        return this.http.put<QuizzQuestion>(this.apiQuestionUrl + '/' + question.Id.toString(), question);
    }

    updateAnswer(answer: QuizzAnswer): Observable<QuizzAnswer> {
        this.clearCache();
        return this.http.put<QuizzAnswer>(this.apiAnswerUrl + '/' + answer.Id.toString(), answer);
    }

    updateInterval(quizzid: number, intervalId: number,dateTimeFrom: Date, dateTimeTo: Date): Observable<QuizzInterval> {
        this.clearCache();

        const url = `${this.apiUrl}/${quizzid}/intervals/${intervalId}`;
        const payload = { Id: intervalId, DateTimeFrom: dateTimeFrom, DateTimeTo: dateTimeTo };

        return this.http.put<QuizzInterval>(url, payload);
    }

    enableIntervals(quizzid: number, EnableInIntervalsOnly:boolean): Observable<QuizzInterval> {
        this.clearCache();
        return this.http.put<QuizzInterval>(`${this.apiUrl}/${quizzid}/enableintervals`, { EnableInIntervalsOnly: EnableInIntervalsOnly});
    }

    delete(id: number): Observable<boolean> {
        this.clearCache();
        return this.http.delete(this.apiUrl + '/' + id.toString()).pipe(map((res: Response) => {
            return res.ok;
        }));
    }

    deleteGroup(id: number): Observable<boolean> {
        this.clearCache();
        return this.http.delete(this.apiGroupUrl + '/'  + id.toString()).pipe(map((res: Response) => {
            return res.ok;
        }));
    }

    deleteQuestion(id: number): Observable<boolean> {
        this.clearCache();
        return this.http.delete(this.apiQuestionUrl + '/' + id.toString()).pipe(map((res: Response) => {
            return res.ok;
        }));
    }

    deleteAnswer(id: number): Observable<boolean> {
        this.clearCache();
        return this.http.delete(this.apiAnswerUrl + '/' + id.toString()).pipe(map((res: Response) => {
            return res.ok;
        }));
    }

    deleteInterval(quizzid:number,id: number): Observable<boolean> {
        this.clearCache();
        return this.http.delete(`${this.apiUrl}/${quizzid}/intervals/${id}`).pipe(map((res: Response) => {
            return res.ok;
        }));
    }

  public clearCache() {
      this.cache = {};
  }
}
