<header class="content-header">
    <nav class="breadcrumb">
        <a *ngIf="applicationExamId" [routerLink]="['/interviews', applicationExamId, interviewerId] | localize" class="bc-back">
            {{'interview_heading' | translate}}
        </a>
    </nav>
    <section class="block" *ngIf="gridItems">
        <header class="block-header">
            <h1 [translate]="'modif_title_Interview'"></h1>
        </header>
        <div style="display: flex; justify-content: space-between; margin-bottom: 2px;">
            <div>
                {{ 'interview_lblApplication' | translate }}: <strong>{{ applicationNumber }}</strong>
            </div>
            <div>
                {{ 'interview_lblExam' | translate }}: <strong>{{ examTitle }}</strong>
            </div>
            <div>
                {{ 'interview_lblInterviewer' | translate }}: <strong>{{ interviewer }}</strong>
            </div>
        </div>
    </section>
</header>

<ngx-datatable #grid
               class="tight app-scrollable-grid"
               [rows]="gridItems"
               [headerHeight]="30"
               [footerHeight]="50"
               [rowHeight]="30"
               [scrollbarV]="true"
               [columnMode]="'flex'"
               [externalSorting]="true"
               [loadingIndicator]="gridLoading"
               [messages]="{ emptyMessage: 'gridEmpty' | translate, totalMessage: 'gridTotal' | translate }"
               (sort)="onGridSort($event)">

    <ngx-datatable-column [width]="20" [flexGrow]="0"
                          [resizeable]="false"
                          [sortable]="false"
                          [draggable]="false"
                          [canAutoResize]="false">
        <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
            <a href="javascript:void(0)"
               [class.datatable-icon-right]="!expanded"
               [class.datatable-icon-down]="expanded"
               (click)="grid.rowDetail.toggleExpandRow(row)">
            </a>
        </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column [name]="'modif_lblModified' | translate" prop="Modified" [flexGrow]="1">
        <ng-template ngx-datatable-cell-template let-row="row">
            {{row.Modified | appDate:'+'}}
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [name]="'modif_lblAction' | translate" prop="ModifAction" [flexGrow]="1">
        <ng-template ngx-datatable-cell-template let-row="row">
            {{'modif_action_' + row.ModifAction | translate}}
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [name]="'modif_lblUser' | translate" prop="ModifiedByName" [flexGrow]="1"></ngx-datatable-column>

    <ngx-datatable-column [name]="'interviews_lblStatus' | translate" prop="Status" [flexGrow]="1"></ngx-datatable-column>
    <ngx-datatable-column [name]="'interview_lblTotal' | translate" prop="TotalGrade" [flexGrow]="1"></ngx-datatable-column>

    <ngx-datatable-row-detail [rowHeight]="getDetailsHeight" #detailRow>
        <ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
            <ng-container *ngFor="let q of row.Grades | keyvalue">
                <div><label>{{ q.key }}</label> {{ q.value }}</div>
            </ng-container>
        </ng-template>
    </ngx-datatable-row-detail>

</ngx-datatable>
