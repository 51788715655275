<header class="content-header">
    <h1 [translate]="'applications_title'"></h1>
</header>

<section class="block" *ngIf="isApplicant">
    <header class="block-header">
        <h2 [translate]="'applications_currentApps'"></h2>
    </header>
    <div class="block-body">
        <div class="alert alert-info" *ngIf="currentAppsDone && currentAppsNoItems && !currentApps.length" [innerHTML]="currentAppsNoItems | safeHtml"></div>
        <ul class="block-list" *ngIf="currentApps.length">
            <li *ngFor="let item of currentApps" class="item-{{item.Id}}">
                <div class="cols">
                    <div class="col-8 title-col">
                        <div>
                            <a [routerLink]="['/applications', item.Number] | localize">{{item.Title}}</a>
                            <sup *ngIf="item.Result"
                                 [class.text-success]="item.Result === 'In'"
                                 [class.text-danger]="item.Result === 'Out'">{{'applicationResultType_' + item.Result | translate}}</sup>
                            <sup class="badge sign" *ngIf="signRequired && item.Status === 'Approved' && !item.isHomines">
                                {{(item.IsSigned ? 'applications_signed' : 'applications_notSigned') | translate}}
                            </sup>
                            <sup class="badge status-IsLateSubmission" *ngIf="item.isLateSubmission && !item.isForeign">
                                {{'applicationStatus_IsLateSubmission' | translate}}
                            </sup>
                        </div>
                        <div *ngIf="item.Note" [innerHTML]="item.Note | safeHtml" class="alert"
                             [class.alert-success]="item.Result === 'In'"
                             [class.alert-danger]="item.Result === 'Out'"></div>
                    </div>
                    <div class="col-4 text-right">
                        <a mat-raised-button color="primary" [href]="quizzRedirectUrl" *ngIf="item.isHomines && (item.Status=='Approved' || item.Status=='Finished')">
                            {{'quizz_openlink' | translate}}
                        </a>
                        <a mat-raised-button color="primary" [routerLink]="['/applications', item.Number] | localize" [queryParams]="{ returnTo: 'applications' }">
                            {{'open' | translate}}
                        </a>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</section>

<section class="block">
    <header class="block-header">
        <h2 [translate]="isHomines ? 'applications_applyForHomines' : (isApplicant ? 'applications_applyForStudy' : 'applications_createApplication')"></h2>
    </header>
    <div class="block-body">
        <div class="alert alert-info" *ngIf="admissionsDone && admissionsNoItems && !availableAdmissions.length" [innerHTML]="admissionsNoItems | safeHtml"></div>
        <ul class="block-list">
            <li *ngFor="let item of availableAdmissions">
                <div class="cols">
                    <div class="col-10">{{item.Title}}</div>
                    <div class="col-2">
                        <button type="button" mat-raised-button color="accent" class="pull-right" (click)="register(item)">
                            {{'applications_register' | translate}}
                        </button>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</section>
