import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { AdmissionCrm } from '../models/Admission';

import { AdmissionService } from '../services/admission.service';
import { AppService } from '../services/app.service';

import { Utils } from '../Utils';

@Component({
    selector: 'app-admission-add',
    templateUrl: './admission-add.component.html'
})
export class AdmissionAddComponent implements OnInit {
    constructor(
        public app: AppService,
        public dialogRef: MatDialogRef<AdmissionAddComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
        private service: AdmissionService
    ) {
        this.currentAdmissions = data.currentAdmissions || [];
    }

    admissions: AdmissionCrm[] = [];
    selectedAdmission: AdmissionCrm;

    private currentAdmissions: string[] = [];
    private admissionTextTemplate: string;

    ngOnInit() {
        let loading = this.app.showLoading();
        if (this.service) {
            this.service.getFromCrm().subscribe(res => {
                this.app.hideLoading(loading);
                this.admissions = res.filter(t => !this.currentAdmissions.some(x => x == t.EnrollId));
            }, err => {
                if (err.status == 409 && err.text() == '"admissionalreadyadded"')
                    this.app.alerts.error(this.app.translate.instant('admissionalreadyadded'));
                else
                    this.app.showLoadError(err);
            });
        }
        this.admissionTextTemplate = this.app.translate.instant('admissions_displayTemplate');
    }

    getAdmissionDisplayText(adm: AdmissionCrm) {
        return Utils.formatString(this.admissionTextTemplate, [adm.EnrollTitle, adm.EnrollPeriodTitle]);
    }

    submit() {
        let loading = this.app.showLoading();
        this.service.add(this.selectedAdmission).subscribe(data => {
            this.app.hideLoading(loading);
            this.dialogRef.close(data);
        });
    }
}
