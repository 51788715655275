<header class="content-header">
    <nav class="breadcrumb">
        <a [routerLink]="['/'] | localize" class="bc-back">{{'home_title' | translate}}</a>
    </nav>
    <h1 [translate]="'templates_title'"></h1>
</header>

<ngx-datatable #grid
                [rows]="gridItems"
                [headerHeight]="'auto'"
                [limit]="20"
                [footerHeight]="'auto'"
                [rowHeight]="'auto'"
                [columnMode]="'force'"
                [loadingIndicator]="gridLoading"
                [externalSorting]="true"
                [messages]="{ emptyMessage: 'gridEmpty' | translate, totalMessage: 'gridTotal' | translate }"
                (sort)="onGridSort($event)">
    <ngx-datatable-column [name]="'templates_lblTitle' | translate" prop="Title"></ngx-datatable-column>
    <ngx-datatable-column [name]="'templates_lblType' | translate" prop="Type">
        <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex" let-value="row.Type">
            {{'documentTemplateType_' + value | translate}}
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [name]="'templates_lblDateFrom' | translate" prop="DateFrom">
        <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex" let-value="row.DateFrom">
            <span>
                {{value | appDate}}
            </span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [name]="'templates_lblDateTo' | translate" prop="DateTo">
        <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex" let-value="row.DateTo">
            <span>
                {{value | appDate}}
            </span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [name]="'templates_lblUseCode' | translate" prop="UseCode"></ngx-datatable-column>
    <ngx-datatable-column [name]="'templates_lblCode' | translate" prop="TemplateCode"></ngx-datatable-column>
    <ngx-datatable-column [resizeable]="false">
        <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
            <a [href]="getFileUrl(row, 'lv')" target="_blank" class="download-link">
                <i class="fa fa-download"></i> LV
            </a>
            <a [href]="getFileUrl(row, 'en')" target="_blank" class="download-link">
                <i class="fa fa-download"></i> EN
            </a>
            <a [routerLink]="['/templates', row.Id] | localize" class="btn-icon edit-group" *ngIf="canEdit" [title]="'edit' | translate">
                <i class="fa fa-pencil"></i>
            </a>
            <button (click)="deleteGridRow(rowIndex)" class="btn-icon" *ngIf="canDelete" [title]="'delete' | translate">
                <i class="fa fa-trash"></i>
            </button>
        </ng-template>
    </ngx-datatable-column>
</ngx-datatable>

<div>
    <a mat-raised-button color="primary" [routerLink]="['/templates/new'] | localize">{{'add' | translate}}</a>
</div>