<app-program-content-wrapper [program]="program" [breadcrumbTitle]="admissionTitle" [formTitle]="'programResults_title' | translate">
    <ng-template let-program="program">
        <form class="filter-form-sm">
            <div class="cols">
                <div class="col-2">
                    <mat-form-field>
                        <mat-select [placeholder]="'programResults_lblResult' | translate"
                                    [(ngModel)]="filterResult" name="filterResult" (selectionChange)="filter()">
                            <mat-option [value]="''">{{'optionNone' | translate}}</mat-option>
                            <mat-option *ngFor="let o of filterResultOptions" [value]="o">{{'applicationResultType_' + o | translate}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="col-3">
                    <mat-form-field>
                        <input #search
                            matInput
                            [(ngModel)]="filterText"
                            (keyup)="filter()"
                            name="filterText"
                            spellcheck="false"
                            [placeholder]="'lblSearch' | translate">
                        <button mat-button *ngIf="search.value" matSuffix mat-icon-button [title]="'lblClear' | translate"
                                (click)="filterText = ''; filter()">
                            <mat-icon>close</mat-icon>
                        </button>
                    </mat-form-field>
                </div>

                <div class="col-2">
                    <mat-form-field>
                        <mat-select [placeholder]="'programResults_lblContractStatus' | translate"
                                    [(ngModel)]="filterContractStatus" name="filterContractStatus" (selectionChange)="filter()">
                            <mat-option [value]="''">{{'optionNone' | translate}}</mat-option>
                            <mat-option *ngFor="let o of filterContractStatusOptions" [value]="o">
                                {{ o | translate}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="col-2">
                    <mat-form-field>
                        <mat-select [placeholder]="'programResults_lblEnrollmentStatus' | translate"
                                    [(ngModel)]="filterEnrollmentStatus" name="filterEnrollmentStatus" (selectionChange)="filter()">
                            <mat-option [value]="''">{{'optionNone' | translate}}</mat-option>
                            <mat-option *ngFor="let o of filterEnrollmentStatusOptions" [value]="o">
                                {{o | translate}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="col-3">
                    <mat-form-field>
                        <input #searchNotifCode
                            matInput
                            [(ngModel)]="filterNotificationCode"
                            (keyup)="filter()"
                            name="filterNotificationCode"
                            spellcheck="false"
                            [placeholder]="'programResults_lblNotificationCode' | translate">
                        <button mat-button *ngIf="searchNotifCode.value" matSuffix mat-icon-button [title]="'lblClear' | translate"
                                (click)="filterNotificationCode = ''; filter()">
                            <mat-icon>close</mat-icon>
                        </button>
                    </mat-form-field>
                </div>
            </div>
            <div class="cols">
                <div class="col-2">
                    <mat-checkbox [(ngModel)]="filterIsLateSubmission" name="filterIsLateSubmission" (change)="filter()">
                        <span style="white-space: normal;">{{'programResults_lblIsLateSubmission' | translate }}</span>
                    </mat-checkbox>
                </div>
                <div class="col-2" *ngIf="isForeign==false">
                    <mat-checkbox [(ngModel)]="filterIsIncomplete" name="filterIsIncomplete" (change)="filter()">
                        <span style="white-space: normal;">{{'programResults_lblIsIncomplete' | translate }}</span>
                    </mat-checkbox>
                </div>
                <div class="col-2">
                    <mat-checkbox [(ngModel)]="filterApplicationsSubmittedByRepresentative" name="filterApplicationsSubmittedByRepresentative" (change)="filter()">
                        <span style="white-space: normal;">{{'programResults_lblApplicationsForAnotherPerson' | translate }}</span>
                    </mat-checkbox>
                </div>
                <div class="col-2">
                    <mat-checkbox [(ngModel)]="filterHasNotificationCode" name="filterHasNotificationCode" (change)="filter()">
                        <span style="white-space: normal;">{{'programResults_lblNotificationCode' | translate}}</span>
                    </mat-checkbox>
                </div>
                <div class="col-2">
                    <mat-checkbox [(ngModel)]="filterOnlyGraduates" name="filterOnlyGraduates" (change)="filter()">
                        <span style="white-space: normal;">{{'programResults_lblOnlyGraduates' | translate}}</span>
                    </mat-checkbox>
                </div>
            </div>
        </form>
        <ngx-datatable class="tight app-scrollable-grid"
                    [rows]="gridItems"
                    [headerHeight]="30"
                    [footerHeight]="50"
                    [rowHeight]="30"
                    [columnMode]="'force'"
                    [externalSorting]="true"
                    [scrollbarH]="true"
                    [scrollbarV]="true"
                    [loadingIndicator]="gridLoading"
                    [messages]="{ emptyMessage: 'gridEmpty' | translate, totalMessage: 'gridTotal' | translate }"
                    (sort)="onGridSort($event)">
            <ngx-datatable-column cellClass="check-cell" headerClass="check-cell" [width]="32" [maxWidth]="32">
                <ng-template ngx-datatable-header-template>
                    <mat-checkbox (change)="selectRows($event)" value="true" [checked]="allRowsSelected()"></mat-checkbox>
                </ng-template>
                <ng-template ngx-datatable-cell-template let-row="row">
                    <mat-checkbox [value]="row.Id" (change)="selectRow(row)" [checked]="rowSelected(row)"></mat-checkbox>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column prop="ApplicationNumber" [name]="'programResults_thApplicationId' | translate" [width]="100">
                <ng-template ngx-datatable-cell-template let-row="row">
                    <a [routerLink]="['/applications', row.ApplicationNumber] | localize">{{row.ApplicationNumber}}
                        <i class="fa fa-calendar" *ngIf="row.IsLateSubmission"></i>
                    </a>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column prop="ApplicationIsIncomplete" [name]="' '" [width]="10" [maxWidth]="10">
                <ng-template ngx-datatable-cell-template let-value="value">
                    <i *ngIf="value" style="color:red;" class="fa fa-exclamation danger" [title]="'programResults_lblIsIncomplete' | translate"></i>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column prop="Name" [name]="'programResults_lblName' | translate"></ngx-datatable-column>
            <ngx-datatable-column prop="Surname" [name]="'programResults_lblSurname' | translate"></ngx-datatable-column>
            <ngx-datatable-column [name]="' '" [width]="24" [maxWidth]="24">
                <ng-template ngx-datatable-cell-template let-row="row">
                    <i class="fa fa-info-circle icon-btn" (click)="openContacts(row)"></i>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column prop="PersonCode" [name]="'programResults_lblPersonCode' | translate" [width]="125" [minWidth]="125"></ngx-datatable-column>
            <ngx-datatable-column prop="Priority" [name]="'programResults_thPriority' | translate" [comparator]="gridNumberComparator" [width]="32" [maxWidth]="32"></ngx-datatable-column>
            <ngx-datatable-column prop="ExamsFinished" [name]="'programResults_thExamStatus' | translate" [width]="23" [maxWidth]="32">
                <ng-template ngx-datatable-cell-template let-value="value">
                    <i class="fa fa-check" *ngIf="value"></i>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column prop="WeightedResult" [name]="'programResults_thWeightedResult' | translate" [comparator]="gridNumberComparator" [width]="70" [minWidth]="70">
                <ng-template ngx-datatable-cell-template let-value="value">
                    {{value | number:'1.2-2'}}
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column prop="ApplicationStatus" [name]="'programResults_thApplicationStatus' | translate">
                <ng-template ngx-datatable-cell-template let-row="row">
                    {{'applicationStatus_' + row.ApplicationStatus | translate}}
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column prop="Result" [name]="'programResults_lblResult' | translate">
                <ng-template ngx-datatable-cell-template let-row="row">
                    <div class="text-ellipsis" *ngIf="!resultEditing[row.Id]">
                        <i *ngIf="row.ApplicationStatus && row.ApplicationStatus!='New'" class="fa fa-pencil icon-btn" (click)="editResult(row)"></i>
                        {{'applicationResultType_' + row.Result | translate}}
                    </div>
                    <mat-form-field [floatLabel]="'never'" *ngIf="resultEditing[row.Id]">
                        <mat-select (selectionChange)="onResultChange($event, row)" [value]="row.Result">
                            <mat-option disabled [value]="row.Result">{{'applicationResultType_' + row.Result | translate}}</mat-option>
                            <mat-option *ngFor="let opt of availableResults" [value]="opt">{{'applicationResultType_' + opt | translate}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column prop="Place" [name]="'programResults_thPlace' | translate" [comparator]="gridPlaceComparator" [width]="40" [maxWidth]="40"></ngx-datatable-column>
            <ngx-datatable-column prop="ContractStatus" [name]="'programResults_thContractStatus' | translate">
                <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex" let-value="row.ContractStatus">
                    {{getContractStatus(value)}}
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column prop="EnrollmentStatus" [name]="'programResults_thEnrollmentStatus' | translate">
                <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
                    {{row.EnrollmentStatus | translate}}
                    <i class="fa fa-info-circle icon-btn" (click)="openBalance(row)"></i>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column prop="NotificationCode" [name]="'programResults_thNotificationCode' | translate">
                <ng-template ngx-datatable-cell-template let-row="row">
                    <i *ngIf="row.NotificationCode" class="fa fa-external-link icon-btn" (click)="openNotification(row.ApplicationId)"></i>
                    {{row.NotificationCode}}
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-footer>
                <ng-template ngx-datatable-footer-template let-rowCount="rowCount">
                    <div>
                        <strong>{{rowCount || 0}}</strong> {{'gridTotal' | translate}}
                    </div>
                </ng-template>
            </ngx-datatable-footer>
        </ngx-datatable>

        <div>
            {{'programResults_lblTotalStudentsIn' | translate}}: <em>{{totalStudentsIn || 0}}</em>
        </div>
    </ng-template>
</app-program-content-wrapper>

<div class="cols">
    <div class="col-7">
        <form #notificationForm="ngForm" class="block form-in form-inline">
            <header class="block-header">
                <h3 [translate]="'programResults_createOrDeleteNotification'" class="form-title"></h3>
            </header>

            <div class="block-body">
                <mat-form-field>
                    <mat-select [placeholder]="'programResults_lblNotificationAction' | translate" [(ngModel)]="notificationOpts.action" name="notificationAction" required>
                        <mat-option *ngFor="let a of notificationActions" [value]="a">
                            {{'programResults_notificationAction_' + a | translate}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field style="min-width: 250px" *ngIf="notificationOpts.action==='create'">
                    <input matInput [placeholder]="'programResults_lblNotificationCode' | translate" [(ngModel)]="notificationOpts.code"
                        name="notificationCode" required #notifCode
                        [matAutocomplete]="notifCodeAuto.bind(notifCode)" />
                    <app-message-autocomplete #notifCodeAuto></app-message-autocomplete>
                </mat-form-field>

                <mat-form-field style="min-width: 250px" *ngIf="notificationOpts.action==='create'">
                    <input matInput [placeholder]="'programResults_lblNotificationCodeExternal' | translate" [(ngModel)]="notificationOpts.codeExternal"
                        name="notificationCodeExternal" #notifCodeExternal required
                        [matAutocomplete]="notifCodeExternalAuto.bind(notifCodeExternal)" />
                    <app-message-autocomplete #notifCodeExternalAuto></app-message-autocomplete>
                </mat-form-field>

                <mat-form-field *ngIf="!!notificationOpts.action">
                    <mat-select [placeholder]="'programResults_lblNotificationTarget' | translate" [(ngModel)]="notificationOpts.target" name="notificationTarget" required>
                        <mat-option *ngFor="let t of notificationTargets" [value]="t">
                            {{'programResults_notificationTarget_' + t | translate}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <button type="submit" class="btn-inline" mat-raised-button color="primary" (click)="createOrDeleteNotification()" [disabled]="!notificationForm.valid">
                    {{'ok' | translate}}
                </button>
            </div>
        </form>
    </div>

    <div class="col-5" *ngIf="!isHomines">
        <form #contractForm="ngForm" class="block form-in form-inline">
            <header class="block-header">
                <h3 [translate]="'programResults_actionsWithApplicants'"></h3>
            </header>

            <div class="block-body">
                <mat-form-field>
                    <mat-select [placeholder]="'programResults_lblContractTarget' | translate" [(ngModel)]="contractOpts.target" name="contractTarget" required>
                        <mat-option *ngFor="let t of contractTargets" [value]="t">
                            {{'programResults_contractTarget_' + t | translate}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field>
                    <mat-select [placeholder]="'programResults_lblContractAction' | translate" [(ngModel)]="contractOpts.action" name="contractAction" required>
                        <mat-option *ngFor="let a of contractActions" [value]="a">
                            {{'programResults_contractAction_' + a | translate}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <button type="button" class="btn-inline" mat-raised-button color="primary" (click)="processContracts()" [disabled]="!contractForm.valid">
                    {{'ok' | translate}}
                </button>
            </div>
        </form>
    </div>
</div>
