<header class="content-header">
    <nav class="breadcrumb">
        <a [routerLink]="['/quizzeditor/'+selectedQuizzGroup.QuizzId] | localize" class="bc-back">{{selectedQuizzGroup.QuizzTitle}}</a>
    </nav>
</header>

<section class="block">
    <header class="block-header">
        <h3>{{'quizzgroupedit_title' | translate}}</h3>
    </header>
    <div class="block-body">
        <form #form="ngForm" (submit)="submitQuizzGroup()">
            <div class="cols">
                <div class="col-10">
                    <mat-form-field>
                        <input autofocus matInput required name="Title" [placeholder]="'quizzeditor_lblTitle' | translate" [(ngModel)]="selectedQuizzGroup.Title" />
                    </mat-form-field>
                </div>
                <div class="col-2">
                    <mat-form-field>
                        <input required matInput name="QuestionCount" type="number" title="{{'valueintervalbeween' | translate:'[1,1000]'}}" min="1" step="1" max="1000" [placeholder]="'quizzeditor_lblQuestionCount' | translate"
                               (keydown)="checkNumbers($event)"
                               [(ngModel)]="selectedQuizzGroup.QuestionCount" />
                    </mat-form-field>
                </div>
            </div>
            <div class="form-submit">
                <button type="submit" mat-raised-button color="primary" [disabled]="!form.valid || selectedQuizzGroup.QuestionCount<1 || selectedQuizzGroup.QuestionCount>1000">{{'save' | translate}}</button>
            </div>
        </form>
    </div>
</section>

<section class="block">
    <header class="block-header">
        <h3>{{'quizzgroupedit_questions' | translate}}</h3>
    </header>
    <div class="block-body">
      <ngx-datatable #grid
                     [rows]="gridItems"
                     [headerHeight]="'auto'"
                     [footerHeight]="'auto'"
                     [rowHeight]="'auto'"
                     [loadingIndicator]="gridLoading"
                     [externalSorting]="true"
                     [columnMode]="'flex'"
                     [messages]="{ emptyMessage: 'gridEmpty' | translate, totalMessage: 'gridTotal' | translate }"
                     (sort)="onGridSort($event)">
        <ngx-datatable-column [name]="''" [flexGrow]="0.5">
          <ng-template ngx-datatable-cell-template let-row="row">
            <i class="fa fa-exclamation-triangle text-warning help" *ngIf="getWarnings(row).length" [title]="getWarnings(row).join('\n')"></i>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [name]="'quizzquestion_lblNr' | translate" prop="SortSequence" [flexGrow]="1.5">
          <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex" let-value="row.SortSequence">
            <ng-container *ngIf="!isGridEditing(row)">
              {{value}}
            </ng-container>
            <mat-form-field *ngIf="isGridEditing(row)">
              <input required
                     matInput
                     name="SortSequence"
                     type="number"
                     (keydown)="checkNumbers($event)"
                     min="1"
                     max="999999"
                     title="{{'valueintervalbeween' | translate:'[1,999999]'}}"
                     step="1"
                     [(ngModel)]="row.SortSequence" />
            </mat-form-field>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [name]="'quizzquestion_lblText' | translate" prop="Title" [flexGrow]="10">
          <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex" let-value="row.Text">
            <ng-container *ngIf="!isGridEditing(row)">
              {{value}}
            </ng-container>
            <mat-form-field *ngIf="isGridEditing(row)">
              <input autofocus
                     matInput
                     required
                     name="Text"
                     [(ngModel)]="row.Text"
                     pattern="[a-zA-Z0-9-_]" />
            </mat-form-field>
            <mat-error *ngIf="row.notUnique">{{'quizzgroup_notuniquequestion' | translate}}</mat-error>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [name]="'quizzquestion_lblAnswerCount' | translate" prop="ActualAnswerCount" [flexGrow]="2">
          <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex" let-value="row.ActualAnswerCount">
            <ng-container *ngIf="!isGridEditing(row)">
              {{value}}
            </ng-container>
            <mat-form-field *ngIf="isGridEditing(row)">
              <input matInput
                     required
                     name="ActualAnswerCount"
                     [(ngModel)]="row.ActualAnswerCount" />
            </mat-form-field>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [name]="'quizzquestion_lblMaxPoint' | translate" prop="MaxPoint" [flexGrow]="2">
          <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex" let-value="row.MaxPoint">
            <ng-container *ngIf="!isGridEditing(row)">
              {{value}}
            </ng-container>
            <mat-form-field *ngIf="isGridEditing(row)">
              <input matInput
                     required
                     name="MaxPoint"
                     [(ngModel)]="row.MaxPoint" />
            </mat-form-field>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [resizeable]="false" [flexGrow]="3">
          <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
            <a [routerLink]="['/quizzquestion', row.Id] | localize" class="btn-icon" *ngIf="!isGridEditing(row)">
              <i class="fa fa-pencil"></i>
            </a>
            <button (click)="saveGridRow(rowIndex)"
                    class="btn-icon"
                    *ngIf="isGridEditing(row)"
                    [disabled]="!isValidGridItem(row)"
                    [title]="'save' | translate">
              <i class="fa fa-floppy-o"></i>
            </button>
            <button (click)="cancelGridRow(rowIndex)" class="btn-icon" *ngIf="isGridEditing(row)" [title]="'cancel' | translate">
              <i class="fa fa-ban"></i>
            </button>
            <button (click)="deleteGridRow(rowIndex)" class="btn-icon" *ngIf="!isNewGridItem(row)" [title]="'delete' | translate">
              <i class="fa fa-trash"></i>
            </button>

          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>

        <div>
            <button mat-raised-button color="primary" (click)="addGridRow()">{{'add' | translate}}</button>
        </div>
    </div>
</section>
