<h2 mat-dialog-title>
    <ng-container *ngIf="!isCreated">
        {{'applications_createContractForProgram' | translate | formatString:program.Title}}
    </ng-container>
    <ng-container *ngIf="isCreated">
        {{'contractsent' | translate}}
    </ng-container>
</h2>
<ng-container *ngIf="isCreated else inProgress">
    <mat-dialog-actions>
        <button mat-raised-button [mat-dialog-close]="true" color="primary">
            {{'confirmDialog_ok' | translate}}
        </button>
    </mat-dialog-actions>
</ng-container>
<ng-template #inProgress>
    <ng-container *ngIf="warnings else noWarnings">
        <mat-dialog-content>
            <div class="text-center">
                {{warnings}}
            </div>
        </mat-dialog-content>
        <mat-dialog-actions>
            <button mat-raised-button color="primary" (click)="createContract(contractSigner,contractReason,true)">
                {{'contractConfirm_ContinueButton' | translate}}
            </button>
            <button mat-raised-button mat-dialog-close>
                {{'contractConfirm_CancelButton' | translate}}
            </button>
        </mat-dialog-actions>
    </ng-container>
</ng-template>
<ng-template #noWarnings>
    <ng-container *ngIf="hasLaterStages else noLaterStages">
        <mat-dialog-content>
            <div class="text-center">
                <div class="cols">
                    <div class="col-12">{{'contractConfirm_HasStudiesAtLaterStages' | translate}}</div>
                </div>
                <div class="cols">
                    <div class="col-6">{{'contractConfirm_LabelApplicationId' | translate}}</div>
                    <div class="col-6">{{'contractConfirm_LabelName' | translate}}</div>
                </div>
                <div class="cols" *ngFor="let app of applicationsWithStudiesAtLaterStages">
                    <div class="col-6"><a [routerLink]="['/applications', app.Number] | localize"
                            target="_blank">{{app.Number}}</a></div>
                    <div class="col-6">{{app.Name || ''}} {{app.Surname || ''}}</div>
                </div>
                <div class="cols">
                    <div class="col-12">{{'contractConfirm_ContinueText' | translate}}</div>
                </div>
            </div>
        </mat-dialog-content>
        <mat-dialog-actions>
            <button mat-raised-button color="primary" (click)="continueWithContract()">
                {{'contractConfirm_ContinueButton' | translate}}
            </button>
            <button mat-raised-button mat-dialog-close>
                {{'contractConfirm_CancelButton' | translate}}
            </button>
        </mat-dialog-actions>
    </ng-container>
</ng-template>
<ng-template #noLaterStages>
    <ng-container *ngIf="hasWithoutLaterStages else noWithoutLaterStages">
        <mat-dialog-content>
            <form #contractForm="ngForm">
                <div class="cols">
                    <div class="col-6">
                        <mat-form-field>
                            <mat-select [placeholder]="'programResults_lblContractSigner' | translate" required
                                #contractSigner>
                                <mat-option *ngFor="let t of contractSigners" [value]="t">
                                    {{getTranslatedValue(t)}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-6">
                        <mat-form-field>
                            <mat-select [placeholder]="'programResults_lblContractReason' | translate" required
                                #contractReason>
                                <mat-option *ngFor="let t of contractReasons" [value]="t">
                                    {{getTranslatedValue(t)}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="cols" *ngIf="!isBudget">
                    <div class="col-6">
                        <mat-form-field>
                            <mat-select [placeholder]="'programResults_lblContractDiscount' | translate"
                                [ngModel]="contractDiscountValue" name="contractDiscount"
                                (ngModelChange)="onDiscountChange($event)">
                                <mat-option>{{'optionNone' | translate}}</mat-option>
                                <mat-option *ngFor="let t of contractDiscounts" [value]="t">
                                    {{getTranslatedValue(t)}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-6">
                        <mat-form-field>
                            <input matInput [matDatepicker]="pickerMinPaymentDate" #minPaymentDate
                                [placeholder]="'contract_lblMinPaymentDate' | translate" [min]="app.env.minDate"
                                [max]="app.env.maxDate" [title]="'dateFormatHint' | translate"
                                (click)="pickerMinPaymentDate.open()" (dateChange)="onMinPaymentDate($event)">
                            <mat-datepicker-toggle matSuffix [for]="pickerMinPaymentDate"></mat-datepicker-toggle>
                            <mat-datepicker #pickerMinPaymentDate></mat-datepicker>
                            <mat-error *ngIf="minPaymentDate.invalid">{{'valueInvalid' | translate}}</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="cols">
                    <div class="col-12" *ngIf="isRZ">
                        <mat-form-field>
                            <mat-select [placeholder]="'contract_lblMedInstitutions' | translate" name="medInstitution"
                                [(ngModel)]="medInstitution" (selectionChange)="onMedInstitutionChange()">
                                <mat-option *ngFor="let t of medInstitutions" [value]="t">
                                    {{getTranslatedValue(t)}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-12">
                        <mat-form-field>
                            <textarea matInput [(ngModel)]="specialConditions"
                                [placeholder]="'contract_lblSpecialConditions' | translate"
                                name="specialConditions"></textarea>
                        </mat-form-field>
                        <mat-form-field *ngIf="isForeign">
                            <textarea matInput [(ngModel)]="specialConditionsEn"
                                [placeholder]="'contract_lblSpecialConditionsEn' | translate"
                                name="specialConditionsEn"></textarea>
                        </mat-form-field>
                    </div>
                </div>
            </form>
        </mat-dialog-content>
        <mat-dialog-actions>
            <button mat-raised-button color="primary" [disabled]="!contractSigner.value || !contractReason.value"
                (click)="createContract(contractSigner,contractReason)">
                {{'confirmDialog_ok' | translate}}
            </button>
            <button mat-raised-button mat-dialog-close>
                {{'confirmDialog_cancel' | translate}}
            </button>
        </mat-dialog-actions>
    </ng-container>
</ng-template>
<ng-template #noWithoutLaterStages>
    <mat-dialog-content>
        {{'confirmContract_noValidApplicationSelected' | translate}}
    </mat-dialog-content>
    <mat-dialog-actions>
        <button mat-raised-button mat-dialog-close>
            {{'confirmDialog_ok' | translate}}
        </button>
    </mat-dialog-actions>
</ng-template>
