import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Classifier } from '../models/Classifier';
import { environment as ENV } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

/**
 * Classifier service
 */
@Injectable()
export class ClassifierService {
    constructor(public http: HttpClient) { }

    public get apiUrl(): string { return `${ENV.apiUrl}/classifiers` }

    /**
     * Get classifiers.
     * @param type Comma-separated classifier types.
     */
    get(type: string): Observable<Classifier[]> {
        const url = `${this.apiUrl}?type=${type}`;
        return this.http.get<Classifier[]>(url);
    }
    getForFooter(lang: string): Observable<Classifier[]> {
        const url = `${this.apiUrl}/forFooter?lang=${lang}`;
        return this.http.get<Classifier[]>(url);
    }

    getById(id: string): Observable<Classifier> {
        const url = `${this.apiUrl}/${id}`;
        return this.http.get<Classifier>(url);
    }

    getByCode(code: string): Observable<Classifier> {
        const url = `${this.apiUrl}/getByCode(${code})`;
        return this.http.get<Classifier>(url);
    }

    search(type: string, value: string): Observable<Classifier[]> {
        const url = `${this.apiUrl}/search?type=${type}&value=${value}`;
        return this.http.get<Classifier[]>(url);
    }

    create(item: Classifier): Observable<Classifier> {
        return this.http.post<Classifier>(this.apiUrl, {
            Type: item.Type,
            Code: item.Code,
            LegacyCode: item.LegacyCode,
            Value: item.ValueLV,
            ValueEN: item.ValueEN,
            Payload: item.Payload
        });
    }

    update(item: Classifier): Observable<Classifier> {
        const url = `${this.apiUrl}/${item.Id}`;
        return this.http.put<Classifier>(url, {
            Code: item.Code,
            LegacyCode: item.LegacyCode,
            Value: item.ValueLV,
            ValueEN: item.ValueEN,
            Payload:item.Payload
        });
    }

    delete(id: string): Observable<any> {
        const url = `${this.apiUrl}/${id}`;
        return this.http.delete(url);
    }
}
