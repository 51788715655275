import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppService } from '../../services/app.service';
import { ProgramService } from '../../services/program.service';


@Component({
  selector: 'app-interviewers',
  templateUrl: './interviewers.component.html',
  styleUrls: ['./interviewers.component.css']
})
export class InterviewersComponent implements OnInit {

    constructor(public app: AppService,private route: ActivatedRoute, private service: ProgramService) { }
    programTitle: string;
    admissionTitle: string;
    admissionId: number;
    ngOnInit() {
        this.route.params.subscribe(params => {
            let programId = +params['id'];
            if (programId) {
                this.service.getBreadcrumbData(programId).subscribe(data => {
                    this.programTitle = data.ProgramTitle;
                    this.admissionTitle = data.AdmissionTitle;
                    this.admissionId = data.AdmissionId;
                });
            }
        });
    }



 }
