import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SwedbankRequest, CitadeleRequest, SebRequest, NordeaRequest } from '../models/BankRequest';
import { OAuthRequest } from '../models/UserInfo';

import { environment as ENV } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class BanklinkService {
    constructor(private http: HttpClient) { }

    loading: boolean;

    get apiBanklinkUrl(): string { return `${ENV.apiUrl}/banklink` }
    
    getSwedbankRequestData(lang:string): Observable<SwedbankRequest> {
        return this.http.get<SwedbankRequest>(this.apiBanklinkUrl + '/actions/swedbank?lang=' + lang).pipe(
            map(data => data || new SwedbankRequest()));
    }
    getCitadeleRequestData(lang: string): Observable<CitadeleRequest> {
        return this.http.get<CitadeleRequest>(this.apiBanklinkUrl + '/actions/citadele?lang=' + lang).pipe(
            map(data => data || new CitadeleRequest()));
    }
    getSEBRequestData(lang: string): Observable<SebRequest> {
        return this.http.get<SebRequest>(this.apiBanklinkUrl + '/actions/seb?lang=' + lang).pipe(
            map(data => data || new SebRequest()));
    }
    getNordeaRequestData(lang: string): Observable<NordeaRequest> {
        return this.http.get<NordeaRequest>(this.apiBanklinkUrl + '/actions/nordea?lang=' + lang).pipe(
            map(data => data || new NordeaRequest()));
    }
    getOAuthRequestData(): Observable<OAuthRequest> {
        return this.http.get<OAuthRequest>(this.apiBanklinkUrl + '/actions/oauth').pipe(
            map(data => data || new OAuthRequest()));
    }
    
    register(nonce: string, language: string, consent: boolean): Observable<any> {
        const url = `${this.apiBanklinkUrl}/register`;
        return this.http.post(url, {
            Nonce: nonce,
            Language: language,
            Consent: consent
        });
    }
}
