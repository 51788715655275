import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ApplicationService } from '../../services/application.service';
import { AppService } from '../../services/app.service';

import { HistoryComponentBase } from './HistoryComponentBase';
import { MessagesService } from '../../messages/messages.service';

@Component({
    selector: 'app-application-history',
    templateUrl: './application.component.html'
})
export class ApplicationHistoryComponent extends HistoryComponentBase {
    constructor(
        public app: AppService,
        protected service: ApplicationService,
        protected route: ActivatedRoute,
        protected messageService: MessagesService
        ) {
        super(app, service, route);

        const messageCodes = [
            'APPLICATION_CONFIRM_GRADES',
            'APPLICATION_STUDIES_AT_LATER_STAGES_LABEL',
            'APPLICATION_IS_GRADUATE_LABEL',
            'APPLICATION_FOR_CREDIT_TRANSFER_LABEL',
            'APPLICATION_IS_INCOMPLETE_LABEL',
            'APPLICATION_CONFIRM_RULES_LABEL'
        ];

        this.messageService.getByCodes(messageCodes).subscribe(data => {     
            const applicationConfirmGrades = data.find(t => t.Code === 'APPLICATION_CONFIRM_GRADES');
            this.applicationConfirmGradesText = applicationConfirmGrades ? this.app.translate.currentLang === 'en' ? applicationConfirmGrades.TextEN : applicationConfirmGrades.TextLV : '';

            const applicationStudiesAtLaterStages = data.find(t => t.Code === 'APPLICATION_STUDIES_AT_LATER_STAGES_LABEL');
            this.applicationStudiesAtLaterStagesText = applicationStudiesAtLaterStages ? this.app.translate.currentLang === 'en' ? applicationStudiesAtLaterStages.TextEN : applicationStudiesAtLaterStages.TextLV : '';

            const applicationIsGraduate = data.find(t => t.Code === 'APPLICATION_IS_GRADUATE_LABEL');
            this.applicationIsGraduateText = applicationIsGraduate ? this.app.translate.currentLang === 'en' ? applicationIsGraduate.TextEN : applicationIsGraduate.TextLV : '';

            const applicationApplicationForCreditTransfer = data.find(t => t.Code === 'APPLICATION_FOR_CREDIT_TRANSFER_LABEL');
            this.applicationApplicationForCreditTransferText = applicationApplicationForCreditTransfer ? this.app.translate.currentLang === 'en' ? applicationApplicationForCreditTransfer.TextEN : applicationApplicationForCreditTransfer.TextLV : '';

            const applicationIsIncomplete = data.find(t => t.Code === 'APPLICATION_IS_INCOMPLETE_LABEL');
            this.applicationIsIncompleteText = applicationIsIncomplete ? this.app.translate.currentLang === 'en' ? applicationIsIncomplete.TextEN : applicationIsIncomplete.TextLV : '';
        
            const applicationConfirmRulesLabel = data.find(t => t.Code === 'APPLICATION_CONFIRM_RULES_LABEL');
            this.applicationConfirmRulesLabelText = applicationConfirmRulesLabel ? this.app.translate.currentLang === 'en' ? applicationConfirmRulesLabel.TextEN : applicationConfirmRulesLabel.TextLV : '';
        });
    }

    get getData() {
        return this.service.getHistory(this.item.Id);
    }

    applicationConfirmGradesText: string;
    applicationStudiesAtLaterStagesText: string;
    applicationIsGraduateText: string;
    applicationApplicationForCreditTransferText: string;
    applicationIsIncompleteText: string;
    applicationConfirmRulesLabelText: string;
}
