import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ApplicationService } from '../../services/application.service';
import { AppService } from '../../services/app.service';

import { HistoryComponentBase } from './HistoryComponentBase';

@Component({
    selector: 'app-application-exams-history',
    templateUrl: './exams.component.html'
})
export class ApplicationExamsHistoryComponent extends HistoryComponentBase {
    constructor(
        public app: AppService,
        protected service: ApplicationService,
        protected route: ActivatedRoute
    ) {
        super(app, service, route);
    }

    get getData() {
         return this.service.getExamsHistory(this.item.Id);
    }
}
