import { DatePipe } from "@angular/common";
import { Component, OnInit, PipeTransform, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { TranslatePipe, TranslateService } from "@ngx-translate/core";
import { DatatableComponent } from "@swimlane/ngx-datatable";
import { DateTime } from "luxon";

import { GridComponentBase } from "../GridComponentBase";

import { InterviewStatus, ScheduledDate, ScheduledInterviewItem } from "../models/Interview";

import { AppService } from "../services/app.service";
import { InterviewService } from "../services/interview.service"
import { AppTimePipe } from "../shared/date.pipe";

@Component({
    //selector: 'app-interviews',
    templateUrl: './my-interviews.component.html',
    styleUrls: ['./my-interviews.component.css']
})
export class MyInterviewsComponent extends GridComponentBase<ScheduledInterviewItem> implements OnInit {
    constructor(
        public app: AppService,
        private service: InterviewService,
        private router: Router,
    ) {
        super(app);
        this.translateStatus = new TranslateStatusPipe(app.translate);
    }

    scheduledDates: ScheduledDate[] = [];
    targetDate: string;
    showCompleted: boolean;

    today: string;

    role: string;
    isPersonnel: boolean;
    isInterviewer: boolean;

    interviews: ScheduledInterviewItem[] = [];

    appTime = new AppTimePipe();
    InterviewStatus = InterviewStatus;

    translateStatus: TranslateStatusPipe;

    @ViewChild(DatatableComponent, { static: true }) private grid: DatatableComponent;


    ngOnInit() {
        this.today = DateTime.now().toISODate();

        this.service.userRole.subscribe(role => {
            //role = 'Interviewer'; // TODO -remove test value
            //role = 'InterviewPersonnel'; // TODO -remove test value
            this.role = role;
            if (role) {
                this.isPersonnel = role === 'InterviewPersonnel';
                this.isInterviewer = role === 'Interviewer';
                this.load();
            }
        });
    }

    private async load(): Promise<void> {
        const loading = this.app.showLoading();
        try {
            this.scheduledDates = await this.service.getScheduledDates(this.role, 4);
            this.scheduledDates.forEach(v => v.Date = v.Date.substr(0, 10));
            if (this.scheduledDates.length > 0) {
                if (this.scheduledDates.some(sd => sd.Date === this.targetDate))
                    await this.goToDate(this.targetDate);
                else
                    await this.goToDate(this.scheduledDates[0].Date);
            }
        }
        catch (err) {
            this.app.showLoadError(err);
        }
        finally {
            this.app.hideLoading(loading);
        }

    }

    async goToDate(date: string) {
        this.targetDate = date;
        const loading = this.app.showLoading();
        try {
            this.interviews = await this.service.getMyInterviews(this.targetDate, this.role);
            this.applyFilter();
        }
        catch (err) {
            this.app.showLoadError(err);
        }
        finally {
            this.app.hideLoading(loading);
        }
    }

    refresh() {
        this.load();
    }

    applyFilter() {
        this.gridItems = this.interviews.filter(i => this.showCompleted || i.status !== InterviewStatus.Completed);
        this.grid.offset = 0;
    }

    canOpen(i: ScheduledInterviewItem): boolean {
        return this.canSetStatus(i) && i.status !== InterviewStatus.Absent;
    }

    canSetStatus(i: ScheduledInterviewItem): boolean {
        return i.status !== InterviewStatus.Completed && <DateTime>i.availableFrom <= DateTime.now();
    }

    openInterview(i: ScheduledInterviewItem) {
        this.router.navigate(['/interviews', i.applicationExamId, i.interviewerId]);
    }

    setAbsent(i: ScheduledInterviewItem, isAbsent: boolean) {
        this.app.confirm({
            title: this.app.translate.instant(isAbsent ? 'interviews_setAbsent' : 'interviews_clearAbsent'),
            text: this.app.translate.instant(isAbsent ? 'interviews_setAbsent_confirm' : 'interviews_clearAbsent_confirm'),
        }, async result => {
            if (result) {
                const loading = this.app.showLoading();
                try {
                    const newStatus = isAbsent ? InterviewStatus.Absent : InterviewStatus.NotStarted;
                    await this.service.setStatus(i.applicationExamId, undefined, newStatus);
                    i.status = newStatus;
                    i.statusDetails && i.statusDetails.forEach(d => d.Status = newStatus);
                    this.refreshGrid();
                }
                catch (err) {
                    this.app.showSaveError(err);
                }
                finally {
                    this.app.hideLoading(loading);
                }
            }
        });
    }

    statusTooltip(i: ScheduledInterviewItem) {
        if (!i.statusDetails || i.statusDetails.length < 2) return null;
        return i.statusDetails.map(sd => `${sd.InterviewerName}: ${this.app.translate.instant('interviewStatus_' + sd.Status)}`).join('\n');
    }
}

class TranslateStatusPipe implements PipeTransform {
    constructor(private translateService: TranslateService) { }

    transform(value: any, ...args: any[]) {
        return this.translateService.instant('interviewStatus_' + value);
    }
}
