import { Pipe, PipeTransform, Inject, LOCALE_ID } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DateTime } from 'luxon';

@Pipe({
    name: 'appDate'
})
export class AppDatePipe extends DatePipe implements PipeTransform {
    constructor(@Inject(LOCALE_ID) locale: string) {
        super(locale);
        this.localeId = locale;
    }

    private localeId: string;

    transform(value: any, args?: any): any {
        let format: string;
        let opts = args;

        if ((args || '')[0] === 'T') {
            let timeFormat = args.substr(1) || 'HH:mm';
            format = timeFormat;
            opts = null;
        }
        else {
            format = 'dd.MM.yyyy';

            if ((args || '')[0] === '+') {
                let timeFormat = args.substr(1) || 'HH:mm';
                format += ' ' + timeFormat;
                opts = null;
            }
        }

        return super.transform(value, opts || format);
    }
}

@Pipe({name: 'appTime' })
export class AppTimePipe implements PipeTransform {
    transform(value: Date | DateTime | number, args?: any) {
        const empty = args && typeof args === 'string' ? args : '';
        if (!value) return empty;
        if (typeof value === 'number') value = DateTime.fromMillis(value);
        else if (value instanceof Date) value = DateTime.fromJSDate(value);
        // Par tukšu uzskatām arī laiku, kas beidzas ar 0 minūtēm 42 sekundēm un 0 milisekundēm
        if (value.minute === 0 && value.second === 42 && value.millisecond === 0)
            return empty;
        return value.toFormat('HH:mm');
    }
}
