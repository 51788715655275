<mat-dialog-content>
    <ng-container *ngIf="!isLoading">
        <div class="cols" *ngFor="let n of data">
            <div class="col-6">{{n.Label}}</div>
            <div class="col-6" [class.is-bold]="n.IsBold" [style]="'color: ' + (n.FontColor || 'inherit')">{{n.Value}}</div>
        </div>
        <div *ngIf="noData && !error" class="text-align-center">{{'applications_balanceInfoNoData' | translate}}</div>
        <div *ngIf="error" class="text-danger text-align-center">{{error}}</div>
    </ng-container>
    <div *ngIf="isLoading" class="text-align-center">...</div>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-stroked-button type="button" mat-dialog-close>{{'close' | translate}}</button>
</mat-dialog-actions>
