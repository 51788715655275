import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Share, PersonSearchResult, SharedObject, IShareObject } from '../models/Share';

import { AuthService } from './auth.service';

import { environment as ENV } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

/**
 * Share service
 */
@Injectable()
export class ShareService {
    constructor(public http: HttpClient, public auth: AuthService) { }

    public get apiUrl(): string { return `${ENV.apiUrl}/share` }

    get(options?: {
        objectType?: string,
        upn?: string,
        dateFrom?: string,
        dateTo?: string
    }): Observable<Share[]> {
        const url = `${this.apiUrl}`;
        return this.http.get<Share[]>(url, { params: options });
    }

    create(data: {
        objectIds: string[],
        objectType: string,
        dateFrom: Date,
        dateTo: Date,
        persons: PersonSearchResult[]
    }): Observable<Share> {
        const url = `${this.apiUrl}`;
        return this.http.post<Share>(url, data);
    }

    getObject(id: string): Observable<SharedObject> {
        const url = `${this.apiUrl}/shared/${id}`;
        return this.http.get<SharedObject>(url);
    }

    delete(id: string): Observable<any> {
        const url = `${this.apiUrl}/${id}`;
        return this.http.delete(url);
    }

    searchPersons(value: string): Observable<PersonSearchResult[]> {
        const url = `${this.apiUrl}/searchPersons`;
        return this.http.get<PersonSearchResult[]>(url, { params: { value: value } });
    }

    getObjectRoute(data: IShareObject): any[] {
        switch (data.ObjectType) {
            case 'Application': return ['/applications', data.ObjectId];
        }

        return undefined;
    }
}
