import { Component, OnInit } from '@angular/core';
import { AppService } from '../services/app.service';
import { QuizzService } from '../services//quizz.service';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

import { GridComponentBase } from '../GridComponentBase';
import { Quizz, QuizzGroup, QuizzQuestion,QuizzAnswer } from '../models/Quizz';

@Component({
    selector: 'app-quizzquestion',
    templateUrl: './quizzquestion.component.html',
    styleUrls: ['./quizzquestion.component.css']
})
export class QuizzquestionComponent extends GridComponentBase<QuizzAnswer> implements OnInit {
    constructor(public app: AppService, private service: QuizzService, private route: ActivatedRoute) {
        super(app);
    }

    selectedQuizzQuestion: QuizzQuestion = new QuizzQuestion();

    ngOnInit() {
        this.app.setWideLayout();
        this.route.params.subscribe(params => {
            let loading = this.app.showLoading();
            let id = params['id'];
            if (id) {
                this.service.getQuestionById(id).subscribe(data => {
                    if (data) {
                        if (data.QuizzAnswers)
                            this.gridItems = data.QuizzAnswers;
                        this.selectedQuizzQuestion = data;
                        this.app.hideLoading(loading);
                    }
                }, err => this.app.showLoadError(err));
            }
        });
    }

    submitQuizzQuestion() {
        let loading = this.app.showLoading();
        this.service.updateQuestion(this.selectedQuizzQuestion).subscribe(res => {
            this.app.hideLoading(loading);
        }, err => this.app.showSaveError(err));
    }

    isValidGridItem(item: QuizzAnswer): boolean {
        if (this.gridItems.filter(g => g.Text.toLowerCase() == item.Text.toLowerCase()).length == 1) {
            item.notUnique = false;
            this.gridItems.filter(g => g.notUnique).forEach((i: QuizzAnswer) => {
                i.notUnique = false;
            });
            return true;
        } else {
            item.notUnique = true;
            this.gridItems.filter(g => g.Text.toLowerCase() == item.Text.toLowerCase()).forEach((i: QuizzAnswer) => {
                i.notUnique = true;
            });
            return false;
        }
    }

    protected createGridItem(item: QuizzAnswer): Observable<QuizzAnswer> {
        return this.service.addAnswer(this.selectedQuizzQuestion.Id, item);
    }

    protected updateGridItem(item: QuizzAnswer): Observable<QuizzAnswer> {
        return this.service.updateAnswer(item);
    }

    protected deleteGridItem(item: QuizzAnswer): Observable<boolean> {
        return this.service.deleteAnswer(item.Id);
    }

    protected createEmptyObject(): QuizzAnswer {
        let item = new QuizzAnswer();
        item.Text = '';
        item.Points = 0;
        return item;
    }
    checkNumbers(e: any) {
        if (!((e.keyCode > 95 && e.keyCode < 106)
            || (e.keyCode > 47 && e.keyCode < 58)
            || e.keyCode == 8)) {
            return false;
        }
    }
}