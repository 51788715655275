<form class="filter-form-sm">
    <div class="cols">
        <div class="col-2">
            <mat-form-field>
                <input matInput #filterCodeInput (keyup)="filter(filterCodeInput.value, 'Code')" spellcheck="false" [placeholder]="'programs_lblCode' | translate">
                <button mat-button *ngIf="filterCodeInput.value" matSuffix mat-icon-button [title]="'lblClear' | translate"
                        (click)="filterCodeInput.value = ''; filter('', 'Code')">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>
        </div>

        <div class="col-4">
            <mat-form-field>
                <input matInput #filterTitleInput (keyup)="filter(filterTitleInput.value, 'Title')" spellcheck="false" [placeholder]="'programs_lblTitle' | translate">
                <button mat-button *ngIf="filterTitleInput.value" matSuffix mat-icon-button [title]="'lblClear' | translate"
                        (click)="filterTitleInput.value = ''; filter('', 'Title')">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>
        </div>
    </div>
</form>

<ngx-datatable #grid
               class="tight app-scrollable-grid"
               [rows]="gridItems"
               [headerHeight]="40"
               [footerHeight]="50"
               [rowHeight]="40"
               [scrollbarV]="true"
               [columnMode]="'force'"
               [scrollbarH]="true"
               [externalSorting]="true"
               [loadingIndicator]="gridLoading"
               [messages]="{ emptyMessage: 'gridEmpty' | translate, totalMessage: 'gridTotal' | translate }"
               (sort)="onGridSort($event)">
    <ngx-datatable-row-detail [rowHeight]="40" #detailRow>
        <ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
            <label [translate]="'programs_lblDegree'"></label> {{row.Degree}}
        </ng-template>
    </ngx-datatable-row-detail>
    <ngx-datatable-column [width]="20" [maxWidth]="20"
                          [resizeable]="false"
                          [sortable]="false"
                          [draggable]="false"
                          [canAutoResize]="false">
        <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
            <a href="javascript:void(0)"
               [class.datatable-icon-right]="!expanded"
               [class.datatable-icon-down]="expanded"
               (click)="toggleGridRow(row)">
            </a>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [name]="'programs_lblCode' | translate" prop="Code" [width]="50"></ngx-datatable-column>
    <ngx-datatable-column [name]="'programs_lblFaculty' | translate" prop="Faculty" [width]="170"></ngx-datatable-column>
    <ngx-datatable-column [name]="'programs_lblTitle' | translate" prop="Title" [width]="200"></ngx-datatable-column>
    <ngx-datatable-column [name]="'programs_lblFinancing' | translate" prop="Financing" [width]="80"></ngx-datatable-column>
    <ngx-datatable-column [name]="'programs_lblLocation' | translate" prop="Location" [width]="70"></ngx-datatable-column>
    <ngx-datatable-column [name]="'programs_lblStudyType' | translate" prop="Form" [width]="120"></ngx-datatable-column>
    <ngx-datatable-column [name]="'programs_lblPricePerYear' | translate" prop="PricePerYear" [width]="50" [comparator]="gridNumberComparator"></ngx-datatable-column>
    <ngx-datatable-column [name]="'programs_lblStartYear' | translate" prop="StartYear" [width]="50" [comparator]="gridNumberComparator"></ngx-datatable-column>
    <ngx-datatable-column [name]="'programs_lblLanguage' | translate" prop="Language" [width]="100"></ngx-datatable-column>
    <ngx-datatable-column [name]="'programs_lblLengthInYears' | translate" prop="LengthInYears" [width]="50"></ngx-datatable-column>
    <ngx-datatable-column [name]="'programs_lblQuota' | translate" prop="Quota" [width]="35" [comparator]="gridNumberComparator"></ngx-datatable-column>
    <ngx-datatable-column [name]="'programs_lblUrl' | translate" prop="Url" [width]="35">
        <ng-template ngx-datatable-cell-template let-row="row">
            <a href="{{row.Url}}" target="_blank" [title]="'programs_lblUrl' | translate" *ngIf="row.Url">
                <i class="fa fa-external-link"></i>
            </a>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [name]="'programs_lblNotificationCode' | translate" prop="NotificationCode" [width]="50"></ngx-datatable-column>
    <ngx-datatable-column [name]="'programs_lblSpeciality' | translate" prop="Speciality" [width]="70"></ngx-datatable-column>
    <ngx-datatable-column [name]="'programs_lblAllowProlongation' | translate" prop="AllowProlongation" [width]="50">
        <ng-template ngx-datatable-cell-template let-row="row">
            <i class="fa fa-check" *ngIf="row.AllowProlongation"></i>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [name]="'programs_lblAllowAdmission' | translate" prop="AllowAdmission" [width]="50">
        <ng-template ngx-datatable-cell-template let-row="row">
            <i class="fa fa-check" *ngIf="row.AllowAdmission"></i>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [name]="'programs_lblAllowApplicantToApply' | translate" prop="AllowApplicantToApply" [width]="50">
        <ng-template ngx-datatable-cell-template let-row="row">
            <i class="fa fa-check" *ngIf="row.AllowApplicantToApply"></i>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [width]="110" [maxWidth]="110" [resizeable]="false" [draggable]="false" [sortable]="false" [frozenRight]="true">
        <ng-template ngx-datatable-cell-template let-row="row">
            <div class="nowrap" style="background-color: white;">
                <button (click)="edit(row)" type="button" class="btn-icon" [title]="'edit' | translate">
                    <i class="fa fa-pencil"></i>
                </button>
                <a [routerLink]="['/programs', row.Id, 'exams'] | localize" class="btn-icon" [title]="'exams_title' | translate">
                    <i class="fa fa-list"></i>
                </a>
                <a [routerLink]="['/programs', row.Id, 'results'] | localize" class="btn-icon" [title]="'programResults_title' | translate">
                    <i class="fa fa-list-ol"></i>
                </a>
                <a [routerLink]="['/programs', row.Id, 'interviewers'] | localize" class="btn-icon" [title]="'editInterviewList' | translate">
                    <i class="fa fa-clipboard"></i>
                </a>
            </div>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-footer>
        <ng-template ngx-datatable-footer-template let-rowCount="rowCount">
            <div>
                <strong>{{rowCount || 0}}</strong> {{'gridTotal' | translate}}
            </div>
        </ng-template>
    </ngx-datatable-footer>
</ngx-datatable>
