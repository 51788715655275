<header class="content-header">
    <nav class="breadcrumb">
        <a [routerLink]="['/admissions', program.AdmissionId] | localize" class="bc-back">
            {{breadcrumbTitle}}
        </a>
    </nav>
    <h1>{{program.Code}} {{program.Title}} <a href="{{program.Url}}" target="_blank" *ngIf="program.Url"><i class="fa fa fa-external-link"></i></a></h1>
</header>

<div class="block">
    <header class="block-header">
        <h2>{{formTitle}}</h2>
    </header>

    <div class="block-body">
        <div class="form-main">
            <div class="cols nowrap">
                <app-field-display [label]="'programs_lblForm'" [value]="program.Form" class="col-4"></app-field-display>
                <app-field-display [label]="'programs_lblFaculty'" [value]="program.Faculty" class="col-5"></app-field-display>
                <app-field-display [label]="'programs_lblLengthInYears'" [value]="program.LengthInYears" class="col-1"></app-field-display>
                <app-field-display [label]="'programs_lblStartYear'" [value]="program.StartYear" class="col-1"></app-field-display>
                <app-field-display [label]="'programs_lblQuota'" [value]="program.Quota" class="col-1"></app-field-display>
            </div>
            <div class="cols nowrap">
                <app-field-display [label]="'programs_lblFinancing'" [value]="program.Financing" class="col-2"></app-field-display>
                <app-field-display [label]="'programs_lblLocation'" [value]="program.Location" class="col-2"></app-field-display>
                <app-field-display [label]="'programs_lblLanguage'" [value]="program.Language" class="col-2"></app-field-display>
                <app-field-display [label]="'programs_lblDegree'" [value]="program.Degree" class="col-6"></app-field-display>
            </div>
        </div>

        <hr />

        <ng-template [ngTemplateOutlet]="content" [ngTemplateOutletContext]="{program: program}"></ng-template>
    </div>
</div>
