import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment as ENV } from '../../environments/environment';
import { DocumentTemplate } from '../models/Template';

import { AuthService } from './auth.service';

@Injectable()
export class TemplateService {
    constructor(public http: HttpClient, private auth: AuthService) { }

    public get apiUrl(): string { return `${ENV.apiUrl}/templates` }

    get(id: number): Observable<DocumentTemplate> {
        let url = `${this.apiUrl}/${id}`;
        return this.http.get<DocumentTemplate>(url);
    }

    getFileUrl(id: number, language?: string): string {
        return `${this.apiUrl}/${id}/file?SessionKey=${this.auth.sessionKey}${language ? `&language=${language}` : ''}`;
    }

    getAll(): Observable<DocumentTemplate[]> {
        const url = `${this.apiUrl}`;
        return this.http.get<DocumentTemplate[]>(url);
    }

    add(documentTemplate: DocumentTemplate): Observable<DocumentTemplate> {
        let url = `${this.apiUrl}`;
        return this.http.post<DocumentTemplate>(url, documentTemplate);
    }

    update(documentTemplate: DocumentTemplate): Observable<DocumentTemplate> {
        let url = `${this.apiUrl}/${documentTemplate.Id}`;
        return this.http.put<DocumentTemplate>(url, documentTemplate);
    }

    delete(id: number): Observable<boolean> {
        let url = `${this.apiUrl}/${id}`;
        return this.http.delete<boolean>(url);
    }
}
