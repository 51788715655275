<h2 mat-dialog-title>{{'applications_confirmPersonLinkTitle' | translate}}</h2>
<mat-dialog-content>
    <div class="confirm-person-link cols" *ngFor="let p of persons">
        <div class="col-3" style="color:deepskyblue; text-align: center;">{{'applications_lblApplicationNewPhoto' | translate}}
            <img src="data:image/jpg;base64,{{p.NewPhoto}}" *ngIf="p.NewPhoto">
            <img src="/assets/img/noprofile.png" *ngIf="!p.NewPhoto">
        </div>
        <div class="col-3" style="color:firebrick; text-align: center;">{{'applications_lblApplicationExistingPhoto' | translate}}
            <img src="data:image/jpg;base64,{{p.PhotoBase64}}" *ngIf="p.PhotoBase64">
            <img src="{{p.PhotoUrl}}" *ngIf="p.PhotoUrl">
            <img src="/assets/img/noprofile.png" *ngIf="!p.PhotoUrl && !p.PhotoBase64">
        </div>
        <div class="col-6" style="text-align: left;">
            <div *ngIf="p.ApplicationNumber">
                <label>{{'persons_lblApplication' | translate}}: </label>
                <span>{{p.ApplicationNumber}}</span>
            </div>
            <div>
                <label>{{'persons_lblName' | translate}}: </label>
                <span>{{p.Name}}</span>
            </div>
            <div>
                <label>{{'persons_lblSurname' | translate}}: </label>
                <span>{{p.Surname}}</span>
            </div>
            <div>
                <label>{{'persons_lblPersonCode' | translate}}: </label>
                <span>{{p.PersonCode}}</span>
            </div>
            <div>
                <label>{{'persons_lblPhone' | translate}}: </label>
                <span>{{p.Phone}}</span>
            </div>
            <div>
                <label>{{'persons_lblEmail' | translate}}: </label>
                <span>{{p.Email}}</span>
            </div>
            <div>
                <label>{{'persons_lblLocalEmail' | translate}}: </label>
                <span>{{p.LocalEmail}}</span>
            </div>
            <div>
                <label>{{'persons_lblIsActive' | translate}}: </label>
                <span>{{(p.IsActive ? 'value_yes' : 'value_no') | translate}}</span>
            </div>
            <div *ngIf="p.CrmId">
                <label>{{'persons_lblCrmMatchType' | translate}}: </label>
                <span>{{p.PrettyCrmMatchType}}</span>
            </div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-raised-button type="button" [mat-dialog-close]="true" color="primary">{{'ok' | translate}}</button>
    <button mat-button type="button" mat-dialog-close>{{'cancel' | translate}}</button>
</mat-dialog-actions>
