<header class="content-header">
    <nav class="breadcrumb">
        <a [routerLink]="['/applications', appNumber] | localize" class="bc-back">{{appTitle}}</a>
    </nav>
    <h1 [translate]="'applications_addProgram'"></h1>
</header>

<p><a (click)="checkOnProgramCount()" mat-raised-button color="primary">{{'applicationPrograms_finish' | translate}}</a></p>

<div class="alert alert-info" [innerHTML]="'applicationPrograms_countInfo' | translate | formatString:[limit,addedPrograms.length] | safeHtml"></div>

<div class="filter-form">
    <div class="cols">
        <div class="col-8">
            <mat-form-field>
                <input matInput [(ngModel)]="currentFilter.title" name="searchTitle" autofocus [placeholder]="'programs_lblTitle' | translate">
            </mat-form-field>
        </div>
        <div class="col-4">
            <div class="mat-align-bottom">
                <mat-checkbox [(ngModel)]="currentFilter.onlyAdded" name="onlyAdded">{{'applicationPrograms_lblOnlyAdded' | translate}}</mat-checkbox>
            </div>
        </div>
    </div>
</div>

<div class="alert alert-info" *ngIf="notes.length" [innerHTML]="notes.join('<br>') | safeHtml"></div>

<ul class="block-list">
    <li *ngFor="let p of filteredPrograms" class="block">
        <div class="program-body">
            <aside class="program-actions aside-right text-right">
                <button type="button" mat-raised-button color="primary" (click)="add(p)" *ngIf="!isAdded(p)" [disabled]="!canAdd(p)">
                    {{'add' | translate}}
                </button>
                <button type="button" mat-raised-button color="accent" (click)="remove(p)" *ngIf="isAdded(p)">
                    {{'remove' | translate}}
                </button>
                <div *ngIf="p.Specializations && p.Specializations.length && !isAdded(p)" class="spec-select">
                    <mat-form-field [floatLabel]="'never'" class="mat-em">
                        <mat-select [placeholder]="'programs_selectSpecialization' | translate" required
                                    [(ngModel)]="p.SelectedSpecialization" name="selectedSpecialization">
                            <mat-option *ngFor="let s of p.Specializations" [value]="s">{{s.SpecializationName}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </aside>
            <div class="program-data">
                <span *ngIf="!p.AllowProlongation && isLateSubmission && !isForeign && addedPrograms.indexOf(p > -1) && applicationStatus === statusEnum.New" class="not-available-program"> {{'applicationPrograms_programNotAvailable' | translate}} </span>
                <h4>{{p.Title}}</h4>
                <div class="meta meta-1">
                    <strong>{{p.Financing ? p.Financing + ', ' : ''}}</strong>
                    {{isUnitedRZ ? '' : p.Faculty + ', '}} {{p.Form}}
                </div>
                <div class="meta meta-2">
                    <span [title]="'programs_lblDegree' | translate"><i class="fa fa-graduation-cap"></i> {{p.Degree}}</span>
                    <span *ngIf="p.Speciality" [title]="'programs_lblSpeciality' | translate"><i class="fa fa-star"></i> {{p.Speciality}}</span>
                    <span *ngIf="isAdded(p) && selectedSpecialization(p)" [title]="'programs_lblDegree' | translate"><i class="fa fa-star-o"></i> {{selectedSpecialization(p)}}</span>
                </div>
                <div class="meta meta-3 text-sm">
                    <span [title]="'programs_lblLocation' | translate"><i class="fa fa-map-marker"></i> {{p.Location}}</span>
                    <span [title]="'programs_lblPricePerYear' | translate"><i class="fa fa-eur"></i> {{p.PricePerYear}}</span>
                    <span [title]="'programs_lblLengthInYears' | translate"><i class="fa fa-clock-o"></i> {{p.LengthInYears}}</span>
                    <span [title]="'programs_lblLanguage' | translate"><i class="fa fa-globe"></i> {{p.Language}}</span>
                </div>
            </div>
        </div>
    </li>
</ul>

<p><a (click)="checkOnProgramCount()" mat-raised-button color="primary">{{'applicationPrograms_finish' | translate}}</a></p>