import { Component, Inject, OnInit } from "@angular/core";
import { FormGroupDirective, NgForm } from "@angular/forms";
import { FormControl } from "@angular/forms";
import { ErrorStateMatcher } from "@angular/material/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MessagesService } from "../messages/messages.service";
import { ApplicationExam, GradeSystemType } from "../models/Application";
import { AppService } from "../services/app.service";
import { ParameterService } from "../services/parameter.service";
import { getCrmGradeString, getViisGrade, getViisRawGradeString } from "./common";

interface IGradeSystem {
    type: GradeSystemType;
    label: string;
    coef: number;
    validation: string;
}

class GradeErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        return control && control.invalid;
    }
}

@Component({
    selector: 'app-exam-grade-edit',
    templateUrl: './grade-edit.component.html'
})
export class ExamGradeDialogComponent implements OnInit {
    constructor(
        private app: AppService,
        private parameters: ParameterService,
        private messages: MessagesService,
        @Inject(MAT_DIALOG_DATA) data: {
            exam: ApplicationExam,
            gradeMappings: { [key: string]: number },
            isEditor: boolean
        }
    ) {
        this.exam = data.exam;
        this.auxType = data.exam.AuxType;
        this.grade = data.exam.RawGrade;
        this.isEditor = data.isEditor;
        this.gradeMappings = data.gradeMappings || {};
        this.auxTypes = (data.exam.AuxTypes || []).filter(t => !!t);

        const sys = this.systems.find(t => t.type == data.exam.GradeSystem);
        this.system = sys || (data.exam.Grade && this.systems.find(t => t.type == GradeSystemType.PercentageBefore2022));

        this.crmGrade = getCrmGradeString(this.app.translate, this.exam);
    }

    private readonly validationPattern100p = '^[1-9][0-9]?(\\.[0-9]{1,3})?$|^100$';

    readonly crmGrade: string;
    readonly isEditor: boolean;
    readonly gradeErrorMatcher = new GradeErrorStateMatcher();

    readonly systems: IGradeSystem[] = [
        {
            type: GradeSystemType.PercentageHigh,
            label: 'gradeSystem100pHigh',
            coef: 1,
            validation: this.validationPattern100p
        },
        {
            type: GradeSystemType.PercentageOptimal,
            label: 'gradeSystem100pOptimal',
            coef: 1,
            validation: this.validationPattern100p
        },
        {
            type: GradeSystemType.PercentageBefore2022,
            label: 'gradeSystem100pBefore2022',
            coef: 1,
            validation: this.validationPattern100p
        },
        {
            type: GradeSystemType.TenPoint,
            label: 'gradeSystem10',
            coef: 1,
            validation: '^[1-9]$|^10$'
        },
        {
            type: GradeSystemType.FivePoint,
            label: 'gradeSystem5',
            coef: 1,
            validation: '^[1-5]$'
        }
    ];

    readonly auxTypes: string[] = [];

    grade: number;
    system: IGradeSystem;
    auxType: string;
    gradeRecalcWarning: string;

    get result() {
        return {
            auxType: this.auxType,
            system: this.system?.type,
            grade: this.grade,
            coef: this.system?.coef,
            finalGrade: this.finalGrade
        };
    }

    get finalGrade(): number {
        if (this.system && this.grade) {
            const sysMap = this.gradeMappings[this.system.type];
            const grade = sysMap ? sysMap[this.grade - 1] : this.grade;

            if (!grade) {
                return undefined;
            }

            return +(+grade * this.system.coef).toFixed(2);
        }

        return undefined;
    }

    get viisGrade(): string {
        return getViisRawGradeString(this.app.translate, this.exam, this.auxType);
    }

    private prevGrade: number;
    private readonly exam: ApplicationExam;
    private readonly gradeMappings: { [key: string]: number } = {};

    ngOnInit() {
        {
            const loading = this.app.showLoading();
            this.messages.getByCode('APPLICATION_EXAM_GRADE_RECALC_WARN').subscribe(data => {
                this.app.hideLoading(loading);
                this.gradeRecalcWarning = this.app.translate.currentLang == 'en' ? data?.TextEN : data?.TextLV;
            });
        }

        {
            const loading = this.app.showLoading();
            this.parameters.getValues().subscribe(data => {
                this.app.hideLoading(loading);

                const percentageBefore2022Coef = +data.find(t => t.Code == 'GradePercentageBefore2022Coef').Value;
                const percentageHighCoef = +data.find(t => t.Code == 'GradePercentageHighCoef').Value;
                const percentageOptimalCoef = +data.find(t => t.Code == 'GradePercentageOptimalCoef').Value;

                this.systems.find(t => t.type == GradeSystemType.PercentageBefore2022).coef = percentageBefore2022Coef;
                this.systems.find(t => t.type == GradeSystemType.PercentageHigh).coef = percentageHighCoef;
                this.systems.find(t => t.type == GradeSystemType.PercentageOptimal).coef = percentageOptimalCoef;
            });
        }
    }

    onGradeChange(event) {
        this.grade = (event || '').replace(',', '.');
        this.prevGrade = this.grade;
    }

    onAuxTypeChange(event: string) {
        const val = event;

        if (val != this.auxType) {
            if (this.exam.Code.toUpperCase().startsWith('CE-')) {
                this.grade = getViisGrade(this.exam, this.auxType);
            } else {
                // prevent user from saving an invalid grade via aux type
                this.grade = this.prevGrade;
            }
        }

        this.auxType = val;
    }
}
