import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { AdmissionOrder, AdmissonOrderApplication } from '../models/Admission';
import { AdmissionService } from '../services/admission.service';
import { AppService } from '../services/app.service';

import { GridComponentBase } from '../GridComponentBase';
import { Utils } from '../Utils';

@Component({
    selector: 'app-admission-orders',
    templateUrl: './orders.component.html'
})
export class OrdersComponent extends GridComponentBase<AdmissionOrder> implements OnInit {
    constructor(
        public app: AppService,
        private service: AdmissionService
    ) {
        super(app);
    }

    @Input() admissionId: number;
    @Input() AdmissionDateTo: Date;
    @ViewChild('grid', { static: true }) grid: any;

    private tempItems: AdmissionOrder[] = [];
    private filterCodeTerms = new Subject<string>();
    private filterTitleTerms = new Subject<string>();
    filterCode: string = "";
    filterTitle: string = "";
    filterDateFrom: any = new Date();
    filterDateTo: any = new Date();

    ngOnInit() {
        this.filterDateFrom = this.AdmissionDateTo;
        if (Utils.getDateString(this.filterDateTo) < this.filterDateFrom) this.filterDateTo = this.AdmissionDateTo;
        this.load();

        this.filterCodeTerms.pipe(
            debounceTime(300),
            distinctUntilChanged()
        ).subscribe(term => this.filterGrid(term));

        this.filterTitleTerms.pipe(
            debounceTime(300),
            distinctUntilChanged()
        ).subscribe(term => this.filterGrid(term));

    }

    load() {
        let loading = this.app.showLoading();
        if (this.admissionId) {
            this.service.getOrders(this.admissionId, {
                dateFrom: this.filterDateFrom == this.AdmissionDateTo ? this.filterDateFrom : Utils.getDateString(this.filterDateFrom),
                dateTo: this.filterDateTo == this.AdmissionDateTo ? this.filterDateTo : Utils.getDateString(this.filterDateTo)
            }).subscribe(data => {
                this.gridItems = data;
                this.tempItems = [...data];
                this.app.hideLoading(loading);
            }, err => this.app.showLoadError(err));
        }
    }

    toggleGridRow(row) {
        this.grid.rowDetail.toggleExpandRow(row);

    }

    filter(term: string, field: string) {
        switch (field) {
            case 'Code': this.filterCodeTerms.next(term); break;
            case 'Title': this.filterTitleTerms.next(term); break;
            case 'Date': this.load(); break;
        }
    }


    filterGrid(term: string) {
        if (!term) {
            this.gridItems = this.tempItems;
            this.grid.offset = 0;
        } else {
            const val = term.toLowerCase();
            let temp = this.tempItems.filter(t => {
                return (t.Code || '').toLowerCase().indexOf(val) > -1 || (t.Title || '').toLowerCase().indexOf(val) > -1;
            });

            this.gridItems = temp;
            this.grid.offset = 0;
        }
    }
}
