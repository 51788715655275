import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { Message } from '../models/Message';
import { environment as ENV } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';


@Injectable()
export class MessagesService {

    constructor(public http: HttpClient) { }
    public get apiUrl(): string { return `${ENV.apiUrl}/messagetemplates` }
    private cache: { [key: string]: any } = {};

    get(filter?: {}): Observable<Message[]> {
        if (!filter && this.cache['getMessages'])
            return of(this.cache['getMessages']);

        let params = {};

        if (filter) {
            Object.keys(filter).forEach(t => {
                if (filter[t] !== null && filter[t] !== undefined)
                    params[t] = filter[t];
            });
        }

        return this.http.get<Message[]>(this.apiUrl, {
            params: params
        }).pipe(map(data => {
            this.cache['getMessages'] = data;
            return data;
        }));
    }

    getCodes(): Observable<string[]> {
        const url = `${this.apiUrl}/codes`;
        const key = 'codes';

        if (this.cache[key])
            return of(this.cache[key]);

        return this.http.get<string[]>(url).pipe(map(res => {
            this.cache[key] = res;
            return this.cache[key];
        }));
    }

    add(message: Message): Observable<Message> {
        this.clearCache();
        return this.http.post<Message>(this.apiUrl, message);
    }

    update(id: number, message: Message): Observable<Message> {
        this.clearCache();
        return this.http.put<Message>(this.apiUrl + '/' + id, message);
    }

    delete(id: number): Observable<any> {
        this.clearCache();
        return this.http.delete(this.apiUrl + '/' + id);
    }

    getById(id: number): Observable<Message> {
        if (this.cache['getMessage_' + id])
            return of(this.cache['getMessage_' + id]);

        return this.http.get<Message>(this.apiUrl + '/' + id).pipe(map(data => {
            this.cache['getMessage_' + id] = data;
            return data;
        }));
    }

    getByCode(code: string): Observable<Message> {
        if (this.cache['getMessage_c' + code])
            return of(this.cache['getMessage_c' + code]);

        const url = `${this.apiUrl}/getByCode(${code})`;

        return this.http.get<Message[]>(url).pipe(map(data => {
            data = data || [];
            this.cache['getMessage_c' + code] = data[0];
            return data[0];
        }));
    }

    getByCodes(codes: string[]): Observable<Message[]> {
        const url = `${this.apiUrl}/getByCode(${codes.join(',')})`;
        return this.http.get<Message[]>(url);
    }

    getForApplicantConsent(language: string): Observable<any[]> {
        const cacheKey = 'getMessage_forApplicantConsent';

        if (this.cache[cacheKey])
            return of(this.cache[cacheKey]);

        const url = `${this.apiUrl}/forApplicantConsent?language=${language}`;

        return this.http.get<any[]>(url).pipe(map(data => {
            this.cache[cacheKey] = data;
            return data;
        }));
    }

    private clearCache() {
        this.cache = {};
    }
}
