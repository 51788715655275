import { Component, OnInit } from '@angular/core';
import { AppService } from '../../services/app.service';
import { QuizzService } from '../../services//quizz.service';
import { Observable } from 'rxjs';

import { GridComponentBase } from '../../GridComponentBase';
import { Quizz } from '../../models/Quizz';

@Component({
    selector: 'app-quizzeditor-list',
  templateUrl: './quizzeditor-list.component.html',
  styleUrls: ['./quizzeditor-list.component.css']
})
export class QuizzeditorComponent extends GridComponentBase<Quizz> implements OnInit {

    constructor(public app: AppService, private service: QuizzService) {
        super(app);
    }

    ngOnInit() {
        this.app.setWideLayout();

        let loading = this.app.showLoading();
        this.service.get().subscribe(data => {
            this.gridItems = data;
            this.app.hideLoading(loading);
        }, err => this.app.showLoadError(err));
    }
    checkNumbers(e: any) {
        if (!((e.keyCode > 95 && e.keyCode < 106)
            || (e.keyCode > 47 && e.keyCode < 58)
            || e.keyCode == 8)) {
            return false;
        }
    }
    isValidGridItem(item: Quizz): boolean {
        if (item.Code && item.Title && item.TimeToFinish && item.TimeToFinish>0) {
            if (this.gridItems.filter(g => g.Title == item.Title || g.Code == item.Code).length == 1) {
                item.notUnique = false;
                this.gridItems.filter(g => g.notUnique).forEach((i: Quizz) => {
                    i.notUnique = false;
                });
                return true;
            }
            else {
                item.notUnique = true;
                this.gridItems.filter(g => g.Title == item.Title || g.Code == item.Code).forEach((i: Quizz) => {
                    i.notUnique = true;
                });
                return false;
            }
        }
        else
            return false;
    }

    protected createGridItem(item: Quizz): Observable<Quizz> {
        return this.service.add(item);
    }

    protected updateGridItem(item: Quizz): Observable<Quizz> {
        return this.service.update(item);
    }
    protected openQuizz(Id: number) {
        this.app.redirect('/quizzeditor/' + Id.toString());
    }
    protected deleteGridItem(item: Quizz): Observable<boolean> {
        return this.service.delete(item.Id);
    }

    protected createEmptyObject(): Quizz {
        let item = new Quizz();

        return item;
    }

    
}
