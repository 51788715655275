import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-admission-result-algorithm',
    templateUrl: './result-algorithm.component.html'
})
export class ResultAlgorithmComponent {
    constructor(public dialogRef: MatDialogRef<ResultAlgorithmComponent>) {
    }

    submit() {
        this.dialogRef.close(true);
    }
}
