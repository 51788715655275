import { KeyValue } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { DateTime } from "luxon";
import { Moment } from "moment";
import { NgxMaterialTimepickerComponent } from "ngx-material-timepicker";
import { Observable, Subject, zip } from "rxjs";
import { environment } from '../../environments/environment';
import { SendEmailsConfirmDialogComponent } from "../admissions/send-emails-confirm.component";
import { ApplicationNoteComponent } from "../applications/application-note-universal/app-note-universal.component";
import { GridComponentBase } from "../GridComponentBase";
import { MessagesService } from "../messages/messages.service";
import { ClassifierType } from "../models/Classifier";
import { ApplicationInterviewSummary, InterviewScheduleConflict, InterviewStatus } from "../models/Interview";
import { Program } from "../models/Program";
import { AdmissionService } from "../services/admission.service";
import { AppService } from "../services/app.service";
import { ClassifierService } from "../services/classifier.service";
import { ExamService } from "../services/exam.service";
import { InterviewService } from "../services/interview.service";
import { ProgramService } from "../services/program.service";
import { ContactsDialogComponent } from "../shared/contacts-dialog.component";
import { Utils } from "../Utils";

@Component({
    selector: 'app-program-interviews',
    templateUrl: './program-interviews.component.html'
})
export class ProgramInterviewsComponent extends GridComponentBase<ApplicationInterviewSummary> implements OnInit {
    constructor(
        public app: AppService,
        private service: InterviewService,
        private admissionService: AdmissionService,
        private classifierService: ClassifierService,
        private programService: ProgramService,
        private examService: ExamService,
        private route: ActivatedRoute,
        private dialog: MatDialog,
        private messageService: MessagesService
    ) {
        super(app);
    }

    // Parameters
    programId: number;
    examId: number;

    env = environment;

    interviewStatus = InterviewStatus;


    program = new Program();
    admissionTitle: string;
    examTitle: string;

    interviewers = new Map<number, string>();
    rooms = new Map<string, string>();

    allInterviews: ApplicationInterviewSummary[];

    filter: {
        Date?: string,
        RoomId?: string,
    } = { Date: null, RoomId: null };

    scheduledRooms: {
        Id: string,
        Text: string,
    }[] = [];

    scheduledDates: string[] = [];

    private notStartedText: string;
    private inProgressText: string;

    isPlanner: boolean = false;

    notificationOpts: {
        action?: string;
        code?: string;
        codeExternal?: string;
        target?: string;
    } = {}

    notificationTargets = ['selected', 'allIn'];

    appNumbersWithExamIds: {
        key?: string[];
        value?: number[];
    } = {}

    planningOpts: {
        action?: string,
        target?: string,
        date?: Moment,
        url?: string;
        roomId?: string;
    } = {}

    PlanningAction = PlanningAction;
    PlanningTarget = PlanningTarget;
    planningActions = Object.keys(PlanningAction).filter(k => isNaN(+k));
    planningTargets = Object.keys(PlanningTarget).filter(k => isNaN(+k));

    today: Date;

    ngOnInit(): void {
        this.app.setWideLayout();
        this.isPlanner = this.app.auth.isAdmin() || this.app.auth.isPowerUser();
        const now = new Date();
        this.today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        this.notStartedText = this.app.translate.instant('programInterviews_status_NotStarted');
        this.inProgressText = this.app.translate.instant('programInterviews_status_InProgress');
        this.route.params.subscribe(para => {
            this.programId = +para['id'];
            this.examId = +para['examId'];
            if (this.programId && this.examId) {
                const loading = this.app.showLoading();
                zip(
                    this.readProgramData(),
                    this.examService.getCodeAndTitle(this.examId),
                    this.programService.getInterviewers(this.programId),
                    this.classifierService.get(ClassifierType.IntervierRoom)
                ).subscribe(d => {
                    const [programData, examInfo, interviewers, classifiers] = d;
                    this.program = programData.program;
                    this.admissionTitle = programData.admissionTitle;
                    this.examTitle = examInfo.Title;
                    this.interviewers = new Map<number, string>(interviewers.map<[number, string]>(i => [i.Id, i.ShortName]));
                    this.rooms = new Map<string, string>(
                        classifiers.filter(c => c.Type === ClassifierType.IntervierRoom)
                            .map<[string, string]>(c => [c.Id, this.app.displayClassifier(c)]));

                    this.refreshInterviews().then(() => this.app.hideLoading(loading));
                });
            }
        });
    }

    async refreshInterviews() : Promise<void> {
        const loading = this.app.showLoading();
        try {
            this.allInterviews = await this.service.getInterviews(this.programId, this.examId);
            this.allInterviews.forEach(i => {
                if (i.RoomId)
                    i.RoomName = this.rooms.get(i.RoomId);
                i.canReschedule = this.isPlanner && !Object.values(i.InterviewResults).some(x => x.Status === InterviewStatus.InProgress || x.Status === InterviewStatus.Completed);
                i.isAbsent = Object.values(i.InterviewResults).some(v => v.Status === InterviewStatus.Absent);
            });

            this.buildFilterOptions();
            this.applyFilter();
}
        finally {
            this.app.hideLoading(loading);
        }
    }

    private buildFilterOptions() {
        this.scheduledRooms.length = 0;
        this.scheduledDates.length = 0;
        this.allInterviews.forEach(i => {
            if (i.InterviewTime) {
                const date = i.InterviewTime.startOf('day').toISODate();
                if (this.scheduledDates.indexOf(date) === -1)
                    this.scheduledDates.push(date);
            }
            if (i.RoomId && !this.scheduledRooms.find(r => r.Id === i.RoomId)) {
                this.scheduledRooms.push(
                    { Id: i.RoomId, Text: i.RoomName }
                );
            }
        });
        this.scheduledDates.sort((a, b) => +a - +b);
        this.scheduledRooms.sort((a, b) => this.compareStrings(a.Text, b.Text));
        if (this.filter.RoomId && this.filter.RoomId !== '–' && this.scheduledRooms.findIndex(r => r.Id === this.filter.RoomId) === -1)
            this.filter.RoomId = null;
        if (this.filter.Date && this.filter.Date !== '–' && this.scheduledDates.indexOf(this.filter.Date) === -1)
            this.filter.Date = null;
    }

    private compareStrings(a: string, b: string): number {
        return a.localeCompare(b);
    }

    private readProgramData(): Observable<{ program: Program, admissionTitle: string }> {
        const done$ = new Subject<{ program: Program, admissionTitle: string }>();
        this.programService.getById(this.programId).subscribe(program => {
            this.admissionService.getById(program.AdmissionId).subscribe(admission => {
                done$.next({ program: program, admissionTitle: admission.Title });
                done$.complete();
            }, error => done$.error(error));
        }, error => done$.error(error));
        return done$.asObservable();
    }

    applyFilter(): void {

        let roomFilter: (i: ApplicationInterviewSummary) => boolean;
        if (!this.filter.RoomId)
            roomFilter = () => true;
        else if (this.filter.RoomId === '-')
            roomFilter = (i) => !i.RoomId;
        else
            roomFilter = (i) => i.RoomId === this.filter.RoomId;

        let dateFilter: (i: ApplicationInterviewSummary) => boolean;
        if (!this.filter.Date)
            dateFilter = () => true;
        else if (this.filter.Date === '-')
            dateFilter = (i) => !i.InterviewTime;
        else
            dateFilter = (i) => i.InterviewTime && i.InterviewTime.toISODate() === this.filter.Date;

        this.gridItems = this.allInterviews.filter(i => roomFilter(i) && dateFilter(i));
    }

    private setTime(interview: ApplicationInterviewSummary, newTime: string): ApplicationInterviewSummary {
        if (!interview.InterviewTime) {
            // Don't do anything when date is not set
            return interview;
        }
        if (!/^(\d{1,2}):(\d{1,2})$/.test(newTime)) {
            // Invalid format, don't do anything
            return interview;
        }
        let interviewTime: DateTime;
        if (!newTime) {
            interviewTime = interview.InterviewTime.set({hour: 0, minute: 0, second:42, millisecond: 0});
        }
        else {
            const [hours, minutes] = newTime.split(':').map(s => parseInt(s, 10));
            interviewTime = interview.InterviewTime.set({ hour: hours, minute: minutes, second: 0 })
            if (interviewTime < DateTime.now()) {
                this.app.showError('programInterviews_problem_isInPast');
                return;
            }
        }
        const loading = this.app.showLoading();
        this.service.setInterviewTime(interview.ApplicationExamId, interviewTime)
            .then((conflicts: InterviewScheduleConflict[]) => {
                interview.InterviewTime = interviewTime;
                // Noņem iepriekšējos konfliktus visiem iesaistītajiem
                this.allInterviews.forEach(i => {
                    if (i.ScheduleConflicts.some(c => c.OtherId === interview.ApplicationExamId))
                        i.ScheduleConflicts = i.ScheduleConflicts.filter(c => c.OtherId !== interview.ApplicationExamId);
                });
                // Uzstāda konfliktus sev
                interview.ScheduleConflicts = conflicts;
                // Pievieno jaunos konfliktus otrajai pusei
                conflicts.forEach(c => {
                    const other = this.allInterviews.find(i => i.ApplicationExamId === c.OtherId);
                    if (other) {
                        const otherC = {
                            MyId: c.OtherId,
                            OtherId: c.MyId,
                            ConflictKind: c.ConflictKind,
                            ConflictingTime: interview.InterviewTime,
                            ConflictingProgramTitle: this.program.Title,
                            ConflictingExamTitle: this.examTitle
                        };
                        other.ScheduleConflicts.push(otherC);
                    }
                });
                if (conflicts.length > 0)
                    this.app.notify('programInterviews_withConflicts');

                interview.InvitationMessageCode = '';

                this.app.hideLoading(loading);
            })
            .catch(err => { this.app.showSaveError(err); this.app.hideLoading(loading) });

        return interview;
    }

    interviewStatusText(result: { Status: InterviewStatus, TotalGrade?: number }): string {
        if (!result) return this.notStartedText;
        switch (result.Status) {
            case InterviewStatus.InProgress:
                return this.inProgressText;
            case InterviewStatus.NotStarted:
            case InterviewStatus.Absent:
                return this.notStartedText;
            default:
                return result.TotalGrade.toString();
        }
    }

    openContacts(item: ApplicationInterviewSummary) {
        let dialogRef = this.dialog.open(ContactsDialogComponent, {
            disableClose: true,
            width: '250px',
            data: {
                phone: item.Phone,
                email: item.Email
            }
        });
    }

    openConflicts(conflicts: InterviewScheduleConflict[]) {
        const message = conflicts.map(sc => {
            const template = this.app.translate.instant('programInterviews_conflict_' + sc.ConflictKind);
            return Utils.formatString(template, [sc.ConflictingTime.toLocaleString(DateTime.TIME_24_SIMPLE), sc.ConflictingProgramTitle, sc.ConflictingExamTitle]);
        }).join('<br/>');
        this.app.showError(message, null, this.app.translate.instant('programInterviews_conflicts'));
        // let dialogRef = this.dialog.open(ContactsDialogComponent, {
        //     disableClose: true,
        //     width: '250px',
        //     data: {
        //         phone: item.Phone,
        //         email: item.Email
        //     }
        // });
    }

    async openNotification(applicationId: number, applicationExamId: number) {
        let emailText = await this.service.getEmailText(applicationId, applicationExamId);
        let label = 'applicationInterviewNotification_lblTitle';
        if(emailText.length > 0) {
            this.dialog.open(ApplicationNoteComponent, {
                disableClose: true,
                data: {
                    label: label,                    
                    applicationId: applicationId,
                    notes: emailText,
                    position: emailText[0].Position
                }
            });
        }
    }

    createOrDeleteNotification() {
        let rows = this.notificationOpts.target === 'selected' ? this.selectedRows : this.gridItems;        
        if(rows.length){
            if(rows.filter(x => x.InterviewTime > DateTime.now() || x.InterviewTime == null).length) {
            // if(this.notificationOpts.action === 'delete') {
            //     let title = this.app.translate.instant('programInterviews_lblDeleteNotificationEmailsConfirm');

            //     let dialogRef = this.dialog.open(ConfirmDialogComponent, {
            //         disableClose: true,
            //         width: '600px',
            //         data: {title: title, text: ""}
            //     })

            //     dialogRef.afterClosed().subscribe(async result => {
            //         if(result) {
            //             const loading = this.app.showLoading();
            //             try {
            //                 await this.service.deleteInterviewEmails({
            //                     applicationExamIds: rows.map(x => x.ApplicationExamId)
            //                 });
            //             }
            //             catch(err) {
            //                 this.app.showDeleteError(err);
            //             }
            //             finally {
            //                 this.app.hideLoading();
            //             }
            //         }
            //     })
            // }

                this.messageService.getByCode(this.notificationOpts.code).subscribe(msg => {
                    let loading = this.app.showLoading();
                    let body = this.app.translate.currentLang === 'en' ? msg.TextEN : msg.TextLV;

                    let dialogRef = this.dialog.open(SendEmailsConfirmDialogComponent, {
                        disableClose: true,
                        width: '600px',
                        data: { body: body }
                    });

                    this.app.hideLoading(loading);

                    dialogRef.afterClosed().subscribe(result => {
                        if(result) {
                            loading = this.app.showLoading();
                            this.service.sendInterviewEmails({
                                applicationExamIds: rows.filter(x => x.InterviewTime > DateTime.now() || x.InterviewTime == null).map(x => x.ApplicationExamId),
                                subject: result.subject,
                                notificationCode: this.notificationOpts.code,
                            }).subscribe(res => {
                                this.app.hideLoading(loading);
                                let flag: boolean = false;
                                for(let i = 0; i <= rows.filter(x => x.InterviewTime > DateTime.now() || x.InterviewTime == null).length; i++) {
                                    if(rows.filter(x => x.InterviewTime > DateTime.now() || x.InterviewTime == null)[i] != rows[i]) {
                                        flag = true;
                                        break;
                                    }
                                }
                                if(flag) {
                                    this.app.notify(this.app.translate.instant('programResults_sendEmails_someNotSent'));
                                }
                                else {
                                    this.app.notify(this.app.translate.instant('programResults_sendEmails_success'));
                                }
                                this.refreshInterviews();
                            }, err => this.app.showSaveError());
                        }
                    });

                }, err => this.app.showLoadError(err));
            }
            else {
                this.app.notify(this.app.translate.instant('programResults_sendEmails_notSent'));
                return;
            }
        }
        else {
            let noRowsSelected = this.app.translate.instant('noRowsSelected_GridComponentSubmit');
            this.app.notify(noRowsSelected);
            return;
        }
    }

    isValidEmailSending() {
        if(this.selectedRows.some(x => x.InterviewTime > DateTime.now() || x.InterviewTime == null) || this.selectedRows == null)
            return false;
        return true;
    }

    getProgramResults (applicationNumbers: string[]) {

    }

    private timePickerRow: ApplicationInterviewSummary;

    pickTime(interview: ApplicationInterviewSummary, picker: NgxMaterialTimepickerComponent) {
        if (!interview.canReschedule || !interview.InterviewTime) return; // vispār nevajadzēja te nonākt
        const notSet = interview.InterviewTime.minute === 0 && interview.InterviewTime.second === 42 && interview.InterviewTime.millisecond === 0;
        picker.defaultTime = notSet ? null : interview.InterviewTime.toLocaleString(DateTime.TIME_24_SIMPLE);
        this.timePickerRow = interview;
        picker.open();
    }

    timePicked(newTime: string) {
        if (this.timePickerRow)
            this.setTime(this.timePickerRow, newTime);
    }

    compareValues(a: KeyValue<string, any>, b: KeyValue<string, any>): number {
        return a.value < b.value ? -1 : a.value === b.value ? 0 : 1;
    }

    private applyScheduleChanges(interview: ApplicationInterviewSummary) : void {
        // Pārplānojot laiku un/vai telpu, dzēšam laiku
        const clearTime = !interview.InterviewTime ||
            this.planningOpts.date.toISOString().substr(0,10) !== interview.InterviewTime.toISODate() ||
            this.planningOpts.roomId !== interview.RoomId;
        this.setInterviewDate(interview, this.planningOpts.date, clearTime);
        interview.RoomId = this.planningOpts.roomId;
        interview.RoomName = this.rooms.get(interview.RoomId);
        interview.InterviewOnlineUrl = this.planningOpts.url;
    }

    private setInterviewDate(interview: ApplicationInterviewSummary, newDate: Moment, clearTime: boolean): ApplicationInterviewSummary {
        const timeSource = (clearTime ? null : interview.InterviewTime) || DateTime.fromISO("2000-01-01T00:00:42.000Z");
        interview.InterviewTime = timeSource.set({ year: newDate.year(), month: newDate.month() + 1, day: newDate.date() });
        return interview;
    }

    async updateSchedules() {
        const rows = ((this.planningOpts.target === PlanningTarget[PlanningTarget.Filtered])
            ? this.gridItems : this.selectedRows).filter(r => r.canReschedule);
        if (rows.length === 0)
            return; // Nothing to do

        const loading = this.app.showLoading();
        try {
            if (this.planningOpts.action === PlanningAction[PlanningAction.Set]) {
                await this.service.updateSchedules(
                    rows.map(r => r.ApplicationExamId),
                    this.planningOpts.date.toJSON(),
                    this.planningOpts.roomId,
                    this.planningOpts.url,
                );
                // Update info in datatable
                rows.forEach(i => this.applyScheduleChanges(i));
            }
            else {
                await this.service.clearSchedules(
                    rows.map(r => r.ApplicationExamId)
                );
                rows.forEach(i => {
                    i.InterviewTime = null;
                    i.RoomId = null;
                    i.RoomName = null;
                    i.InterviewOnlineUrl = null;
                });
            }
            // Indicate that the the previously sent notifications have been cleared on server
            rows.forEach(i => i.InvitationMessageCode = '');

            const conflicts = await this.service.getScheduleConflicts(this.program.Id, this.examId);
            this.allInterviews.forEach(i => {
                i.ScheduleConflicts = conflicts.filter(c => c.MyId === i.ApplicationExamId);
            });
            if (conflicts.length > 0) {
                this.app.notify('programInterviews_withConflicts');
            }
            else {
                this.app.notify('programInterviews_schedulesUpdated');
            }
            this.buildFilterOptions();
            this.applyFilter();
        } catch (err) {
            this.app.showSaveError();
            throw (err);
        } finally {
            this.app.hideLoading(loading);
        }
    }
}

enum PlanningAction {
    Set,
    Clear,
}

enum PlanningTarget {
    Filtered,
    Selected,
}

