import { Component, OnInit } from '@angular/core';
import { ClassifierService } from '../services/classifier.service';
import { TranslateService } from '@ngx-translate/core';
import { Classifier } from '../models/Classifier';

@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
	columns: { items: Classifier[] }[];
	constructor(
		public translate: TranslateService,
		private classifierService: ClassifierService
	) {}

    params = { year: new Date().getFullYear() };

	ngOnInit() {
		const prefix = `Footer-${this.translate.currentLang}-`;
		//let types: string[] = [];
		//for (let i = 1; i <= 4; i++) {
		//	types.push(prefix + i);
		//}
        this.classifierService.getForFooter(this.translate.currentLang).subscribe(data => {
			this.columns = [];
			for (let i = 1; i <= 4; i++) {
				let column = { items: [] };
				column.items = data
					.filter(classifier => classifier.Type === prefix + i)
					.sort((classifier1, classifier2) =>
						classifier1.Code.localeCompare(classifier2.Code)
					);
				this.columns.push(column);
			}
		});
	}
}
