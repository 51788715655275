import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewChecked } from '@angular/core';

import { AppService } from '../services/app.service';
import { TranslateService } from '@ngx-translate/core';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';

import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { BanklinkService } from './banklink.service';

import { SwedbankRequest, CitadeleRequest, SebRequest, NordeaRequest } from '../models/BankRequest';
import { OAuthRequest } from '../models/UserInfo';

@Component({
    selector: 'app-banklink',
    templateUrl: './banklink.component.html',
    styleUrls: ['./banklink.component.css']
})
export class BanklinkComponent implements OnInit, AfterViewChecked {
    bankname: string;
    @Input() swedbank: SwedbankRequest = new SwedbankRequest();
    @Input() citadele: CitadeleRequest = new CitadeleRequest();
    @Input() seb: SebRequest = new SebRequest();
    @Input() nordea: NordeaRequest = new NordeaRequest();
    @ViewChild('swedbankform', { static: false }) swedbankform: ElementRef;
    @ViewChild('citadeleform', { static: false }) citadeleform: ElementRef;
    @ViewChild('sebform', { static: false }) sebform: ElementRef;
    @ViewChild('nordeaform', { static: false }) nordeaform: ElementRef;
    oAuthRequestUrl: string = '';

    constructor(
        private service: BanklinkService,
        private router: Router,
        private app: AppService,
        private auth: AuthService,
        private route: ActivatedRoute,
        private translate: TranslateService,
        private localize: LocalizeRouterService
    ) { }

    ngOnInit() {
        this.route.params.subscribe(params => {
            if (params['bankname']) {
                this.bankname = params['bankname'];
            }
            else
                this.bankname = '';
            if (!this.auth.isAuthenticated) {
                switch (this.bankname) {
                    case 'confirmbanklinklogin': {
                        this.checkCookie(params['key']);
                        break;
                    }

                    case 'adfs': {
                        if (params['key']) {
                            const loading = this.app.showLoading();
                            this.auth.loginAdfs(params['key']).subscribe(result => {
                                this.app.hideLoading(loading);
                                if (result.redirectTo) {
                                    location.href = result.redirectTo;
                                } else {
                                    this.checkCookie();
                                }
                            },
                                error => {
                                    this.router.navigate([this.localize.translateRoute('/banklink/error')]);
                                });
                        }
                        break;
                    }

                    case 'oauth': {
                        if (window.location.hash.indexOf("#") === 0) {
                            //has acces token make auth request
                            const data = this.parseQueryString(location.hash.substr(1));
                            const loading = this.app.showLoading();
                            this.auth.loginOAuth(data.access_token, data.state, data.token_type).subscribe((result) => {
                                this.app.hideLoading(loading);
                                if (result.redirectTo) {
                                    location.href = result.redirectTo;
                                } else {
                                    this.checkCookie();
                                }
                            }, err => {
                                this.router.navigate([this.localize.translateRoute('/banklink/error')]);
                            });
                        } else {
                            const loading = this.app.showLoading();
                            this.service.getOAuthRequestData().subscribe((result: OAuthRequest) => {
                                this.app.hideLoading(loading);
                                if (result && result.ClientId)
                                    this.oAuthRequestUrl = this.addQueryString(result.AuthServerUri + result.AuthPath, {
                                        'client_id': result.ClientId,
                                        'redirect_uri': result.ReturnUri,
                                        'state': result.Nonce,
                                        'scope': result.Scope,
                                        'response_type': 'token',
                                    });
                                else
                                    this.checkCookie();
                            });

                        }
                        break;
                    }

                    case 'swedbank': {
                        const loading = this.app.showLoading();
                        this.service.getSwedbankRequestData(this.translate.currentLang).subscribe((result: SwedbankRequest) => {
                            this.app.hideLoading(loading);
                            if (result && result.VK_ENCODING)
                                this.swedbank = result;
                            else
                                this.checkCookie();
                        });
                        break;
                    }

                    case 'citadele': {
                        const loading = this.app.showLoading();
                        this.service.getCitadeleRequestData(this.translate.currentLang).subscribe((result: CitadeleRequest) => {
                            this.app.hideLoading(loading);
                            if (result && result.xmldata)
                                this.citadele = result;
                            else
                                this.checkCookie();
                        });
                        break;
                    }

                    case 'seb': {
                        const loading = this.app.showLoading();
                        this.service.getSEBRequestData(this.translate.currentLang).subscribe((result: SebRequest) => {
                            this.app.hideLoading(loading);
                            if (result && result.IB_SND_ID)
                                this.seb = result;
                            else
                                this.checkCookie();
                        });
                        break;
                    }

                    case 'nordea': {
                        const loading = this.app.showLoading();
                        this.service.getNordeaRequestData(this.translate.currentLang).subscribe((result: NordeaRequest) => {
                            this.app.hideLoading(loading);
                            if (result && result.A01Y_ACTION_ID)
                                this.nordea = result;
                            else
                                this.checkCookie();
                        });
                        break;
                    }
                }
            } else {
                this.router.navigate([this.localize.translateRoute('/')]);
            }
        });
    }
    ngAfterViewChecked() {
        if (this.swedbank && this.swedbank.VK_ENCODING && this.swedbankform) {
            this.swedbankform.nativeElement.submit();
        }
        if (this.citadele && this.citadele.xmldata && this.citadeleform) {
            this.citadeleform.nativeElement.submit();
        }
        if (this.seb && this.seb.IB_SND_ID && this.sebform) {
            this.sebform.nativeElement.submit();
        }
        if (this.nordea && this.nordea.A01Y_ACTION_ID && this.nordeaform) {
            this.nordeaform.nativeElement.submit();
        }
        if (this.oAuthRequestUrl)
            location.href = this.oAuthRequestUrl;
    }
    checkCookie(key: string = '') {
        let returnUrl = sessionStorage.getItem('returnUrl');
        if (returnUrl)
            sessionStorage.removeItem('returnUrl');

        if (!this.auth.isAuthenticated && key) {
            this.auth.checkLocalContext(key).subscribe(response => {
                this.router.navigate([this.localize.translateRoute(returnUrl || '/applications')]);
            });

        }
        else {
            if (!this.auth.isAuthenticated)
                this.router.navigate([this.localize.translateRoute(returnUrl || '/')]);
            else
                this.router.navigate([this.localize.translateRoute(returnUrl || '/applications')]);
        }
    }
    parseQueryString(queryString): any {
        let data = {},
            pairs, pair, separatorIndex, escapedKey, escapedValue, key, value;

        if (queryString === null) {
            return data;
        }

        pairs = queryString.split("&");

        for (var i = 0; i < pairs.length; i++) {
            pair = pairs[i];
            separatorIndex = pair.indexOf("=");

            if (separatorIndex === -1) {
                escapedKey = pair;
                escapedValue = null;
            } else {
                escapedKey = pair.substr(0, separatorIndex);
                escapedValue = pair.substr(separatorIndex + 1);
            }

            key = decodeURIComponent(escapedKey);
            value = decodeURIComponent(escapedValue);

            data[key] = value;
        }

        return data;
    }
    addQueryString(uri, parameters) {
        var delimiter = (uri.indexOf('?') == -1) ? '?' : '&';
        for (var parameterName in parameters) {
            var parameterValue = parameters[parameterName];
            uri += delimiter + encodeURIComponent(parameterName) + '=' + encodeURIComponent(parameterValue);
            delimiter = '&';
        }
        return uri;
    }
}
