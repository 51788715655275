import { Component, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { AppService } from '../services/app.service';

@Component({
    selector: 'app-application-aux-files',
    templateUrl: './aux-files.component.html'
})

export class AuxFilesComponent implements OnChanges {
    constructor(
        private app: AppService
    ) { }

    @Input() label: string;
    @Input() files: any[] = [];
    @Input() auxType: string;
    @Input() maxUploadSize: number;
    @Input() allowedExtensions: string;
    @Input() defaultRow: boolean = true;
    @Input() minRows: number = 0;

    @Output() select: EventEmitter<any> = new EventEmitter<any>();
    @Output() remove: EventEmitter<any> = new EventEmitter<any>();

    canAddRows: boolean;
    needsMoreFiles = false;

    list: any[];

    ngOnChanges(changes: SimpleChanges) {
        if (changes['minRows'] || changes['files']) {
            if (!this.files) this.files = [];
            // Clears out empty entries, while keeping data-bound reference
            this.files.splice(0, this.files.length, ...this.files.filter(f => !!f.fileName));
            this.list = [...this.files];
            this.padRows();
        }
    }

    addRow() {
        this.list.push(this.newRow());
        this.canAddRows = false;
    }

    removeRow(row: any) {
        let i = this.list.indexOf(row);
        if (i === -1) return;

        if (!row.fileName)
            this.doRemove(row, i);
        else
            this.app.confirm(this.app.translate.instant('confirmFileInputRemove'),
                result => { if (result) this.doRemove(row, i); });
    }

    private doRemove(row: any, i: number): void {
        if (this.list.length <= this.minRows)
            this.list[i] = this.newRow(row.index);
        else
            this.list.splice(i, 1);
        if (!!row.fileName) {
            const filesIndex = this.files.indexOf(row);
            if (filesIndex >= 0 )
                this.files.splice(filesIndex, 1);
            this.remove.emit(row);
        }
        this.padRows();
    }

    setFile(file: any, data: any) {
        file.Binary = data.file;
        file.fileName = data.file.name;
        if (this.files.indexOf(file) < 0)
            this.files.push(file);
        this.select.emit(file);
        this.recalcAvailableActions();
    }

    private padRows() {
        if (this.defaultRow && !this.list.length)
            this.list.push(this.newRow());
        while (this.list.length < this.minRows)
            this.list.push(this.newRow());
        this.recalcAvailableActions();
    }

    private newRow(index?: number): any {
        return {
            Type: this.auxType,
            index: index || this.getNextIndex()
        };
    }

    private getNextIndex(): number {
        return this.list.reduce((maxIndex, f) => +f.index > maxIndex ? +f.index : maxIndex, 0) + 1;
    }

    private recalcAvailableActions() {
        this.canAddRows = !this.list.some(f => !f.fileName);
        this.needsMoreFiles = this.list.reduce((acc,f) => !!f.fileName ? acc + 1 : acc, 0) < this.minRows;
    }
}
