<h2 mat-dialog-title>{{'applications_esign_title' | translate}}</h2>
<mat-dialog-content>
    <form #form="ngForm" class="sign-edoc">
        <section *ngIf="showDownload" class="download">
            <h3>{{'applications_esign_downloadTitle' | translate}}</h3>
            <a href="{{currentFile.url}}" target="_blank">
                <i class="fa fa-download"></i>
                {{currentFile.name}}
            </a>
        </section>
        <section class="upload">
            <h3>{{'applications_esign_uploadTitle' | translate}}</h3>
            <div class="text-warning edoc-warning" [innerHTML]="'applications_esign_help' | translate | formatString:[contractFileName] | safeHtml"></div>

            <div class="cols input-row">
                <div class="col-10">
                    <app-field-file [label]="'applications_esign_lblEdoc' | translate"
                                    [file]="file"
                                    [accept]="'.edoc'"
                                    [maxSize]="maxSize"
                                    [showRemove]="false"
                                    (select)="setFile($event)"></app-field-file>
                </div>
                <div class="col-2">
                    <button type="button" class="input-row-btn btn-icon" (click)="removeFile()" *ngIf="actualFile">
                        <i class="fa fa-trash-o"></i>
                    </button>
                </div>
            </div>

            <mat-error *ngIf="file && !validate()" class="app-field-mat-error">
                {{'applications_esign_invalidFileName' | translate}}
            </mat-error>
            <div *ngIf="showMutuallySigned">
                <mat-checkbox [(ngModel)]="mutuallySigned" name="mutuallySigned" [value]="true">
                    {{'applications_esign_lblMutuallySigned' | translate}}
                </mat-checkbox>
            </div>
        </section>
        <div>&nbsp;</div><!-- to avoid the scrollbar caused by mat-error -->
    </form>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-raised-button type="button" color="primary" (click)="submit()">{{'applications_esign_btnUpload' | translate}}</button>
    <button mat-raised-button type="button" mat-dialog-close>{{'cancel' | translate}}</button>
</mat-dialog-actions>
