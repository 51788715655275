<ng-container *ngIf="item">
    <header class="content-header">
        <nav class="breadcrumb">
            <a [routerLink]="['/admissions'] | localize" class="bc-back" [translate]="'admissions_title'"></a>
        </nav>
    </header>

    <form #form="ngForm" (submit)="submit()" class="block">
        <header class="block-header">
            <h1>{{title}}</h1>
            <aside>
                <ng-container *ngIf="showUnitedRZStatusMenu">
                    <mat-menu #unitedRZStatusMenu="matMenu">
                        <button mat-menu-item *ngFor="let s of unitedRZStatusList" (click)="setApplicationUnitedRZStatus(s)">
                            <span [class.mat-menu-item-selected]="s == item.UnitedRZStatus">{{'applicationUnitedRZStatus_' + s | translate}}</span>
                        </button>
                    </mat-menu>
                    <button mat-raised-button type="button" [matMenuTriggerFor]="unitedRZStatusMenu">
                        {{'admissions_btnApplicationUnitedRZStatus' | translate}}
                    </button>
                </ng-container>
                <button mat-raised-button type="submit" color="primary" [disabled]="!form.valid">
                    {{'save' | translate}}
                </button>
                <button type="button" mat-raised-button color="accent" *ngIf="isAdmin" (click)="refreshFromCrm()">
                    <i class="fa fa-refresh"></i>
                    {{'admissions_btnRefreshFromSIS' | translate}}
                </button>
                <button type="button" mat-raised-button color="accent" *ngIf="isAdmin" (click)="refreshFormsFromCrm()">
                    <i class="fa fa-refresh"></i>
                    {{'admissions_btnRefreshFormsFromSIS' | translate}}
                </button>
                <button mat-raised-button type="button" color="accent" (click)="anonymise()" [disabled]="!canAnonymise">
                    <i class="fa fa-user-o"></i>
                    {{'admissions_btnAnonymise' | translate}}
                </button>
            </aside>
        </header>

        <div class="block-body">
            <div class="form-main">
                <div class="cols">
                    <div class="col-6 nowrap">
                        <mat-form-field>
                            <input matInput [(ngModel)]="item.Title" name="title" [placeholder]="'admissions_lblTitle' | translate">
                        </mat-form-field>
                    </div>
                    <div class="col-3 nowrap">
                        <app-field-display [label]="'admissions_lblLevel' | translate" [value]="item.Level"></app-field-display>
                    </div>
                    <div class="col-3 nowrap">
                        <app-field-display [label]="'admissions_lblTargetAudience' | translate" [value]="item.TargetAudience"></app-field-display>
                    </div>
                </div>

                <div class="cols">
                    <div class="col-2">
                        <mat-form-field>
                            <input matInput [matDatepicker]="pickerDateFrom" [(ngModel)]="item.DateFrom" name="dateFrom"
                            [placeholder]="'admissions_lblDateFrom' | translate" required [min]="env.minDate" [max]="item.DateTo" [title]="'dateFormatHint' | translate">
                            <mat-datepicker-toggle matSuffix [for]="pickerDateFrom"></mat-datepicker-toggle>
                            <mat-datepicker #pickerDateFrom></mat-datepicker>
                            <mat-error>{{'admissionEdit_WrongDateFrom' | translate}} </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-2">
                        <mat-form-field>
                            <input matInput [matDatepicker]="pickerDateTo" [(ngModel)]="item.DateTo" name="dateTo"
                            (ngModelChange)="setMinVisibleUntilDate()"  [placeholder]="'admissions_lblDateTo' | translate" required [min]="item.DateFrom" [max]="maxDateForDateTo" [title]="'dateFormatHint' | translate">
                            <mat-datepicker-toggle matSuffix [for]="pickerDateTo"></mat-datepicker-toggle>
                            <mat-datepicker #pickerDateTo></mat-datepicker>
                            <mat-error>{{'admissionEdit_WrongDateTo' | translate}} </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-2">
                        <mat-form-field>
                            <input matInput [(ngModel)]="item.TimeTo" name="timeTo"
                                   pattern="^([01]?[0-9]|2[0-3]):[0-5][0-9]$"
                                   [placeholder]="'admissions_lblTimeTo' | translate">
                        </mat-form-field>
                    </div>

                    <div class="col-2">
                        <mat-form-field>
                            <input matInput [matDatepicker]="pickerPublishDate" [(ngModel)]="item.PublishDate" name="publishDate"
                            (ngModelChange)="setMaxDateTo()" [placeholder]="'admissions_lblPublishDate' | translate" required
                                    [min]="item.DateTo" [max]="env.maxDate" [title]="'dateFormatHint' | translate">
                            <mat-datepicker-toggle matSuffix [for]="pickerPublishDate"></mat-datepicker-toggle>
                            <mat-datepicker #pickerPublishDate></mat-datepicker>
                            <mat-error>{{'admissionEdit_WrongPublishDate' | translate}} </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-2">
                        <mat-form-field>
                            <input matInput [(ngModel)]="item.PublishTime" name="publishTime" required
                                   pattern="^([01]?[0-9]|2[0-3]):[0-5][0-9]$"
                                   [placeholder]="'admissions_lblPublishTime' | translate">
                        </mat-form-field>
                    </div>

                    <div class="col-2">
                        <mat-form-field>
                            <input matInput [matDatepicker]="pickerDateExtended" [(ngModel)]="item.DateExtended" name="dateExtended"
                            (ngModelChange)="setMaxDateTo();setMinVisibleUntilDate()" [placeholder]="'admissions_lblDateExtended' | translate" [min]="item.DateTo" [max]="env.maxDate" [title]="'dateFormatHint' | translate">
                            <mat-datepicker-toggle matSuffix [for]="pickerDateExtended"></mat-datepicker-toggle>
                            <mat-datepicker #pickerDateExtended></mat-datepicker>
                            <mat-error>{{'admissionEdit_WrongExtendedDate' | translate}} </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-2">
                        <mat-form-field>
                            <input matInput [matDatepicker]="pickerVisibleUntilDate" [(ngModel)]="item.VisibleUntilDate" name="visibleUntilDate"
                            [placeholder]="'admissions_lblVisibleUntilDate' | translate" [min]="minDateForVisibleUntilDate" [max]="env.maxDate" [title]="'dateFormatHint' | translate">
                            <mat-datepicker-toggle matSuffix [for]="pickerVisibleUntilDate"></mat-datepicker-toggle>
                            <mat-datepicker #pickerVisibleUntilDate></mat-datepicker>
                            <mat-error>{{'admissionEdit_WrongVisibleUntilDate' | translate}} </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-2">
                        <mat-form-field>
                            <input matInput [matDatepicker]="pickerContractFilesAccessibleFrom" [(ngModel)]="item.ContractFilesAccessibleFrom" name="contractFilesAccessibleFrom"
                                   [placeholder]="'admissions_lblContractFilesAccessibleFrom' | translate" [min]="env.minDate" [max]="item.ContractFilesAccessibleUntil" [title]="'dateFormatHint' | translate">
                            <mat-datepicker-toggle matSuffix [for]="pickerContractFilesAccessibleFrom"></mat-datepicker-toggle>
                            <mat-datepicker #pickerContractFilesAccessibleFrom></mat-datepicker>
                        </mat-form-field>
                    </div>

                    <div class="col-2">
                        <mat-form-field>
                            <input matInput [matDatepicker]="pickerContractFilesAccessibleUntil" [(ngModel)]="item.ContractFilesAccessibleUntil" name="contractFilesAccessibleUntil"
                                   [placeholder]="'admissions_lblContractFilesAccessibleUntil' | translate" [min]="item.ContractFilesAccessibleFrom" [max]="env.maxDate" [title]="'dateFormatHint' | translate">
                            <mat-datepicker-toggle matSuffix [for]="pickerContractFilesAccessibleUntil"></mat-datepicker-toggle>
                            <mat-datepicker #pickerContractFilesAccessibleUntil></mat-datepicker>
                        </mat-form-field>
                    </div>

                </div>

                <div class="cols">
                    <div class="col-3">
                        <mat-form-field>
                            <input matInput [(ngModel)]="item.NotificationCode" name="notificationCode"
                                   [placeholder]="'admissions_lblNotificationCode' | translate" #notifCode
                                   [matAutocomplete]="notifCodeAuto.bind(notifCode)" validateMessageCode
                                   [disabled]="!notifCodeAuto.ready"
                                   [errorStateMatcher]="messageCodeStateMatcher">
                            <app-message-autocomplete #notifCodeAuto></app-message-autocomplete>
                        </mat-form-field>
                    </div>

                    <div class="col-3">
                        <mat-form-field>
                            <input matInput [(ngModel)]="item.AppSubmitNotificationCode" name="appSubmitNotificationCode"
                                   [placeholder]="'admissions_lblAppSubmitNotificationCode' | translate" #submitNotifCode
                                   [matAutocomplete]="submitNotifCodeAuto.bind(submitNotifCode)" validateMessageCode
                                   [disabled]="!submitNotifCodeAuto.ready"
                                   [errorStateMatcher]="messageCodeStateMatcher">
                            <app-message-autocomplete #submitNotifCodeAuto></app-message-autocomplete>
                        </mat-form-field>
                    </div>

                    <div class="col-3">
                        <mat-form-field>
                            <input matInput [(ngModel)]="item.AppApproveNotificationCode" name="appApproveNotificationCode"
                                   [placeholder]="'admissions_lblAppApproveNotificationCode' | translate" #approveNotifCode
                                   [matAutocomplete]="approveNotifCodeAuto.bind(approveNotifCode)" validateMessageCode
                                   [disabled]="!approveNotifCodeAuto.ready"
                                   [errorStateMatcher]="messageCodeStateMatcher">
                            <app-message-autocomplete #approveNotifCodeAuto></app-message-autocomplete>
                        </mat-form-field>
                    </div>

                    <div class="col-3">
                        <mat-form-field>
                            <input matInput [(ngModel)]="item.InAdmissionNotificationCode" name="inAdmissionNotificationCode"
                                   [placeholder]="'admissions_lblInAdmissionNotificationCode' | translate" #admissionNotifCode
                                   [matAutocomplete]="admissionNotifCodeAuto.bind(admissionNotifCode)" validateMessageCode
                                   [disabled]="!admissionNotifCodeAuto.ready"
                                   [errorStateMatcher]="messageCodeStateMatcher">
                            <app-message-autocomplete #admissionNotifCodeAuto></app-message-autocomplete>
                        </mat-form-field>
                    </div>

                    <div class="col-3">
                        <mat-form-field>
                            <input matInput [(ngModel)]="item.ResultBudgetInNotificationCode" name="resultBudgetInNotificationCode"
                                   [placeholder]="'admissions_lblResultBudgetInNotificationCode' | translate" #resultBudgetInNotificationCode
                                   [matAutocomplete]="resultBudgetInNotificationCodeAuto.bind(resultBudgetInNotificationCode)" validateMessageCode
                                   [disabled]="!resultBudgetInNotificationCodeAuto.ready"
                                   [errorStateMatcher]="messageCodeStateMatcher">
                            <app-message-autocomplete #resultBudgetInNotificationCodeAuto></app-message-autocomplete>
                        </mat-form-field>
                    </div>

                    <div class="col-3">
                        <mat-form-field>
                            <input matInput [(ngModel)]="item.ResultFeeInNotificationCode" name="resultFeeInNotificationCode"
                                   [placeholder]="'admissions_lblResultFeeInNotificationCode' | translate" #resultFeeInNotificationCode
                                   [matAutocomplete]="resultFeeInNotificationCodeAuto.bind(resultFeeInNotificationCode)" validateMessageCode
                                   [disabled]="!resultFeeInNotificationCodeAuto.ready"
                                   [errorStateMatcher]="messageCodeStateMatcher">
                            <app-message-autocomplete #resultFeeInNotificationCodeAuto></app-message-autocomplete>
                        </mat-form-field>
                    </div>

                    <div class="col-3">
                        <mat-form-field>
                            <input matInput [(ngModel)]="item.ResultBudgetOutNotificationCode" name="resultBudgetOutNotificationCode"
                                   [placeholder]="'admissions_lblResultBudgetOutNotificationCode' | translate" #resultBudgetOutNotificationCode
                                   [matAutocomplete]="resultBudgetOutNotificationCodeAuto.bind(resultBudgetOutNotificationCode)" validateMessageCode
                                   [disabled]="!resultBudgetOutNotificationCodeAuto.ready"
                                   [errorStateMatcher]="messageCodeStateMatcher">
                            <app-message-autocomplete #resultBudgetOutNotificationCodeAuto></app-message-autocomplete>
                        </mat-form-field>
                    </div>

                    <div class="col-3">
                        <mat-form-field>
                            <input matInput [(ngModel)]="item.ResultFeeOutNotificationCode" name="resultFeeOutNotificationCode"
                                   [placeholder]="'admissions_lblResultFeeOutNotificationCode' | translate" #resultFeeOutNotificationCode
                                   [matAutocomplete]="resultFeeOutNotificationCodeAuto.bind(resultFeeOutNotificationCode)" validateMessageCode
                                   [disabled]="!resultFeeOutNotificationCodeAuto.ready"
                                   [errorStateMatcher]="messageCodeStateMatcher">
                            <app-message-autocomplete #resultFeeOutNotificationCodeAuto></app-message-autocomplete>
                        </mat-form-field>
                    </div>

                    <div class="col-3">
                        <mat-form-field>
                            <input matInput [(ngModel)]="item.ResultNotParticipatedNotificationCode" name="resultNotParticipatedNotificationCode"
                                   [placeholder]="'admissions_lblResultNotParticipatedNotificationCode' | translate" #resultNotParticipatedNotificationCode
                                   [matAutocomplete]="resultNotParticipatedNotificationCodeAuto.bind(resultNotParticipatedNotificationCode)" validateMessageCode
                                   [disabled]="!resultNotParticipatedNotificationCodeAuto.ready"
                                   [errorStateMatcher]="messageCodeStateMatcher">
                            <app-message-autocomplete #resultNotParticipatedNotificationCodeAuto></app-message-autocomplete>
                        </mat-form-field>
                    </div>
                </div>

                <div class="cols">
                    <div class="col-3">
                        <mat-form-field>
                            <input matInput [(ngModel)]="item.ContractESignEmailSubjectCode" name="contractESignEmailSubjectCode"
                                   [placeholder]="'admissions_lblContractESignEmailSubjectCode' | translate" #contractESignEmailSubjectCode
                                   [matAutocomplete]="notifCodeAuto.bind(contractESignEmailSubjectCode)" validateMessageCode
                                   [disabled]="!contractESignEmailSubjectCodeAuto.ready"
                                   [errorStateMatcher]="messageCodeStateMatcher">
                            <app-message-autocomplete #contractESignEmailSubjectCodeAuto></app-message-autocomplete>
                        </mat-form-field>
                    </div>

                    <div class="col-3">
                        <mat-form-field>
                            <input matInput [(ngModel)]="item.ContractESignEmailBodyCode" name="contractESignEmailBodyCode"
                                   [placeholder]="'admissions_lblContractESignEmailBodyCode' | translate" #contractESignEmailBodyCode
                                   [matAutocomplete]="submitNotifCodeAuto.bind(contractESignEmailBodyCode)" validateMessageCode
                                   [disabled]="!contractESignEmailBodyCodeAuto.ready"
                                   [errorStateMatcher]="messageCodeStateMatcher">
                            <app-message-autocomplete #contractESignEmailBodyCodeAuto></app-message-autocomplete>
                        </mat-form-field>
                    </div>

                    <div class="col-3">
                        <mat-form-field>
                            <input matInput [(ngModel)]="item.ContractSignApprovalEmailNotificationSubject" name="contractSignApprovalEmailNotificationSubject"
                                [placeholder]="'admissions_lblContractSignApprovalEmailNotificationSubject' | translate" #contractSignApprovalEmailNotificationSubject
                                [matAutocomplete]="notifCodeAuto.bind(contractSignApprovalEmailNotificationSubject)" validateMessageCode
                                [disabled]="!contractSignApprovalEmailNotificationSubjectAuto.ready"
                                [errorStateMatcher]="messageCodeStateMatcher">
                            <app-message-autocomplete #contractSignApprovalEmailNotificationSubjectAuto></app-message-autocomplete>
                        </mat-form-field>
                    </div>

                    <div class="col-3">
                        <mat-form-field>
                            <input matInput [(ngModel)]="item.ContractSignApprovalEmailNotificationBody" name="contractSignApprovalEmailNotificationBody"
                                [placeholder]="'admissions_lblContractSignApprovalEmailNotificationBody' | translate" #contractSignApprovalEmailNotificationBody
                                [matAutocomplete]="notifCodeAuto.bind(contractSignApprovalEmailNotificationBody)" validateMessageCode
                                [disabled]="!contractSignApprovalEmailNotificationBodyAuto.ready"
                                [errorStateMatcher]="messageCodeStateMatcher">
                                <app-message-autocomplete #contractSignApprovalEmailNotificationBodyAuto></app-message-autocomplete>
                        </mat-form-field>
                    </div>
                </div>
                <div class="cols">
                    <div class="col-3">
                        <p>
                            {{'admissions_lblPriorityCount' | translate}}: <b>{{((item.PriorityCount > 0) ? item.PriorityCount : 0)}}</b>
                        </p>
                    </div>
                    <div class="col-3">
                        <p>
                            {{'admissions_lblExtensionPriorityCount' | translate}}: <b>{{((item.ExtensionPriorityCount > 0) ? item.ExtensionPriorityCount : 0)}}</b>
                        </p>
                    </div>
                    <div class="col-3">
                        <p>
                            {{'admissions_lblPDFApplicationFormIsAvailableToApplicants' | translate}}: <b>{{(item.PdfAvailable ? 'value_yes' : 'value_no') | translate}}</b>
                        </p>
                    </div>
                    <div class="col-3">
                        <p *ngIf="item.Anonymise">
                            {{'admissions_lblAnonymise' | translate}}: <b>{{('admissionAnonymiseStatus_' + item.Anonymise) | translate}}</b>
                        </p>
                    </div>
                </div> 
            </div>

            <hr />

            <mat-tab-group (selectedTabChange)="onTabChange($event)" [@.disabled]="true">
              <mat-tab aria-label="Programs" [label]="'admissions_tabPrograms' | translate">
                <app-admission-programs [admissionId]="item.Id"></app-admission-programs>
              </mat-tab>
              <mat-tab aria-label="Results" [label]="'admissions_tabResults' | translate">
                <app-admission-results [admission]="item" *ngIf="loadResults"></app-admission-results>
              </mat-tab>
              <mat-tab aria-label="Interviews" *ngIf="item.HasInterviews" [label]="'admissions_tabInterviews' | translate">
                <app-admission-interviews [admissionId]="item.Id" *ngIf="loadInterviews"></app-admission-interviews>
              </mat-tab>
              <mat-tab aria-label="Applicants" [label]="'admissions_tabApplicants' | translate">
                <app-admission-applicants [admission]="item" *ngIf="loadApplicants"></app-admission-applicants>
              </mat-tab>
              <mat-tab aria-label="Orders" [label]="'admissions_tabOrders' | translate">
                <app-admission-orders [admissionId]="item.Id" [AdmissionDateTo]="item.DateTo" *ngIf="loadOrders"></app-admission-orders>
              </mat-tab>
            </mat-tab-group>
        </div>
    </form>
</ng-container>
