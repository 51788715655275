import { Component, ViewChild, ElementRef, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';

export class CamPhotoSnapshot {
    public constructor(
        public src: string,
        public orientation: string = 'horizontal'
    ) { }
}

@Component({
    selector: 'app-cam-photo',
    templateUrl: './cam-photo.component.html',
    styleUrls: ['./cam-photo.component.css']
})
export class CamPhotoComponent implements OnInit, OnDestroy {
    @Input() rotation: number = 0;
    @Output() error: EventEmitter<any> = new EventEmitter<any>();
    @Output() selected: EventEmitter<CamPhotoSnapshot> = new EventEmitter<CamPhotoSnapshot>();

    width: number = 640;
    height: number = 480;

    snapshots: CamPhotoSnapshot[] = [];
    previewSnapshot: any = undefined;
    snapshotLimit = 3;
    snapshotTopMargin = 10;
    ready: boolean;

    get imgRotation(): number {
        return 360 - this.rotation;
    }

    @ViewChild('video', { static: true }) private videoEl: ElementRef;
    private stream: MediaStream;

    private camWidth: number;
    private camHeight: number;

    private get isVertical(): boolean {
        return this.rotation === 90 || this.rotation === 270;
    }

    ngOnInit() {
        // populate with dummies
        for (let i = 0; i < this.snapshotLimit; i++)
            this.snapshots.push(null);

        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            navigator.mediaDevices.getUserMedia({
                video: true
            }).then((stream) => {
                this.stream = stream;
                this.videoEl.nativeElement.srcObject = stream;
                this.videoEl.nativeElement.play();

                let retry = 5;
                let interval = setInterval(() => {
                    if (this.height || retry-- < 0) {
                        clearInterval(interval);

                        this.camWidth = this.width;
                        this.camHeight = this.height;

                        if (this.rotation > 0)
                            this.rotate(this.rotation);

                        this.ready = true;
                    } else {
                        this.height = this.videoEl.nativeElement.videoHeight;
                        this.width = this.videoEl.nativeElement.videoWidth;
                    }
                }, 500);
            }).catch(err => {
                this.error.emit(err);
            });
        }
    }

    capture() {
        let dummyIndex = this.snapshots.indexOf(null);

        // discard the first snapshot
        if (dummyIndex === -1 && this.snapshots.length === this.snapshotLimit)
            this.snapshots.splice(0, 1);

        let canvas = document.createElement('canvas');
        let len = Math.max(this.width, this.height);

        canvas.width = canvas.height = len;

        let pivot = len * 0.5;
        let ctx = canvas.getContext('2d');

        ctx.save();
        ctx.translate(pivot, pivot);
        ctx.rotate(this.imgRotation * Math.PI / 180);

        let offsetX = 0;
        let offsetY = 0;

        switch (this.imgRotation) {
            case 90: offsetX = len - this.height; offsetY = len - this.width; break;
            case 180: offsetX = len - this.width; offsetY = len - this.height; break;
        }

        ctx.translate(-pivot + offsetX, -pivot + offsetY);
        ctx.drawImage(this.videoEl.nativeElement, 0, 0);
        ctx.restore();

        let cropCanvas = document.createElement('canvas');
        cropCanvas.width = this.width;
        cropCanvas.height = this.height;
        cropCanvas.getContext('2d').drawImage(canvas, 0, 0);

        let src = cropCanvas.toDataURL();
        let snap = new CamPhotoSnapshot(src, this.isVertical ? 'vertical' : 'horizontal');

        if (dummyIndex > -1) {
            this.snapshots[dummyIndex] = snap;
        } else {
            this.snapshots.push(snap);
        }
    }

    select(snapshot: CamPhotoSnapshot) {
        this.selected.emit(snapshot);
    }

    preview(snapshot: CamPhotoSnapshot) {
        if (snapshot)
            this.previewSnapshot = snapshot;
    }

    closePreview() {
        this.previewSnapshot = undefined;
    }

    rotate(rotation?: number) {
        if (rotation) {
            if (rotation % 90 !== 0) {
                console.warn('CamPhoto can be rotated by 90 degrees only.');
                rotation = 0;
            }

            this.rotation = rotation;
        } else {
            this.rotation += 90;
        }

        if (this.rotation === 360)
            this.rotation = 0;

        this.height = this.isVertical ? this.camWidth : this.camHeight;
        this.width = this.isVertical ? this.camHeight : this.camWidth;
    }

    calcSnapshotHeight() {
        let vid = this.getVideoElementSize();
        return (vid.height - (this.snapshotLimit - 1) * this.snapshotTopMargin) / this.snapshotLimit;
    }

    calcSnapshotWidth() {
        let vid = this.getVideoElementSize();
        return this.calcSnapshotHeight() * (vid.width / vid.height);
    }

    ngOnDestroy() {
        this.stream && this.stream.getTracks().forEach(t => t.stop());
    }

    private getVideoElementSize(): { width: number, height: number } {
        let clw = this.videoEl.nativeElement.clientWidth;
        let clh = this.videoEl.nativeElement.clientHeight;
 
        return {
            height: this.isVertical ? clw : clh,
            width: this.isVertical ? clh : clw
        };
    }
}