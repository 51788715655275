<header class="content-header">
    <nav class="breadcrumb" *ngIf="showForm">
        <a (click)="$event.preventDefault();cancel();" href="#" class="bc-back">{{'messages_title' | translate}}</a>
    </nav>
    <h1 *ngIf="!showForm">{{'messages_title' | translate}}</h1>
</header>

<form class="filter-form" #filterForm="ngForm" (submit)="refresh()" *ngIf="!showForm">
    <div class="cols">
        <div class="col-2">
            <mat-form-field>
                <mat-select [(ngModel)]="filter.group" [placeholder]="'messages_colGroup' | translate" name="filterGroup">
                    <mat-option [value]="undefined"></mat-option>
                    <mat-option *ngFor="let opt of groups" [value]="opt">{{opt}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="col-2">
            <mat-form-field>
                <input matInput [(ngModel)]="filter.code" name="filterCode" [placeholder]="'messages_code' | translate">
            </mat-form-field>
        </div>

        <div class="col-2">
            <mat-form-field>
                <input matInput [(ngModel)]="filter.title" name="filterTitle" [placeholder]="'messages_coltitle' | translate">
            </mat-form-field>
        </div>

        <div class="col-3">
            <mat-form-field>
                <input matInput [(ngModel)]="filter.textLv" name="filterTextLv" [placeholder]="'messages_colTextLv' | translate">
            </mat-form-field>
        </div>

        <div class="col-3">
            <mat-form-field>
                <input matInput [(ngModel)]="filter.textEn" name="filterTextEn" [placeholder]="'messages_colTextEn' | translate">
            </mat-form-field>
        </div>
    </div>

    <div>
        <button type="submit" mat-raised-button color="primary">
            {{'filter' | translate}}
        </button>
        <button type="button" (click)="filterForm.reset(); refresh()" mat-raised-button>
            {{'clear' | translate}}
        </button>
    </div>
</form>

<form #form="ngForm" (submit)="submit()" *ngIf="showForm" class="block">
    <header class="block-header" *ngIf="isEdit">
        <h3 *ngIf="isEdit">{{'messages_edittitle' | translate:selectedMessage}}</h3>
    </header>
    <header class="block-header" *ngIf="!isEdit">
        <h3>{{'messages_newtitle' | translate}}</h3>
    </header>
    

    <div class="block-body">
        
        <div class="cols">
            <div class="col-4">
                <mat-form-field>
                    <input autofocus matInput required name="TitleInput" [placeholder]="'messages_coltitle' | translate" [(ngModel)]="selectedMessage.Title" />
                </mat-form-field>
            </div>

            <div class="col-4">
                <mat-form-field>
                    <input autofocus matInput required name="CodeInput" [placeholder]="'messages_code' | translate" [(ngModel)]="selectedMessage.Code" />
                </mat-form-field>
            </div>

            <div class="col-4">
                <mat-form-field>
                    <input autofocus matInput name="GroupInput" [placeholder]="'messages_colGroup' | translate" [(ngModel)]="selectedMessage.Group" />
                </mat-form-field>
            </div>
        </div>

        <div class="help-block">
            <div>
                {{'messages_description_headline' | translate}}
                <a href="#" (click)="$event.preventDefault(); showDescription = !showDescription">
                    {{(showDescription ? 'messages_description_hide' : 'messages_description_show') | translate}}
                </a>
            </div>
            <div [innerHTML]="'messages_description' | translate | safeHtml" *ngIf="showDescription"></div>
        </div>

        
        <div class="form-row">
            <label>{{'messages_colTextLv' | translate}}</label>
            <editor [(ngModel)]="selectedMessage.TextLV" name="TextLVInput" [init]="editorConfig"></editor>
        </div>

        <div class="form-row">
            <label>{{'messages_colTextEn' | translate}}</label>
            <editor [(ngModel)]="selectedMessage.TextEN" name="TextENInput" [init]="editorConfig"></editor>
        </div>

        <div class="form-row">
            <mat-checkbox autofocus name="EditTextOnlyInput" disabled [(ngModel)]="selectedMessage.EditTextOnly">{{'messages_colEditTextOnly' | translate}}</mat-checkbox>
        </div>

        <div class="form-submit">
            <button mat-raised-button type="submit" color="primary" [disabled]="!form.valid">
                {{'save' | translate}}
            </button>
            <a mat-raised-button (click)="$event.preventDefault();cancel();">
                {{'cancel' | translate}}
            </a>
        </div>
    </div>
</form>

<ng-container *ngIf="!showForm">
    <ngx-datatable #grid
                   [rows]="gridItems"
                   [limit]="20"
                   [headerHeight]="'auto'"
                   [footerHeight]="'auto'"
                   [rowHeight]="'auto'"
                   [columnMode]="'force'"
                   [loadingIndicator]="gridLoading"
                   [externalSorting]="true"
                   [messages]="{ emptyMessage: 'gridEmpty' | translate, totalMessage: 'gridTotal' | translate }"
                   (sort)="onGridSort($event)">
        <ngx-datatable-column [name]="'messages_colGroup' | translate" prop="Group"></ngx-datatable-column>
        <ngx-datatable-column [name]="'messages_code' | translate" prop="Code"></ngx-datatable-column>
        <ngx-datatable-column [name]="'messages_coltitle' | translate" prop="Title"></ngx-datatable-column>
        <ngx-datatable-column [name]="'messages_colEditTextOnly' | translate" prop="EditTextOnly">
            <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex" let-value="row.EditTextOnly">
                <i class="fa fa-check" *ngIf="value"></i>
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [name]="'messages_colCreated' | translate" prop="Created">
            <ng-template ngx-datatable-cell-template let-row="row" let-value="row.Created">
                {{value | appDate}}
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [name]="'messages_colModified' | translate" prop="Modified">
            <ng-template ngx-datatable-cell-template let-row="row" let-value="row.Modified">
                {{value | appDate}}
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [resizeable]="false">
            <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
                <button (click)="editMessage(rowIndex)" class="btn-icon edit-group" [title]="'edit' | translate">
                    <i class="fa fa-pencil"></i>
                </button>
                <button (click)="deleteGridRow(rowIndex)" class="btn-icon"  [title]="'delete' | translate">
                    <i class="fa fa-trash"></i>
                </button>
            </ng-template>
        </ngx-datatable-column>
    </ngx-datatable>
    <div>
        <button mat-raised-button color="primary" (click)="addNew()">{{'add' | translate}}</button>
    </div>
</ng-container>
