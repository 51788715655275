import { Component, OnInit, ViewChild } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';

import { ApplicationSearch, ApplicationStatus, ApplicationResultType } from '../models/Application';
import { Admission } from '../models/Admission';
import { ApplicationService } from '../services/application.service';
import { AdmissionService } from '../services/admission.service';
import { ClassifierService } from '../services/classifier.service';
import { ReportService } from '../services/report.service';
import { AppService } from '../services/app.service';

import { GridComponentBase } from '../GridComponentBase';
import { Classifier } from '../models/Classifier';
import { UserInfo, UserType } from '../models/UserInfo';

@Component({
    selector: 'app-application-search',
    templateUrl: './search.component.html'
})
export class ApplicationSearchComponent extends GridComponentBase<ApplicationSearch> implements OnInit {
    constructor(public app: AppService, private service: ApplicationService, private admissionService: AdmissionService,
        private classifierService: ClassifierService, private reportService: ReportService) {
        super(app);
    }

    filterAdmissionId: number;
    filterNumber: string;
    filterName: string;
    filterSurname: string;
    filterPersonCode: string;
    filterEmail: string;

    Universities: Classifier[];
    Statuses: {}[] = [
        { Title: this.app.translate.instant('applicationStatus_Approved'), Status: ApplicationStatus.Approved },
        { Title: this.app.translate.instant('applicationStatus_Cancelled'), Status: ApplicationStatus.Cancelled },
        { Title: this.app.translate.instant('applicationStatus_Finished'), Status: ApplicationStatus.Finished },
        { Title: this.app.translate.instant('applicationStatus_New'), Status: ApplicationStatus.New },
        { Title: this.app.translate.instant('applicationStatus_OnHold'), Status: ApplicationStatus.OnHold },
        { Title: this.app.translate.instant('applicationStatus_SubmittedByApplicant'), Status: ApplicationStatus.SubmittedByApplicant },
        { Title: this.app.translate.instant('applicationStatus_SubmittedByClerk'), Status: ApplicationStatus.SubmittedByClerk }
    ];
    Results: {}[] = [
        { Title: this.app.translate.instant('applicationResultType_In'), Result: ApplicationResultType.In },
        { Title: this.app.translate.instant('applicationResultType_InHigher'), Result: ApplicationResultType.InHigher },
        { Title: this.app.translate.instant('applicationResultType_InLower'), Result: ApplicationResultType.InLower },
        { Title: this.app.translate.instant('applicationResultType_InManual'), Result: ApplicationResultType.InManual },
        { Title: this.app.translate.instant('applicationResultType_Out'), Result: ApplicationResultType.Out },
        { Title: this.app.translate.instant('applicationResultType_OutManual'), Result: ApplicationResultType.OutManual }
    ];
    filterStatus: string;
    filterResult: string;
    filterUniversity: string;
    filterIncludeAll: boolean = false;
    isUnitedEnrollmentClerk: boolean = false;
    isAdmin: boolean = false;
    isUnitedRZ: boolean = false;
    currentUser: UserInfo;
    filteredAdmissions: Admission[] = [];
    filterAdmissionOptions: Admission[] = [];
    showIncludeAll: boolean;
    canCreate: boolean;

    @ViewChild(DatatableComponent, { static: true }) grid: DatatableComponent;

    private tempItems: ApplicationSearch[] = [];

    ngOnInit() {
        this.app.setWideLayout();
        this.app.auth.user.subscribe(u => {
            this.currentUser = u;
            if (u) {
                this.isUnitedEnrollmentClerk = this.app.auth.isUnitedEnrollClerk(this.currentUser);
                this.isAdmin = this.app.auth.isAdmin(this.currentUser);

                if (this.isAdmin || this.app.auth.isPowerUser())
                    this.Statuses.push({
                        Title: this.app.translate.instant('applicationStatus_Deleted'),
                        Status: ApplicationStatus.Deleted
                    });
            }

            this.showIncludeAll = this.app.auth.userIn([
                UserType.Administrator,
                UserType.AdmClerk,
                UserType.PowerUser,
                UserType.UnitedEnrollmentClerk
            ]);

            this.canCreate = !this.app.auth.isDataViewer();
        });

        this.admissionService.get().subscribe(data => {
            this.filterAdmissionOptions = data;
            this.setFilterIncludeAll()
        });
        this.classifierService.get('University').subscribe(data => {
            this.Universities = data;
        });
    }
    changeAdmission() {
        if (this.filterAdmissionId) {
            let adm = this.filterAdmissionOptions.find(a => a.Id == this.filterAdmissionId);
            if (adm) {
                this.isUnitedRZ = adm.IsUnitedRZ;
            }
        }
    }
    filter() {
        this.loadItems();
    }
    setFilterIncludeAll() {
        let emptyAdmission = new Admission();
        emptyAdmission.Title = '...';
        if (this.filterIncludeAll) {
            this.filteredAdmissions = [emptyAdmission, ...this.filterAdmissionOptions];
        }
        else {
            //(includeAll || t.Admission.VisibleUntilDate == null || DbFunctions.TruncateTime(t.Admission.VisibleUntilDate) >= today)
            this.filteredAdmissions = [emptyAdmission, ...this.filterAdmissionOptions
                .filter(a => !a.VisibleUntilDate || new Date(a.VisibleUntilDate) >= new Date())];
        }
    }
    private loadItems() {
        let loading = this.app.showLoading();

        this.service.search({
            admissionId: this.filterAdmissionId || '',
            number: this.filterNumber ? this.filterNumber.trim() : '',
            name: this.filterName ? this.filterName.trim() : '',
            surname: this.filterSurname ? this.filterSurname.trim() : '',
            personCode: this.filterPersonCode ? this.filterPersonCode.trim() : '',
            email: this.filterEmail ? this.filterEmail.trim() : '',
            status: this.filterStatus ? this.filterStatus.trim() : '',
            includeAll: this.filterIncludeAll
        }).subscribe(data => {
            data.forEach(t => {
                t['createdByFullName'] = t.CreatedBy ? `${t.CreatedBy.FirstName} ${t.CreatedBy.LastName}` : '';
                t['universityName'] = this.getTranslatedValue(t.University);
            });

            this.gridItems = data;
            if (this.filterResult || this.filterUniversity) {
                this.gridItems = data.filter(d => {
                    if (!this.filterResult || this.filterResult == d.Result) {
                        if (!this.filterUniversity || (this.filterUniversity == 'notselected' && !d.University) || (d.University && this.filterUniversity.toUpperCase() == d.University.Id.toUpperCase()))
                            return true;
                    }
                    return false;
                });
            }
            this.tempItems = [...data];
            this.app.hideLoading(loading);
        }, err => this.app.showLoadError(err));
    }
    getReport() {
        this.reportService.getUnitedRZReport(this.gridItems.map(app => app.Id)).subscribe(res => {
            if (res && res._body) {
                this.downloadFile(res._body,
                    this.filterAdmissionId ? this.filteredAdmissions.find(a => a.Id == this.filterAdmissionId).Title + ".zip" : this.app.translate.instant('applications_makeUnitedRZReport') + ".zip")
            }
        }, err => this.app.showError(err));
        //report url
        //return this.reportService.getUnitedRZReportUrl(this.gridItems.map(app => app.Id));
    }
    downloadFile(blob: Blob, fileName: string): void {
        const objectUrl: string = URL.createObjectURL(blob);
        const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;

        a.href = objectUrl;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();

        document.body.removeChild(a);
        URL.revokeObjectURL(objectUrl);
    }
    getTranslatedValue(c: Classifier) {
        if (c) {
            if (this.app.translate.currentLang == 'en' && c.ValueEN)
                return c.ValueEN;
            else
                return c.Value;
        }
        else
            return '';
    }
}
