import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AppService } from '../services/app.service';
import { ApplicationService } from '../services/application.service';
import { AdmissionService } from '../services/admission.service';
import { ParameterService } from '../services/parameter.service';
import { MessagesService } from '../messages/messages.service';

import { Admission } from '../models/Admission';

import { RegisterApplicationComponent } from './register.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-applications',
  templateUrl: './applications.component.html',
  styleUrls: ['./applications.component.css']
})
export class ApplicationsComponent implements OnInit {
    constructor(
        public app: AppService,
        private service: ApplicationService,
        private admissionService: AdmissionService,
        private messageService: MessagesService,
        private paramService: ParameterService,
        private router: Router,
        private dialog: MatDialog
    ) { }

    currentApps = [];
    availableAdmissions = [];
    isApplicant = false;
    isHomines: boolean;
    quizzRedirectUrl: string = '';
    currentAppsNoItems: string;
    currentAppsDone: boolean;
    admissionsNoItems: string;
    admissionsDone: boolean;
    signRequired: boolean;

    ngOnInit() {
        const isEn = this.app.translate.currentLang === 'en';

        this.isHomines = this.app.env.isHomines;
        this.quizzRedirectUrl = this.app.env.quizzUrl + '/' + this.app.translate.currentLang + '/home';

        let myLoading = this.app.showLoading();
        this.service.getMy().subscribe(data => {
            this.currentApps = data;
            this.currentAppsDone = true;
            this.app.hideLoading(myLoading);
        }, err => this.app.showLoadError(err));

        let admLoading = this.app.showLoading();
        this.admissionService.getAvailable().subscribe(data => {
            this.availableAdmissions = data;
            this.admissionsDone = true;
            this.app.hideLoading(admLoading);
        }, err => this.app.showLoadError(err));

        this.messageService.getByCodes(['APPLICATIONS_CURRENT_NO_ITEMS', 'APPLICATIONS_ADMISSIONS_NO_ITEMS']).subscribe(data => {
            let currentNoItems = data.find(t => t.Code === 'APPLICATIONS_CURRENT_NO_ITEMS');
            this.currentAppsNoItems = currentNoItems ? isEn ? currentNoItems.TextEN : currentNoItems.TextLV : undefined;

            let admissionsNoItems = data.find(t => t.Code === 'APPLICATIONS_ADMISSIONS_NO_ITEMS');
            this.admissionsNoItems = admissionsNoItems ? isEn ? admissionsNoItems.TextEN : admissionsNoItems.TextLV : undefined;
        });

        this.paramService.getValues().subscribe(values => {
            this.signRequired = values.some(t => t.Code === 'ApplicationSignRequired' && t.Value === '1');
        });

        this.isApplicant = this.app.auth.isApplicant();
    }

    register(admission: Admission) {
        if (this.app.auth.isApplicant() || this.app.auth.isAgent()) {
            let loading = this.app.showLoading();
            this.service.create(admission.Id).subscribe(data => {
                this.app.hideLoading(loading);
                this.router.navigate([this.app.localize.translateRoute(`/applications/${data.Number}/person`)],
                    { queryParams: { returnTo: 'applications' } });
            }, err => this.app.showSaveError(err));
        } else {
            let dialogRef = this.dialog.open(RegisterApplicationComponent, {
                disableClose: true,
                autoFocus: true,
                width: '400px',
                data: { admissionId: admission.Id }
            });
        }
    }
}
