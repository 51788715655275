import { Component, OnInit, ViewChild } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { Territory } from '../models/Territory';
import { TerritoryService } from '../services/territory.service';
import { AppService } from '../services/app.service';

import { GridComponentBase } from '../GridComponentBase';

@Component({
  selector: 'app-territories',
  templateUrl: './territories.component.html',
  styleUrls: ['./territories.component.css']
})
export class TerritoriesComponent extends GridComponentBase<Territory>  implements OnInit {
    constructor(public app: AppService, private service: TerritoryService) {
        super(app);
    }

    @ViewChild(DatatableComponent, { static: true }) grid: DatatableComponent;

    private tempItems: Territory[] = [];
    private searchTerms = new Subject<string>();

    ngOnInit() {
        if (this.app.auth.isAuthenticated) {
            let loading = this.app.showLoading();
            this.service.get().subscribe(data => {
                this.gridItems = data;
                this.tempItems = [...data];
                this.app.hideLoading(loading);
            }, err => this.app.showLoadError(err));

            this.searchTerms.pipe(
                debounceTime(300),
                distinctUntilChanged()
            ).subscribe(term => this.filterGrid(term));
        }
    }

    filter(term: string) {
        this.searchTerms.next(term);
    }

    filterGrid(term: string) {
        if (!term) {
            this.gridItems = this.tempItems;
            this.grid.offset = 0;
        } else {
            const val = term.toLowerCase();
            const temp = this.tempItems.filter(t => {
                return (t.Code || '').indexOf(val) > -1 || (t.Name || '').toLowerCase().indexOf(val) > -1;
            });

            this.gridItems = temp;
            this.grid.offset = 0;
        }
    }
}
