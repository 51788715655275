import { Injectable } from '@angular/core';

import { environment as ENV } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

/**
 * Exam service
 */
@Injectable()
export class ExamService {
    constructor(public http: HttpClient) { }

    public get apiUrl(): string { return `${ENV.apiUrl}/exams` }

    getCodeAndTitle(examId: number) {
        const url = `${this.apiUrl}/${examId}/title`;
        return this.http.get<{ Code: string, Title: string }>(url);
    }
}
