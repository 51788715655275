import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Observable, of } from 'rxjs';

import { Utils } from '../Utils';

export interface IFieldFile {
    fileName: string;
    url: string;
}

/**
 * Form field file component to match angular material design.
 */
@Component({
    selector: 'app-field-file',
    template: `
    <app-field-display [label]="hasFile ? label : '&nbsp;'" [cssClass]="'file-input'" [ifEmpty]="''">
        <div class="file-input-wrapper" [class.has-file]="hasFile" [class.app-field-invalid]="maxSizeError">
            <div (click)="fileInput.click()" [class.file-input-toggle]="!hasFile" [class.file-input-value]="hasFile">
                <ng-container *ngIf="hasFile">{{file.fileName}}</ng-container>
                <ng-container *ngIf="!hasFile"><i class="fa fa-upload color-accent"></i> {{label | translate}}{{required ? ' *' : ''}}</ng-container>
            </div>
            <a *ngIf="hasFile && showRemove" class="file-input-btn file-input-remove" href="#" (click)="$event.preventDefault(); removeFile()"><i class="fa fa-times"></i></a>
            <a *ngIf="file && file.url" class="file-input-btn file-input-download" href="{{file.url}}" target="_blank"><i class="fa fa-download"></i></a>
        </div>
        <input name="file" type="file" #fileInput (change)="selectFile($event.target.files, $event)" [accept]="accept">
    </app-field-display>
    <mat-error *ngIf="maxSizeError" class="app-field-mat-error">
        {{'fileMaxSizeErrorSizes' | translate | formatString:[prettySize(size), prettySize(maxSize)]}}
    </mat-error>
    <mat-error *ngIf="wrongExtensionError" class="app-field-mat-error">
        {{'wrongExtensionError' | translate | formatString:accept}}
    </mat-error>
    `
})
export class FormFieldFileComponent {
    @Input() file: IFieldFile;
    @Input() label: string;
    @Input() accept: string;
    @Input() maxSize: number;
    @Input() showRemove: boolean = true;
    @Input() required: boolean = false;
    @Input() onRemove: () => Observable<boolean> = () => of(true);

    @Output() select: EventEmitter<any> = new EventEmitter<any>();
    @Output() remove: EventEmitter<any> = new EventEmitter<any>();

    size: number;
    maxSizeError: boolean;
    wrongExtensionError: boolean;

    get hasFile(): boolean {
        return !!(this.file && this.file.fileName);
    }

    selectFile(files: File[], event: Event) {
        if (!files || !files.length) {
            if (this.file) {
                this.file = undefined;
                this.remove.emit();
            }
            return;
        }
        const file = files[0];
        this.size = file.size;

        if (this.maxSize && this.size > this.maxSize) {
            this.maxSizeError = true;
        } else {
            this.maxSizeError = false;
            let extension = file.name.substring(file.name.lastIndexOf('.'))
            if (this.accept.indexOf(extension.toLowerCase()) < 0)
                this.wrongExtensionError = true;
            else {
                this.wrongExtensionError = false;
                this.file = <IFieldFile> { fileName: file.name, url: undefined };
                this.select.emit({ file: file, event: event });
            }
        }
    }

    removeFile() {
        this.onRemove().subscribe(result => {
            if (result) {
            }
        });
    }

    prettySize(size: number): string {
        return Utils.prettyFileSize(size);
    }
}
