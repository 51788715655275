import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { GridComponentBase } from '../../GridComponentBase';
import { Interviewer } from '../../models/Program';
import { AppService } from '../../services/app.service';
import { ProgramService } from '../../services/program.service';

@Component({
  selector: 'app-interviewersGrid',
  templateUrl: './interviewersgrid.component.html',
  styleUrls: ['./interviewersgrid.component.css']
})
export class InterviewersGridComponent extends GridComponentBase<Interviewer> implements OnInit {

    constructor(public app: AppService,private route: ActivatedRoute, public service: ProgramService) {
        super(app);
    }
    private programId: number;
    ngOnInit() {
        this.route.params.subscribe(params => {
            this.programId = params['id'];
            if (this.programId) {
                    this.service.getInterviewers(this.programId).subscribe(data => {
                    this.gridItems = data;
                }, err => this.app.showLoadError(err));
            }
        });
    }

    isValidGridItem(item: Interviewer): boolean {
        if (!item.SignatureName || !item.ShortName || !item.PersonCode) return false;
        if(!/^[0-9]{6}-?[0-9]{5}$/.test(item.PersonCode)) return false;

        return true;
    }

    private normalizeFields(item: Interviewer) {
        if (item.PersonCode && item.PersonCode.includes('-'))
            item.PersonCode = item.PersonCode.replace('-', '');
    }

    protected createGridItem(item: Interviewer): Observable<Interviewer> {
        this.normalizeFields(item);
        return this.service.createInterviewer(item);
    }

    protected updateGridItem(item: Interviewer): Observable<Interviewer> {
        this.normalizeFields(item);
        return this.service.updateInterviewer(item);
    }

    protected deleteGridItem(item: Interviewer): Observable<boolean> {
        return this.service.deleteInterviewer(item.ProgramId,item.Id);
    }

    protected createEmptyObject(): Interviewer {
        let item = new Interviewer();
        item.ProgramId = this.programId;
        return item;
    }

}
