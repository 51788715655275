<div class="register-container">
    <form name="form" class="block form-centered" (ngSubmit)="submit()" #form="ngForm" novalidate>
        <header class="block-header">
            <h1 [translate]="'eenrollmenttitle'"></h1>
        </header>
        <div class="block-body">
            <div [innerHTML]="consentBody | safeHtml" class="alert alert-warning text-left" *ngIf="consentBody"></div>

            <div class="form-row text-left" *ngIf="consentCheckbox">
                <mat-checkbox [(ngModel)]="consent" name="consent">
                    <span style="white-space: normal">{{consentCheckbox}}</span>
                </mat-checkbox>
            </div>

            <div class="form-submit">
                <button mat-raised-button color="primary" type="submit" [disabled]="!consent">{{'registerlabel' | translate}}</button>
            </div>
        </div>
    </form>
</div>
