<header class="content-header">
    <h1 [translate]="'parameters_title'"></h1>
</header>

<ngx-datatable [rows]="gridItems"
               [headerHeight]="'auto'"
               [footerHeight]="50"
               [rowHeight]="'auto'"
               [columnMode]="'force'"
               [externalSorting]="true"
               [loadingIndicator]="gridLoading"
               [messages]="{ emptyMessage: 'gridEmpty' | translate, totalMessage: 'gridTotal' | translate }"
               (sort)="onGridSort($event)">
    <ngx-datatable-column [name]="'parameters_lblCode' | translate" prop="Code">
        <ng-template ngx-datatable-cell-template let-row="row" let-value="row.Code">
            {{value}}
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [name]="'parameters_lblValue' | translate" prop="Value">
        <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex" let-value="row.Value">
            <span *ngIf="!isGridEditing(row)">
                {{value}}
            </span>
            <mat-form-field *ngIf="isGridEditing(row)">
                <input matInput
                       autofocus
                       required
                       name="value"
                       [(ngModel)]="row.Value" />
            </mat-form-field>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column>
        <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
            <button (click)="editGridRow(rowIndex)" class="btn-icon" *ngIf="!isGridEditing(row)" [title]="'edit' | translate">
                <i class="fa fa-pencil"></i>
            </button>
            <button (click)="saveGridRow(rowIndex)" 
                    class="btn-icon" 
                    *ngIf="isGridEditing(row)"
                    [disabled]="!isValidGridItem(row) || !checkDataURL(row)" 
                    [title]="'save' | translate">
                <i class="fa fa-floppy-o"></i>
            </button>
            <button (click)="cancelGridRow(rowIndex)" class="btn-icon" *ngIf="isGridEditing(row)" [title]="'cancel' | translate">
                <i class="fa fa-ban"></i>
            </button>
        </ng-template>
    </ngx-datatable-column>
</ngx-datatable>
