import { Component, ContentChild, Input, OnChanges, SimpleChanges, TemplateRef } from '@angular/core';
import { Program } from '../models/Program';

@Component({
    selector: 'app-program-content-wrapper',
    templateUrl: 'program-content-wrapper.component.html'
})
export class ProgramContentWrapperComponent {
    @Input() program: Program;
    @Input() formTitle: string;
    @Input() breadcrumbTitle: string;
    @ContentChild(TemplateRef, { static: true }) content: TemplateRef<Program>;
}
