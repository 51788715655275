export enum UserType {
    Anonymous = 'Anonymous',
    Applicant = 'Applicant',
    Administrator = 'Administrator',
    PowerUser = 'PowerUser',
    AdmClerk = 'AdmClerk',
    UnitedEnrollmentClerk = 'UnitedEnrollmentClerk',
    ExamClerk = 'ExamClerk',
    Agent = 'Agent',
    QuizzUser = 'QuizzUser',
    DataViewer = 'DataViewer'
}

export class Claim {
    ClaimType: string;
    Value: string;
    ValueType: string;
}

export class UserInfo {
    email: string;
    firstName: string;
    lastName: string;
    personCode: string;
    type: UserType | string;
    accountType: string;
    sessionKey: string;
    startUrl: string;
    universityId: string;
    signoutUrl: string;
}
export class OAuthRequest {
    UserInfoPath: string;
    AuthPath: string;
    AuthServerUri: string;
    ClientId: string;
    ReturnUri: string;
    Nonce: string;
    Scope: string;
}
export class UserCrypto {
    key: string;
    iv: string;
}
export class UserListItem {
    Id: string;
    Username: string
    AccountType: string;
    Name: string;
    LastName: string;
    UserType: string;
    Created:Date;
    LastLogin:Date;
    PersonCode: string;
    UniversityId: string;
}
export const UserTypes: any = [
    { value: 'Administrator', text: 'userType_Administrator' },
    { value: 'Applicant', text: 'userType_Applicant' },
    { value: 'PowerUser', text: 'userType_PowerUser' },
    { value: 'AdmClerk', text: 'userType_AdmClerk' },
    { value: 'ExamClerk', text: 'userType_ExamClerk' },
    { value: 'Agent', text: 'userType_Agent' },
    { value: 'UnitedEnrollmentClerk', text: 'userType_UnitedEnrollmentClerk' }
];
export const AccountTypes: any = [
    { value: 'ADFS', text: 'accountType_ADFS' },
    { value: 'BankLink', text: 'accountType_BankLink' },
    { value: 'Registered', text: 'accountType_Registered' }
];
