import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DatatableComponent } from '@swimlane/ngx-datatable';

import { Admission } from '../models/Admission';

import { AdmissionService } from '../services/admission.service';
import { AppService } from '../services/app.service';

import { GridComponentBase } from '../GridComponentBase';
import { AdmissionAddComponent } from './admission-add.component';
import { MatDialog } from '@angular/material/dialog';

// we need the Date objects for filtering (WebApi returns strings)
class AdmissionItem extends Admission {
    _dateFrom: Date;
    _dateTo: Date;
    _dateExtended: Date;
    _publishDate: Date;
    _visibleUntil: Date;
}

@Component({
  selector: 'app-admissions',
  templateUrl: './admissions.component.html',
  styleUrls: ['./admissions.component.css']
})
export class AdmissionsComponent extends GridComponentBase<AdmissionItem> implements OnInit {
    constructor(
        public app: AppService,
        public dialog: MatDialog,
        private service: AdmissionService,
        private router: Router
    ) {
        super(app);

        this.todayForFiltering = new Date();
        this.todayForFiltering.setHours(23);
        this.todayForFiltering.setMinutes(59);
    }

    admissionCrmIds: string[] = [];
    filterOnlyActual: boolean = true;
    isAdmin: boolean;

    private tempItems: AdmissionItem[] = [];
    @ViewChild(DatatableComponent, { static: true }) private grid: DatatableComponent;

    private todayForFiltering: Date;

    ngOnInit() {
        this.app.setWideLayout();
        this.app.auth.user.subscribe(u => {
            this.isAdmin = this.app.auth.isAdmin();
        });
        let loading = this.app.showLoading();
        this.service.get().subscribe(data => {
            let items = data.map(t => {
                let item = <AdmissionItem>t;
                this.setDates(item);
                return item;
            });

            this.tempItems = [...items];
            this.admissionCrmIds = data.map(t => t.CrmId);
            this.filter();
            this.app.hideLoading(loading);
        }, err => this.app.showLoadError(err));
    }

    addAdmission() {
        let dialogRef = this.dialog.open(AdmissionAddComponent, {
            disableClose: true,
            width: '600px',
            data: {
                currentAdmissions: this.admissionCrmIds
            }
        });

        dialogRef.afterClosed().subscribe((result: Admission) => {
            if (result) {
                this.router.navigate([this.app.localize.translateRoute(`/admissions/${result.Id}`)]);
            }
        });
    }

    filter() {
        this.gridItems = this.tempItems.filter(t => !this.filterOnlyActual || this.isActual(t));
        this.grid.offset = 0;
    }

    private isActual(admission: AdmissionItem): boolean {
        return this.isActualDate(admission._dateFrom)
            || this.isActualDate(admission._dateTo)
            || this.isActualDate(admission._dateExtended)
            || this.isActualDate(admission._publishDate)
            || this.isActualDate(admission._visibleUntil);
    }

    private isActualDate(date: Date): boolean {
        return date && date >= this.todayForFiltering;
    }

    private setDates(admission: AdmissionItem) {
        admission._dateFrom = this.getDate(admission.DateFrom);
        admission._dateTo = this.getDate(admission.DateTo);
        admission._dateExtended = this.getDate(admission.DateExtended);
        admission._publishDate = this.getDate(admission.PublishDate);
        admission._visibleUntil = this.getDate(admission.VisibleUntilDate);
    }

    private getDate(date: string | Date): Date {
        return date ? typeof date === 'string' ? new Date(date) : date : undefined;
    }
}
