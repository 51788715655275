import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { Share } from '../models/Share';

import { ShareService } from '../services/share.service';
import { AppService } from '../services/app.service';

import { GridComponentBase } from '../GridComponentBase';
import { Utils } from '../Utils';

@Component({
    selector: 'app-shared-items',
    templateUrl: './shared-items.component.html'
})
export class SharedItemsComponent extends GridComponentBase<Share> implements OnInit {
    constructor(public app: AppService, private service: ShareService) {
        super(app);
    }

    filterType: string = 'Application';
    filterUpn: string;
    filterDateFrom: any = new Date();
    filterDateTo: any = new Date();

    showView: boolean;

    private tempItems: Share[] = [];

    ngOnInit() {
        this.showView = true;
        this.app.setWideLayout();
        this.loadItems();
    }
    
    filter() {
        this.loadItems();
    }

    getObjectUrl(item: Share): any {
        return this.service.getObjectRoute(item);
    }

    protected deleteGridItem(item: Share): Observable<any> {
        return this.service.delete(item.Id);
    }

    private loadItems() {
        let loading = this.app.showLoading();

        this.service.get({
            objectType: this.filterType,
            upn: this.filterUpn,
            dateFrom: Utils.getDateString(this.filterDateFrom),
            dateTo: Utils.getDateString(this.filterDateTo)
        }).subscribe(data => {
            this.gridItems = data;
            this.tempItems = [...data];
            this.app.hideLoading(loading);
        }, err => this.app.showLoadError(err));
    }
}
