import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { AlertMessage, AlertMessageType } from '../services/alert.service';

@Component({
    template: `
        <div class="alert-dialog alert-dialog-{{message.type.toLowerCase()}}">
            <h2 mat-dialog-title *ngIf="message.title">{{message.title}}</h2>
            <h2 mat-dialog-title *ngIf="isError && !message.title">{{'appErrorDialogTitle' | translate}}</h2>
            <mat-dialog-content>
                <div [innerHTML]="message.text | safeHtml"></div>
            </mat-dialog-content>
            <mat-dialog-actions>
                <button mat-raised-button mat-dialog-close color="accent">OK</button>
            </mat-dialog-actions>
        </div>`
})
export class AlertDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<AlertDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public message: AlertMessage) { }

    get isError(): boolean {
        return this.message.type === AlertMessageType.Error;
    }
}
