import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { PersonalEmailToApplicantModel } from "../models/Application";
import { AppService } from "../services/app.service";
import { ApplicationService } from "../services/application.service";

@Component({
    templateUrl: './personal-email-history.component.html',
    styleUrls: ['./personal-email-history.component.css']
})

export class PersonalEmailHistoryComponent implements OnInit {
    constructor(
        public dialogRef: MatDialogRef<PersonalEmailHistoryComponent>,
        @Inject(MAT_DIALOG_DATA) public appId: number,
        private readonly service: ApplicationService,
        public readonly app: AppService
    ) { }

    personalEmails: PersonalEmailToApplicantModel[];

    async ngOnInit() {
        const loading = this.app.showLoading();
        try {
            this.personalEmails = await this.service.getPersonalEmails(this.appId);
            this.personalEmails.sort((a, b) => a.Date < b.Date ? 1 : -1);
            this.personalEmails.forEach(x => x.Body = x.Body.replace(/\n/g, '<br/>'));
        }
        catch (err) {
            this.app.showLoadError(err);
        }
        finally {
            this.app.hideLoading(loading);
        }
    }
}
