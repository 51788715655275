import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from './auth.service';

import {
    Program, Interviewer, ProgramResult, ProgramExam, ApplicationResultType, SignedAppsViewModel,
    ProgramApplicationStatus, ProgramSpecialization, InterviewPersonnel
} from '../models/Program';
import { ProgramApplicationContract, EnrollmentOrder } from '../models/Application';
import { Admission } from '../models/Admission';

import { environment as ENV } from '../../environments/environment';
import { AppService } from './app.service';
import { HttpClient } from '@angular/common/http';

/**
 * Program service
 */
@Injectable()
export class ProgramService {
    constructor(public http: HttpClient, public auth: AuthService, private app: AppService) { }

    public get apiUrl(): string { return `${ENV.apiUrl}/programs` }

    get(admissionId?: number): Observable<Program[]> {
        const url = `${this.apiUrl}${admissionId ? `?admissionId=${admissionId}` : ''}`;
        return this.http.get<Program[]>(url);
    }

    getById(id: number): Observable<Program> {
        const url = `${this.apiUrl}/${id}`;
        return this.http.get<Program>(url);
    }

    getAvailable(admissionId: number): Observable<Program[]> {
        const url = `${this.apiUrl}/available?admissionId=${admissionId}`;
        return this.http.get<Program[]>(url);
    }

    getSpecializations(Id: number): Observable<ProgramSpecialization[]> {
        const url = `${this.apiUrl}/${Id}/specs`;
        return this.http.get<ProgramSpecialization[]>(url);
    }

    update(item: Program) {
        const url = `${this.apiUrl}/${item.Id}`;
        return this.http.put(url, {
            Title: item.Title,
            Quota: item.Quota,
            AllowProlongation: item.AllowProlongation,
            AllowAdmission: item.AllowAdmission,
            AllowApplicantToApply: item.AllowApplicantToApply,
            NotificationCode: item.NotificationCode
        });
    }

    getResults(id: number): Observable<ProgramResult[]> {
        const url = `${this.apiUrl}/${id}/results`;
        return this.http.get<ProgramResult[]>(url);
    }

    getContractStatuses(id: number): Observable<ProgramApplicationStatus[]> {
        const url = `${this.apiUrl}/${id}/contracts`;
        return this.http.get<ProgramApplicationStatus[]>(url);
    }

    getExams(id: number): Observable<ProgramExam[]> {
        const url = `${this.apiUrl}/${id}/exams`;
        return this.http.get<ProgramExam[]>(url);
    }

    getBreadcrumbData(programId: number): Observable<any> {
        const url = `${this.apiUrl}/${programId}/breadcrumb`;
        return this.http.get(url);
    }

    setResult(programId: number, applicationId: number, result: ApplicationResultType): Observable<any> {
        const url = `${this.apiUrl}/${programId}/result`;
        return this.http.put(url, {
            ApplicationId: applicationId,
            Result: result
        });
    }

    createNotification(code: { local: string, external: string }, programApplicationIds: number[]): Observable<any> {
        const url = `${this.apiUrl}/notification`;
        return this.http.post(url, {
            LocalCode: code.local,
            ExternalCode: code.external,
            ProgramApplicationIds: programApplicationIds
        });
    }

    deleteNotification(programApplicationIds: number[]): Observable<any> {
        const url = `${this.apiUrl}/notification`;
        return this.http.post(url, {
            Delete: true,
            ProgramApplicationIds: programApplicationIds
        });
    }

    /**
     * Create contracts.
     * @param programId
     * @param applicationIds {string[]} Application number list
     * @param signerId
     * @param reasonId
     * @param discountId
     */
    createContracts(programId: number, applicationIds: number[], contract: CreateContractsOptions, force: boolean = false) : Observable<string> {
        const subject = new Subject<string>();
        const problemsText = (response: { Problem: string, Applications: string[] }[]) =>
            response.map<string>(p => this.app.translate.instant(p.Problem, { apps: p.Applications.join(',')})).join('<br/>');
        const url = `${this.apiUrl}/actions/createcontracts`;
        this.http.post<any>(url, {
            ProtocolNr: contract.protocolNr,
            ProtocolDate: contract.protocolDate,
            SignerId: contract.signerId,
            ReasonId: contract.reasonId,
            DiscountId: contract.discountId,
            ProgramId: programId,
            ApplicationIds: applicationIds,
            MinPaymentDate: contract.minPaymentDate,
            MedInstitutionId: contract.medInstitutionId,
            SpecialConditions: contract.specialConditions,
            SpecialConditionsEn: contract.specialConditionsEn,
            ContractStatus: contract.status,
            TotalCP: contract.totalCP,
            StartYear: contract.startYear,
            StartSemester: contract.startSemester,
            Force: force
        }).subscribe(result => {
            if (result.Errors)
                subject.error(problemsText(result.Errors));
            else if (result.Warnings)
                subject.next(problemsText(result.Warnings));
            else
                subject.next();
        }, err => {
            subject.error(err);
        });
        return subject.asObservable();
    }

    getContract(programId: number, applicationid: number): Observable<ProgramApplicationContract> {
        const url = `${this.apiUrl}/${programId}/contract?applicationid=${applicationid}`;
        return this.http.get<ProgramApplicationContract>(url);
    }
    getAdmission(programId: number): Observable<Admission> {
        const url = `${this.apiUrl}/${programId}/admission`;
        return this.http.get<Admission>(url);
    }
    signContract(programId: number, appNumbers: string[], isESign?: boolean): Observable<SignedAppsViewModel> {
        const url = `${this.apiUrl}/${programId}/signcontracts`;
        return this.http.post<SignedAppsViewModel>(url, { appNumbers: appNumbers, isESign: isESign });
    }
    createApplicant(programId: number, appNumbers: string[]): Observable<string[]> {
        const url = `${this.apiUrl}/${programId}/createapplicants`;
        return this.http.post<string[]>(url, { appNumbers: appNumbers });
    }
    createEnrollOrder(programId: number, order: EnrollmentOrder): Observable<EnrollmentOrder> {
        const url = `${this.apiUrl}/${programId}/enrollorder`;
        return this.http.post<EnrollmentOrder>(url,order);
    }

    getInterviewers(programId: number): Observable<Interviewer[]> {
        const url = `${this.apiUrl}/${programId}/interviewers`;
        return this.http.get<Interviewer[]>(url);
    }

    createInterviewer(item: Interviewer): Observable<Interviewer> {
        const url = `${this.apiUrl}/${item.ProgramId}/interviewers`;
        return this.http.post<Interviewer>(url, {
            SignatureName: item.SignatureName,
            ShortName: item.ShortName,
            PersonCode: item.PersonCode
        });
    }

    updateInterviewer(item: Interviewer): Observable<Interviewer> {
        const url = `${this.apiUrl}/${item.ProgramId}/interviewers/${item.Id}`;
        return this.http.put<Interviewer>(url, {
            SignatureName: item.SignatureName,
            ShortName: item.ShortName,
            PersonCode: item.PersonCode
        });
    }

    deleteInterviewer(programId:number,id: number): Observable<boolean> {
        const url = `${this.apiUrl}/${programId}/interviewers/${id}`;
        return this.http.delete(url).pipe(map((res: Response) => {
            return res.ok;
        }));
    }

    getInterviewPersonnel(programId: number): Observable<InterviewPersonnel[]> {
        const url = `${this.apiUrl}/${programId}/interviewpersonnel`;
        return this.http.get<InterviewPersonnel[]>(url);
    }

    createInterviewPersonnel(item: InterviewPersonnel): Observable<InterviewPersonnel> {
        const url = `${this.apiUrl}/${item.ProgramId}/interviewpersonnel`;
        return this.http.post<InterviewPersonnel>(url, {
            UserIdentity: item.UserIdentity,
            FullName: item.FullName,
            PersonCode: item.PersonCode
        });
    }

    updateInterviewPersonnel(item: InterviewPersonnel): Observable<InterviewPersonnel> {
        const url = `${this.apiUrl}/${item.ProgramId}/interviewpersonnel/${item.Id}`;
        return this.http.put<InterviewPersonnel>(url, {
            UserIdentity: item.UserIdentity,
            FullName: item.FullName,
            PersonCode: item.PersonCode
        });
    }

    deleteInterviewPersonnel(programId:number,id: number): Observable<any> {
        const url = `${this.apiUrl}/${programId}/interviewpersonnel/${id}`;
        return this.http.delete(url);
    }

    getSignerName(programId: number): Observable<string>{
        const url = `${this.apiUrl}/${programId}/getSignerName`;
        return this.http.get<string>(url);
    }
}

interface CreateContractsOptions {
    signerId: string,
    reasonId: string,
    discountId?: string,
    minPaymentDate?: Date,
    medInstitutionId?: string,
    specialConditions?: string,
    specialConditionsEn?: string,
    protocolNr?: string,
    protocolDate?: Date,
    status?: string,
    totalCP?: number,
    startYear?: number,
    startSemester?: number
}
