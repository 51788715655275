import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject, of, zip } from 'rxjs';
import { map, switchMap, debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { AppService } from '../services/app.service';
import { ApplicationService } from '../services/application.service';
import { AdmissionService } from '../services/admission.service';
import { ClassifierService } from '../services/classifier.service';
import { MessagesService } from '../messages/messages.service';
import { ProgramService } from '../services/program.service';
import { ParameterService } from '../services/parameter.service';

import {
    Application, ApplicationNote, ApplicationExam, ApplicationPerson, ApplicationProgram,
    ApplicationStatus, ApplicationContractStatus, ApplicationAuxData, AuxType, ApplicationProgramPriority, ApplicationNotePosition,
    ApplicationPreviousUniversity, ApplicationForContract, ApplicationForEnrollment, ApplicationEducation, ApplicationUnitedRZStatus,
    StudentPayStatusModel,
    ViisGrade,
    PersonalEmailToApplicantModel,
    GradeSystemType
} from '../models/Application';
import { StudyLevel } from '../models/Admission';
import { Program, ApplicationResultType } from '../models/Program';
import { Classifier } from '../models/Classifier';
import { StudentPayModel } from '../models/StudentPayModel';
import { UserType } from '../models/UserInfo';
import { SignEdocDialogComponent } from './sign-edoc.component';

import { Utils } from '../Utils';
import {
    defaultMaxFileUploadSize,
    defaultFileUploadExtensions,
    parseValidationErrors,
    AuxDataParser,
    ApplicationPersonToLink,
    getViisGrade,
    getViisGradeString,
    getCrmGradeString,
    getViisRawGradeString
} from './common';
import { ContractDialogOptions, ContractDialogComponent } from '../shared/contract-dialog.component';
import { SchedulesDialogOptions, ApplicationSchedulesComponent } from '../applications/application-schedules/application-schedules.component';
import { ShareComponent } from '../share/share.component';
import { EnrollmentDialogOptions, EnrollmentDialogComponent } from '../shared/enrollment-dialog.component';
import { ConfirmPersonLinkDialogComponent } from './confirm-person-link.component';

import { ICanDeactivateGuard } from '../CanDeactivateGuard';
import { SendEmailsConfirmDialogComponent } from '../admissions/send-emails-confirm.component';
import { PersonalEmailHistoryComponent } from './personal-email-history.component';
import { MatDialog } from '@angular/material/dialog';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Message } from '../models/Message';
import { ExamGradeDialogComponent } from './grade-edit.component';

// Cache the "return to" location so it's persistent across application sub-paths
let applicationReturnToCache: string;

export function setReturnTo(value: string) {
    applicationReturnToCache = value;
}

interface IAppFile {
    name: string;
    url: string;
    pdfUrl?: string;
    type: string;
    status: string;
    institution?: string;
    index: number
}

function buildFiles(files: any[]): IAppFile[] {
    const result: IAppFile[] = [];

    const getIndex = (type) => {
        switch (type) {
            case 'Payment': return 1333;
            case 'Suborder': return 1222;
            case 'Contract': return 1111;
            case 'ApplicationForm': return 999;
            case 'PersonDocument': return 888;
        }

        return 0;
    };

    const add = (file) => {
        file && (file.fileName || file.Type === 'Contract') && result.push({
            name: !!file.fileName ? file.fileName : '',
            url: file.url,
            pdfUrl: file.pdfUrl,
            type: file.Type.split('_')[0],
            status: file.status,
            institution: file.institution,
            index: getIndex(file.Type)
        });
    };

    files.forEach(t => add(t));
    result.sort((a, b) => b.index - a.index);

    return result;
}

@Component({
    selector: 'app-application-edit',
    templateUrl: './application-edit.component.html',
    styleUrls: ['./application-edit.component.css']
})
export class ApplicationEditComponent implements OnInit, ICanDeactivateGuard {
    constructor(
        public readonly app: AppService,
        private readonly service: ApplicationService,
        private readonly programService: ProgramService,
        private readonly admissionService: AdmissionService,
        private readonly classifierService: ClassifierService,
        private readonly messageService: MessagesService,
        private readonly paramService: ParameterService,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly dialog: MatDialog,
        private readonly sanitizer: DomSanitizer
    ) { }

    readonly getViisGrade = getViisGrade;

    item: Application = new Application();
    itemExists: boolean = true;
    prevUni: ApplicationPreviousUniversity;
    thesis: ApplicationAuxData = new ApplicationAuxData();
    thesisOld: ApplicationAuxData = new ApplicationAuxData();
    programs: ApplicationProgram[] = [];
    acceptedInProgram: ApplicationProgram;
    admissionTitle: string;
    submitMessage: string;
    isAdmin: boolean;
    isPowerUser: boolean;
    isEditor: boolean;
    isUnitedEnrollClerk: boolean;
    isAgent: boolean;
    isDataViewer: boolean;
    isApplicant: boolean;
    isEmployeeThatCanSeeContractStatus: boolean;
    isEmployeeThatCanEditGrades: boolean;
    persons: ApplicationPerson[] = [];
    selectedPersons: ApplicationPerson[] = [];
    exams: ApplicationExam[] = [];
    filledExamIds: number[] = [];
    requiredExamIds: number[] = [];
    notes: ApplicationNote[] = [];
    programResultNotes: string[] = [];
    examTree: {
        id: number,
        name: string,
        item: ApplicationProgram,
        groups: { name: string, exams: ApplicationExam[], maxGrade: number }[]
    }[] = [];
    showAllExamResults: boolean;
    programLimit: number;
    dataLoaded: boolean;
    contractStatusToSet: string = '';
    contractStatusRead: ApplicationContractStatus = null;
    contractStatuses: Classifier[] = [];
    contractStatus: string;
    workplaces: Classifier[] = [];
    admissionRulesUrl: string;
    errors: any[] = [];
    isDoctor: boolean;
    applicationNotePosition = ApplicationNotePosition;
    translations: any;
    programResult: string;
    isResidentAdmission: boolean;
    IsAdmissionPublished: boolean = false;
    isHomines: boolean = false;
    isUnitedRZ: boolean = false;
    isForeign: boolean = false;
    showBanklink: boolean = false;
    contractCreated: boolean = false;
    contractSigned: boolean = false;
    applicantCreatedButtonClicked: boolean = false;
    orderCreated: boolean = false;
    statusEnum = ApplicationStatus;
    quizzRedirectUrl: string;
    onlineApplicationFormIsAvailable: boolean = false;
    isLateSubmission: boolean = false;
    pdfAvailable: boolean = false;
    showApplicantDocuments: boolean;

    showDocuments: boolean;
    showExams: boolean;
    showConfirmations: boolean;
    showPay: boolean;
    showPersonGrid: boolean;
    showThesis: boolean;
    showWayOfApp: boolean;
    showWhereFoundInfo: boolean;

    maxFileUploadSize: number;
    maxEdocUploadSize: number;
    fileUploadExtensions: string;
    contactsEmailSubject: string = '';
    educations: ApplicationEducation[] = [];
    aux: any = {
        WayOfApp: {},
        WhereFoundInfo: {},
        applicationForm: {},
        personDocuments: [],
        educationDocuments: [],
        studentContracts: [],
        enrollmentOrders: [],
        paymentFiles: [],
        examFiles: {},
        workplaceFiles: {},
    };
    under18Message: string;
    payInfo: string;
    paymentFileUploaded: boolean;
    reasonNeeded: boolean;
    terminationReasonId: string;
    terminationReasons: Classifier[];
    studentPayStatusModel: StudentPayStatusModel = new StudentPayStatusModel();
    signRequired: boolean;
    esignHelpText: string;
    confirmRulesText: string;
    contractFilesAccessibleFrom: Date;
    contractFilesAccessibleUntil: Date;
    areContractFilesAccessible: boolean = false;
    personalEmailSubjectText: string;
    selectStudiesInLaterStagesText: string;
    applicationConfirmGradesText: string;
    applicationConfirmHominesRulesText: string;
    applicationStudiesAtLaterStagesText: string;
    applicationIsGraduateText: string;
    applicationApplicationForCreditTransferText: string;
    applicationIsIncompleteText: string;
    applicationIsNotAvailableMsgText: string;
    applicationConfirmRemovingLateSubmissionTagText: string;
    personalEmail: PersonalEmailToApplicantModel = new PersonalEmailToApplicantModel();
    contractDVSId: number;
    DVSlink: string;
    personDocumentsDownloadUrl: string;
    actionPay: boolean = false;

    payData: string;
    payFormUrl: string;
    paidReg = false;
    paidFirstPart = false;

    hasVIISExams: boolean;
    hasDVAExams: boolean;

    @ViewChild('payForm', { static: true }) private payForm: ElementRef;

    get isUnder18(): boolean {
        let bd = Utils.ensureDate(this.item.Birthdate);
        return !!bd && Utils.getAge(bd) < 18;
    }

    get canAddPrograms(): boolean {
        return ((!this.isUnitedRZ || this.item.UnitedRZStatus === ApplicationUnitedRZStatus.SpecialitySelection)
            && this.programs.length < this.programLimit) || (this.isLateSubmission && this.item.Status === ApplicationStatus.New);
    }

    get canOrderPrograms(): boolean {
        return (!this.isUnitedRZ && this.canEdit) || this.item.UnitedRZStatus === ApplicationUnitedRZStatus.SpecialityApproval;
    }

    get canRemovePrograms(): boolean {
        return (!this.isUnitedRZ && this.canEdit) || this.item.UnitedRZStatus === ApplicationUnitedRZStatus.SpecialitySelection;
    }

    get canApproveSpeciality(): boolean {
        return this.programs.length && this.item.UnitedRZStatus === ApplicationUnitedRZStatus.SpecialityApproval;
    }

    get canApproveUniversity(): boolean {
        return this.item.Status === ApplicationStatus.Approved && this.item.UnitedRZStatus === ApplicationUnitedRZStatus.UniversitySelection;
    }

    get showProgramOrder(): boolean {
        return !this.isUnitedRZ || [
            ApplicationUnitedRZStatus.SpecialityApproved,
            ApplicationUnitedRZStatus.UniversitySelection,
            ApplicationUnitedRZStatus.UniversitySelected
        ].indexOf(this.item.UnitedRZStatus) > -1;
    }

    get pdfUrl(): string {
        return this.service.getPdfUrl(this.item.Id);
    }

    get canEdit(): boolean {
        return this.isPowerUser || this.item.Status === ApplicationStatus.New || this.item.Status === ApplicationStatus.SubmittedByApplicant;
    }

    get canEditGrades(): boolean {
        return this.isEmployeeThatCanEditGrades || this.item.Status === ApplicationStatus.New || this.item.Status === ApplicationStatus.SubmittedByApplicant;
    }

    get canSubmit(): boolean {
        return !this.admissionExpired && (this.item.Status === ApplicationStatus.New
            || (this.isEditor && this.item.Status === ApplicationStatus.OnHold));
    }

    get canSubmitAgain(): boolean {
        return !this.admissionExpired && this.isApplicant && this.item.Status === ApplicationStatus.SubmittedByApplicant;
    }

    get canSign(): boolean {
        return this.signRequired && !this.item.IsSigned && !this.isHomines
            && this.item.Status === ApplicationStatus.Approved && this.isApplicant;
    }

    get canApprove(): boolean {
        return this.isEditor && (this.item.Status === ApplicationStatus.SubmittedByApplicant
            || this.item.Status === ApplicationStatus.SubmittedByClerk
            || this.item.Status === ApplicationStatus.Recalled
            || this.item.Status === ApplicationStatus.RecalledWaiting);
    }

    get canCancel(): boolean {
        return this.canApprove;
    }

    get canDelete(): boolean {
        return this.isEditor || this.item.Status === ApplicationStatus.New;
    }

    get canRecall(): boolean {
        return this.programResult === 'in' && (
            this.item.Status === ApplicationStatus.Approved
            || (this.item.Status === ApplicationStatus.RecalledWaiting && this.isPowerUser));
    }

    get canSetOnHold(): boolean {
        return this.canApprove;
    }

    get canGeneratePdf(): boolean {
        return (this.isPowerUser || this.isAdmin) && (this.item.Status === ApplicationStatus.Approved || this.item.IsForeign) && !this.isDataViewer;
    }

    get canShare(): boolean {
        return this.isPowerUser;
    }

    get personDataErrors(): any[] {
        
        return this.errors.filter(t => (t.Group || '').indexOf('personData') === 0);
    }

    get personDataErrorAlert(): string {
        return `<p>${this.app.translate.instant('applications_applicationDataEmpty')}</p>
            <div>${parseValidationErrors(this.personDataErrors, (t, params) => this.app.translate.instant(t, params))}</div>`;
    }

    get personVerified(): boolean {
        return this.item.PersonVerified === `${this.item.Name},${this.item.Surname},${this.item.PersonCode}`;
    }

    get personIsChecked(): boolean {
        return this.personVerified || this.item.IsSISDataChecked || this.isHomines || this.isUnitedRZ;
    }

    get returnTo(): string {
        return applicationReturnToCache || (this.isEditor ? 'admission' : 'applications');
    }

    get returnUrl(): any {
        let route: any;

        switch (this.returnTo) {
            case 'search': route = ['/applications/search']; break;
            case 'admission': route = ['/admissions', this.item.AdmissionId]; break;
            case 'applications': route = ['/applications']; break;
        }

        return this.app.localize.translateRoute(route);
    }

    get prevUniSelectedPrograms(): string {
        if (!this.prevUni)
            return '';

        return this.prevUni.SelectedPrograms.map(t => t.Title).join(', ');
    }

    get personCodeHidden(): boolean {
        return this.item.IsForeign && (this.app.auth.isApplicant() || this.app.auth.isAgent());
    }

    get allFiles(): IAppFile[] {
        let files = [
            ...this.aux.personDocuments,
            ...this.aux.educationDocuments,
            ...this.aux.enrollmentOrders,
            ...this.aux.paymentFiles,
            ...this.aux.studentContracts,
            ...[].concat(...Object.values(this.aux.examFiles)),
            ...[].concat(...Object.values(this.aux.workplaceFiles)),
        ];

        if (this.onlineApplicationFormIsAvailable)
            files.push(this.aux.applicationForm);

        return buildFiles(files);
    }

    get applicantFiles(): IAppFile[] {
        let files = [...this.aux.studentContracts];

        if (this.onlineApplicationFormIsAvailable)
            files.push(this.aux.applicationForm);

        return buildFiles(files);
    }

    get universitySelected(): boolean {
        return this.programs.some(t => !!t.University);
    }

    get canSetPayment(): boolean {
        return this.app.auth.userIn([
            UserType.Administrator,
            UserType.AdmClerk,
            UserType.ExamClerk,
            UserType.PowerUser,
            UserType.UnitedEnrollmentClerk
        ]);
    }

    get canCreateContract(): boolean {
        return !this.contractCreated && (!this.item.IsUnitedRZ || !!this.item.PersonVerified) 
        && (this.contractStatus === 'Canceled' || this.contractStatus === 'Annulled' || this.contractStatus === 'DvsDeletionSuccess' || !this.allFiles.some(x => x.type === 'Contract')
        || this.allFiles.filter(f => f.type == 'Contract').every(f => f.status === 'Canceled' || f.status === 'Annulled'));
    }

    get canTerminateOrAnnulContract(): boolean {
        return this.isAdmin || this.isPowerUser;
    }
    
    get canAnnulContract(): boolean {
        return this.acceptedInProgram.ContractStatus === 'NotCreatedInDVS' 
                || this.acceptedInProgram.ContractStatus === 'AvailableToSignInDVS'
                || this.acceptedInProgram.ContractStatus === 'Created';
    }

    get canTerminateContract(): boolean {
        return (this.acceptedInProgram.ContractStatus === 'Signed' 
                || this.acceptedInProgram.ContractStatus === 'ApplicantCreated')
            && (this.acceptedInProgram.EnrollmentStatus === 'ReadyToSend'
                || this.acceptedInProgram.EnrollmentStatus === 'CheckingPayment'
                || this.acceptedInProgram.EnrollmentStatus === 'ReadyForEnrollment');
    }

    get isAnyContractCreated(): boolean {
        return this.allFiles.some(x => x.type === 'Contract');
    }

    get isPayButtonEnabled(): boolean {
        return !this.admissionExpired && !!this.item.CrmEnrollmentNumber && (this.acceptedInProgram.ContractStatus === 'Signed'
            || (this.acceptedInProgram.ContractStatus === 'ApplicantCreated'
                && (this.acceptedInProgram.EnrollmentStatus === 'ReadyToSend' || this.acceptedInProgram.EnrollmentStatus === 'CheckingPayment')));
    }

    get shouldSignContract(): boolean {
        return !this.item.CrmContractId && (this.item.Status === ApplicationStatus.Approved) && this.acceptedInProgram
            && this.acceptedInProgram.ContractStatus === 'Created' && !this.acceptedInProgram.EnrollmentStatus;
    }

    get canUploadEdoc(): boolean {
        return (!this.isApplicant || this.areContractFilesAccessible) && !this.contractSigned &&
            this.contractStatusRead && this.contractStatusRead.ESignedByEmployee && !this.contractStatusRead.ESignedByApplicant;
    }

    get canApproveContractESign(): boolean {
        return !this.isApplicant && !this.contractSigned && this.contractStatusRead
            && this.contractStatusRead.ESignedByApplicant && this.contractStatusRead.ESignedByEmployee;
    }

    private gradeMappings: { [key: string]: string[] } = {};
    private workplaceTerms = new Subject<string>();
    private auxSaveSubject = new Subject<any>();
    private admissionExpired = false;

    ngOnInit() {
        const params = this.route.snapshot.params;
        const query = this.route.snapshot.queryParams;

        if (params['id'] === 'pay') {
            this.actionPay = true;

            let { type, AplicationId: ApplicationId, ApplicationProgramId } = query;

            this.pay(type, ApplicationId, ApplicationProgramId);

            return;
        }

        const langEn = this.app.translate.currentLang === 'en';

        this.quizzRedirectUrl = this.app.env.quizzUrl + '/' + this.app.translate.currentLang + '/home';

        const appNumber = params['id'];
        const returnTo = query['returnTo'];

        applicationReturnToCache = returnTo || applicationReturnToCache;

        if (appNumber || (this.item && this.item.Number))
            this.load(appNumber);

        this.loadClassifiers();

        this.isAdmin = this.app.auth.isAdmin();
        this.isPowerUser = this.isAdmin || this.app.auth.isPowerUser();
        this.isUnitedEnrollClerk = this.app.auth.isUnitedEnrollClerk();
        this.isEditor = this.isPowerUser || this.app.auth.isAdmClerk() || this.isUnitedEnrollClerk;
        this.isEmployeeThatCanSeeContractStatus = this.app.auth.isEmployeeThatCanSeeContractStatus();
        this.isAgent = this.app.auth.isAgent();
        this.isDataViewer = this.app.auth.isDataViewer();
        this.isApplicant = this.app.auth.isApplicant();
        this.isEmployeeThatCanEditGrades = this.app.auth.isEmployeeThatCanEditGrades();
        const messageCodes = [
            'CONTACTS_EMAIL_SUBJECT',
            'APPLICATION_UNDER18',
            'APPLICATION_PAY_INFO',
            'APPLICATION_ESIGN_HELP',
            'APPLICATION_CONFIRM_RULES',
            'APP_SEND_PERSONAL_EMAIL',
            'STUDIES_IN_LATER_STAGES_MESSAGE'
        ];

        this.messageService.getByCodes(messageCodes).subscribe(data => {
            let subjParam = data.find(t => t.Code === 'CONTACTS_EMAIL_SUBJECT');
            this.contactsEmailSubject = subjParam ? langEn ? subjParam.TextEN : subjParam.TextLV : '';

            let under18Message = data.find(t => t.Code === 'APPLICATION_UNDER18');
            if (under18Message)
                this.under18Message = this.app.translate.currentLang === 'en' ? under18Message.TextEN : under18Message.TextLV;

            let payInfo = data.find(t => t.Code === 'APPLICATION_PAY_INFO');
            this.payInfo = payInfo ? langEn ? payInfo.TextEN : payInfo.TextLV : '';

            const esignHelp = data.find(t => t.Code === 'APPLICATION_ESIGN_HELP');
            this.esignHelpText = esignHelp ? langEn ? esignHelp.TextEN : esignHelp.TextLV : '';

            const confirmRules = data.find(t => t.Code === 'APPLICATION_CONFIRM_RULES');
            this.confirmRulesText = confirmRules ? langEn ? confirmRules.TextEN : confirmRules.TextLV : '[[APPLICATION_CONFIRM_RULES]]';

            const personalEmailSubject = data.find(t => t.Code === 'APP_SEND_PERSONAL_EMAIL');
            this.personalEmailSubjectText = personalEmailSubject ? langEn ? personalEmailSubject.TextEN : personalEmailSubject.TextLV : '';

            const selectStudiesInLaterStages = data.find(t => t.Code === 'STUDIES_IN_LATER_STAGES_MESSAGE');
            this.selectStudiesInLaterStagesText = selectStudiesInLaterStages ? langEn ? selectStudiesInLaterStages.TextEN : selectStudiesInLaterStages.TextLV : '';
        });
        
        // TODO: Change the way of how the messageService is called
        // On calling the messageservice, passing all the necessary parameters, it returns 400 Bad Request (maybe because of the URL length?), that's why the call is divided into 2 parts. 
        const messageCodesForApplications = [
            'APPLICATION_CONFIRM_GRADES',
            'APPLICATION_CONFIRM_HOMINES_RULES',
            'APPLICATION_STUDIES_AT_LATER_STAGES_LABEL',
            'APPLICATION_IS_GRADUATE_LABEL',
            'APPLICATION_FOR_CREDIT_TRANSFER_LABEL',
            'APPLICATION_IS_INCOMPLETE_LABEL'
        ];

        this.messageService.getByCodes(messageCodesForApplications).subscribe(data => {
            const applicationConfirmGrades = data.find(t => t.Code === 'APPLICATION_CONFIRM_GRADES');
            this.applicationConfirmGradesText = applicationConfirmGrades ? langEn ? applicationConfirmGrades.TextEN : applicationConfirmGrades.TextLV : '';

            const applicationConfirmHominesRules = data.find(t => t.Code === 'APPLICATION_CONFIRM_HOMINES_RULES');
            this.applicationConfirmHominesRulesText = applicationConfirmHominesRules ? langEn ? applicationConfirmHominesRules.TextEN : applicationConfirmHominesRules.TextLV : '';

            const applicationStudiesAtLaterStages = data.find(t => t.Code === 'APPLICATION_STUDIES_AT_LATER_STAGES_LABEL');
            this.applicationStudiesAtLaterStagesText = applicationStudiesAtLaterStages ? langEn ? applicationStudiesAtLaterStages.TextEN : applicationStudiesAtLaterStages.TextLV : '';

            const applicationIsGraduate = data.find(t => t.Code === 'APPLICATION_IS_GRADUATE_LABEL');
            this.applicationIsGraduateText = applicationIsGraduate ? langEn ? applicationIsGraduate.TextEN : applicationIsGraduate.TextLV : '';

            const applicationApplicationForCreditTransfer = data.find(t => t.Code === 'APPLICATION_FOR_CREDIT_TRANSFER_LABEL');
            this.applicationApplicationForCreditTransferText = applicationApplicationForCreditTransfer ? langEn ? applicationApplicationForCreditTransfer.TextEN : applicationApplicationForCreditTransfer.TextLV : '';

            const applicationIsIncomplete = data.find(t => t.Code === 'APPLICATION_IS_INCOMPLETE_LABEL');
            this.applicationIsIncompleteText = applicationIsIncomplete ? langEn ? applicationIsIncomplete.TextEN : applicationIsIncomplete.TextLV : '';
        });

        const messageCodesForApplications_2 = [
            'APPLICATION_IS_NOT_AVAILABLE_MSG',
            'APPLICATION_REMOVE_LATE_SUBMISSION_TAG'
        ];

        this.messageService.getByCodes(messageCodesForApplications_2).subscribe(data => {
            const applicationIsNotAvailableMsg = data.find(t => t.Code === 'APPLICATION_IS_NOT_AVAILABLE_MSG');
            this.applicationIsNotAvailableMsgText = applicationIsNotAvailableMsg ? langEn ? applicationIsNotAvailableMsg.TextEN : applicationIsNotAvailableMsg.TextLV : '';
            
            const applicationConfirmRemovingLateSubmissionTag = data.find(t => t.Code === 'APPLICATION_REMOVE_LATE_SUBMISSION_TAG');
            this.applicationConfirmRemovingLateSubmissionTagText = applicationConfirmRemovingLateSubmissionTag ? langEn ? applicationConfirmRemovingLateSubmissionTag.TextEN : applicationConfirmRemovingLateSubmissionTag.TextLV : '';
        });

        this.app.translate.get(['contractssent', 'crmIdmissing', 'contract_pleasewait', 'contract_notsent', 'contract_sent']).subscribe((result: any) => {
            this.translations = result;
        });

        this.workplaceTerms.pipe(
            debounceTime(300),
            distinctUntilChanged(),
            switchMap((term: string) => {
                if (term) {
                    let parts = term.split('@');
                    parts.pop(); // remove program identifier
                    term = parts.join('@');

                    if (term)
                        return this.classifierService.search('MedInstitution', term);
                }

                return of([]);
            }))
            .subscribe((data: any) => this.workplaces = data);

        this.auxSaveSubject.pipe(debounceTime(300)).subscribe(() => this.saveAux());
        this.DVSlink = this.app.env.dvsDocumentLink;
    }

    canDeactivate() {
        return this.confirmInvalidGrades();
    }

    showStatusValue(statusClassifer: Classifier) {
        if (this.app.translate.currentLang == 'en')
            return statusClassifer.ValueEN;
        else
            return statusClassifer.Value;
    }

    statusChange(event) {
        if (!this.item.IsForeign)
            this.reasonNeeded = false;
        else {
            if (!event || !event.value)
                this.reasonNeeded = false;
            else {
                let cls: Classifier = this.contractStatuses.find(c => c.Id == event.value);
                if (cls && cls.Code == 'TERM')
                    this.reasonNeeded = true;
                else
                    this.reasonNeeded = false;
            }
        }
    }

    createContract(program: Program) {
        let contractApp: ApplicationForContract = new ApplicationForContract(this.item);
        let dialogComponent: any;
        let dialogOpts: any;

        if (contractApp.StudiesAtLaterStages && (program.MatriculationYear || 1) < 2) {
            dialogComponent = ApplicationSchedulesComponent;
            dialogOpts = {
                data: new SchedulesDialogOptions(program.Id, this.item.Id),
                disableClose: true,
                autoFocus: true,
                //height: '800px',
                width: '800px'
            };
        } else {
            dialogComponent = ContractDialogComponent;
            dialogOpts = {
                data: new ContractDialogOptions(program, [contractApp]),
                disableClose: true,
                autoFocus: true,
                width: '600px'
            };
        }

        this.dialog.open(dialogComponent, dialogOpts).afterClosed().subscribe((result: boolean) => {
            if (result) {
                if (contractApp.StudiesAtLaterStages) {
                    this.programService.getContract(program.Id, this.item.Id).subscribe(data => {
                        if (data.ContractStatus == 'NotReady')
                            this.app.notify('contract_dataSaved');
                        else
                            this.app.notify('contract_sentToCreate');
                    });
                }
                else
                    this.app.notify('contract_sentToCreate');
                this.contractCreated = true;
                this.load(this.item.Number);
            }
        });
    }

    createOrder(program: Program) {
        let enrollmentApp: ApplicationForEnrollment = new ApplicationForEnrollment(this.item);
        let opts = new EnrollmentDialogOptions(program, [enrollmentApp]);
        let dialogRef = this.dialog.open(EnrollmentDialogComponent, {
            data: opts,
            disableClose: true,
            autoFocus: true,
            width: '800px'
        });
        dialogRef.afterClosed().subscribe((result: boolean) => {
            if (result) {
                this.orderCreated = true;
                this.load(this.item.Number);
            }
        });
    }

    createSISApplicant(program: Program) {
        let appNumbers = [this.item.Number];
        let msg = this.app.translate.instant('contract_confirmApplicantCreate', { appnumber: this.getAppLink(appNumbers[0]) });
        this.app.confirm(msg, result => {
            if (!result)
                return;
            this.programService.createApplicant(program.Id, appNumbers).subscribe(r => {
                if (r.length != 1) {
                    this.app.alerts.warning(this.app.translate.instant('contract_applicantNotSentForCreation'));
                }
                else {
                    this.app.notify('contract_applicantSentForCreation');
                    this.applicantCreatedButtonClicked = true;
                    this.load(this.item.Number);
                }
            }, err => this.app.showError(err));
        });
    }

    signContract(program: Program, isESign?: boolean) {
        const msg = this.app.translate.instant('contract_confirmSignSingle', { appnumber: this.getAppLink(this.item.Number) });

        this.app.confirm(msg, result => {
            if (!result)
                return;

            const loading = this.app.showLoading();

            this.programService.signContract(program.Id, [this.item.Number], isESign).subscribe(r => {
                this.app.hideLoading(loading);

                if (r.appNumbers.indexOf(this.item.Number) > -1) {
                    this.contractSigned = true;
                    this.acceptedInProgram.ContractStatus = 'Signed';
                    this.acceptedInProgram.EnrollmentStatus = 'ReadyToSend';
                    this.app.notify('programResults_appSigned');
                    this.load(this.item.Number);
                }
                else
                    this.app.showError('application_ContractNotSigned');
            }, err => {
                this.app.hideLoading(loading);
                this.app.showError(err);
            });
        });
    }

    annulContract(program: Program) {
        const msg = this.app.translate.instant('contract_confirmAnnul', { program: program.Title + ', ' + program.Financing });

        this.app.confirm(msg, result => {
            if (!result)
                return;

            let annulId = this.contractStatuses.find(t => t.Code.toLowerCase() === 'anul').Id;
            let loading = this.app.showLoading();
            this.service.setContractStatus(this.item.Id, annulId).subscribe(result => {
                this.app.hideLoading(loading);
                this.app.alerts.success(this.app.translate.instant('application_contractStatusSuccess'));
                this.contractStatusRead.IsBeingProcessed = true;
                this.load(this.item.Number);
            }, err => this.app.showSaveError(err));
        });
    }

    terminateContract(program: Program) {
        const msg = this.app.translate.instant('contract_confirmTerminate', { program: program.Title + ', ' + program.Financing });

        this.app.confirm(msg, result => {
            if (!result)
                return;

           let termId = this.contractStatuses.find(t => t.Code.toLowerCase() === 'term').Id;
           let loading = this.app.showLoading();
           this.service.setContractStatus(this.item.Id, termId).subscribe(result => {
               this.app.hideLoading(loading);
               this.app.alerts.success(this.app.translate.instant('application_contractStatusSuccess'));
               this.contractStatusRead.IsBeingProcessed = true;
               this.load(this.item.Number);
           }, err => this.app.showSaveError(err));
        });
    }

    uploadEdoc() {
        const contractFile = this.allFiles.find(f => f.type === 'Contract');

        if (!contractFile) return;

        const dialogRef = this.dialog.open(SignEdocDialogComponent, {
            data: {
                isApplicant: this.isApplicant,
                applicationId: this.item.Id,
                maxFileSize: this.maxEdocUploadSize,
                contractFileName: contractFile.name,
                currentFile: { name: contractFile.name, url: contractFile.url }
            },
            disableClose: true,
            width: '600px'
        });

        dialogRef.afterClosed().subscribe((result: boolean) => {
            if (result) {
                this.app.alerts.success(this.app.translate.instant('applications_esign_edocUploadSuccess'));

                const auxLoading = this.app.showLoading();
                this.service.getAuxData(this.item.Id).subscribe(aux => {
                    this.app.hideLoading(auxLoading);
                    this.aux = this.parseAuxData(aux);
                });

                const contractLoading = this.app.showLoading();
                this.service.getContractStatus(this.item.Id).subscribe(contract => {
                    this.app.hideLoading(contractLoading);

                    if (contract) {
                        this.contractStatusRead = contract;
                        this.contractStatusToSet = this.getContractStatuses()[0].Id;
                    }

                    this.load(this.item.Number);
                }, err => this.app.showLoadError(err));
            }
        });
    }

    getAppLink(number: string) {
        let route = this.app.localize.translateRoute(`/applications/${number}`);
        return `<a href="${route}" target="_blank">${number}</a>`;
    }

    getContractStatusText(status: string) {
        if(!!this.contractStatusRead && this.contractStatusRead.ESignedByApplicant && this.contractStatusRead.ESignedByEmployee
            && status === 'Created')
        {
            status = 'waitingapprovalfromrsu';
        }
        return status ? this.app.translate.instant('contract_' + status.toLowerCase()) : '';
    }

    getLastContractDisplayStatus() {
        if (this.allFiles) {
            let lastContractFile = this.allFiles.find(f => f.type == 'Contract');//already sorted
            if (lastContractFile) {
                return this.getContractStatusText(lastContractFile.status);
            }
        }
        return '';
    }

    setContractStatus() {
        let loading = this.app.showLoading();
        this.service.setContractStatus(this.item.Id, this.contractStatusToSet, this.terminationReasonId).subscribe(result => {
            this.app.hideLoading(loading);
            this.app.alerts.success(this.app.translate.instant('application_contractStatusSuccess'));
            this.contractStatusRead.IsBeingProcessed = true;
        }, err => this.app.showSaveError(err));
    }

    sendPriorities() {
        let send: ApplicationProgramPriority[] = this.programs.sort((p1, p2) => {
            let valueA = p1.Priority;
            let valueB = p2.Priority;
            return (+valueA) - (+valueB);
        }).map((p, index, a) => {
            let pr = new ApplicationProgramPriority();
            pr.Priority = index + 1;
            pr.ProgramId = p.Program.Id;
            return pr;
        });
        let loading = this.app.showLoading();
        this.service.setProgramPriorities(this.item.Id, send).subscribe(result => {
            this.app.hideLoading(loading);
        }, err => this.app.showSaveError(err));
    }

    moveProgramUp(program: ApplicationProgram) {
        let index = this.programs.indexOf(program);

        if (index > 0) {
            let a = this.programs[index];
            let b = this.programs[index - 1];
            b.Priority++;
            a.Priority--;
            this.programs[index] = b;
            this.programs[index - 1] = a;
            this.sendPriorities();
        }
    }

    moveProgramDown(program: ApplicationProgram) {
        let index = this.programs.indexOf(program);

        if (index < this.programs.length - 1) {
            let a = this.programs[index];
            let b = this.programs[index + 1];
            b.Priority--;
            a.Priority++;
            this.programs[index] = b;
            this.programs[index + 1] = a;
            this.sendPriorities();
        }
    }

    addProgram() {
        this.router.navigate([this.app.localize.translateRoute(`/applications/${this.item.Number}/programs/add`)]);
    }

    removeProgram(program: ApplicationProgram) {
        if(program.ContractStatus === 'Annulled' || 
            program.ContractStatus === 'Canceled' ||
            program.ContractStatus === 'ApplicantCreated' ||
            program.ContractStatus === 'NotSent' ||
            !program.ContractStatus) {
            this.app.confirm({
                text: this.app.translate.instant('applications_confirmProgramRemoval'),
                okText: this.app.translate.instant('confirm')
            }, result => {
                if (!result)
                    return;

                let loading = this.app.showLoading();
                this.service.removeProgram(this.item.Id, program.Program.Id).subscribe(result => {
                    let index = this.programs.indexOf(program);
                    this.programs.splice(index, 1);
                    this.app.hideLoading(loading);
                    this.loadExams();
                }, err => this.app.showDeleteError(err));
            });
        }
        else {
            this.app.alerts.warning(this.app.translate.instant('application_ContractAlreadyCreatedRemove'));
        }
    }

    enroll(program: ApplicationProgram) {
        if(this.canCreateContract) {
            if (this.requiredExamsFilled) {
                let loading = this.app.showLoading();
                this.service.enroll(this.item.Id, program.Program.Id).subscribe(() => {
                    this.programs.filter(t => t.Result === ApplicationResultType.In).forEach(t => t.Result = ApplicationResultType.Out);
                    program.Result = ApplicationResultType.In;
                    this.acceptedInProgram = program;
                    this.app.hideLoading(loading);
                }, err => this.app.showSaveError(err));
            }
            else {
                this.app.alerts.warning(this.app.translate.instant('application_ExamMandatoryMissing'));
            }
        }
        else {
            this.app.alerts.warning(this.app.translate.instant('application_ContractAlreadyCreatedEnroll'));
        }
    }

    saveUniversity(program: ApplicationProgram) {
        let loading = this.app.showLoading();
        this.service.setUniversity(this.item.Id, program.Program.Id, program.University ? program.University.Id : '').subscribe(() => {
            this.app.hideLoading(loading);
        }, err => this.app.showSaveError(err));
    }

    setGrade(exam: ApplicationExam, group: any) {
        const dialogRef = this.dialog.open(ExamGradeDialogComponent, {
            disableClose: true,
            autoFocus: true,
            width: '600px',
            data: {
                exam,
                gradeMappings: this.gradeMappings,
                isEditor: this.isEditor
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                exam.Grade = result.finalGrade;
                exam.GradeSystem = result.system;
                exam.AuxType = result.auxType;
                exam.GradeCoef = result.coef;
                exam.RawGrade = result.grade;

                this.saveGrade(exam, group);
            }
        });
    }

    async examSendToDvsChange(event: MatCheckboxChange, exam: ApplicationExam) {
        const loading = this.app.showLoading();
        try {
            exam.SendToDvs = await this.service.updateSendExamToDvs(this.item.Id, exam.Id, event.checked);
        }
        catch (err) {
            this.app.showSaveError(err);
        }
        finally {
            this.app.hideLoading(loading);
        }
    }

    hasAdvancedGradeEditor(exam: ApplicationExam): boolean {
        return exam.TypeCode === 'CE' && exam.DisplayTypeCode !== 'WGT';
    }

    canEditGrade(exam: ApplicationExam): boolean {
        if (!this.canEditGrades) return false;
        if (!this.isEmployeeThatCanEditGrades && exam.Code.toUpperCase().startsWith('CE-')) {
            const viisGrade = this.getViisGrade(exam);
            return viisGrade === undefined;
        }
        return true;
    }

    saveGrade(exam: ApplicationExam, group: any): Observable<ApplicationExam> {
        console.log(this.validateGrade(exam), this.validateExamGroup(group));
        console.log(exam.Grade, exam['_prevGrade']);
        console.log(exam.AuxType, exam['_prevAuxType']);

        if (this.validateGrade(exam) && this.validateExamGroup(group)
            && (exam.Grade != exam['_prevGrade'] || exam.AuxType != exam['_prevAuxType'])) {
            return this.saveExam(exam);
        }

        return of(exam);
    }

    getExamFinalGrade(exam: ApplicationExam): number {
        return +(((exam.Grade || 0) * exam.Weight).toFixed(3));
    }

    classifierDisplay = (item: Classifier) =>
        item ? (this.app.translate.currentLang == 'en' ? item.ValueEN : item.Value) : '';

    saveThesis() {
        if (this.thesis && JSON.stringify(this.thesis) !== JSON.stringify(this.thesisOld)) {
            if (!this.thesis.Type)
                this.thesis.Type = AuxType.Thesis;
            this.thesisOld = JSON.parse(JSON.stringify(this.thesis));
            let loading = this.app.showLoading();
            this.service.saveAuxData(this.item.Id, [this.thesis]).subscribe(() => {
                this.app.hideLoading(loading);
            }, err => this.app.showSaveError(err));
        }
    }

    getProgramExamsTotal(program: any): number {
        let total = 0;

        program.groups.forEach(g => {
            g.exams.forEach(e => {
                total += +(this.getExamFinalGrade(e) || 0);
            });
        });

        return +total.toFixed(3);
    }

    getExamGroupTotal(group: any): number {
        let total = 0;

        group.exams.forEach(e => {
            total += +(e.Grade || 0);
        });

        return total;
    }

    validateExamGroup(group: any): boolean {
        if (group.name && this.getExamGroupTotal(group) > group.maxGrade)
            return false;

        return true;
    }

    hasWorkplaceSelected(program: ApplicationProgram, value: boolean) {
        if (program.HasWorkplace !== value) {
            program.HasWorkplace = value;
            if (!value) {
                program.Workplace = null;
                program.WorkplaceId = null;
                if (this.aux.workplaceFiles) {
                    this.aux.workplaceFiles[program.Id] = [];
                    this.workplaceFileRemoved(program.Id);
                }
            }
            this.updateWorkplace(program);
        }
    }

    workplaceSelected(item: Classifier, program: ApplicationProgram) {
        program.WorkplaceId = item.Id;
        program.Workplace = this.app.displayClassifier(item);
        this.updateWorkplace(program)
    }

    onWorkplaceChange(term: string, program: ApplicationProgram) {
        program.Workplace = term;
        program.WorkplaceId = undefined;
        this.workplaceTerms.next(((term || '') + `@${program.Program.Id}`).toLowerCase());
    }

    onWorkplaceBlur(program: ApplicationProgram) {
        if (program.HasWorkplace && !program.WorkplaceId) {
            this.updateWorkplace(program)
        }
    }

    setStatus(status: ApplicationStatus) {
        let proceed = () => {
            let msg: Observable<string>;

            if (status === ApplicationStatus.Recalled && this.app.auth.isApplicant())
                status = ApplicationStatus.RecalledWaiting;

            if (status === ApplicationStatus.RecalledWaiting) {
                let lang = this.app.translate.currentLang;
                msg = this.messageService.getByCode('APPLICATIONS_CONFIRM_RECALL').pipe(map((t: Message) => lang === 'en' ? t.TextEN : t.TextLV));
            } else {
                let defaultMessage = Utils.formatString(
                    this.app.translate.instant('applications_confirmStatus'),
                    this.app.translate.instant(`applications_confirmStatus${status}`)
                );
                msg = of(defaultMessage);
            }

            msg.subscribe(message => {
                this.app.confirm(message, result => {
                    if (!result)
                        return;

                    const loading = this.app.showLoading();
                    this.service.setStatus(this.item.Id, status).subscribe(result => {
                        this.app.hideLoading(loading);

                        let doneMessage: string;
                        let submitByApplicant = status === ApplicationStatus.SubmittedByApplicant;

                        if (submitByApplicant || status === ApplicationStatus.SubmittedByClerk)
                            doneMessage = result.Message;

                        if (!doneMessage) {
                            doneMessage = Utils.formatString(
                                this.app.translate.instant('applications_statusSet'),
                                this.app.translate.instant(`applications_statusSet${status}`)
                            ) || '';
                        }

                        this.app.confirm({
                            text: doneMessage,
                            cancelText: ''
                        }, result => {
                            if (submitByApplicant && !this.item.IsForeign)
                                this.router.navigate([this.app.localize.translateRoute('/applications')]);
                            else
                                this.load();
                        });
                    }, err => {
                        this.app.hideLoading(loading);

                        const body = err.error;

                        if (body && body.Error) {
                            let errText = '';

                            if (body.Errors && body.Errors instanceof Array) {
                                errText = parseValidationErrors(body.Errors, (t, params) => this.app.translate.instant(t, params));
                            }

                            this.app.showError(`<p>${this.app.translate.instant(body.Error)}</p><div>${errText}</div>`, null,
                                this.app.translate.instant('applications_applicationNotSubmitted'));
                        } else {
                            this.app.showSaveError(err);
                        }
                    });
                });
            });
        };

        if (!this.hasInvalidGrades()) {
            let gradeRequests: Observable<ApplicationExam>[] = [];

            // if all grades are valid, save them
            // (there may be unsaved grades because for some exams default nulls are replaced by 0 for convenience)
            this.examTree.forEach(p => {
                p.groups.forEach(g => {
                    g.exams.forEach(e => {
                        gradeRequests.push(this.saveGrade(e, g));
                    });
                });
            });

            if (gradeRequests.length)
                zip.apply(null, gradeRequests).subscribe(() => proceed());
            else
                proceed();
        } else {
            this.confirmInvalidGrades().subscribe(res => res && proceed());
        }
    }

    setUnitedRZStatus(status: ApplicationUnitedRZStatus) {
        this.confirmInvalidGrades().subscribe(res => {
            if (!res)
                return;

            let isEn = this.app.translate.currentLang === 'en';

            let confirmTitle: string;
            let confirmText: string;
            let confirmBody: string;

            switch (status) {
                case ApplicationUnitedRZStatus.SpecialityApproved:
                    confirmTitle = 'applications_confirmUnitedRZStatusSpecialityApproved';
                    confirmText = 'applications_confirmUnitedRZStatusSpecialityApprovedBody';
                    confirmBody = '<ol><li>' + this.programs.map(t => t.Program.Title).join('</li><li>') + '</li></ol>';
                    break;
                case ApplicationUnitedRZStatus.UniversitySelected:
                    confirmTitle = 'applications_confirmUnitedRZStatusUniversitySelected';
                    confirmText = 'applications_confirmUnitedRZStatusUniversitySelectedBody';

                    let uni = this.programs.filter(t => t.University).map(t => t.University);

                    confirmBody = uni.length ? '<ol><li>'
                        + uni.map(t => t[isEn ? 'ValueEN' : 'Value']).join('</li><li>')
                        + '</li></ol>' : '';
                    break;
            }

            this.app.confirm({
                okText: this.app.translate.instant('applications_btnConfirmUnitedRZStatus'),
                cancelText: this.app.translate.instant('applications_btnCancelUnitedRZStatus'),
                title: this.app.translate.instant(confirmTitle),
                text: this.app.translate.instant(confirmText).replace('{body}', confirmBody)
            }, res => {
                if (res) {
                    let loading = this.app.showLoading();
                    this.service.setUnitedRZStatus(this.item.Id, status).subscribe(res => {
                        this.item.UnitedRZStatus = status;
                        this.app.hideLoading();
                    }, err => this.app.showSaveError(err));
                }
            });
        });
    }

    submit() {
        if(this.programs.length > this.programLimit)
        {
            let text: string = this.app.translate.instant('applicationPrograms_addedCountIsMoreThanPriority', { availableCount: this.programLimit ? this.programLimit : 0, addedCount: this.programs.length });
            this.app.alerts.warning(text);
        }
        else if(this.programs.some(x => !x.AllowProlongation) && this.isLateSubmission && !this.isForeign)
        {
            let programNames = '';
            this.programs.forEach(element => {
                if(!element.AllowProlongation)
                {
                    programNames += '<br> &nbsp * ' + element.Program.Title;
                    programNames += ' (' + element.Program.Financing;
                    programNames += ', ' + element.Program.Form + ')';
                }
            });
            programNames += '<br>';
            let text: string = this.app.translate.instant('applicationPrograms_addedProgramIsNotAvailable', {programNames: programNames});
            this.app.alerts.warning(text);
        }
        else
        {
            this.setStatus(this.isEditor ? ApplicationStatus.SubmittedByClerk : ApplicationStatus.SubmittedByApplicant);
        }
    }

    delete() {
        this.app.confirm(this.app.translate.instant('applications_confirmDelete'), result => {
            if (!result)
                return;

            let loading = this.app.showLoading();
            this.service.delete(this.item.Id).subscribe(() => {
                this.app.hideLoading(loading);
                this.router.navigate(this.returnUrl);
            });
        });
    }

    confirm(type: string, event) {
        let loading = this.app.showLoading();
        this.service.confirm(this.item.Id, type, event.checked).subscribe(() => {
            this.app.hideLoading(loading);
        }, err => this.app.showSaveError(err));
    }

    studiesAtLaterStagesSelection(event) {
        let loading = this.app.showLoading();
                    this.service.setStudiesAtLaterStages(this.item.Id, event.checked).subscribe(() => {
                    this.app.hideLoading(loading);
                    this.loadPaymentInfo();
        }, err => this.app.showSaveError(err));
    }

    studiesAtLaterStagesChange(event, application: Application) {
        if(event.checked)
        {
            this.app.confirm({
                text: this.app.translate.instant(this.selectStudiesInLaterStagesText),
                cancelText: this.app.translate.instant('cancel'),
                okText: this.app.translate.instant('confirm')
            }, result => {
                if(result)
                {
                    this.studiesAtLaterStagesSelection(event);
                    application.StudiesAtLaterStages = true;
                }
                else
                {
                    application.StudiesAtLaterStages = false; 
                }
            });
        }
        else
        {
            this.studiesAtLaterStagesSelection(event);
            application.StudiesAtLaterStages = false;
        }
    }

    isGraduateChange(event) {
        let loading = this.app.showLoading();
        this.service.setIsGraduate(this.item.Id, event.checked).subscribe(() => {
            this.app.hideLoading(loading);
        }, err => this.app.showSaveError(err));
    }

    isIncompleteChange(event) {
        let loading = this.app.showLoading();
        this.service.setIsIncomplete(this.item.Id, event.checked).subscribe(() => {
            this.app.hideLoading(loading);
        }, err => this.app.showSaveError(err));
    }

    applicationForCreditTransferChange(event) {
        let loading = this.app.showLoading();
        this.service.setApplicationForCreditTransfer(this.item.Id, event.checked).subscribe(() => {
            this.app.hideLoading(loading);
        }, err => this.app.showSaveError(err));
    }

    getGradeTooltip(exam: ApplicationExam): string {
        return Utils.formatString(this.app.translate.instant('applications_gradeTooltipMinMax'), [exam.MinGrade || '', exam.MaxGrade || ''])
            + ((exam.GradeRoundPositions || 0) > 0
                ? Utils.formatString(this.app.translate.instant('applications_gradeTooltipRoundPositions'), exam.GradeRoundPositions)
                : '');
    }
    getPreApprovalGrade(exam: ApplicationExam): string {
        if (!exam.PreApprovalGrade || (this.item.Status != 'Approved' && this.item.Status != 'Finished'))
            return '';
        return this.app.translate.instant('applicationExams_preApprovalGrade') + ' ' + exam.PreApprovalGrade;
    }

    onGradeChange(event, exam: ApplicationExam, input: HTMLInputElement) {
        let val = (event || '').replace(',', '.');
        if (val && this.validateGrade(exam) && this.filledExamIds.indexOf(exam.Id) < 0)
            this.filledExamIds.push(exam.Id);

        exam['_prevGrade'] = exam.Grade;
        exam['_prevAuxType'] = exam.AuxType;

        input.value = val;
        exam.Grade = val === '' ? null : +val;
    }

    get requiredExamsFilled(): boolean {
        return !this.exams.some(t => this.requiredExamIds.indexOf(t.Id) > -1 && (t.Grade + '') == '');
    }

    validateGrade(exam: ApplicationExam): boolean {
        const str = exam.Grade + '';

        if (!exam.Grade && str !== '0')
            return true;

        if (exam.Grade > exam.MaxGrade || exam.Grade < exam.MinGrade) {
            console.log('Exam grade not in min-max range.', exam);
            return false;
        }

        if (exam.GradeRoundPositions < 1 && str.indexOf('.') !== -1) {
            console.log('Exam grade incorrect round positions.', exam);
            return false;
        }

        const regex = new RegExp('^\\d+([.,]\\d{0,' + exam.GradeRoundPositions + '})?$');

        if (!regex.test(str)) {
            console.log('Exam grade not valid.', exam);
            return false;
        }

        return true;
    }

    checkPerson() {
        let loading = this.app.showLoading();
        this.service.checkPerson(this.item.Id).subscribe(data => {
            this.persons = data;
            this.item.IsSISDataChecked = true;
            this.showPersonGrid = true;
            this.app.hideLoading(loading);
        }, err => this.app.showLoadError(err));
    }

    checkBalance() {
        let loading = this.app.showLoading();
        this.service.checkBalance(this.selectedPersons.map(k => k.Id)).subscribe(data => {
            if (data) {
                data.forEach(item => {
                    let person: ApplicationPerson = this.selectedPersons.find(k => k.Id == item.Id);
                    if (person) {
                        person.Debt = item.Debt;
                        person.DebtInspectionDate = item.DebtInspectionDate;
                    }
                });
            }
            this.app.hideLoading(loading);
        }, err => this.app.showLoadError(err));
    }

    checkContractBalance() {
        let loading = this.app.showLoading();
        this.service.checkContractBalance(this.item.Id).subscribe(data => {
            if (data) {
                this.item.ContractTotal = data.Total;
                this.item.ContractPaid = data.Paid;
                this.item.ContractBalanceRefreshDate = data.ContractBalanceRefreshDate;
            }
            this.app.hideLoading(loading);
        }, err => this.app.showLoadError(err));
    }

    linkPerson(person: ApplicationPerson) {
        if (this.item.PersonCode.replace('-','') != person.PersonCode.replace('-', '') && !this.app.auth.isAdmin()) {
            this.app.alerts.error(this.app.translate.instant('applications_linkPerson_AdminOnly'), ' ');
            return;
        }

        let loading = this.app.showLoading();
        let proceed = (persons: ApplicationPersonToLink[]) => {
            this.app.hideLoading(loading);

            let dialogRef = this.dialog.open(ConfirmPersonLinkDialogComponent, {
                disableClose: true,
                width: '800px',
                data: {
                    persons: persons
                }
            });

            dialogRef.afterClosed().subscribe(result => {
                if (result) {
                    loading = this.app.showLoading();
                    this.service.linkPerson(this.item.Id, person.CrmId).subscribe(data => {
                        if (person.CrmId)
                            this.item.CrmPersonId = person.CrmId;
                        else
                            person.IsLocalLinked = true;

                        this.app.hideLoading(loading);
                    }, err => this.app.showLoadError(err));
                }
            });
        };

        if (person.CrmId) {
            let personToLink = <ApplicationPersonToLink>person;
            personToLink.PrettyCrmMatchType = this.prettifyCrmMatchType(person);

            this.service.getPersonCrmPhoto(person.LocalEmail).subscribe(photo => {
                if(photo)
                {
                    personToLink.PhotoBase64 = photo;
                }
            });
            if(!personToLink.PhotoBase64)
            {
                this.service.getPersonPhotoFromApplication(person.Name, person.Surname, person.Email, person.PersonCode, person.Phone, this.item.Id, false).subscribe(photo => {
                    if(photo)
                    {
                        personToLink.PhotoBase64 = photo;
                    }
                });
            }

            this.service.getPersonPhotoFromApplication(this.item.Name, this.item.Surname, this.item.Email, this.item.PersonCode, this.item.Phone, this.item.Id, true).subscribe(photo => {
                if(photo)
                {
                    personToLink.NewPhoto = photo;
                    proceed([personToLink]);
                }
            }, () => proceed([personToLink]));
        } else {
            let requests = [];

            person.LinkedApplications.forEach(t => requests.push(this.service.getById(t.Id)));

            zip.apply(null, requests).subscribe((data: Application[]) => {
                proceed(data.map(t => <ApplicationPersonToLink>{
                    ApplicationNumber: t.Number,
                    Email: t.Email,
                    Name: t.Name,
                    PersonCode: t.PersonCode,
                    Phone: t.Phone,
                    Surname: t.Surname,
                    PhotoUrl: t.PhotoTimestamp ? this.service.getPhotoUrl(t.Id, t.PhotoTimestamp) : null
                }));
            });
        }
    }

    unlinkPerson() {
        let loading = this.app.showLoading();
        this.service.unlinkPerson(this.item.Id).subscribe(data => {
            this.item.CrmPersonId = null;
            this.persons.forEach(t => t.IsLocalLinked = false);
            this.app.hideLoading(loading);
        }, err => this.app.showLoadError(err));
    }

    canLinkPerson(person: ApplicationPerson): boolean {
        return person.CrmId ? person.CrmId !== this.item.CrmPersonId : !person.IsLocalLinked;
    }

    canUnlinkPerson(person: ApplicationPerson): boolean {
        return (person.CrmId && person.CrmId === this.item.CrmPersonId) || person.IsLocalLinked;
    }

    prettifyCrmMatchType(person: ApplicationPerson): string {
        if ((person.CrmMatchType || '').length === 0)
            return '';

        return person.CrmMatchType.split(',').map(t => this.app.translate.instant('personCrmMatchType_' + t)).join(', ');
    }

    onAuxChange() {
        this.auxSaveSubject.next(this.aux);
    }

    saveAux() {
        let aux = <ApplicationAuxData[]>[
            {
                Type: 'WayOfApp.ViaRepresentative',
                Data: this.aux.WayOfApp.ViaRepresentative,
                Text1: this.aux.WayOfApp.ViaRepresentativeText
            },
            { Type: 'WayOfApp.OnMyOwn', Data: this.aux.WayOfApp.OnMyOwn },
            {
                Type: 'WhereFoundInfo.ViaRepresentative',
                Data: this.aux.WhereFoundInfo.ViaRepresentative,
                Text1: this.aux.WhereFoundInfo.ViaRepresentativeText
            },
            { Type: 'WhereFoundInfo.CurrentStudent', Data: this.aux.WhereFoundInfo.CurrentStudent },
            { Type: 'WhereFoundInfo.Alumni', Data: this.aux.WhereFoundInfo.Alumni },
            { Type: 'WhereFoundInfo.Internet', Data: this.aux.WhereFoundInfo.Internet },
            { Type: 'WhereFoundInfo.Exhibition', Data: this.aux.WhereFoundInfo.Exhibition, Text1: this.aux.WhereFoundInfo.ExhibitionText },
            { Type: 'WhereFoundInfo.OpenDays', Data: this.aux.WhereFoundInfo.OpenDays },
            { Type: 'WhereFoundInfo.Other', Data: this.aux.WhereFoundInfo.Other }
        ];

        let loading = this.app.showLoading();

        this.service.saveAuxData(this.item.Id, aux).subscribe(() => this.app.hideLoading(loading));
    }

    getGradeHints(exam: ApplicationExam): string[] {
        const hints = [];

        if (exam.Grade) {
            const isFivePoint = exam.GradeSystem == GradeSystemType.FivePoint;
            const isTenPoint = exam.GradeSystem == GradeSystemType.TenPoint;

            if (isFivePoint || isTenPoint) {
                const max = isFivePoint ? 5 : 10;
                hints.push(`${exam.RawGrade} ${this.app.translate.instant('applications_pointsFrom')} ${max} = ${exam.Grade}`);
            } else if (exam.GradeCoef) {
                hints.push(`${exam.RawGrade} * ${exam.GradeCoef} = ${exam.Grade}`);
            }

            if (exam.Weight) {
                hints.push(`<strong>(${exam.Weight}) * ${exam.Grade} = ${this.getExamFinalGrade(exam)}</strong>`);
            }
        }

        return hints;
    }

    downloadPersonFiles() {

    }

    selectRow(item: ApplicationPerson) {
        let index = this.selectedPersons.indexOf(item);

        if (index > -1)
            this.selectedPersons.splice(index, 1);
        else
            this.selectedPersons.push(item);
    }

    selectRows(event) {
        if (event.checked)
            this.selectedPersons = [...this.persons];
        else
            this.selectedPersons = [];
    }

    rowSelected(item: ApplicationPerson): boolean {
        return this.selectedPersons.indexOf(item) > -1;
    }

    allRowsSelected(): boolean {
        return this.selectedPersons.length === this.persons.length;
    }

    getContractStatuses(): Classifier[] {
        const items: Classifier[] = [];
        const contract = this.allFiles.find(f => f.type === 'Contract');
        const noContract = !contract || !contract.status;
        const contractIs = (code: string) => !noContract && contract.status.toLowerCase() === code.toLowerCase();
        const isSigned = contractIs('signed');
        const add = (code: string) => items.push(this.contractStatuses.find(t => t.Code.toLowerCase() === code.toLowerCase()));

        if (!this.item.IsForeign && noContract) {
            add('READY');
        } else if (isSigned) {
            add('TERM');
        } else {
            add('SIGNED');
        }

        return items;
    }

    refreshViisGrades(): Observable<any> {
        const subj = new Subject<any[]>();
        let loading = this.app.showLoading();
        this.service.refreshViisGrades(this.item.Id).subscribe((data: { appExamId: number, grades: ViisGrade[] }[]) => {
            this.refreshExamExtGrades(
                data.map(d => { return { appExamId: d.appExamId, grades: d.grades }; }),
                (e, gradeData) => { e.ViisGrades = gradeData ? gradeData as ViisGrade[] : []; }
            );
            this.app.hideLoading(loading);
            this.app.notify('applications_refreshViisGrades_success');
            subj.next(data);
        }, err => this.app.showSaveError(err));

        return subj.asObservable();
    }

    refreshCrmGrades(): Observable<any> {
        const subj = new Subject<any[]>();
        let loading = this.app.showLoading();
        this.service.refreshCrmGrades(this.item.Id).subscribe((data: { appExamId: number, grades: number[] }[]) => {
            this.refreshExamExtGrades(
                data.map(d => { return { appExamId: d.appExamId, grades: d.grades }; }),
                (e, gradeData) => {
                    e.CrmGrades = gradeData ? gradeData as number[] : null;
                    if (!e.Grade && !!e.CrmGrades && e.CrmGrades.length > 0)
                        e.Grade = e.CrmGrades[0];
                }
            );
            this.app.hideLoading(loading);
            this.app.notify('applications_refreshCrmGrades_success');
            subj.next(data);
        }, err => this.app.showSaveError(err));

        return subj.asObservable();
    }

    getTranslatedValue(c: Classifier) {
        if (c) {
            if (this.app.translate.currentLang == 'en' && c.ValueEN)
                return c.ValueEN;
            else
                return c.Value;
        }
        else
            return '';
    }

    saveAuxFile(type: string, collection: any[], data: any) {

        let aux = collection.map(t => {
            return t.Id
                ? {
                    Id: t.Id,
                    Type: type
                } : {
                    Binary: t.Binary,
                    Type: type,
                    Text1: t.fileName,
                    Text3: t.index,
                    Text5: 'HybridCase'
                };
        });

        let loading = this.app.showLoading();

        this.service.saveAuxData(this.item.Id, <any>aux).subscribe(() => {
            this.service.getAuxData(this.item.Id).subscribe(aux => {
                this.aux = this.parseAuxData(aux);
                this.app.hideLoading(loading);
            });
        }, err => this.app.showError(err));
    }

    auxFileRemoved(type: string, collection: any[]) {

        const aux = collection.length
            ? collection.map(t => { return { Id: t.Id, Type: t.Type } })
            : [{ Id: -1, Type: type }];

        const loading = this.app.showLoading();

        this.service.saveAuxData(this.item.Id, <any>aux).subscribe(() => {
            this.service.getAuxData(this.item.Id).subscribe(aux => {
                this.aux = this.parseAuxData(aux);
                this.app.hideLoading(loading);
            });
        }, err => this.app.showError(err));
    }

    saveExamFile(examId: number, data: any) {
        this.saveAuxFile(`ExamFile_${examId}`, this.aux.examFiles[examId], data);
    }

    examFileRemoved(examId: number, data?: any) {
        this.auxFileRemoved(`ExamFile_${examId}`, this.aux.examFiles[examId]);
    }

    saveWorkplaceFile(programId: number, data: any) {
        this.saveAuxFile(`WorkplaceFile_${programId}`, this.aux.workplaceFiles[programId], data);
    }

    workplaceFileRemoved(programId: number, data?: any) {
        this.auxFileRemoved(`WorkplaceFile_${programId}`, this.aux.workplaceFiles[programId]);
    }

    savePaymentFile(data: any) {
        this.saveAuxFile('Payment', this.aux.paymentFiles, data);
    }

    paymentFileRemoved(data?: any) {
        this.auxFileRemoved('Payment', this.aux.paymentFiles);
    }

    setPayment() {
        let loading = this.app.showLoading();
        this.service.setPayInfo(this.item.Id, this.paidReg, this.paidFirstPart).subscribe(() => {
            this.app.hideLoading(loading);
            if (this.paidReg)
                this.studentPayStatusModel.PaidRegAmount = this.studentPayStatusModel.NeedTopPayRegAmount;
        }, err => this.app.showError(err));
    }

    getViisGradeString(exam: ApplicationExam) {
        return getViisGradeString(this.app.translate, exam);
    }

    getViisRawGradeString(exam: ApplicationExam) {
        return getViisRawGradeString(this.app.translate, exam);
    }

    getCrmGradeString(exam: ApplicationExam) {
        return getCrmGradeString(this.app.translate, exam);
    }

    onExamFileRemove = () => {
        let subj = new Subject<boolean>();
        this.app.confirm(this.app.translate.instant('confirmFileInputRemove'), res => subj.next(res));
        return subj.asObservable();
    }

    generatePdf() {
        let loading = this.app.showLoading();
        this.service.generatePdf(this.item.Id).subscribe(res => {
            this.aux.applicationForm = {
                Id: res.Id,
                Type: 'ApplicationForm',
                Created: res.Created,
                fileName: res.FileName,
                url: this.service.getAuxFileUrl(res.Id)
            };

            this.app.hideLoading(loading);
        }, err => this.app.showLoadError(err));
    }

    share() {
        let dlg = this.dialog.open(ShareComponent, {
            disableClose: true,
            autoFocus: true,
            width: '400px',
            data: { objectId: this.item.Id, objectType: 'Application' }
        });

        dlg.afterClosed().subscribe(result => {
            if (result) {
                // do nothing?
            }
        });
    }

    sanitize(url: string) {
        return this.sanitizer.bypassSecurityTrustUrl(url);
    }

    sendPersonalApplicantEmail() {
        let subject = this.personalEmailSubjectText;

        if(this.isAdmin || this.isEditor) {
            let dialogRef = this.dialog.open(SendEmailsConfirmDialogComponent, {
                disableClose: true,
                width: '600px',
                data: {subject: subject, canEditBody: true}
            });

            dialogRef.afterClosed().subscribe(result => {
                if (result) {
                    let loading = this.app.showLoading();
                    try {
                        this.service.sendPersonalEmail(this.item.Number, {
                            subject: result.subject,
                            body: result.body,
                            applicationId: this.item.Id,
                            applicationNumber: this.item.Number,
                            address: this.item.Email
                        });

                        this.app.notify(this.app.translate.instant('personalEmails_sentSuccessfully'));
                    }
                    catch (err) {
                        this.app.showSaveError(err);
                    }
                    finally {
                        this.app.hideLoading(loading);
                    }
                }
                else {
                    return;
                }
            });
        }
        else return;
    }

    getPersonalEmailHistory() {
        if(this.isAdmin || this.isEditor)
            this.dialog.open(PersonalEmailHistoryComponent, { width: '800px', data: this.item.Id });
        else return;
    }

    showProgramUniversitySelection(program: ApplicationProgram): boolean {
        return this.isUnitedRZ
            && [ApplicationUnitedRZStatus.UniversitySelection, ApplicationUnitedRZStatus.UniversitySelected].indexOf(this.item.UnitedRZStatus) > -1
            && program.Program.Universities && ['In', 'InManual'].indexOf(program.Result) > -1;
    }

    getProgramRankString(program: ApplicationProgram): string {
        let rank = program.Rank.split('/');
        let text = rank[1].slice(-1) === '1' ? 'applicationPrograms_rankString1' : 'applicationPrograms_rankString';
        return Utils.formatString(this.app.translate.instant(text), rank);
    }

    removeLateSubmissionTag() {
        if(this.isPowerUser || this.isAdmin)
        {
            this.app.confirm({
            text: this.app.translate.instant(this.applicationConfirmRemovingLateSubmissionTagText),
            cancelText: this.app.translate.instant('cancel'),
            okText: this.app.translate.instant('continue')
            }, result => {
                if(result)
                {
                    const loading = this.app.showLoading();
                    this.service.removeLateSubmissionTag(this.item.Id).subscribe(result => {
                        this.app.hideLoading(loading);
                        this.load(this.item.Number);
                    }, err => this.app.showError(err));
                }
            });
        }
    }

    pay(type: string, aplicationId: number, ApplicationProgramId?: number) {
        let loading = this.app.showLoading();
        let contract: StudentPayModel = new StudentPayModel();
        contract.AplicationId = aplicationId;
        if (type == 'FirstPartPayment')
            contract.ApplicationProgramId = ApplicationProgramId;
        contract.Type = type;

        this.service.pay(contract).subscribe(response => {
            this.app.hideLoading(loading);

            this.payData = response.Data;
            this.payFormUrl = response.Url;

            // does not work immediately
            setTimeout(() => {
                this.payForm.nativeElement.submit();
            }, 100);
        }, err => this.app.showError(err));
    }

    startPay() {
        this.app.alerts.info(this.app.translate.instant('applications_pay_processing'));
    }

    sign() {
        this.app.confirm({
            text: this.app.translate.instant('applications_signConfirm'),
            cancelText: this.app.translate.instant('applications_signCancel'),
            okText: this.app.translate.instant('applications_signOk')
        }, ok => {
            if (ok) {
                const loading = this.app.showLoading();
                this.service.sign(this.item.Id).subscribe(result => {
                    this.app.hideLoading(loading);
                    this.item.IsSigned = result;
                    this.load(this.item.Number);
                }, err => this.app.showError(err));
            }
        });
    }

    private loadExams(appId?: number): Observable<any> {
        this.hasVIISExams = this.hasDVAExams = false;
        let subj = new Subject();
        let loading = this.app.showLoading();
        this.service.getExams(appId || this.item.Id).subscribe(data => {
            this.exams = data;

            let programs: any[] = [];

            for (let i = 0; i < this.exams.length; i++) {
                let exam = this.exams[i];
                let pId = exam.ProgramId || undefined;
                let gName = exam.Group || undefined;

                this.hasVIISExams = this.hasVIISExams || exam.Code.toUpperCase().startsWith('CE-');
                this.hasDVAExams = this.hasDVAExams || exam.Code.toUpperCase().startsWith('DVA-');

                if (exam.ExamMandatory)
                    this.requiredExamIds.push(exam.Id);

                let p = programs.find(t => t.id === pId);

                if (!p) {
                    p = {
                        id: pId,
                        name: pId > 0 ? exam.Program : undefined,
                        item: this.programs.find(t => t.Program.Id === pId),
                        groups: []
                    };

                    programs.push(p);
                }

                let g = p.groups.find(t => t.name === gName);

                if (!g) {
                    g = {
                        name: gName,
                        exams: []
                    };

                    p.groups.push(g);
                }

                // we need this field to compare later if grade has changed
                exam['_prevGrade'] = exam.Grade;
                exam['_prevAuxType'] = exam.AuxType;

                if (this.isResidentAdmission && !exam.Grade && exam.TypeCode === 'CE')
                    exam.Grade = 0;

                g.exams.push(exam);
            }

            for (let i = 0; i < programs.length; i++) {
                let p = programs[i];

                for (let j = 0; j < p.groups.length; j++) {
                    p.groups[j].exams.sort((a, b) => (a.SortOrder || 0) - (b.SortOrder || 0));

                    let maxGrade = 0;

                    p.groups[j].exams.forEach(e => {
                        if (e.MaxGrade > maxGrade) {
                            maxGrade = e.MaxGrade;
                        }
                    });

                    p.groups[j].maxGrade = maxGrade;
                }

                p.groups.sort((a, b) => (a.GroupSort || 0) - (b.GroupSort || 0));
            }

            programs.sort((a, b) => (a.item ? a.item.Priority : 999) - (b.item ? b.item.Priority : 999));

            this.examTree = programs;

            this.app.hideLoading(loading);
            subj.next();
        }, err => this.app.showLoadError(err));

        return subj.asObservable();
    }

    private refreshExamExtGrades(
        data: { appExamId: number, grades: any }[],
        updater: (appExam: ApplicationExam, gradeData: ViisGrade[] | number[]) => void) {
        for (let i = 0; i < this.examTree.length; i++) {
            const p = this.examTree[i];
            for (let j = 0; j < p.groups.length; j++) {
                const g = p.groups[j];
                for (let k = 0; k < g.exams.length; k++) {
                    const e = g.exams[k];
                    const examData = data.find(x => x.appExamId == e.Id);
                    if (examData) {
                        updater(e, examData.grades);
                    }
                }
            }
        }
    }

    private saveExam(exam: ApplicationExam, grade?: number): Observable<ApplicationExam> {
        const subj = new Subject<ApplicationExam>();
        const loading = this.app.showLoading();
        this.service.saveExam(this.item.Id, exam.Id, {
            grade: grade || exam.Grade,
            gradeSystem: exam.GradeSystem,
            auxType: exam.AuxType,
            gradeCoef: exam.GradeCoef,
            rawGrade: exam.RawGrade
        }).subscribe(() => {
            this.app.hideLoading(loading);
            exam['_prevGrade'] = exam.Grade;
            exam['_prevAuxType'] = exam.AuxType;
            subj.next(exam);
        }, err => {
            this.app.hideLoading(loading);
            this.app.showSaveError(err);
        });

        return subj.asObservable();
    }

    private parseAuxData(data: ApplicationAuxData[]): any {
        if (!data)
            data = [];

        let parser = new AuxDataParser(data);

        let createFile = (data: ApplicationAuxData): any => {
            const isContract = data.Type == 'Contract';
            return {
                Id: data.Id,
                Type: data.Type,
                Created: data.Created,
                fileName: !!data.Text1 ? data.Text1 : '',
                status: isContract ? data.Text3 : '',
                url: this.service.getAuxFileUrl(data.Id),
                pdfUrl: isContract && (data.Text1 || '').toLowerCase().endsWith('.docx') ? this.service.getAuxFileUrl(data.Id, true) : undefined
            };
        };

        let getFiles = (type: string, startsWith?: boolean) => {
            return data.filter(t => startsWith ? t.Type.startsWith(type) : t.Type === type).map(t => createFile(t));
        };

        let appForms = getFiles('ApplicationForm').sort((a, b) => new Date(b.Created).getTime() - new Date(a.Created).getTime());

        let aux = {
            WayOfApp: {
                ViaRepresentative: parser.getBool('WayOfApp.ViaRepresentative'),
                ViaRepresentativeText: parser.getText('WayOfApp.ViaRepresentative', 1),
                OnMyOwn: parser.getBool('WayOfApp.OnMyOwn')
            },
            WhereFoundInfo: {
                ViaRepresentative: parser.getBool('WhereFoundInfo.ViaRepresentative'),
                ViaRepresentativeText: parser.getText('WhereFoundInfo.ViaRepresentative', 1),
                CurrentStudent: parser.getBool('WhereFoundInfo.CurrentStudent'),
                Alumni: parser.getBool('WhereFoundInfo.Alumni'),
                Internet: parser.getBool('WhereFoundInfo.Internet'),
                Exhibition: parser.getBool('WhereFoundInfo.Exhibition'),
                ExhibitionText: parser.getText('WhereFoundInfo.Exhibition', 1),
                OpenDays: parser.getBool('WhereFoundInfo.OpenDays'),
                Other: parser.getText('WhereFoundInfo.Other')
            },
            applicationForm: appForms.length ? appForms[0] : undefined,
            personDocuments: getFiles('PersonDocument'),
            educationDocuments: [],
            studentContracts: getFiles('Contract').sort((a, b) => new Date(b.Created).getTime() - new Date(a.Created).getTime()),
            enrollmentOrders: [getFiles('Suborder').sort((a, b) => new Date(b.Created).getTime() - new Date(a.Created).getTime())[0]],
            paymentFiles: getFiles('Payment').sort((a, b) => new Date(b.Created).getTime() - new Date(a.Created).getTime()),
            examFiles: {},
            workplaceFiles: {}
        };

        data.forEach(t => {
            if (t.Type && (
                t.Type.startsWith('EduAttestation_')
                || t.Type.startsWith('EduGrades_')
                || t.Type.startsWith('EduAicOpinion_')
                || t.Type.startsWith('EduOther_'))
            ) {
                let id = +t.Type.split('_').pop();
                let edu = this.educations.find(x => x.Id === id);

                let file = createFile(t);
                file.institution = edu ? edu.Institution : '';

                aux.educationDocuments.push(file);
            }
            if (t.Type && t.Type === 'Contract') {
                if (aux.studentContracts.some(x => x.Id === t.Id)) {
                    this.service.getApplicationAuxDataProgram(t.Id).subscribe(resultProgram => {
                        if (resultProgram) {
                            aux.studentContracts.filter(x => x.Id === t.Id)[0].institution =
                                (!!resultProgram.Code ? (resultProgram.Code + ', ') : '') +
                                (!!resultProgram.Title ? (resultProgram.Title + ', ') : '') +
                                (!!resultProgram.Financing ? (resultProgram.Financing) : '');

                        }
                    });
                }
            }
        });

        aux.educationDocuments.sort((a, b) => a.institution ? a.institution.localeCompare(b.institution) : 1);

        this.exams.forEach(t => {
            aux.examFiles[t.Id] = getFiles(`ExamFile_${t.Id}`);
        });

        this.programs.forEach(ap => {
            (aux.workplaceFiles[ap.Id] = getFiles(`WorkplaceFile_${ap.Id}`)).forEach(f => f.institution = ap.Workplace);

        });

        if (aux.studentContracts.length > 0)
            this.showDocuments = true;

        return aux;
    }

    private loadPaymentInfo() {
        let loading = this.app.showLoading();
        this.service.getPayInfo(this.item.Id).subscribe(data => {
            this.studentPayStatusModel = data;
            this.paidReg = this.studentPayStatusModel.PaidReg;
            this.paidFirstPart = this.studentPayStatusModel.PaidFirstPart;

            if (this.paidReg)
                this.studentPayStatusModel.PaidRegAmount = this.studentPayStatusModel.NeedTopPayRegAmount;

            this.app.hideLoading(loading);
        }, err => this.app.showLoadError(err));
    }

    private load(appNumber?: string) {
        if (!appNumber)
            appNumber = this.item.Number;

        let loading = this.app.showLoading();

        this.service.getByNumber(appNumber).subscribe(data => {
            const appId = data.Id;

            this.item = data;
            this.isHomines = this.item.IsHomines;
            this.isUnitedRZ = this.item.IsUnitedRZ;
            this.isForeign = this.item.IsForeign;
            this.showBanklink = this.item.ShowBanklink;
            this.paymentFileUploaded = this.item.PaymentFileUploaded;
            this.personDocumentsDownloadUrl = this.service.getPersonDocumentsDownloadUrl(appId);

            this.showApplicantDocuments = this.isApplicant && !this.item.IsForeign && !this.isHomines;
            this.showDocuments = !this.showApplicantDocuments;

            this.showExams =
                this.showPay =
                this.showThesis =
                this.showWayOfApp =
                this.showWhereFoundInfo =
                this.item.Status === ApplicationStatus.New ||
                !this.isApplicant && [ApplicationStatus.SubmittedByApplicant, ApplicationStatus.SubmittedByClerk].some(s => s === this.item.Status);

            this.showConfirmations = this.item.Status === ApplicationStatus.New || !this.isApplicant;
            this.paramService.getValues().subscribe(values => {
                let code = 'AdmissionRulesUrl'
                if (this.isHomines)
                    code = 'HominesAdmissionRulesUrl';

                let para = values.find(t => t.Code === code);
                if (para)
                    this.admissionRulesUrl = para.Value;

                let maxFileUploadSizeParam = values.find(t => t.Code === 'ApplicationFileUploadMaxSize');
                this.maxFileUploadSize = maxFileUploadSizeParam.Value ? +maxFileUploadSizeParam.Value : defaultMaxFileUploadSize;

                let fileUploadExtensionsParam = values.find(t => t.Code === 'ApplicationFileUploadExtensions');
                this.fileUploadExtensions = '.'
                    + (fileUploadExtensionsParam.Value ? fileUploadExtensionsParam.Value : defaultFileUploadExtensions)
                        .split(',').join(',.');

                this.signRequired = values.some(t => t.Code === 'ApplicationSignRequired' && t.Value === '1');

                const maxEdocFileSizeParam = values.find(t => t.Code === 'ApplicationEdocUploadMaxSize');
                this.maxEdocUploadSize = maxEdocFileSizeParam.Value ? +maxEdocFileSizeParam.Value : defaultMaxFileUploadSize;
            });

            zip(
                this.service.getPrograms(appId),
                this.service.getNotes(appId),
                this.service.getPerson(appId),
                this.service.validate(appId),
                this.service.getAuxData(appId),
                this.service.getEducations(appId)
            ).subscribe(data => {
                this.notes = data[1];
                this.persons = data[2];
                this.educations = data[5];

                if (this.isHomines) {
                    this.programs = [];
                    if (data[0].length > 0)
                        this.programs.push(data[0][0]);
                }
                else
                    this.programs = data[0];

                this.programs.sort((a, b) => a.Priority - b.Priority);
                this.programs.forEach(t => {
                    if (t.Program.Universities) {
                        t.Program.Universities.forEach(u => u['order'] = u.Code === 'RSU' ? 1 : u.Code === 'LU' ? 2 : 99);
                        t.Program.Universities.sort((a, b) => a['order'] - b['order']);
                        if (t.University && t.University.Id) {
                            let found = t.Program.Universities.find(u => u.Id == t.University.Id);
                            if (found)
                                t.University = found;
                        }
                    }
                });

                if (this.programs && this.programs.length) {
                    this.acceptedInProgram = this.programs.find(p => p.Result === ApplicationResultType.In || p.Result === ApplicationResultType.InManual);
                }
                if (this.acceptedInProgram)
                    this.programResult = 'in';
                else if (this.programs.some(t => t.Result === ApplicationResultType.Out))
                    this.programResult = 'out';

                this.admissionService.getById(this.item.AdmissionId).subscribe(adm => {
                    this.admissionExpired = adm.IsExpired;
                    this.isLateSubmission = adm.IsLateSubmission;
                    this.pdfAvailable = adm.PdfAvailable;
                    if (adm.LevelId) {
                        this.classifierService.getById(adm.LevelId).subscribe(level => {
                            this.isResidentAdmission = level.Code === 'R';
                            // we need programs and, if not empty, admission level code to be loaded to build exam tree
                            this.loadExams(appId).subscribe(() => {
                                this.aux = this.parseAuxData(data[4]);
                            });
                        });
                    } else {
                        this.loadExams(appId).subscribe(() => {
                            this.aux = this.parseAuxData(data[4]);
                        });
                    }

                    if (adm.PublishDate && adm.PublishTime) {
                        let h = +adm.PublishTime.split(':')[0];
                        let min = +adm.PublishTime.split(':')[1] + h * 60;
                        let dateTime = new Date(new Date(adm.PublishDate).getTime() + min * 60000);

                        let current = new Date();
                        if (dateTime < current)
                            this.IsAdmissionPublished = true;
                    }

                    if (adm.LevelId === StudyLevel.Doctoral) {
                        this.isDoctor = true;
                        this.service.getAuxData(appId).subscribe(auxData => {
                            let thesisAuxData = auxData.find(k => k.Type == AuxType.Thesis);
                            if (thesisAuxData)
                                this.thesis = thesisAuxData;
                        });
                    }

                    this.areContractFilesAccessible = true;
                    let now = Date.now();
                    if (!!adm.ContractFilesAccessibleFrom) {
                        if (now < new Date(adm.ContractFilesAccessibleFrom).getTime()) {
                            this.contractFilesAccessibleFrom = adm.ContractFilesAccessibleFrom;
                            this.areContractFilesAccessible = false;
                        }
                    }
                    if (this.areContractFilesAccessible && !!adm.ContractFilesAccessibleUntil) {
                        this.contractFilesAccessibleUntil = adm.ContractFilesAccessibleUntil;
                        let untilExcl = new Date(adm.ContractFilesAccessibleUntil).getTime() + 24 * 60 * 60 * 1000;
                        if (now >= untilExcl) {
                            this.areContractFilesAccessible = false;
                        }
                    }
                    if (adm.PdfAvailable || !this.isApplicant) {
                        this.onlineApplicationFormIsAvailable = true;
                    }
                    this.programLimit = (adm.IsLateSubmission) ? adm.ExtensionPriorityCount : adm.PriorityCount;
                    this.app.hideLoading(loading);
                });

                this.errors = data[3];
                this.programResultNotes = this.notes.filter(t => t.Position === ApplicationNotePosition.ProgramResult && t.Text).map(t => t.Text);

                if (this.item.ApplicationForCreditTransfer) {
                    this.service.getPreviousUniversity(this.item.Id).subscribe(uni => this.prevUni = uni);
                }

                this.dataLoaded = true;
            }, err => this.app.showLoadError(err));

            // this call is moved outside the app call list since it's optional
            // and may fail due to insufficient rights
            this.loadPaymentInfo();

            //if (this.isEditor) {
            this.service.getContractStatus(appId).subscribe(status => {
                if (status) {
                    this.contractStatusRead = status;
                    this.contractStatus = status.currentStatus;
                    this.contractDVSId = status.DvsDocumentId;
                    if (this.isEmployeeThatCanSeeContractStatus) {
                        const availStatuses = this.getContractStatuses();
                        this.contractStatusToSet = availStatuses.length ? availStatuses[0].Id : undefined;
                    }
                }
            }, err => this.app.showLoadError(err));
            //}
        }, async err => {
            this.app.hideLoading(loading);

            if (err.status !== 404) {
                this.app.showLoadError(err);
                return;
            }

            this.itemExists = false;
        });
    }

    private hasInvalidGrades(): boolean {
        return this.exams.some(t => !this.validateGrade(t));
    }

    private confirmInvalidGrades(): Observable<boolean> {
        if (this.hasInvalidGrades()) {
            let subj = new Subject<boolean>();
            this.app.confirm(this.app.translate.instant('applications_invalidGradesConfirm'), result => {
                subj.next(result);
            });
            return subj.asObservable();
        }

        return of(true);
    }

    private async updateWorkplace(program: ApplicationProgram): Promise<void> {
        const loading = this.app.showLoading();
        try {
            await this.service.updateProgramWorkplace(this.item.Id, program.Program.Id, program);
        }
        catch (err) {
            this.app.showSaveError(err);
        }
        finally {
            this.app.hideLoading(loading);
        }
    }

    private loadClassifiers() {
        const loading = this.app.showLoading();

        this.classifierService.get('Grade5,Grade10,ContractStatus,ASNContractTerminationReason').subscribe(data => {
            this.app.hideLoading(loading);

            this.gradeMappings[GradeSystemType.FivePoint] = data.filter(t => t.Type === 'Grade5').map(t => {
                return {
                    i: +t.Code.split('_').pop(),
                    val: t.Value
                };
            }).sort((a, b) => a.i - b.i).map(t => t.val);

            this.gradeMappings[GradeSystemType.TenPoint] = data.filter(t => t.Type === 'Grade10').map(t => {
                return {
                    i: +t.Code.split('_').pop(),
                    val: t.Value
                };
            }).sort((a, b) => a.i - b.i).map(t => t.val);

            this.contractStatuses = data.filter(t => t.Type === 'ContractStatus');
            this.terminationReasons = data.filter(t => t.Type === 'ASNContractTerminationReason');

        });
    }
}
