import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AppService } from '../services/app.service';
import { AuthService } from '../services/auth.service';
import { ParameterService } from '../services/parameter.service';
import { UserCrypto } from '../models/UserInfo';
import { AES, mode, pad, enc } from 'crypto-ts';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
    emailValue: string;
    passwordValue: string;
    loading = false;
    loginError: boolean = false;
    cryptoKey: string = '';
    userCryptoKey: string;
    crypto: UserCrypto;
    emailAuthEnabled: boolean;
    bankAuthEnabled: boolean;
    oAuthEnabled: boolean;
    isHomines: boolean;
    isAuthenticated: boolean;

    constructor(
        private app: AppService,
        private auth: AuthService,
        private params: ParameterService
    ) { }

    ngOnInit() {
        this.isAuthenticated = this.app.auth.isAuthenticated;

        if (this.app.auth.isAuthenticated) {
            if (this.app.auth.currentUser.startUrl)
                this.app.redirect("/" + this.app.auth.currentUser.startUrl);
            else
                this.redirectOnSuccess();
        } else {
            this.isHomines = this.app.env.isHomines;
            this.userCryptoKey = new Date().getTime() + '_' + Math.floor(Math.random() * 100);
            this.auth.getCryptoKey(this.userCryptoKey).subscribe(responseObj => {
                this.crypto = responseObj;
            });

            let loading = this.app.showLoading();
            this.params.getValues().subscribe(values => {
                this.bankAuthEnabled = values.some(t => t.Code === 'BankAuthEnabled' && t.Value === '1');
                this.oAuthEnabled = values.some(t => t.Code === 'oAuthEnabled' && t.Value === '1');
                this.emailAuthEnabled = values.some(t => t.Code === 'EmailAuthEnabled' && t.Value === '1');
                this.app.hideLoading(loading);
            });
        }
    }

    login() {
        let key = enc.Utf8.parse(this.crypto.key);
        let iv = enc.Utf8.parse(this.crypto.iv);
        let cryptedObj = AES.encrypt(this.passwordValue, key, {
            iv: iv,
            mode: mode.CBC,
            padding: pad.PKCS7
        });

        this.loading = true;
        let loading = this.app.showLoading();

        this.auth.login(this.emailValue, enc.Hex.stringify(cryptedObj.ciphertext), this.userCryptoKey)
            .subscribe(
                response => {
                    this.loading = false;
                    this.app.hideLoading(loading);

                    this.loginError = false;

                    let returnUrl = this.app.returnUrl;
                    this.app.returnUrl = undefined;

                    if (returnUrl)
                        this.app.router.navigateByUrl(returnUrl);
                    else if (this.app.auth.isAuthenticated && this.app.auth.currentUser.startUrl)
                        this.app.redirect("/" + this.app.auth.currentUser.startUrl);
                    else
                        this.redirectOnSuccess();

                },
                error => {
                    this.loginError = true;
                    this.loading = false;
                    this.app.hideLoading(loading);
                });
    }

    public redirectOnSuccess() {
        this.app.redirect(this.app.auth.isDataViewer() ? '/applications/search' : '/applications');
    }
}
