import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { AppService } from '../services/app.service';
import { ApplicationService } from '../services/application.service';
import { IFieldFile } from '../shared/form-field-file.component';

import { Utils } from '../Utils';

export interface ISignEdocDialogComponentData {
    isApplicant: boolean;
    applicationId: number;
    maxFileSize: number;
    contractFileName: string;
    currentFile: { name: string, url: string };
}

@Component({
    templateUrl: 'sign-edoc.component.html',
    styleUrls: ['./application-edit.component.css']
})
export class SignEdocDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<SignEdocDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ISignEdocDialogComponentData,
        private app: AppService,
        private service: ApplicationService
    ) {
        this.applicationId = data.applicationId;
        this.maxSize = data.maxFileSize;
        this.contractFileName = Utils.getFileNameWithoutExtension(data.contractFileName) + '.edoc';
        this.showMutuallySigned = !data.isApplicant;
        this.currentFile = data.currentFile;
        this.showDownload = data.isApplicant;
    }

    readonly applicationId: number;
    readonly maxSize: number;
    readonly contractFileName: string;
    readonly showMutuallySigned: boolean;
    readonly showDownload: boolean;
    readonly currentFile: { name: string, url: string };
    
    file: IFieldFile = <IFieldFile>{};
    actualFile: File;
    mutuallySigned: boolean;

    setFile(data) {
        this.actualFile = data.file;
    }

    removeFile() {
        this.file = <IFieldFile>{};
        this.actualFile = undefined;
    }

    validate() {
        if (!this.actualFile) return true;
        return this.actualFile.name.toLowerCase() === this.contractFileName.toLowerCase();
    }

    submit() {
        if (!this.validate()) {
            this.app.alerts.warning(this.app.translate.instant('applications_esign_fileRequired'), '');
            return false;
        }

        const loading = this.app.showLoading();
        this.service.uploadEdoc(this.applicationId, this.actualFile, this.mutuallySigned).subscribe(result => {
            this.app.hideLoading(loading);
            this.dialogRef.close(true);
        }, err => this.app.showSaveError(err));
    }
}
