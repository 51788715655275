import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { GridComponentBase } from '../../GridComponentBase';
import { InterviewPersonnel } from '../../models/Program';
import { AppService } from '../../services/app.service';
import { ProgramService } from '../../services/program.service';

@Component({
  selector: 'app-interviewpersonnel',
  templateUrl: './interviewpersonnel.component.html',
  styleUrls: ['./interviewpersonnel.component.css']
})
export class InterviewPersonnelComponent extends GridComponentBase<InterviewPersonnel> implements OnInit {

    constructor(public app: AppService, private route: ActivatedRoute, private service: ProgramService) {
        super(app);
    }
    private programId: number;
    ngOnInit() {
        this.route.params.subscribe(params => {
            this.programId = params['id'];
            if (this.programId) {
                this.service.getInterviewPersonnel(this.programId).subscribe(data => {
                    this.gridItems = data;
                }, err => this.app.showLoadError(err));
            }
        });
    }

    protected onBeforeEditGridRow(item: InterviewPersonnel) {
        if (item.UserIdentity) {
            item.UserIdentity = item.UserIdentity.replace(/@rsu\.lv$/, '')
        }
    }

    isValidGridItem(item: InterviewPersonnel): boolean {
        if (!item.FullName) return false;
        if (!item.UserIdentity && !item.PersonCode) return false;
        if (item.PersonCode && !/^[0-9]{6}-?[0-9]{5}$/.test(item.PersonCode)) return false;
        return true;
    }

    protected createGridItem(item: InterviewPersonnel): Observable<InterviewPersonnel> {
        this.normalizeFields(item);
        return this.service.createInterviewPersonnel(item);
    }

    private normalizeFields(item: InterviewPersonnel) {
        if (item.PersonCode && item.PersonCode.includes('-'))
            item.PersonCode = item.PersonCode.replace('-', '');
        if (item.UserIdentity)
            item.UserIdentity += '@rsu.lv';
    }

    protected updateGridItem(item: InterviewPersonnel): Observable<InterviewPersonnel> {
        this.normalizeFields(item);
        return this.service.updateInterviewPersonnel(item);
    }

    protected deleteGridItem(item: InterviewPersonnel): Observable<boolean> {
        return this.service.deleteInterviewPersonnel(item.ProgramId, item.Id);
    }

    protected createEmptyObject(): InterviewPersonnel {
        let item = new InterviewPersonnel();
        item.ProgramId = this.programId;
        return item;
    }

}
