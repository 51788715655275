<header class="header">
    <div class="top">
        <div class="container">
            <div class="language" *ngIf="env.showLanguageSwitch" >
                <a href="#" class="language-link" *ngIf="locale!='en'" (click)="changeLocale($event,'en')" hreflang="en">English</a>
                <a href="#" class="language-link" *ngIf="locale!='lv'" (click)="changeLocale($event,'lv')" hreflang="lv">Latviešu</a>
            </div>

            <nav role="navigation" class="top-menu">
                <div *ngIf="env.showDebugMsg"  class="env-label">
                    <span>{{ 'devenvironment' | translate }}</span>
                </div>
                <div *ngIf="currentUser" class="user">
                    <span class="greeting">{{ 'hello' | translate:userName }}</span>
                    <a href="#" (click)="$event.preventDefault(); logout()">
                        <i class="fa fa-sign-out"></i>
                        {{'logoutlabel' | translate}}
                    </a>
                </div>
                <div *ngIf="!currentUser" class="user">
                    <a [routerLink]="['/login'] | localize" [translate]="'loginlabel'"></a>
                </div>
            </nav>
        </div>
    </div>

    <div class="menu">
        <div class="container clearfix">
            <div class="branding">
                <a [routerLink]="[logoUrl] | localize" title="Home">
                    <img src="/assets/img/logo.svg" alt="Home">
                </a>
            </div>
            <div class="branding branding-right" *ngIf="isUnitedRZ">
                <a [routerLink]="['/'] | localize" title="Home">
                    <img src="/assets/img/lu_logo.png" alt="Home">
                </a>
            </div>
            <nav role="navigation" class="main-menu" [class.opened]="menuOpened" *ngIf="currentUser">
                <ul class="clearfix">
                    <li [class.opened]="n.isOpened" (mouseover)="openMenuItem(n)" (mouseout)="closeMenuItem(n)" *ngFor="let n of getVisibleNavItems(navItems)">
                        <span class="triangle" *ngIf="n.children && n.children.length"></span>
                        <a [routerLink]="[n.route] | localize" *ngIf="n.route" (click)="closeMenuItem(n)" [translate]="n.titleRes"></a>
                        <a *ngIf="!n.route" [translate]="n.titleRes"></a>
                        <ul *ngIf="n.children && getVisibleNavItems(n.children).length" class="clearfix">
                            <li *ngFor="let c of getVisibleNavItems(n.children)">
                                <a [routerLink]="[(n.childrenRoutePrefix || '') + c.route] | localize"
                                    (click)="closeMenuItem(n); toggleMenu()"
                                    *ngIf="c.route"
                                    [translate]="c.titleRes"></a>
                                <a *ngIf="!c.route" [translate]="c.titleRes"></a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </nav>

            <span class="main-menu-button" [class.burger]="!menuOpened" [class.cross]="menuOpened" (click)="toggleMenu()"></span>
            
        </div>
    </div>
</header>
