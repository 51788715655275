import { BaseEntity } from './BaseEntity';

export class Message extends BaseEntity {
    Code: string;
    Group: string;
    Title: string;
    TextLV: string;
    TextEN: string;
    EditTextOnly: boolean;
    Created: Date;
    Modified: Date;
}
