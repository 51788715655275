import { BaseEntity } from './BaseEntity';

export enum DocumentTemplateType {
    Order = 'Order',
    Suborder = 'Suborder',
    Interview = 'Interview',
    Reports ='Reports'
}

export class DocumentTemplate extends BaseEntity {
    Title: string;
    DateFrom: Date;
    DateTo: Date;
    UseCode: string;
    TemplateCode: string;
    FileLv: Blob;
    FileEn: Blob;
    FileName: string;
    Type: DocumentTemplateType;
}
