import { KeyValue } from "@angular/common";
import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { UntypedFormControl, Validators } from "@angular/forms";
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from "@angular/material/autocomplete";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";

import { AppService } from "../../services/app.service";
import { AddressApiQuery, AddressApiResponse, AddressLookupOptions, ApplicationService } from "../../services/application.service";
import { Address } from "./address.component";

@Component({
    selector: 'app-lv-address-form',
    templateUrl: './lv-address-form.component.html',
    styleUrls: ['./lv-address-form.component.css'],
})
export class LvAddressFormComponent implements OnChanges {

    @Input() data: Address;
    @Input() readonly = false;

    readonly lookupFieldNames = [
        'County',
        'City',
        'Parish',
        'Village',
        'Street',
        'House',
        'Apartment'
    ];

    readonly lookupFields: {
        [fieldName: string]:
        {
            readonly control: UntypedFormControl,
            lookupSource: Map<string, string>,
            filteredSource?: Observable<Map<string, string>>,
            isSet?: boolean,
        }
    };

    isComplete: boolean;

    constructor(
        private app: AppService,
        private service: ApplicationService,
    ) {
        this.lookupFields = {};
        this.lookupFieldNames.forEach(fieldName => {
            this.lookupFields[fieldName] = {
                control: new UntypedFormControl(null, [Validators.required, this._validateSelected]),
                lookupSource: new Map<string, string>(),
            };
        });
        Object.values(this.lookupFields).forEach(field => {
            field.control.disable();
            field.filteredSource = field.control.valueChanges
                .pipe(
                    startWith(''),
                    map((value: any) => (!value || typeof value === 'string') ? value : value.value),
                    map((filter: string) => this._filterSource(field.lookupSource, filter)),
                );
        });
    }

    private _validateSelected(control: UntypedFormControl) {
        return !control.value || typeof control.value !== 'string' ? null
            : {
                validateSelected: {
                    valid: false
                }
            };
    }

    private _filterSource(lookupSource: Map<string, string>, filter: string): Map<string, string> {
        if (filter) {
            filter = filter.toLowerCase();
        }
        const result = new Map<string, string>();
        lookupSource.forEach((v, k) => {
            if (!filter || !v || v.toLowerCase().startsWith(filter))
                result.set(k, v);
        });
        return result;
    }

    getValue(option: KeyValue<string, string>): string {
        return option && option.value ? option.value : '';
    }

    isRequired(fieldName: string) : boolean {
        return !this.isComplete || !!this.data[fieldName + 'Id'];
    }

    originalOrder(a: KeyValue<string, string>, b: KeyValue<string, string>): number {
        return 0;
    }

    onClick(event: Event, trigger: MatAutocompleteTrigger) {
        if (!trigger.panelOpen) {
            event.stopImmediatePropagation();
            trigger.openPanel();
        }
    }

    onOptionSelected(fieldName: string, event: MatAutocompleteSelectedEvent) {
        this._optionSelected(fieldName, { key: event.option.value.key, value: event.option.viewValue });
    }

    onClosed(fieldName: string) {
        const field = this.lookupFields[fieldName];
        if (!field.control.value || typeof field.control.value === 'string') {
            field.control.setValue({ key: this.data[fieldName + 'Id'], value: this.data[fieldName] });
        }
    }

    private async _optionSelected(fieldName: string, kv: KeyValue<string, string>): Promise<void> {
        if (this._setFieldValue(fieldName, kv)) {
            this._clearSubsequentFields(fieldName);
            await this._updateLookupSources();
        }
    }

    private _clearSubsequentFields(fieldName: string) {
        let indexInHierarchy = this.lookupFieldNames.indexOf(fieldName);
        while (++indexInHierarchy < this.lookupFieldNames.length) {
            const fname = this.lookupFieldNames[indexInHierarchy];
            this._setFieldValue(fname, { key: '', value: '' });
        }
    }

    private _setFieldValue(fieldName: string, kv: KeyValue<string, string>): boolean {
        const field = this.lookupFields[fieldName];
        field.control.setValue(kv);
        field.isSet = !!kv.key;
        if (this.data[fieldName + 'Id'] !== kv.key) {
            this.data[fieldName + 'Id'] = kv.key;
            this.data[fieldName] = kv.value;
            return true;
        }
        return false;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['data'] && this.data) {

            //if (!this.readonly) this._updateLookupSources();

            const pseydoResponse = new AddressApiResponse();
            pseydoResponse.AddressText = this.data.AddressText;
            pseydoResponse.PostCode = this.data.PostCode;
            this.lookupFieldNames.forEach(fieldName => this._setInitialOptionsFromData(fieldName, pseydoResponse));

            this._processResponse(pseydoResponse, true);

            if (!this.readonly) this._updateLookupSources();
        }
    }

    private async _setInitialOptionsFromData(fieldName: string, target: AddressApiResponse): Promise<void> {
        if (this.data[fieldName + 'Id']) {
            if (!target[fieldName + 'Options'] || !target[fieldName + 'Options'].size)
                target[fieldName + 'Options'] = new Map<string, string>([[this.data[fieldName + 'Id'], this.data[fieldName]]]);
        }
    }

    private async _updateLookupSources(): Promise<void> {
        const loading = this.app.showLoading();
        try {
            const query = new AddressApiQuery();
            for (let prop in query) query[prop] = this.data[prop];

            const response = await this.service.queryAddress(query);

            this._processResponse(response, false);
        }
        catch (err) {
            this.app.showLoadError(err);
        }
        finally {
            this.app.hideLoading();
        }
    }

    private _processResponse(response: AddressApiResponse, isInitial: boolean) {
        const emptyValue = { key: '', value: '' };
        this.lookupFieldNames.forEach(fieldName => {
            const field = this.lookupFields[fieldName];
            const options: AddressLookupOptions = response[fieldName + 'Options'];
            if (options) {
                if (!options.size) {
                    field.lookupSource = new Map<string, string>();
                    this._setFieldValue(fieldName, emptyValue);
                    field.control.disable();
                }
                else {
                    if (fieldName !== 'County' || field.lookupSource.size === 0 || options.size !== 1) {
                        field.lookupSource = new Map(options);
                        field.lookupSource.set('', '');
                    }
                    if (options.size === 1 && fieldName === 'County' || isInitial) {
                        const autoSetValue = options.entries().next().value;
                        this._setFieldValue(fieldName, { key: autoSetValue[0], value: autoSetValue[1] });
                    }
                    else {
                        this._setFieldValue(fieldName, emptyValue) || field.control.setValue(emptyValue);
                    }
                    if (this.readonly)
                        field.control.disable();
                    else
                        field.control.enable();
                }
            }
        });
        this.data.PostCode = response.PostCode ? 'LV-' + response.PostCode : '';
        this.data.AddressText = response.AddressText;
        this.isComplete = !!response.AddressText;
    }
}
