import { Component, OnInit, ViewChild } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { Subject } from 'rxjs';

import { Log } from '../models/Log';
import { LogService } from '../services/log.service';
import { AppService } from '../services/app.service';

import { GridComponentBase } from '../GridComponentBase';

const filterLevels = ['INFO', 'DEBUG', 'ERROR', 'WARN', 'FATAL'];
const fitlerRequestMethods = ['GET', 'POST', 'PUT', 'DELETE'];

@Component({
  selector: 'app-log',
  templateUrl: './log.component.html',
  styleUrls: ['./log.component.css']
})
export class LogComponent extends GridComponentBase<Log>  implements OnInit {
    constructor(public app: AppService, private service: LogService) {
        super(app);
    }

    now: Date = new Date();

    filterLevelOptions = filterLevels;
    filterRequestMethodOptions = fitlerRequestMethods;

    filterDate: any = new Date();
    filterTimeFrom: string = '00:00';
    filterTimeTo: string = '23:59';
    filterLevel: string[] = [];
    filterRequestMethod: string[] = [];

    searchValue: string;

    @ViewChild(DatatableComponent, { static: true }) grid: DatatableComponent;

    private tempItems: Log[] = [];
    private searchTerms = new Subject<string>();

    ngOnInit() {
        if (this.app.auth.isAuthenticated) {
            this.app.setWideLayout();
            this.loadItems();
        }
    }

    filter() {
        this.loadItems();
    }

    toggleRow(row: Log) {
        this.grid.rowDetail.toggleExpandRow(row);
    }

    private loadItems() {
        let loading = this.app.showLoading();

        this.service.get({
            date: this.filterDate instanceof Date ? this.filterDate : this.filterDate.toDate(),
            timeFrom: this.filterTimeFrom || '00:00',
            timeTo: this.filterTimeTo || '23:59',
            level: this.filterLevel.join(','),
            requestMethod: this.filterRequestMethod.join(','),
            search: this.searchValue || ''
        }).subscribe(data => {
            this.gridItems = data;
            this.tempItems = [...data];
            this.app.hideLoading(loading);
        }, err => this.app.showLoadError(err));
    }
}
