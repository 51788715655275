<ng-container>
    <header class="content-header">
        <h1 [translate]="'quizzeditorlist_title'"></h1>
    </header>

    <ngx-datatable #grid
                   [rows]="gridItems"
                   [headerHeight]="'auto'"
                   [footerHeight]="'auto'"
                   [rowHeight]="'auto'"
                   [columnMode]="'force'"
                   [loadingIndicator]="gridLoading"
                   [externalSorting]="true"
                   [messages]="{ emptyMessage: 'gridEmpty' | translate, totalMessage: 'gridTotal' | translate }"
                   (sort)="onGridSort($event)">
        <ngx-datatable-column [name]="'quizzeditor_lblTitle' | translate" prop="Title">
            <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex" let-value="row.Title">
                <span *ngIf="!isGridEditing(row)">
                    {{value}}
                </span>
                <mat-form-field *ngIf="isGridEditing(row)">
                    <input autofocus
                           matInput
                           required
                           name="value"
                           [(ngModel)]="row.Title" />
                </mat-form-field>
                <mat-error *ngIf="row.notUnique">{{'quizzeditor_notunique' | translate}}</mat-error>
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [name]="'quizzeditor_lblCode' | translate" prop="Code">
            <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex" let-value="row.Code">
                <span *ngIf="!isGridEditing(row)">
                    {{value}}
                </span>
                <mat-form-field *ngIf="isGridEditing(row)">
                    <input required
                           matInput
                           name="value"
                           [(ngModel)]="row.Code" />
                </mat-form-field>
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [name]="'quizzeditor_lblTimeToFinish' | translate" prop="TimeToFinish">
            <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex" let-value="row.TimeToFinish">
                <span *ngIf="!isGridEditing(row)">
                    {{value}}
                </span>
                <mat-form-field *ngIf="isGridEditing(row)">
                    <input matInput required name="TimeToFinish" type="number" [(ngModel)]="row.TimeToFinish" (keydown)="checkNumbers($event)" min="0" step="1" />
                </mat-form-field>
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [resizeable]="false">
            <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
                <button (click)="openQuizz(row.Id)" class="btn-icon" *ngIf="!isGridEditing(row)" [title]="'edit' | translate">
                    <i class="fa fa-pencil"></i>
                </button>
                <button (click)="saveGridRow(rowIndex)"
                        class="btn-icon"
                        *ngIf="isGridEditing(row)"
                        [disabled]="!isValidGridItem(row)"
                        [title]="'save' | translate">
                    <i class="fa fa-floppy-o"></i>
                </button>
                <button (click)="cancelGridRow(rowIndex)" class="btn-icon" *ngIf="isGridEditing(row)" [title]="'cancel' | translate">
                    <i class="fa fa-ban"></i>
                </button>
                <button (click)="deleteGridRow(rowIndex)" class="btn-icon" *ngIf="!isNewGridItem(row)" [title]="'delete' | translate">
                    <i class="fa fa-trash"></i>
                </button>
            </ng-template>
        </ngx-datatable-column>
    </ngx-datatable>

    <div>
        <button mat-raised-button color="primary" (click)="addGridRow()">{{'add' | translate}}</button>
    </div>
</ng-container>