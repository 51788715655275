import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { AppService } from '../../services/app.service';
import { ApplicationService } from '../../services/application.service';

import { ApplicationStatus, ApplicationPreviousUniversity } from '../../models/Application';
import { setReturnTo } from '../application-edit.component';

import { ICanDeactivateGuard } from '../../CanDeactivateGuard';

@Component({
    selector: 'app-application-prevuniversity',
    template: `
        <app-application-prevuniversity-edit *ngIf="item && canEdit" [item]="item" #editCmp></app-application-prevuniversity-edit>
        <app-application-prevuniversity-view *ngIf="item && !canEdit" [item]="item"></app-application-prevuniversity-view>`
})
export class IndexComponent implements OnInit, ICanDeactivateGuard {
    constructor(
        public app: AppService,
        private service: ApplicationService,
        private route: ActivatedRoute
    ) { }

    item: ApplicationPreviousUniversity;
    canEdit: boolean;

    @ViewChild('editCmp', { static: false }) private editCmp: ICanDeactivateGuard;

    ngOnInit() {
        this.route.params.subscribe(params => {
            let appNumber = params['id'];

            if (!appNumber)
                return;

            let returnTo = this.route.snapshot.queryParams['returnTo'];

            if (returnTo)
                setReturnTo(returnTo);

            let loading = this.app.showLoading();

            this.app.auth.user.subscribe(user => {
                this.canEdit = this.app.auth.isAdmin() || this.app.auth.isPowerUser() || this.app.auth.isAdmClerk() || this.app.auth.isUnitedEnrollClerk();
            });

            this.service.getByNumber(appNumber).subscribe(data => {
                if (!this.canEdit)
                    this.canEdit = data.Status !== ApplicationStatus.Approved;

                this.service.getPreviousUniversity(data.Id).subscribe(item => {
                    this.item = item || new ApplicationPreviousUniversity();
                    this.item.ApplicationId = data.Id;
                });

                this.app.hideLoading(loading);
            });
        });
    }

    canDeactivate() {
        return !this.editCmp ? true : this.editCmp.canDeactivate();
    }
}
