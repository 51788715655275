import { Directive, ElementRef } from '@angular/core';
import { DataTableColumnDirective  } from '@swimlane/ngx-datatable';

@Directive({
    selector: 'ngx-datatable-column[flexGrow]'
})
export class NgxDataTableColumnFlexGrowModifier {
    constructor(el: DataTableColumnDirective) {
        // Resizing columns works buggy when in flex mode
        el.resizeable = false;
        el.draggable = false;
    }
}