import { Component, OnInit } from '@angular/core';
import { AppService } from '../services/app.service';
import { MessagesService } from './messages.service';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

import { GridComponentBase } from '../GridComponentBase';
import { Message } from '../models/Message';

@Component({
    selector: 'app-messages',
    templateUrl: './messages.component.html',
    styleUrls: ['./messages.component.css']
})
export class MessagesComponent extends GridComponentBase<Message> implements OnInit {
    selectedMessage: Message = new Message();
    isEdit: boolean = false;
    showForm: boolean = false;
    showDescription: boolean;
    groups: string[] = [];

    editorConfig = {
        convert_urls: false,
        forced_root_block: '',
        entity_encoding: 'raw',
        plugins: 'link textcolor table code lists image',
        toolbar:
            'undo redo | formatselect | bold italic forecolor backcolor'
            + ' | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat'
            + ' | table link image | code',
        paste_data_images: true,
        image_title: false,
        image_description: false,
        image_dimensions: false,
        image_caption: false,
        file_picker_types: 'image',
        file_picker_callback: function (cb, value, meta) {
            const input = document.createElement('input');
            input.setAttribute('type', 'file');
            input.setAttribute('accept', 'image/*');
            input.onchange = function (e: Event) {
                const file = (<HTMLInputElement>e.target).files[0];
                const reader = new FileReader();
                reader.onload = function () {
                    cb(this.result);
                };
                reader.readAsDataURL(file);
            };
            input.click();
        }
    };

    filter = {
        code: undefined,
        group: undefined,
        title: undefined,
        textLv: undefined,
        textEn: undefined
    };

    constructor(public app: AppService, private service: MessagesService, private route: ActivatedRoute) {
        super(app);
    }

    ngOnInit() {
        this.app.setWideLayout();
        this.route.params.subscribe(params => {
            const id = params['id'];
            this.refresh(id);
        });

    }
    refresh(id?: number) {
        const loading = this.app.showLoading();

        const onError = err => {
            this.app.hideLoading(loading);
            this.app.showLoadError(err);
        };

        if (id) {
            this.service.getById(id).subscribe(data => {
                this.app.hideLoading(loading);
                this.selectedMessage = data;
                this.isEdit = true;
                this.showForm = true;
            }, onError);
        } else {
            this.service.get(this.filter).subscribe(data => {
                this.app.hideLoading(loading);

                if (!this.groups.length) {
                    data.reduce((arr, n) => {
                        if (n.Group && !arr.includes(n.Group)) {
                            arr.push(n.Group);
                        }

                        return arr;
                    }, this.groups);
                }

                this.gridItems = data;
                this.isEdit = false;
                this.showForm = false;
            }, onError);
        }
    }
    submit() {
        if (this.selectedMessage) {
            const loading = this.app.showLoading();
            if (this.isEdit && this.selectedMessage.Id) {
                this.service.update(this.selectedMessage.Id, this.selectedMessage).subscribe(res => {
                    this.app.hideLoading(loading);
                    this.app.redirect('/messages');
                }, err => this.app.showSaveError(err));
            }
            else {
                this.service.add(this.selectedMessage).subscribe(res => {
                    this.app.hideLoading(loading);
                    this.gridItems.push(res);
                    this.selectedMessage = new Message();
                    this.showForm = false;
                }, err => this.app.showSaveError(err));
            }
        }
    }
    cancel() {
        if (this.isEdit && this.selectedMessage.Id) {
            this.app.redirect('/messages');
        }
        else {
            this.selectedMessage = new Message();
            this.showForm = false;
        }
    }
    addNew() {
        this.showForm = true;
    }

    protected createGridItem(item: Message): Observable<Message> {
        return this.service.add(item);
    }

    protected updateGridItem(item: Message): Observable<Message> {
        return this.service.update(item.Id,item);
    }
    //protected openQuizzQuestion(Id: number) {
    //    this.app.redirect('/quizzquestion/' + Id.toString());
    //}
    protected deleteGridItem(item: Message): Observable<boolean> {
        return this.service.delete(item.Id);
    }

    protected createEmptyObject(): Message {
        const item = new Message();
        return item;
    }

    editMessage(rowIndex) {
        this.app.redirect('/messages/' + this.gridItems[rowIndex].Id);
    }

    getEditTextOnlyClass({ row, column, value }): any {
        return 'editonly-checkbox';
    }
}
