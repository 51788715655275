import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApplicationNote } from 'src/app/models/Application';

export class ApplicatonNoteOptions {
    label?: string;    
    applicationId: number;
    notes?: ApplicationNote[];
    position: string;
}

@Component({
    selector: 'app-universal-note',
    templateUrl: './app-note-universal.component.html'
})
export class ApplicationNoteComponent {
    constructor(public dialogRef: MatDialogRef<ApplicationNoteComponent>, @Inject(MAT_DIALOG_DATA) public data: ApplicatonNoteOptions) {        
        this.applicationId = data.applicationId;
        this.notes = data.notes;
        this.label = data.label;
        this.position = data.position;
    }

    applicationId: number;
    notes: ApplicationNote[];
    label: string;
    position: string;

    submit() {
        this.dialogRef.close();
    }
}
