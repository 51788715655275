<section class="block">
    <header class="block-header">
        <h3>{{lblTitle | translate}}</h3>
        <aside>
            <button mat-raised-button type="button" (click)="showDetails = !showDetails">
                <i class="fa fa-{{showDetails ? 'eye-slash' : 'pencil'}}" aria-hidden="true"></i>
                {{(showDetails ? 'address_hideDetails' : 'address_showDetails') | translate}}
            </button>
        </aside>
    </header>
    <div *ngIf="!showDetails" class="block-body">
        <ng-container *ngIf="isSameAsOther else addressText">
            {{ sameAsLabel | translate }}
        </ng-container>
        <ng-template #addressText>
            {{ shortAddress }}
        </ng-template>
    </div>
    <div *ngIf="showDetails" class="block-body">
        <div *ngIf="sameAsPrefix" class="cols">
            <mat-checkbox class="col-12" [(ngModel)]="isSameAsOther"><span style="white-space: normal;">{{ sameAsLabel | translate }}</span></mat-checkbox>
        </div>
        <ng-container *ngIf="!isSameAsOther">
            <div class="cols">
                <div class="col-4">
                    <mat-form-field appearance="standard">
                        <mat-label [translate]="'address_lblCountry'"></mat-label>
                        <input matInput [formControl]="countryControl" [matAutocomplete]="country"
                            #trigger="matAutocompleteTrigger" required>
                        <mat-autocomplete #country="matAutocomplete" autoActiveFirstOption
                            [displayWith]="displayClassifier" (optionSelected)="onCountrySelected($event)">
                            <mat-option *ngFor="let option of filteredCountries | async" [value]="option">
                                {{ displayClassifier(option) }}
                            </mat-option>
                        </mat-autocomplete>
                        <mat-icon matSuffix *ngIf="!!selectedCountry">check</mat-icon>
                    </mat-form-field>
                </div>
            </div>
            <hr />
            <app-lv-address-form *ngIf="address && !isAnyAddress else anyAddress" [data]="address" [readonly]="readonly">
            </app-lv-address-form>
            <ng-template #anyAddress>
                <div *ngIf="address" class="cols">
                    <div class="col-6">
                        <mat-form-field>
                            <input matInput [(ngModel)]="address.County" [placeholder]="'address_lblCounty' | translate"
                                [required]="(!address.City && !item.IsForeign) || item.IsForeign">
                        </mat-form-field>
                        <mat-form-field>
                            <input matInput [(ngModel)]="address.City" [placeholder]="'address_lblCity' | translate"
                                [required]="!address.County || item.IsForeign">
                        </mat-form-field>
                        <mat-form-field *ngIf="!item.IsForeign">
                            <input matInput [(ngModel)]="address.Parish"
                                [placeholder]="'address_lblParish' | translate">
                        </mat-form-field>
                        <mat-form-field *ngIf="!item.IsForeign">
                            <input matInput [(ngModel)]="address.Village"
                                [placeholder]="'address_lblVillage' | translate">
                        </mat-form-field>
                    </div>
                    <div class="col-6">
                        <mat-form-field>
                            <input matInput [(ngModel)]="address.Street" [placeholder]="'address_lblStreet' | translate"
                                [required]="!address.House || item.IsForeign">
                        </mat-form-field>
                        <mat-form-field *ngIf="!item.IsForeign">
                            <input matInput [(ngModel)]="address.House" [placeholder]="'address_lblHouse' | translate"
                                [required]="!address.Street">
                        </mat-form-field>
                        <mat-form-field>
                            <input matInput [(ngModel)]="address.Apartment"
                                [placeholder]="'address_lblApartment' | translate">
                        </mat-form-field>
                        <mat-form-field>
                            <input matInput [(ngModel)]="address.PostCode"
                                [placeholder]="'address_lblPostCode' | translate" required>
                        </mat-form-field>
                    </div>
                </div>
            </ng-template>
        </ng-container>
    </div>
</section>
