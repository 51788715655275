<div class="register-container" *ngIf="emailAuthEnabled">
    <form name="form" class="block form-centered" (ngSubmit)="f.form.valid && register()" #f="ngForm" novalidate>
        <header class="block-header">
			<h1 *ngIf="registerSuccess==''" [translate]="'eenrollmenttitle'"></h1>
			<h1 *ngIf="registerSuccess!=''" [translate]="'registerThanks'"></h1>
        </header>
        <div class="block-body">
            <div class="error-container" *ngIf="registerError!=''">
                <mat-error>{{registerError}}</mat-error>
            </div>

            <div class="success-container" *ngIf="registerSuccess!=''">
                <span>{{registerSuccess}}</span>
            </div>

            <div class="form-row" *ngIf="registerSuccess==''">
                <mat-form-field>
                    <input matInput type="text" name="firstname" [(ngModel)]="userToRegister.firstName" #name="ngModel"
                           [placeholder]="'name' | translate" required autofocus />
                    <mat-error *ngIf="f.submitted && firstname && !firstname.valid">{{'registernameerror' | translate}}</mat-error>
                </mat-form-field>
            </div>

            <div class="form-row" *ngIf="registerSuccess==''">
                <mat-form-field>
                    <input matInput type="text" name="lastname" [(ngModel)]="userToRegister.lastName" #name="ngModel" [placeholder]="'lastname' | translate" required />
                    <mat-error *ngIf="f.submitted && lastname && !lastname.valid">{{'registerlastnameerror' | translate}}</mat-error>
                </mat-form-field>
            </div>

            <div class="form-row" *ngIf="registerSuccess==''">
                <mat-form-field>
                    <input matInput type="email" name="email" [(ngModel)]="userToRegister.email" #email="ngModel"
                           [placeholder]="'emaillabel' | translate" required email />
                    <mat-error *ngIf="f.submitted && email && !email.valid">{{'emailerror' | translate}}</mat-error>
                </mat-form-field>
            </div>

            <ng-container *ngIf="!registerSuccess">
                <div [innerHTML]="consentBody | safeHtml" class="alert alert-warning text-left" *ngIf="consentBody"></div>

                <div class="form-row text-left" *ngIf="consentCheckbox">
                    <mat-checkbox [(ngModel)]="consent" name="consent">
                        <span style="white-space: normal">{{consentCheckbox}}</span>
                    </mat-checkbox>
                </div>
            </ng-container>

            <div class="form-row recaptcha" *ngIf="registerSuccess==''">
                <re-captcha (resolved)="resolved($event)" #recaptchaRef siteKey="{{reCaptchaSiteKey}}" ></re-captcha>
            </div>

            <div class="form-submit" *ngIf="registerSuccess==''">
                <button mat-raised-button color="primary" type="submit" [disabled]="!consent || !reCaptchaResponse || loading">{{'registerlabel' | translate}}</button>
                <div class="form-more">
                    <a [routerLink]="['/login'] | localize" [translate]="'loginlabel'"></a>
                </div>
            </div>
        </div>
    </form>
</div>
