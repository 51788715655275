import { Component, OnInit, ViewChild, Directive } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';

import { ApplicationService } from '../../services/application.service';
import { AppService } from '../../services/app.service';

import { Application } from '../../models/Application';
import { GridComponentBase } from '../../GridComponentBase';

@Directive()
export abstract class HistoryComponentBase extends GridComponentBase<any> implements OnInit {
    constructor(
        public app: AppService,
        protected service: ApplicationService,
        protected route: ActivatedRoute
    ) {
        super(app);
    }

    abstract get getData(): Observable<any>;

    item: Application;

    ngOnInit() {
        this.app.setWideLayout();
        this.route.params.subscribe(para => {
            let id = para['id'];
            if (id) {
                let loading = this.app.showLoading();
                this.service.getByNumber(id).subscribe(data => {
                    this.item = data;
                    this.getData.subscribe(data => {
                        HistoryComponentBase.identifyChangedProperties(data, this.orderFn, this.prevItemFn);
                        this.gridItems = [...data].sort(HistoryComponentBase.orderByModified);
                        this.app.hideLoading(loading);
                    }, err => this.app.showLoadError(err));
                });
            }
        });
    }

    protected orderFn(i1: any, i2: any): number {
        return HistoryComponentBase.orderByIdAndModified(i1, i2);
    }

    protected prevItemFn(item: any, index: number, orderedItems: any[]) {
        return HistoryComponentBase.prevItem(item, index, orderedItems);
    }

    public static prevItem(item: any, index: number, orderedItems: any[]) {
        const prev = (index < orderedItems.length - 1) ? orderedItems[index+1] : {};
        return prev.Id === item.Id ? prev : {};
    }

    public static orderByIdAndModified(i1: any, i2: any) : number {
        if (i1.Id !== i2.Id) return i1.Id < i2.Id ? -1 : 1;
        return this.orderByModified(i1, i2);
    }

    public static orderByModified(i1: any, i2: any) : number {
        return i1.Modified < i2.Modified ? 1 : i1.Modified > i2.Modified ? -1 : 0;
    }

    public static identifyChangedProperties(items: any[], orderFn?: (a: any, b: any) => number,  prevItemFn?: (item: any, index: number, orderedItems: any[]) => any) {
        items.sort(orderFn || this.orderByIdAndModified);
        const itemsCount = items.length;
        for (let i = 0; i < itemsCount; i++) {
            const item = items[i];
            const prevItem = (prevItemFn || this.prevItem)(item, i, items);
            item._changedProps = new Set<string>();
            Object.keys(item).forEach(prop => {
                if (item[prop] !== prevItem[prop])
                    item._changedProps.add(prop);
            });
            item.hasChanged = function(...propertyName: string[]) {
                 return propertyName.some(pn => this._changedProps.has(pn));
            }
        }
    }

    yesno(value: boolean): string {
        return (!value && value !== false) ? '' : this.app.translate.instant('value_' + value);
    }
}
