<h2 mat-dialog-title>
    <ng-container *ngIf="isCreated">
        {{'contractsent' | translate}}
    </ng-container>
    <ng-container *ngIf="!isCreated">
        {{'applicationSchedule_FormHeader' | translate}}
    </ng-container>
</h2>
<ng-container *ngIf="isCreated else inProgress">
    <mat-dialog-actions>
        <button mat-raised-button [mat-dialog-close]="true" color="primary">
            {{'confirmDialog_ok' | translate}}
        </button>
    </mat-dialog-actions>
</ng-container>
<ng-template #inProgress>
    <ng-container *ngIf="warnings else noWarnings">
        <mat-dialog-content>
            <div class="text-center">
                {{warnings}}
            </div>
        </mat-dialog-content>
        <mat-dialog-actions>
            <button mat-raised-button color="primary" (click)="createContract(null, false, true)">
                {{'contractConfirm_ContinueButton' | translate}}
            </button>
            <button mat-raised-button mat-dialog-close>
                {{'contractConfirm_CancelButton' | translate}}
            </button>
        </mat-dialog-actions>
    </ng-container>
</ng-template>
<ng-template #noWarnings>
    <mat-dialog-content>
        <form #contractForm="ngForm">
            <div class="cols">
                <div class="col-6">
                    <mat-form-field>
                        <mat-select [placeholder]="'paymentyearsemester' | translate" name="SelectedSemester"
                            [(value)]="selectedSemester" (selectionChange)="onSemesterChange($event)">
                            <mat-option *ngFor="let s of semesterList" [value]="s">
                                {{s.SemesterName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="cols">
                <div class="col-6">
                    <mat-form-field>
                        <input matInput name="ProtocolNr" [(ngModel)]="contract.ProtocolNr" autofocus
                            [placeholder]="'contract_lblProtocolNr' | translate" (change)="onProtocolNumber($event)" />
                    </mat-form-field>
                </div>
                <div class="col-6">
                    <mat-form-field>
                        <input matInput [matDatepicker]="pickerProtocolDate" name="ProtocolDate"
                            [(ngModel)]="contract.ProtocolDate" [placeholder]="'contract_lblProtocolDate' | translate"
                            [min]="app.env.minDate" [max]="app.env.maxDate" [title]="'dateFormatHint' | translate"
                            (click)="pickerProtocolDate.open()" (dateChange)="onProtocolDate($event)">
                        <mat-datepicker-toggle matSuffix [for]="pickerProtocolDate"></mat-datepicker-toggle>
                        <mat-datepicker #pickerProtocolDate></mat-datepicker>
                        <mat-error>{{'valueInvalid' | translate}}</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="cols">
                <div class="col-6">
                    <mat-form-field>
                        <mat-select [placeholder]="'programResults_lblContractSigner' | translate" name="ContractSigner"
                            required [(ngModel)]="contract.SignerId">
                            <mat-option *ngFor="let t of contractSigners" [value]="t.Id">
                                {{getTranslatedValue(t)}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-6">
                    <mat-form-field>
                        <mat-select [placeholder]="'programResults_lblContractReason' | translate" required
                            name="ContractReason" [(ngModel)]="contract.ReasonId">
                            <mat-option *ngFor="let t of contractReasons" [value]="t.Id">
                                {{getTranslatedValue(t)}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="cols" *ngIf="!isBudget">
                <div class="col-6">
                    <mat-form-field>
                        <mat-select [placeholder]="'programResults_lblContractDiscount' | translate"
                            name="ContractDiscount" (selectionChange)="onDiscountChange($event)"
                            [(ngModel)]="contract.DiscountId">
                            <mat-option [value]="null">-- {{'optionNone' | translate}} --</mat-option>
                            <mat-option *ngFor="let t of contractDiscounts" [value]="t.Id">
                                {{getTranslatedValue(t)}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-6">
                    <mat-form-field>
                        <input matInput [matDatepicker]="pickerMinPaymentDate" name="MinPaymentDate"
                            [(ngModel)]="contract.MinPaymentDate"
                            [placeholder]="'contract_lblMinPaymentDate' | translate" [min]="app.env.minDate"
                            [max]="app.env.maxDate" [title]="'dateFormatHint' | translate"
                            (click)="pickerMinPaymentDate.open()" (change)="onMinPaymentDate($event)"
                            (dateChange)="onMinPaymentDate($event)">
                        <mat-datepicker-toggle matSuffix [for]="pickerMinPaymentDate"></mat-datepicker-toggle>
                        <mat-datepicker #pickerMinPaymentDate></mat-datepicker>
                        <mat-error>{{'valueInvalid' | translate}}</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="cols" *ngIf="admission.IsRZ || admission.IsUnitedRZ">
                <div class="col-12">
                    <mat-form-field>
                        <mat-select [placeholder]="'contract_lblMedInstitutions' | translate" name="MedInstitution"
                            (selectionChange)="onMedInstitution($event)" [(ngModel)]="contract.MedInstitutionId">
                            <mat-option [value]="null">-- {{'optionNone' | translate}} --</mat-option>
                            <mat-option *ngFor="let t of medInstitutions" [value]="t.Id">
                                {{getTranslatedValue(t)}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="cols">
                <div class="col-6">
                    <mat-form-field>
                        <input matInput type="number" min="1" max="1000" required step="1" name="CP"
                            title="{{'valueintervalbeween' | translate:'[1,1000]'}}" (keydown)="checkNumbers($event)"
                            [placeholder]="'contract_lblTotalCP' | translate" [(ngModel)]="contract.TotalCP"
                            (change)="setConditions()" />
                    </mat-form-field>
                </div>
            </div>
            <div class="cols">
                <div class="col-6">
                    <mat-form-field>
                        <input matInput [matDatepicker]="pickerScheduleStartDate" name="SchedulePaymentStartDate"
                            [(ngModel)]="contract.SchedulePaymentStartDate"
                            [placeholder]="'contract_lblSchedulePaymentStartDate' | translate" [min]="app.env.minDate"
                            [max]="app.env.maxDate" [title]="'dateFormatHint' | translate"
                            (click)="pickerScheduleStartDate.open()" (change)="onScheduleStartDate()"
                            (dateChange)="onScheduleStartDate($event)">
                        <mat-datepicker-toggle matSuffix [for]="pickerScheduleStartDate"></mat-datepicker-toggle>
                        <mat-datepicker #pickerScheduleStartDate></mat-datepicker>
                        <mat-error>{{'valueInvalid' | translate}}</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-6">
                    <mat-form-field>
                        <input matInput [matDatepicker]="pickerScheduleEndDate" name="SchedulePaymentEndDate"
                            [(ngModel)]="contract.SchedulePaymentEndDate"
                            [placeholder]="'contract_lblSchedulePaymentEndDate' | translate" [min]="app.env.minDate"
                            [max]="app.env.maxDate" [title]="'dateFormatHint' | translate"
                            (click)="pickerScheduleEndDate.open()" (change)="onScheduleEndDate()"
                            (dateChange)="onScheduleEndDate($event)">
                        <mat-datepicker-toggle matSuffix [for]="pickerScheduleEndDate"></mat-datepicker-toggle>
                        <mat-datepicker #pickerScheduleEndDate></mat-datepicker>
                        <mat-error>{{'valueInvalid' | translate}}</mat-error>
                    </mat-form-field>
                </div>
            </div>
        </form>
        <section class="block" *ngIf="gridItems && gridItems.length>0">
            <header class="block-header">
                <h2 [translate]="'paymentsemesters'"></h2>
            </header>
            <ngx-datatable [rows]="gridItems" [headerHeight]="'auto'" [footerHeight]="'auto'" [rowHeight]="'auto'"
                [columnMode]="'force'" [loadingIndicator]="gridLoading" [externalSorting]="true"
                [messages]="{ emptyMessage: 'gridEmpty' | translate, totalMessage: 'gridTotal' | translate }"
                (sort)="onGridSort($event)">

                <ngx-datatable-column [name]="'paymentsemester' | translate" prop="Semester">
                    <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex"
                        let-value="row.Semester">
                        {{value}}
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column [name]="'paymentCP' | translate" prop="CP">
                    <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex" let-value="row.CP">
                        <span *ngIf="!isGridEditing(row)">
                            {{value}}
                        </span>
                        <mat-form-field *ngIf="isGridEditing(row)">
                            <input matInput required type="number" min="1" max="1000" step="1" name="CP"
                                title="{{'valueintervalbeween' | translate:'[1,1000]'}}"
                                (keydown)="checkNumbers($event)" [(ngModel)]="row.CP" />
                        </mat-form-field>
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column [name]="'paymentAmmount' | translate" prop="Payment">
                    <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex"
                        let-value="row.Payment">
                        {{value | number:'1.2-2'}}
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column [resizeable]="false">
                    <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
                        <button (click)="editGridRow(rowIndex)" class="btn-icon" *ngIf="!isGridEditing(row)"
                            [title]="'edit' | translate">
                            <i class="fa fa-pencil"></i>
                        </button>
                        <button (click)="updateCP(row,rowIndex)" class="btn-icon" *ngIf="isGridEditing(row)"
                            [disabled]="!isValidGridItem(row)" [title]="'save' | translate">
                            <i class="fa fa-floppy-o"></i>
                        </button>
                        <button (click)="cancelGridRow(rowIndex)" class="btn-icon" *ngIf="isGridEditing(row)"
                            [title]="'cancel' | translate">
                            <i class="fa fa-ban"></i>
                        </button>
                    </ng-template>
                </ngx-datatable-column>
            </ngx-datatable>
        </section>
        <section class="block">
            <app-application-schedules-payments #payments [applicationId]="applicationId" [programId]="programId">
            </app-application-schedules-payments>
            <div class="cols">
                <div class="col-12">
                    <mat-form-field>
                        <textarea class="wide-text" matInput [(ngModel)]="contract.SpecialConditions"
                            [placeholder]="'contract_lblSpecialConditions' | translate"></textarea>
                    </mat-form-field>
                    <mat-form-field *ngIf="admission.IsForeign">
                        <textarea class="wide-text" matInput [(ngModel)]="contract.SpecialConditionsEn"
                            [placeholder]="'contract_lblSpecialConditionsEn' | translate"></textarea>
                    </mat-form-field>
                </div>
            </div>
        </section>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button mat-raised-button color="primary"
            [disabled]="!contract.SignerId || !contract.ReasonId || !contract.TotalCP" (click)="createContract()">
            {{'contract_btnSaveAndCreate' | translate}}
        </button>
        <button mat-raised-button color="primary" (click)="saveAndClose()">
            {{'save' | translate}}
        </button>
        <button mat-raised-button mat-dialog-close>
            {{'confirmDialog_cancel' | translate}}
        </button>
    </mat-dialog-actions>
</ng-template>
