<header class="content-header">
    <nav class="breadcrumb">
        <a *ngIf="item" [routerLink]="['/applications', item.Number, 'person'] | localize" class="bc-back">
            {{'applications_edit_titlePrefix' | translate}}
            "{{item.AdmissionTitle}}", {{item.Number}}
        </a>
    </nav>
    <h1 [translate]="'modif_title_ApplicationPersonDocuments'"></h1>
</header>

<ngx-datatable #grid
               class="tight"
               [rows]="gridItems"
               [headerHeight]="'auto'"
               [footerHeight]="50"
               [rowHeight]="'auto'"
               [columnMode]="'flex'"
               [limit]="100"
               [externalSorting]="true"
               [loadingIndicator]="gridLoading"
               [messages]="{ emptyMessage: 'gridEmpty' | translate, totalMessage: 'gridTotal' | translate }"
               (sort)="onGridSort($event)">

    <ngx-datatable-column [width]="20"
                          [resizeable]="false"
                          [sortable]="false"
                          [draggable]="false"
                          [canAutoResize]="false"
                          [flexGrow]="1">
        <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
            <a href="javascript:void(0)"
               [class.datatable-icon-right]="!expanded"
               [class.datatable-icon-down]="expanded"
               (click)="grid.rowDetail.toggleExpandRow(row)">
            </a>
        </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column [name]="'modif_lblModified' | translate" prop="Modified" [flexGrow]="1">
        <ng-template ngx-datatable-cell-template let-row="row">
            {{row.Modified | appDate:'+'}}
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [name]="'modif_lblAction' | translate" prop="ModifAction" [flexGrow]="1">
        <ng-template ngx-datatable-cell-template let-row="row">
            {{'modif_action_' + row.ModifAction | translate}}
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [name]="'modif_lblUser' | translate" prop="ModifiedByName" [flexGrow]="1"></ngx-datatable-column>

    <ngx-datatable-row-detail [rowHeight]="'auto'" #detailRow>
        <ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
            <mat-checkbox [ngClass]="{'app-hide-unchanged': !showUnchanged.checked }" #showUnchanged>{{ 'modif_show_unchanged' | translate }}</mat-checkbox>
            <div [ngClass]="{ 'app-differ': row.hasChanged('Type') }"><label [translate]="'applications_lblDocType'"></label> {{'applications_docType_' + row.Type | translate}}</div>
            <div [ngClass]="{ 'app-differ': row.hasChanged('Number') }"><label [translate]="'applications_lblDocNumber'"></label> {{row.Number}}</div>
            <div [ngClass]="{ 'app-differ': row.hasChanged('Date') }"><label [translate]="'applications_lblDocDate'"></label> {{row.Date | appDate}}</div>
            <div [ngClass]="{ 'app-differ': row.hasChanged('Expiry') }"><label [translate]="'applications_lblDocExpiry'"></label> {{row.Expiry | appDate}}</div>
            <div [ngClass]="{ 'app-differ': row.hasChanged('IssuerText') }"><label [translate]="'applications_lblDocIssuer'"></label> {{row.IssuerText}}</div>
            <div [ngClass]="{ 'app-differ': row.hasChanged('Citizenship') }"><label [translate]="'applications_lblCitizenship'"></label> {{row.Citizenship}}</div>
        </ng-template>
    </ngx-datatable-row-detail>

</ngx-datatable>
