import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject, of } from 'rxjs';

import { AppService } from '../../services/app.service';
import { ApplicationService } from '../../services/application.service';
import { ParameterService } from '../../services/parameter.service';

import { Application, ApplicationProgram, ApplicationPreviousUniversity, PreviousUniversitySelectedProgram } from '../../models/Application';

import { ICanDeactivateGuard } from '../../CanDeactivateGuard';
import { Utils } from '../../Utils';
import { parseValidationErrors } from '../common';

@Component({
    selector: 'app-application-prevuniversity-edit',
    templateUrl: './edit.component.html'
})
export class EditComponent implements OnInit, ICanDeactivateGuard {
    constructor(
        public app: AppService,
        private service: ApplicationService,
        private params: ParameterService,
        private router: Router
    ) { }

    @Input() item: ApplicationPreviousUniversity = new ApplicationPreviousUniversity();

    application: Application = new Application();
    programs: ApplicationProgram[] = [];
    transferRulesUrl: string;

    minStudyDate = new Date(1900, 0, 1, 0, 0, 0);
    maxStudyDate = new Date(2100, 11, 31, 0, 0, 0);

    private origItem: string;

    ngOnInit() {
        this.origItem = JSON.stringify(this.item);

        this.service.getById(this.item.ApplicationId).subscribe(data => {
            this.application = data;
        });

        this.service.getPrograms(this.item.ApplicationId).subscribe(data => {
            this.programs = data;
            this.programs.sort((a, b) => a.Priority - b.Priority);
        });

        this.params.getValues().subscribe(values => {
            let para = values.find(t => t.Code === 'TransferRulesUrl');

            if (para)
                this.transferRulesUrl = para.Value;
        });
    }

    canDeactivate() {
        if (JSON.stringify(this.item) !== this.origItem) {
            var subj = new Subject<boolean>();
            this.app.confirm(this.app.translate.instant('unsavedDataConfimation'), result => {
                subj.next(result);
            });
            return subj.asObservable();
        }

        return of(true);
    }

    submit() {
        let loading = this.app.showLoading();

        this.service.savePreviousUniversity(this.item).subscribe(() => {
            this.origItem = JSON.stringify(this.item);
            this.app.hideLoading(loading);

            this.confirmSubmit(() => {
                this.router.navigate([this.app.localize.translateRoute('/applications'), this.application.Number]);
            });
        }, err => this.app.showSaveError(err));
    }

    onSelectProgram(program: ApplicationProgram) {
        let index = this.item.SelectedPrograms.findIndex(t => t.ApplicationProgramId === program.Id);

        if (index > -1) {
            this.item.SelectedPrograms.splice(index, 1);
        } else {
            let p = new PreviousUniversitySelectedProgram();
            p.ProgramId = program.Program.Id;
            p.ApplicationProgramId = program.Id;
            p.Title = program.Program.Title;

            this.item.SelectedPrograms.push(p);
        }
    }

    toggleInArray<T>(value: T, array: T[]) {
        let index = array.indexOf(value);

        if (index > -1)
            array.splice(index, 1);
        else
            array.push(value);
    }

    inArray<T>(value: T, array: T[]) {
        return array ? array.indexOf(value) > -1 : false;
    }

    isProgramSelected(program: ApplicationProgram): boolean {
        return this.item.SelectedPrograms.some(t => t.ApplicationProgramId === program.Id);
    }

    private confirmSubmit(callback: () => any) {
        let loading = this.app.showLoading();
        this.service.validate(this.application.Id).subscribe(errors => {
            this.app.hideLoading(loading);
            let uniErrors = errors.filter(t => (t.Group || '').indexOf('prevUni') === 0);

            if (uniErrors.length) {
                let errText = parseValidationErrors(uniErrors, (t, params) => this.app.translate.instant(t, params));
                this.app.confirm({
                    text: `<p>${this.app.translate.instant('applications_applicationDataEmpty')}</p>
                        <div>${errText}</div>
                        <p>${this.app.translate.instant('applications_prevUni_confirmSave')}</p>`,
                    okText: this.app.translate.instant('confirm')
                }, result => {
                    result && callback();
                });
            } else {
                callback();
            }
        }, err => this.app.showLoadError(err));
    }
}
