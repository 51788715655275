<form #form="ngForm" (ngSubmit)="submit()">
    <h2 mat-dialog-title [translate]="'programs_editTitle'"></h2>
    <mat-dialog-content>
        <mat-form-field>
            <input matInput [(ngModel)]="item.Title" name="title" [placeholder]="'programs_lblTitle' | translate" required>
        </mat-form-field>

        <div class="cols">
            <div class="col-4">
                <mat-form-field>
                    <input matInput [(ngModel)]="item.Quota" name="quota" [placeholder]="'programs_lblQuota' | translate" required>
                </mat-form-field>
            </div>
            <div class="col-8">
                <mat-form-field>
                    <input matInput [(ngModel)]="item.NotificationCode" name="notificationCode" [placeholder]="'programs_lblNotificationCode' | translate">
                </mat-form-field>
            </div>
        </div>

        <div class="checkbox-row">
            <mat-checkbox [(ngModel)]="item.AllowProlongation" name="allowProlongation">{{'programs_lblAllowProlongation' | translate}}</mat-checkbox>
        </div>
        <div class="checkbox-row">
            <mat-checkbox [(ngModel)]="item.AllowAdmission" name="allowAdmission">{{'programs_lblAllowAdmission' | translate}}</mat-checkbox>
        </div>
        <div class="checkbox-row">
            <mat-checkbox [(ngModel)]="item.AllowApplicantToApply" name="allowApplicantApply">{{'programs_lblAllowApplicantToApply' | translate}}</mat-checkbox>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button mat-raised-button type="submit" color="primary" [disabled]="!form.valid">{{'save' | translate}}</button>
        <button mat-button mat-dialog-close>{{'cancel' | translate}}</button>
    </mat-dialog-actions>
</form>
