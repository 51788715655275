import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';

import { AppService } from './services/app.service';
import { LogService } from './services/log.service';

@Injectable()
export class AppErrorHandler implements ErrorHandler {
    constructor(private injector: Injector, private ngZone: NgZone) { }

    private _app: AppService;
    private get app(): AppService {
        if (!this._app)
            this._app = this.injector.get(AppService);

        return this._app;
    }

    private _log: LogService;
    private get log(): LogService {
        if (!this._log)
            this._log = this.injector.get(LogService);

        return this._log;
    }

    handleError(error) {
        let logToConsole = true;
        
        if (error.status == 401) {
            if (!this.app.auth.isAuthenticated)
                logToConsole = false;

            let errorText = error.error;

            if (errorText === 'anonymousnotallowed' && !this.app.accessDenied) {
                this.app.accessDenied = true;
                this.app.returnUrl = this.app.router.url;
                this.app.auth.clearSession();
            }
        }

        try {
            this.log.logError(error.stack || error.toString()).subscribe(() => { }, () => { });
        } catch (ex) { }

        logToConsole && console.error(error);

        this.ngZone.run(() => {
            this.app.hideLoading();
        });
    }
}
