<h2 mat-dialog-title>{{'share_title' | translate}}</h2>
<mat-dialog-content>
    <form #form="ngForm">
        <div class="cols">
            <div class="col-6">
                <mat-form-field>
                    <input matInput [matDatepicker]="pickerDateFrom" [(ngModel)]="dateFrom" name="dateFrom"
                           [placeholder]="'share_lblDateFrom' | translate" [min]="today" [max]="dateTo" required [title]="'dateFormatHint' | translate">
                    <mat-datepicker-toggle matSuffix [for]="pickerDateFrom"></mat-datepicker-toggle>
                    <mat-datepicker #pickerDateFrom></mat-datepicker>
                </mat-form-field>
            </div>

            <div class="col-6">
                <mat-form-field>
                    <input matInput [matDatepicker]="pickerDateTo" [(ngModel)]="dateTo" name="dateTo"
                           [placeholder]="'share_lblDateTo' | translate" [min]="dateFrom" required [title]="'dateFormatHint' | translate">
                    <mat-datepicker-toggle matSuffix [for]="pickerDateTo"></mat-datepicker-toggle>
                    <mat-datepicker #pickerDateTo></mat-datepicker>
                </mat-form-field>
            </div>
        </div>

        <div class="cols">
            <div class="col-12">
                <mat-form-field>
                    <input type="text" [placeholder]="'share_lblPersonSearch' | translate" matInput
                           [formControl]="personsControl" [matAutocomplete]="personsAutocomplete">
                    <mat-autocomplete #personsAutocomplete="matAutocomplete" (optionSelected)="personSelected($event)">
                        <mat-option *ngFor="let p of filteredPersons" [value]="p" [disabled]="isPersonSelected(p)">
                            {{p.Name}} {{p.Surname}} <small class="text-muted">{{p.Email}}</small>
                        </mat-option>
                    </mat-autocomplete>
                    <mat-spinner [diameter]="16" matSuffix [style.visibility]="isLoadingPersons ? 'visible' : 'hidden'"></mat-spinner>
                </mat-form-field>
            </div>
        </div>

        <div class="selected-persons">
            <div *ngFor="let p of selectedPersons" class="selected-person">
                <span class="info">{{p.Name}} {{p.Surname}} <small class="text-muted">{{p.Email}}</small></span>
                <button class="btn-icon remove" type="button" (click)="removePerson(p)"><i class="fa fa-times"></i></button>
            </div>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-raised-button type="button" color="primary" [disabled]="!isValid" (click)="submit()">{{'ok' | translate}}</button>
    <button mat-button type="button" mat-dialog-close>{{'cancel' | translate}}</button>
</mat-dialog-actions>