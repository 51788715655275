import { Component, OnInit, Input, ViewChild, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';

import { ProgramExam } from '../models/Program';

import { AdmissionService } from '../services/admission.service';
import { ProgramService } from '../services/program.service';
import { AppService } from '../services/app.service';

import { GridComponentBase } from '../GridComponentBase';

@Component({
    selector: 'app-program-exams',
    templateUrl: './exams.component.html'
})
export class ProgramExamsComponent extends GridComponentBase<ProgramExam> implements OnInit {
    constructor(
        public app: AppService,
        private service: ProgramService,
        private admissionService: AdmissionService,
        private route: ActivatedRoute) {
        super(app);
    }

    @ViewChild('grid', { static: true }) grid: any;

    programId: number;
    admissionId: number;
    programTitle: string;
    admissionTitle: string;
    expanded: any = {};

    ngOnInit() {
        this.app.setWideLayout();

        this.route.params.subscribe(params => {
            this.programId = +params['id'];

            if (this.programId) {
                let loading = this.app.showLoading();

                this.service.getById(this.programId).subscribe(data => {
                    this.programTitle = data.Title;
                    this.admissionId = data.AdmissionId;

                    this.admissionService.getById(data.AdmissionId).subscribe(data => this.admissionTitle = data.Title);
                }, err => this.app.showLoadError(err));

                this.service.getExams(this.programId).subscribe(data => {
                    this.gridItems = data;
                    this.app.hideLoading(loading);
                }, err => this.app.showLoadError(err));
            }
        });
    }

    toggleGridRow(row) {
        this.grid.rowDetail.toggleExpandRow(row);
    }

    getMaxTotalGrade() {
        let total = 0;
        this.gridItems.forEach(item => total += item.MaxGrade * item.Weight);
        return total;
    }
}
