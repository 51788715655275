import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';

import { AuthService } from './auth.service';
import { AlertService } from './alert.service';

import { ConfirmDialogOptions } from '../shared/confirm-dialog.component';

import { environment } from '../../environments/environment';
import { Classifier } from '../models/Classifier';
import { HttpErrorResponse } from '@angular/common/http';

export class ConfirmArguments {
    constructor(
        public dialogOptions: ConfirmDialogOptions,
        public callback: (result: boolean) => any) { }
}

/**
 * App common service
 */
@Injectable()
export class AppService {
    constructor(
        public auth: AuthService,
        public alerts: AlertService,
        public translate: TranslateService,
        public localize: LocalizeRouterService,
        public router: Router
    ) { }

    env = environment;
    isError404: boolean;
    returnUrl: string;
    /** This property indicates whether app was redirected to login component because of "not allowed" request */
    accessDenied: boolean;

    private _stateIs404: boolean;
    private loadings: any[] = [];
    private mainCssClass: string[] = [];
    // skip alerts for the following messages
    private skipErrors = ['anonymousnotallowed', 'notappuser'];
    private confirmSubject = new Subject<ConfirmArguments>();
    private notificationSubject = new Subject<string>();
    private mainCssClassSubject = new Subject<string>();

    showLoading(delay: number = 100): number {
        let data = {
            id: Date.now(),
            active: undefined
        };

        this.loadings.push(data);

        setTimeout(() => {
            if (data.active !== false)
                data.active = true;
        }, delay);

        return data.id;
    }

    hideLoading(id?: number, delay: number = 400) {
        setTimeout(() => {
            if (id) {
                let i = this.loadings.findIndex(t => t.id === id);
                if (i > -1) {
                    this.loadings[i].active = false;
                    this.loadings.splice(i, 1);
                }
            } else {
                this.loadings.forEach(t => this.hideLoading(t.id));
            }
        }, delay);
    }

    clearLoading() {
        this.loadings = [];
    }

    isLoading(): boolean {
        return this.loadings.some(t => t.active);
    }

    showLoadError(error?: any) {
        // TODO this is a workaround, because after upgrading Angular version loadings do no hide on HTTP errors
        this.hideLoading();
        this.showError('itemLoadFailed', error);
    }

    showSaveError(error?: any) {
        // TODO this is a workaround, because after upgrading Angular version loadings do no hide on HTTP errors
        this.hideLoading();
        this.showError('itemSaveFailed', error);
    }

    showDeleteError(error?: any) {
        // TODO this is a workaround, because after upgrading Angular version loadings do no hide on HTTP errors
        this.hideLoading();
        this.showError('itemDeleteFailed', error);
    }

    showCopyError(error?: any) {
        // TODO this is a workaround, because after upgrading Angular version loadings do no hide on HTTP errors
        this.hideLoading();
        this.showError('applications_copyFailed', error);
    }

    showError(message: string, error?: any, title?: string) {
        // TODO this is a workaround, because after upgrading Angular version loadings do no hide on HTTP errors
        this.hideLoading(); 

        if (typeof message !== 'string' && !error) {
            error = message;
            message = null;
        }
        if (error && error instanceof HttpErrorResponse) {
            try {
                const body = error.error;
                if (body) {
                    if (body.Message) {
                        error = body.Message;
                    }
                    else if (typeof body === 'string') {
                        error = body;
                    }
                }
            }
            catch {
                error = '';
            }
        }
        if (error && error instanceof HttpErrorResponse) {
            error = this.getHttpResponseError(error);
        }


        if (!error || this.skipErrors.indexOf(error) < 0) {
            let msg = [];

            if (message)
                msg.push(this.translate.instant(message));

            if (error) {
                if (typeof error === 'string')
                    msg.push(this.translate.instant(error));
                else
                    console.warn(`Complex structure as an error in showError: ${JSON.stringify(error)}`);
            }
            this.alerts.error(msg.length === 0 ? '??' : msg.join(' '), title);
        }
    }

    getHttpResponseError(response: any): string {
        if (!response)
            return '';

        if (typeof response === 'string' || typeof response === 'number')
            return this.translate.instant(response + '');

        let text: string;
        let httpStatus: string;

        if (response.error && response.error.Message) {
            text = response.error.Message;
        }
        else if (response.json) {
            let body: any;

            try {
                let body = response.json();
                text = body.ExceptionMessage || body.Message || body;
            } catch (e) {
                text = response.text();
            }
        }

        this.isError404 = response.status === 404;

        if (!text)
            text = httpStatus = `httpStatus_${response.status}`;

        try {
            if (text)
                text = this.translate.instant(text);

            if (text === httpStatus)
                text = '';
            else if (!text)
                text = this.translate.instant('unknownError');
        } catch (err) { }

        return text;
    }

    addMainCssClass(cssClass: string, reset: boolean = false) {
        cssClass = cssClass || '';

        if (reset)
            this.mainCssClass = cssClass.split(' ');
        else
            cssClass.split(' ').forEach(t => this.mainCssClass.push(t));

        this.mainCssClassSubject.next(this.mainCssClass.join(' ').trim());
    }

    getMainCssClass(): Observable<string> {
        return this.mainCssClassSubject.asObservable();
    }

    setWideLayout() {
        this.addMainCssClass('l-wide');
    }

    redirect(toPath: string, query?: {}) {
        this.router.navigate([this.localize.translateRoute(toPath)], { queryParams: query });
    }

    logout() {
        this.auth.logout().subscribe();
    }

    confirm(dialogOptions: ConfirmDialogOptions | string, callback: (result: boolean) => any) {
        if (typeof dialogOptions === 'string') {
            let opts = new ConfirmDialogOptions();
            opts.text = dialogOptions;
            dialogOptions = opts;
        }

        let args = new ConfirmArguments(dialogOptions, callback);
        this.confirmSubject.next(args);
    }

    getConfirm(): Observable<ConfirmArguments> {
        return this.confirmSubject.asObservable();
    }

    notify(text: string) {
        this.notificationSubject.next(this.translate.instant(text));
    }

    getNotification() {
        return this.notificationSubject.asObservable();
    }

    displayClassifier = (c: Classifier) : string =>
        !c ? '' : (this.translate.currentLang == 'en' && !!c.ValueEN) ? c.ValueEN : c.Value;
}
