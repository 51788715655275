<header class="content-header">
    <h1 [translate]="'nav_interviews_schedule'"></h1>
</header>

<div class="filter-form">
    <div style="display: flex">
        <a *ngFor="let sd of scheduledDates" [routerLink]="[]" (click)="goToDate(sd.Date)" [ngStyle]="{ 'font-weight': sd.Date === targetDate ? 'bold' : 'normal', 'margin-right': '20px' }">
            <ng-container *ngIf="sd.Date != today else showtoday">
                {{ sd.Date | appDate }}
            </ng-container>
            <ng-template #showtoday>
                {{ 'today' | translate }}
            </ng-template>
            ({{ sd.Count }})
        </a>
    </div>
    <div style="display: flex; justify-content: space-between;">
        <mat-checkbox [(ngModel)]="showCompleted" name="showCompleted" (change)="applyFilter()">
            {{'interviews_lblShowCompleted' | translate}}
        </mat-checkbox>
        <button type="button" class="btn-inline" mat-raised-button color="primary" (click)="refresh()">
            <i class="fa fa-refresh"></i> {{'refresh' | translate }}
        </button>
    </div>
</div>

<ngx-datatable #grid
               [rows]="gridItems"
               [headerHeight]="'auto'"
               [footerHeight]="50"
               [rowHeight]="'auto'"
               [columnMode]="'force'"
               [loadingIndicator]="gridLoading"
               [messages]="{ emptyMessage: 'gridEmpty' | translate, totalMessage: 'gridTotal' | translate }">
    <ngx-datatable-column [name]="'interviews_lblTime' | translate" prop="scheduledTime" [pipe]="appTime" [minWidth]="50" [maxWidth]="50" [flexGrow]="0"></ngx-datatable-column>
    <ngx-datatable-column [name]="'interviews_lblProgram' | translate" prop="programName"></ngx-datatable-column>
    <ngx-datatable-column [name]="'interviews_lblApplicantName' | translate" prop="applicantName"></ngx-datatable-column>
    <ngx-datatable-column [name]="'interviews_lblRoom' | translate" prop="roomTitle"></ngx-datatable-column>
    <ngx-datatable-column [name]="'interviews_lblStatus' | translate" prop="status" [pipe]="translateStatus">
        <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
            <span [matTooltip]="statusTooltip(row)" matTooltipClass="mat-multiline-tooltip">{{ value }}</span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column *ngIf="isInterviewer" [name]="' '" >
        <ng-template ngx-datatable-cell-template let-row="row">
            <button mat-raised-button color="primary" [disabled]="!canOpen(row)" (click)="openInterview(row)">{{ 'interviews_Open' | translate }}</button>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column *ngIf="isPersonnel" [name]="' '" >
        <ng-template ngx-datatable-cell-template let-row="row">
            <a *ngIf="row.status !== InterviewStatus.Completed" [href]="row.meetingUrl" target="_blank" class="btn-inline" mat-raised-button color="primary">{{ 'interview_join' | translate }}</a>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column *ngIf="isPersonnel" [name]="' '" >
        <ng-template ngx-datatable-cell-template let-row="row">
            <button mat-raised-button [matMenuTriggerFor]="menu" color="primary" [disabled]="!canSetStatus(row)">{{ 'interviews_SetStatus' | translate }}</button>
            <mat-menu #menu="matMenu" [overlapTrigger]="false">
                <ng-template matMenuContent>
                    <button *ngIf="row.status !== InterviewStatus.Absent" (click)="setAbsent(row, true)" mat-menu-item>{{ 'interviews_setAbsent' | translate }}</button>
                    <button *ngIf="row.status === InterviewStatus.Absent" (click)="setAbsent(row, false)" mat-menu-item>{{ 'interviews_clearAbsent' | translate }}</button>
                </ng-template>
            </mat-menu>
</ng-template>
    </ngx-datatable-column>
</ngx-datatable>
