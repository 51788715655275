import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

import { AdmissionResult } from '../models/Admission';
import { AdmissionService } from '../services/admission.service';
import { ReportService, InterviewPageReportFormat } from '../services/report.service';
import { AppService } from '../services/app.service';
import { MessagesService } from '../messages/messages.service';

import { Admission } from '../models/Admission';
import { GridComponentBase } from '../GridComponentBase';
import { ResultAlgorithmComponent } from './result-algorithm.component';
import { MessageCodeStateMatcher } from '../shared/message-autocomplete.component';
import { SendEmailsConfirmDialogComponent } from './send-emails-confirm.component';
import { MatDialog } from '@angular/material/dialog';

export class DynamicErrorStateMatcher implements ErrorStateMatcher {
    constructor(private invalid: () => boolean) { }

    isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        return this.invalid();
    }
}

@Component({
    selector: 'app-admission-results',
    templateUrl: './results.component.html'
})
export class ResultsComponent extends GridComponentBase<AdmissionResult> implements OnInit {
    constructor(
        public app: AppService,
        private service: AdmissionService,
        private reportService: ReportService,
        private messageService: MessagesService,
        private dialog: MatDialog
    ) {
        super(app);
    }

    @Input() admission: Admission;

    selectedRows: AdmissionResult[] = [];
    targetRowTypes = ['Selected', 'All'];
    reportTarget: string;
    reportType: string;
    isHomines: boolean;

    messageCodeStateMatcher = new MessageCodeStateMatcher();
    targetRowsStateMatcher = new DynamicErrorStateMatcher(() => {
        return (this.sendEmailsOpts.target === 'All' && !this.gridItems.length)
            || (this.sendEmailsOpts.target === 'Selected' && !this.selectedRows.length);
    });

    sendEmailsOpts = {
        target: '',
        applicantType: '',
        notificationCode: ''
    };
    sendEmailsApplicantTypes = ['Approved', 'ApprovedMultiPriorities', 'Enrolled'];

    ngOnInit() {
        this.load();
    }

    load() {
        if (this.admission) {
            let loading = this.app.showLoading();
            this.isHomines = this.admission.IsHomines;
            this.service.getResults(this.admission.Id).subscribe(data => {
                this.gridItems = data;
                this.app.hideLoading(loading);
            }, err => this.app.showLoadError(err));
        }
    }

    launchAlgorithm() {
        let dialogRef = this.dialog.open(ResultAlgorithmComponent, {
            disableClose: true
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                let loading = this.app.showLoading();
                this.service.launchAlgorithm(this.admission.Id).subscribe(data => {
                    this.app.hideLoading(loading);
                    this.app.notify('admissionResults_algorithmSuccess');
                    this.load();
                }, err => this.app.showLoadError(err));
            }
        });
    }

    selectRow(item: AdmissionResult) {
        let index = this.selectedRows.indexOf(item);

        if (index > -1)
            this.selectedRows.splice(index, 1);
        else
            this.selectedRows.push(item);
    }

    selectRows(event) {
        if (event.checked)
            this.selectedRows = [...this.gridItems];
        else
            this.selectedRows = [];
    }

    rowSelected(item: AdmissionResult): boolean {
        return this.selectedRows.indexOf(item) > -1;
    }

    allRowsSelected(): boolean {
        return this.selectedRows.length === this.gridItems.length;
    }

    getReportUrl(): string {
        let ids: number[] = [];
        
        if (this.reportTarget === 'Selected')
            ids = this.selectedRows.map(r => r.Id);
        else
            ids = this.gridItems.map(r => r.Id);

        switch (this.reportType) {
            case 'WithCodes': return this.reportService.getApplicationReportUrl(ids, true);
            case 'WithoutCodes': return this.reportService.getApplicationReportUrl(ids);
            case 'ResidentExams': return this.reportService.getResidentExamReportUrl(ids,'currentgrade');
            case 'ResidentExamsWithSelfControl': return this.reportService.getResidentExamReportUrl(ids, 'selfcontrol');
            case 'InterviewPagesDocx': return this.reportService.getInterviewPagesReportUrl(ids, InterviewPageReportFormat.Docx);
            case 'InterviewPagesXlsx': return this.reportService.getInterviewPagesReportUrl(ids, InterviewPageReportFormat.Xlsx);
        }

        return '';
    }

    sendEmails() {
        let rows = this.sendEmailsOpts.target === 'Selected' ? this.selectedRows : this.gridItems;

        let loading = this.app.showLoading();

        this.messageService.getByCode(this.sendEmailsOpts.notificationCode).subscribe(msg => {
            let body = this.admission.IsForeign ? msg.TextEN : msg.TextLV;

            let dialogRef = this.dialog.open(SendEmailsConfirmDialogComponent, {
                disableClose: true,
                width: '600px',
                data: { body: body }
            });

            this.app.hideLoading(loading);

            dialogRef.afterClosed().subscribe(result => {
                if (result) {
                    loading = this.app.showLoading();
                    this.service.sendApplicantEmails(this.admission.Id, {
                        programIds: rows.map(t => t.Id),
                        subject: result.subject,
                        notificationCode: this.sendEmailsOpts.notificationCode,
                        applicantType: this.sendEmailsOpts.applicantType
                    }).subscribe(res => {
                        this.app.hideLoading(loading);
                        this.app.notify('admissionResults_sendEmails_success');
                    }, err => this.app.showSaveError(err));
                }
            });

        }, err => this.app.showLoadError(err));
    }
}
