<header class="content-header">
    <nav class="breadcrumb">
        <a [routerLink]="['/templates'] | localize" class="bc-back">{{'templates_title' | translate}}</a>
    </nav>
</header>

<div class="block" *ngIf="isLoaded">
    <header class="block-header">
        <h1>
            <ng-container *ngIf="item.Id">{{title}}</ng-container>
            <ng-container *ngIf="!item.Id">{{'templates_newTemplate' | translate}}</ng-container>
        </h1>
    </header>
    <div class="block-body">
        <form class="templates-form" (ngSubmit)="f.form.valid && saveTemplate()" #f="ngForm" novalidate autocomplete="off">
            <div class="cols">
                <div class="col-8">
                    <mat-form-field>
                        <input matInput [(ngModel)]="item.Title" name="Title" [placeholder]="'templates_lblTitle' | translate" required maxlength="256">
                    </mat-form-field>
                </div>
                <div class="col-2">
                    <mat-form-field>
                        <input matInput [matDatepicker]="pickerDateFrom" [(ngModel)]="item.DateFrom" name="dateFrom" #dateFromInput
                               [placeholder]="'templates_lblDateFrom' | translate" [min]="minDate" [max]="maxDate" required
                               [title]="'dateFormatHint' | translate" (click)="pickerDateFrom.open()">
                        <mat-datepicker-toggle matSuffix [for]="pickerDateFrom"></mat-datepicker-toggle>
                        <mat-datepicker #pickerDateFrom></mat-datepicker>
                        <mat-error *ngIf="dateFromInput.invalid">{{'valueInvalid' | translate}}</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-2">
                    <mat-form-field>
                        <input matInput [matDatepicker]="pickerDateTo" [(ngModel)]="item.DateTo" name="dateTo" #dateToInput
                               [placeholder]="'templates_lblDateTo' | translate" [min]="minDate" [max]="maxDate"
                               [title]="'dateFormatHint' | translate" (click)="pickerDateTo.open()">
                        <mat-datepicker-toggle matSuffix [for]="pickerDateTo"></mat-datepicker-toggle>
                        <mat-datepicker #pickerDateTo></mat-datepicker>
                        <mat-error *ngIf="dateToInput.invalid">{{'valueInvalid' | translate}}</mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="cols">
                <div class="col-4">
                    <mat-form-field>
                        <input matInput [(ngModel)]="item.TemplateCode" name="TemplateCode" [placeholder]="'templates_lblCode' | translate" maxlength="128">
                    </mat-form-field>
                </div>

                <div class="col-4">
                    <mat-form-field>
                        <input matInput [(ngModel)]="item.UseCode" name="UseCode" [placeholder]="'templates_lblUseCode' | translate" maxlength="128">
                    </mat-form-field>
                </div>

                <div class="col-4">
                    <mat-form-field>
                        <mat-select [(ngModel)]="item.Type" name="type" required
                                    [placeholder]="'templates_lblType' | translate">
                            <mat-option *ngFor="let item of types" [value]="item">{{'documentTemplateType_' + item | translate}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

            <div class="cols">
                <div class="col-4">
                    <mat-form-field>
                        <input matInput [(ngModel)]="item.FileName" name="FileName" required [placeholder]="'templates_lblFileName' | translate" maxlength="256">
                    </mat-form-field>
                </div>
                <div class="col-4">
                    <div class="mat-align-middle">
                        <input type="file" #fileLvInput name="fileLvInput" required (change)="setFile($event,'lv')" accept=".docx" style="display:none" />
                        <button mat-raised-button type="button" onclick="fileLvInput.click()">
                            <span>
                                {{'templates_lblFileLv' | translate}}
                                <i class="mat-placeholder-required mat-form-field-required-marker">*</i>
                            </span>
                        </button>
                        <span *ngIf="fileUploadedNameLv!=''">{{fileUploadedNameLv}}</span>
                    </div>
                </div>
                <div class="col-4">
                    <div class="mat-align-middle">
                        <input type="file" #fileEnInput name="fileEnInput" required (change)="setFile($event,'en')" accept=".docx" style="display:none" />
                        <button mat-raised-button type="button" onclick="fileEnInput.click()">
                            <span>
                                {{'templates_lblFileEn' | translate}}
                                <i class="mat-placeholder-required mat-form-field-required-marker">*</i>
                            </span>
                        </button>
                        <span *ngIf="fileUploadedNameEn!=''">{{fileUploadedNameEn}}</span>
                    </div>
                </div>
            </div>
            <div class="form-row">
                
            </div>
            <div class="form-submit">
                <button mat-raised-button color="primary" type="submit" [disabled]="!f.valid || fileUploadedNameEn === '' || fileUploadedNameLv === ''">
                    {{'templates_Add' | translate}}
                </button>
                <a mat-raised-button [routerLink]="['/templates'] | localize">
                    {{'cancel' | translate}}
                </a>
            </div>
        </form>
    </div>
</div>