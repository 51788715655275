<h2 mat-dialog-title>{{'applications_register' | translate}}</h2>
<mat-dialog-content>
    <form #form="ngForm" *ngIf="!conflicts.length">
        <mat-form-field>
            <input matInput type="text" [placeholder]="'applications_lblPersonCode' | translate" [(ngModel)]="personCode"
                   name="personCode" maxlength="12" autofocus pattern="[0-9]{6}-?[0-9]{5}"
                   [readOnly]="personCodeReadOnly">
        </mat-form-field>
        <mat-form-field>
            <input matInput type="email" [placeholder]="'applications_lblEmail' | translate" [(ngModel)]="email" name="email">
        </mat-form-field>
        <mat-form-field *ngIf="isAdmin && showApplicationIdField">
            <input matInput type="text" [placeholder]="'applications_lblNumber' | translate" name="applicationNumber" [(ngModel)]="applicationNumber" pattern="[A-z|0-9]{8}">
        </mat-form-field>
    </form>
    
    <div *ngIf="conflicts.length">
        <p>{{'applications_register_conflictsFound' | translate}}</p>

        <div *ngIf="personCodeConflicts.length">
            <h5>{{'applications_register_personCodeConflicts' | translate}}</h5>
            <table class="table">
                <thead>
                    <tr>
                        <th>{{'applications_register_lblConflictAppId' | translate}}</th>
                        <th>{{'applications_register_lblConflictNameSurname' | translate}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let row of personCodeConflicts">
                        <td>
                            <a [routerLink]="['/applications', row.Number] | localize" target="_blank">{{row.Number}}</a>
                        </td>
                        <td>
                            {{row.Name}} {{row.Surname}}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div *ngIf="emailConflicts.length">
            <h5>{{'applications_register_emailConflicts' | translate}}</h5>
            <table class="table">
                <thead>
                    <tr>
                        <th>{{'applications_register_lblConflictAppId' | translate}}</th>
                        <th>{{'applications_register_lblConflictNameSurname' | translate}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let row of emailConflicts">
                        <td>
                            <a [routerLink]="['/applications', row.Number] | localize" target="_blank">{{row.Number}}</a>
                        </td>
                        <td>
                            {{row.Name}} {{row.Surname}}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <p>{{'applications_register_conflictsContinue' | translate}}</p>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-raised-button type="button" (click)="submit()" color="primary" [disabled]="!isValid">
        {{'applications_register_submit' | translate}}
    </button>
    <button mat-button type="button" mat-dialog-close>{{'cancel' | translate}}</button>
</mat-dialog-actions>
