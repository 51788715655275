import { TranslateService } from '@ngx-translate/core';
import { ApplicationAuxData, ApplicationExam, ApplicationPerson } from '../models/Application';
import { AppService } from '../services/app.service';

export const defaultMinApplicantAge = 16;
export const defaultMaxFileUploadSize = 307200; // 300KB
export const defaultFileUploadExtensions = 'jpg,png,docx,xlsx,odt';

export function parseValidationErrors(errors: any[], translate: (text: string, params?: Object) => string): string {
    let text = '';
    let groups = {};

    errors.forEach(t => {
        let groupParts = (t.Group || 'otherErrors').split('.');
        let group = groupParts[0];
        let subgroup = groupParts.length > 1 ? groupParts[1] : '.';

        if (!groups[group])
            groups[group] = {};

        if (!groups[group][subgroup])
            groups[group][subgroup] = [];

        let arr = groups[group][subgroup];
        arr.push(parseValidationErrorText(t.Text, translate, group, t.Params));
    });

    for (let gr in groups) {
        text += (gr ? `<h5>${translate(`applications_${gr}`)}</h5>` : '') + '<ul>';

        for (let sgr in groups[gr]) {
            text += (sgr === '.')
                ? `<li>${groups[gr][sgr].join('</li><li>')}</li>`
                : `<li>${sgr !== gr ? translate(`applications_${sgr}`) + ' - ' : ''}${groups[gr][sgr].join(', ')}</li>`;
        }

        text += '</ul>';
    }

    return text;
}

export function parseValidationErrorText(text: string, translate: (text: string, params?: Object) => string, group?: string, params?: Object): string {
    let key: string;
    let resolved: string;

    if (text.indexOf('f_') === 0) {
        text = text.substring(2);

        if (group) {
            key = `applications_${group}_lbl${text}`;
            resolved = translate(key, params);

            if (resolved && resolved !== key)
                return resolved;
        }

        key = `applications_lbl${text}`;
        resolved = translate(key, params);

        if (resolved && resolved !== key)
            return resolved;
    } else {
        if (group) {
            key = `applications_${group}_${text}`;
            resolved = translate(key, params);

            if (resolved && resolved !== key)
                return resolved;
        }

        key = `applications_${text}`;
        resolved = translate(key, params);

        if (resolved && resolved !== key)
            return resolved;
    }

    return translate(text, params);
}

export function prettifyCrmMatchType(person: ApplicationPerson, translate: (key: string) => any): string {
    if ((person.CrmMatchType || '').length === 0)
        return '';

    return person.CrmMatchType.split(',').map(t => translate('personCrmMatchType_' + t)).join(', ');
}

export function getViisGrade(exam: ApplicationExam, auxType?: string): number {
    return getViisGradeByType('final', exam, auxType);
}

export function getViisRawGrade(exam: ApplicationExam, auxType?: string): number {
    return getViisGradeByType('raw', exam, auxType);
}

function getViisGradeByType(type: 'final' | 'raw', exam: ApplicationExam, auxType?: string): number {
    const viisGrade = exam.ViisGrades ? exam.ViisGrades.find(vg => vg.Name === (auxType || exam.AuxType)) : undefined;
    if (viisGrade === null) return null;
    if (viisGrade) return (type == 'raw' ? viisGrade.RawGrade : viisGrade.Grade);
    // undefined, if not found at all;
}

export function getViisGradeString(translate: TranslateService, exam: ApplicationExam, auxType?: string): string {
    return getViisGradeStringByType('final', translate, exam, auxType);
}

export function getViisRawGradeString(translate: TranslateService, exam: ApplicationExam, auxType?: string): string {
    return getViisGradeStringByType('raw', translate, exam, auxType);
}

function getViisGradeStringByType(type: 'final' | 'raw', translate: TranslateService, exam: ApplicationExam, auxType?: string): string {
    if (!exam.Code.toUpperCase().startsWith('CE-')) return;

    const viisGrade = getViisGradeByType(type, exam, auxType);
    if (viisGrade === undefined) {
        return '?' + translate.instant('applications_viisGrade_noData');
    }

    const prefix = viisGrade != exam.Grade ? '!' : '+';
    if (viisGrade === null) {
        return prefix + translate.instant('applications_viisGrade_exempt');
    }

    return prefix + viisGrade.toString() + '%';
}

export function getCrmGradeString(translate: TranslateService, exam: ApplicationExam): string {
    if (!exam.Code.toUpperCase().startsWith('DVA-')) return;

    if (!exam.CrmGrades || exam.CrmGrades.length === 0) {
        return '?' + translate.instant('applications_crmGrade_noData');
    }
    let matched = false;
    let resultParts: string[] = [];
    for (let i = 0; i < exam.CrmGrades.length; i++) {
        let crmGrade = exam.CrmGrades[i];
        if (!crmGrade) continue;
        if (crmGrade == exam.Grade) {
            matched = true;
            resultParts.push('<strong>' + crmGrade.toString() + '</strong>');
        }
        else {
            resultParts.push(crmGrade.toString());
        }
    }
    const prefix = matched ? '+' : '!';
    return prefix + resultParts.join(', ');
}

export class AuxDataParser {
    constructor(public data: ApplicationAuxData[]) { }

    getByType(type: string): ApplicationAuxData {
        return this.data.find(t => t.Type === type);
    }

    getText(type: string, textProperty?: number): string {
        let d = this.getByType(type);
        return d ? textProperty ? d['Text' + textProperty] : d.Data : undefined;
    }

    getBool(type: string, textProperty?: number): boolean {
        return this.getText(type, textProperty) === 'true';
    }
}

export class ApplicationPersonToLink extends ApplicationPerson {
    ApplicationId: number;
    ApplicationNumber: string;
    PrettyCrmMatchType: string;
    PhotoUrl: string;
    PhotoBase64: string;
    NewPhoto: string;
}
