import { Component, OnInit } from '@angular/core';
import {
    Router,
    Event as RouterEvent,
    NavigationStart,
    NavigationCancel,
    NavigationError,
    NavigationEnd
} from '@angular/router';

import { AlertMessage, AlertMessageType } from './services/alert.service';
import { AppService } from './services/app.service';
import { ParameterService } from './services/parameter.service';
import { MessagesService } from './messages/messages.service';

import { AlertDialogComponent } from './shared/alert-dialog.component';
import { ConfirmDialogComponent } from './shared/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ViewChild } from '@angular/core';
import { ElementRef } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
    constructor(
        public app: AppService,
        public paramService: ParameterService,
        public messageService: MessagesService,
        private router: Router,
        private dialog: MatDialog,
        private snackBar: MatSnackBar
    ) { }

    title = 'eEnrollmentApp';
    errors: string[] = [];
    authDone: boolean;
    mainCssClass: string = 'content-wrapper';

    get isLoading(): boolean {
        return this.app.isLoading();
    }

    private hasErrorDialog: boolean;
    private signOutUrl: string;
    private isAuthenticated: boolean;

    @ViewChild('main', { static: false }) private mainDiv: ElementRef;

    ngOnInit() {
        this.app.getMainCssClass().subscribe(value => {
            if (this.mainDiv) {
                this.mainDiv.nativeElement.className = ('content-wrapper ' + value).trim();
            } else {
                let attempt = 0;
                const interval = setInterval(() => {
                    attempt++;

                    if (attempt > 10 || this.mainDiv) {
                        clearInterval(interval);
                    }

                    if (this.mainDiv) {
                        this.mainDiv.nativeElement.className = ('content-wrapper ' + value).trim();
                    }
                }, 100);
            }
        });

        this.app.auth.authenticate().subscribe(() => {
            this.authDone = true;
        });

        this.app.auth.user.subscribe(user => {
            if (user) {
                this.isAuthenticated = true;

                if (user.signoutUrl)
                    this.signOutUrl = user.signoutUrl;

                this.checkConsent(user);
                this.app.accessDenied = false;
            } else {
                if (this.isAuthenticated || this.app.accessDenied) {
                    if (this.signOutUrl)
                        location.href = this.signOutUrl;
                    else
                        this.app.redirect('/');
                }

                this.isAuthenticated = false;
            }
        });

        this.paramService.getValues().subscribe(data => {
            let appName = data.find(t => t.Code === 'AppName');

            if (appName)
                document.title = appName.Value;
        });

        this.router.events.subscribe((e: any) => {
            this.interceptNavigation(e);
            if (e && e.url && this.isStaffOnlyRoute(e.url) && (!this.app.auth.isAuthenticated || this.app.auth.isApplicant()))
                this.app.redirect('/applications');
            if (!this.app.translate.currentLang) {
                this.app.localize.changeLanguage('lv');
            } else if (e && e.url && e.url.indexOf('/' + this.app.translate.currentLang) < 0) {
                this.router.navigate([this.app.localize.translateRoute(e.url)]);
            }
        });

        this.app.alerts.getMessage().subscribe(msg => msg && this.showAlert(msg));

        this.app.getConfirm().subscribe(args => {
            let dialogRef = this.dialog.open(ConfirmDialogComponent, {
                data: args.dialogOptions,
                disableClose: true,
                maxWidth: 800
            });

            dialogRef.afterClosed().subscribe((result: boolean) => {
                args.callback(result);
            });
        });

        this.app.getNotification().subscribe(text => {
            this.snackBar.open(text, ' ', {
                duration: 5000,
                panelClass: 'app-notification',
                verticalPosition: 'top'
            });
        });
    }

    private notAuthLink(url:string): boolean {
        if (url.indexOf('/account') < 0 && url.indexOf('/register') < 0 && url.indexOf('/login') < 0
            && url.indexOf('/banklink')<0) {
            return true;
        }
        return false;
    }

    private isStaffOnlyRoute(url: string) {
        if (url.endsWith('/admissions') || url.endsWith('/examresults'))
            return true;
        else
            return false;
    }

    private showAlert(message: AlertMessage) {
        // Show only one error dialog at a time
        let isError = message.type === AlertMessageType.Error;

        if (!isError || !this.hasErrorDialog) {
            let dialogRef = this.dialog.open(AlertDialogComponent, {
                data: message,
                disableClose: true
            });

            if (isError) {
                this.hasErrorDialog = true;
                dialogRef.beforeClosed().subscribe(() => {
                    this.hasErrorDialog = false;

                    if (this.app.isError404) {
                        this.app.isError404 = false;
                        this.router.navigate([this.app.localize.translateRoute('/')]);
                    }
                });
            }
        }
    }

    private interceptNavigation(event: RouterEvent) {
        if (event instanceof NavigationStart) {
            this.errors = [];
            this.app.addMainCssClass('', true);
            this.app.clearLoading();
        }

        if (event instanceof NavigationCancel) {
            this.app.clearLoading();
        }

        if (event instanceof NavigationError) {
            this.app.clearLoading();
        }

        if (event instanceof NavigationEnd) {
            window.scroll(0, 0);
        }
    }

    private checkConsent(user) {
        const storageKey = 'consent';

        if (sessionStorage[storageKey])
            return;
        
        this.app.auth.checkConsent().subscribe(consent => {
            if (consent) {
                sessionStorage.setItem(storageKey, '1');
            } else {
                const consentBodyCode = 'APPLICANT_CONSENT_BODY';
                const consentCheckboxCode = 'APPLICANT_CONSENT_CB';

                this.messageService.getForApplicantConsent(this.app.translate.currentLang).subscribe(data => {
                    let body = data.find(t => t.Code === consentBodyCode);
                    let checkbox = data.find(t => t.Code === consentCheckboxCode);

                    if (!body)
                        body = { Text: consentBodyCode };

                    if (!checkbox)
                        checkbox = { Text: consentCheckboxCode };

                    this.app.confirm({
                        cancelText: '',
                        okText: this.app.translate.instant('continue'),
                        text: `<div>${body.Text}</div><p class="text-danger"><strong>${checkbox.Text.trim('.')}.</strong></p>`
                    }, () => {
                        let loading = this.app.showLoading();
                        this.app.auth.consent().subscribe(() => {
                            sessionStorage.setItem(storageKey, '1');
                            this.app.hideLoading(loading);
                        }, err => this.app.showSaveError(err));
                    });
                });
            }
        });
    }
}
