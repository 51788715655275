<div class="cam-photo clearfix">
    <div class="cam">
        <div class="curtain" *ngIf="!ready">
            <mat-spinner [diameter]="20"></mat-spinner>
        </div>
        <video #video autoplay 
               [style.display]="previewSnapshot ? 'none' : 'block'"
               [style.transform]="'rotate(' + imgRotation + 'deg)'"></video>
        <img [src]="previewSnapshot.src" *ngIf="previewSnapshot" />
        <div class="btns">
            <button type="button" (click)="capture()" *ngIf="!previewSnapshot">
                <i class="fa fa-camera"></i>
            </button>
            <button type="button" (click)="rotate()" *ngIf="!previewSnapshot">
                <i class="fa fa-repeat"></i>
            </button>
            <button type="button" (click)="select(previewSnapshot)" *ngIf="previewSnapshot">
                <i class="fa fa-check"></i>
            </button>
            <button type="button" (click)="closePreview()" *ngIf="previewSnapshot">
                <i class="fa fa-times"></i>
            </button>
        </div>
        <div class="resolution">{{width}}&times;{{height}}</div>
    </div>

    <div class="snaps">
        <div *ngFor="let s of snapshots; let i = index" (click)="preview(s)" class="snap {{s ? s.orientation : ''}}"
                [class.snap-empty]="!s"
                [class.current]="s === previewSnapshot">
            <img [src]="s.src" *ngIf="s">
            <span *ngIf="!s">&nbsp;</span>
            <div class="btns" *ngIf="s">
                <button type="button" (click)="$event.stopPropagation(); select(s)">
                    <i class="fa fa-check"></i>
                </button>
            </div>
        </div>
    </div>
</div>