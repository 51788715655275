import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';

export interface ICanDeactivateGuard {
    canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable()
export class DeactivateGuardService implements CanDeactivate<ICanDeactivateGuard> {
    canDeactivate(component: ICanDeactivateGuard) {
        return component.canDeactivate ? component.canDeactivate() : true;
    }
}