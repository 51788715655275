import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { DatatableComponent } from "@swimlane/ngx-datatable";

import { GridComponentBase } from "../GridComponentBase";

import { AdmissionInterviewSummary, InterviewStatus, ScheduledDate } from "../models/Interview";

import { AppService } from "../services/app.service";
import { InterviewService } from "../services/interview.service"

class ProgramInterviewsSummaryViewModel {
    constructor(
        public ProgramId: number,
        public ProgramTitle: string,
        public ExamId: number,
        public ExamCode: string,
        public NotStarted: number,
        public Unfinished: number,
        public Finished: number
    ){
        this.Total = this.NotStarted + this.Unfinished + this.Finished;
    }
    Total: number;
}


@Component({
    selector: 'app-admission-interviews',
    templateUrl: './admission-interviews.component.html'
})
export class AdmissionInterviewsComponent extends GridComponentBase<ProgramInterviewsSummaryViewModel> implements OnInit {
    constructor(
        public app: AppService,
        private service: InterviewService,
    ) {
        super(app);
    }

    @Input() admissionId: number;

    selectedRows: ProgramInterviewsSummaryViewModel[] = [];
    targetRowTypes = ['Selected', 'All'];
    publishTarget: string;

    ngOnInit() {
        this.load();
    }

    load() {
        if (this.admissionId) {
            let loading = this.app.showLoading();
            this.service.getAdmissionInterviewSummaries(this.admissionId).then(data => {
                this.gridItems = data.map(ais => new ProgramInterviewsSummaryViewModel(
                    ais.ProgramId,
                    ais.ProgramTitle,
                    ais.ExamId,
                    ais.ExamCode,
                    (ais.Counts['NotStarted'] || 0) + (ais.Counts['Absent'] || 0),
                    ais.Counts['InProgress'] || 0,
                    ais.Counts['Completed'] || 0
                ));
                this.app.hideLoading(loading);
            }, err => this.app.showLoadError(err));
        }
    }

    selectRow(item: ProgramInterviewsSummaryViewModel) {
        let index = this.selectedRows.indexOf(item);

        if (index > -1)
            this.selectedRows.splice(index, 1);
        else
            this.selectedRows.push(item);
    }

    selectRows(event) {
        if (event.checked)
            this.selectedRows = [...this.gridItems];
        else
            this.selectedRows = [];
    }

    rowSelected(item: ProgramInterviewsSummaryViewModel): boolean {
        return this.selectedRows.indexOf(item) > -1;
    }

    allRowsSelected(): boolean {
        return this.selectedRows.length === this.gridItems.length;
    }


    publish() {
        const rows = this.publishTarget === 'Selected' ? this.selectedRows : this.gridItems;
        if (!rows.length) return;
        const loading = this.app.showLoading();
        this.service.publishResults(rows.map(t => { return { ProgramId: t.ProgramId, ExamId: t.ExamId }; })).then(res => {
            this.app.hideLoading(loading);
            this.app.notify('admissionInterviews_publish_success');
        }, err => this.app.showSaveError(err));
    }

}
