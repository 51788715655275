import { Component, OnInit, Input, Inject } from '@angular/core';

import { AppService } from '../services/app.service';
import { ApplicationService } from '../services/application.service';

import { ApplicationNote } from '../models/Application';

const editorConfig = {
    plugins: 'link textcolor table code lists',
    toolbar:
        'undo redo | formatselect | bold italic forecolor backcolor'
        + ' | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat'
        + ' | table link | code'
};

@Component({
    selector: 'app-application-notes',
    styleUrls: ['./notes.component.css'],
    template: `
    <div class="notes">
        <ul *ngIf="positionNotes.length">
            <li *ngFor="let n of positionNotes">
                <i class="note-icon fa fa-comment-o"></i>
                <div *ngIf="!isEditing(n)" class="note-view">
                    <span [innerHTML]="n.Text"></span>
                    <a href="#" *ngIf="canEdit" (click)="$event.preventDefault(); edit(n)"><i class="fa fa-pencil"></i></a>
                </div>
                <div *ngIf="isEditing(n)" class="note-edit">
                    <label>{{'applications_lblNote' | translate}}</label>
                    <editor [(ngModel)]="n.Text" name="text" [init]="editorConfig"></editor>
                    <div class="note-actions">
                        <button mat-raised-button type="button" color="primary" (click)="save(n)" [disabled]="!isValid(n)">{{'save' | translate}}</button>
                        <button mat-raised-button type="button" color="accent" (click)="delete(n)" [disabled]="n.Id < 1">{{'delete' | translate}}</button>
                        <button mat-raised-button type="button" (click)="cancel(n)">{{'cancel' | translate}}</button>
                    </div>
                </div>
            </li>
        </ul>
        <a href="#" *ngIf="canAdd" (click)="$event.preventDefault(); add()">
            <i class="fa fa-comment-o"></i>
            {{'applications_addNote' | translate}}
        </a>
    </div>
    `
})
export class ApplicationNotesComponent implements OnInit {
    constructor(
        public app: AppService,
        private service: ApplicationService
    ) { }

    @Input() applicationId: number;
    @Input() position: string;
    @Input() single: boolean;
    @Input() notes: ApplicationNote[];
    @Input() canEdit: boolean;

    get positionNotes(): ApplicationNote[] {
        return this.notes.filter(t => t.Position === this.position && (this.canEdit || t.Text));
    }

    get canAdd(): boolean {
        return this.canEdit && (!this.single || !this.positionNotes.length) && !this.editing[0];
    }

    private editing: { [key: number]: any } = {};

    ngOnInit() {
        if (this.notes === undefined) {
            this.notes = [];

            let loading = this.app.showLoading();
            if (this.applicationId) {
                this.service.getNotes(this.applicationId).subscribe(data => {
                    this.notes = data.filter(t => t.Position === this.position);
                    this.notes.sort((a, b) => {
                        return Date.parse(a.Created.toString()) - Date.parse(b.Created.toString());
                    });
                    this.app.hideLoading(loading);
                }, err => this.app.showLoadError(err));
            }
        }
    }

    add() {
        let note = new ApplicationNote();
        note.Id = 0;
        note.ApplicationId = this.applicationId;
        note.Position = this.position;

        this.notes.push(note);
        this.edit(note);
    }

    edit(note: ApplicationNote) {
        this.editing[note.Id] = {
            oldText: note.Text,
            note: note
        };
    }

    delete(note: ApplicationNote) {
        let loading = this.app.showLoading();
        this.service.deleteNote(this.applicationId, note.Id).subscribe(() => {
            this.app.hideLoading(loading);
            this.editing[note.Id] = undefined;
            this.notes.splice(this.notes.indexOf(note), 1);
        }, err => this.app.showSaveError(err));
    }

    cancel(note: ApplicationNote) {
        let id = note.Id;

        if (id === 0) {
            this.notes.splice(this.notes.indexOf(note), 1);
        } else {
            note.Text = this.editing[id].oldText;
        }

        this.editing[id] = undefined;
    }

    save(note: ApplicationNote) {
        let isNew = note.Id < 1;

        let loading = this.app.showLoading();
        this.service.saveNote(note).subscribe(data => {
            Object.assign(note, data);

            this.editing[note.Id] = undefined;

            this.app.hideLoading(loading);
        }, err => this.app.showSaveError(err));
    }

    isEditing(note: ApplicationNote): boolean {
        return this.editing[note.Id] !== undefined;
    }

    isValid(note: ApplicationNote): boolean {
        return (this.editing[note.Id] && (this.editing[note.Id].note.Text || '')).length > 0;
    }
}