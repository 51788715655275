import { Component, OnInit } from '@angular/core';
import { AppService } from '../services/app.service';
import { QuizzService } from '../services//quizz.service';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, Validators } from '@angular/forms';

import { GridComponentBase } from '../GridComponentBase';
import { Quizz, QuizzGroup, QuizzQuestion } from '../models/Quizz';

@Component({
    selector: 'app-quizzgroup',
    templateUrl: './quizzgroup.component.html',
    styleUrls: ['./quizzgroup.component.css']
})
export class QuizzgroupComponent extends GridComponentBase<QuizzQuestion> implements OnInit {

    selectedQuizzGroup: QuizzGroup = new QuizzGroup();
    //minQuestionCount: number = 1;
    //maxQuestionCount: number = 1000;
    //SortSequence = new FormControl('', [Validators.required, Validators.maxLength(6), Validators.max(999999)]);

    constructor(public app: AppService, private service: QuizzService, private route: ActivatedRoute) {
        super(app);
    }

    ngOnInit() {
        this.app.setWideLayout();
        this.route.params.subscribe(params => {
            let loading = this.app.showLoading();
            let id = params['id'];
            if (id) {
                this.service.getGroupById(id).subscribe(data => {
                    if (data) {
                        if (data.QuizzQuestions)
                            this.gridItems = data.QuizzQuestions;
                        this.selectedQuizzGroup = data;
                        this.app.hideLoading(loading);
                    }
                }, err => this.app.showLoadError(err));
            }
        });

    }

    submitQuizzGroup() {
        let loading = this.app.showLoading();
        this.service.updateGroup(this.selectedQuizzGroup).subscribe(res => {
            this.app.hideLoading(loading);
        }, err => this.app.showSaveError(err));
    }

    isValidGridItem(item: QuizzQuestion): boolean {
        if (!item.SortSequence || item.SortSequence < 1 || item.SortSequence > 999999)
            return false;
        else if (!item.Text)
            return false;
        else {
            if (this.gridItems.filter(g => g.Text == item.Text).length == 1) {
                item.notUnique = false;
                this.gridItems.filter(g => g.notUnique).forEach((i: QuizzQuestion) => {
                    i.notUnique = false;
                });
                return true;
            }
            else {
                item.notUnique = true;
                this.gridItems.filter(g => g.Text == item.Text).forEach((i: QuizzQuestion) => {
                    i.notUnique = true;
                });
                return false;
            }
        }
    }

    getWarnings(item: QuizzQuestion): string[] {
        let messages = [];

        if (item.ActualAnswerCount < 2)
            messages.push(this.app.translate.instant('quizzanswer_notEounghAnswers'));

        if (item.notUnique)
            messages.push(this.app.translate.instant('quizzgroup_notuniquequestion'));

        return messages;
    }

    protected createGridItem(item: QuizzQuestion): Observable<QuizzQuestion> {
        return this.service.addQuestion(this.selectedQuizzGroup.Id, item);
    }

    protected updateGridItem(item: QuizzQuestion): Observable<QuizzQuestion> {
        return this.service.updateQuestion(item);
    }

    protected deleteGridItem(item: QuizzQuestion): Observable<boolean> {
        return this.service.deleteQuestion(item.Id);
    }

    protected createEmptyObject(): QuizzQuestion {
        let item = new QuizzQuestion();
        return item;
    }

    checkNumbers(e: any) {
        if (!((e.keyCode > 95 && e.keyCode < 106)
            || (e.keyCode > 47 && e.keyCode < 58)
            || e.keyCode == 8)) {
            return false;
        }
    }
}