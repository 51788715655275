<header class="content-header">
    <nav class="breadcrumb">
        <a *ngIf="item" [routerLink]="['/applications', item.Number] | localize" class="bc-back">
            {{'applications_edit_titlePrefix' | translate}}
            "{{item.AdmissionTitle}}", {{item.Number}}
        </a>
    </nav>
    <h1 [translate]="'modif_title_Application'"></h1>
</header>

<ngx-datatable #grid
               class="tight"
               [rows]="gridItems"
               [headerHeight]="'auto'"
               [footerHeight]="50"
               [rowHeight]="'auto'"
               [columnMode]="'flex'"
               [limit]="100"
               [externalSorting]="true"
               [loadingIndicator]="gridLoading"
               [messages]="{ emptyMessage: 'gridEmpty' | translate, totalMessage: 'gridTotal' | translate }"
               (sort)="onGridSort($event)">

    <ngx-datatable-column [width]="20"
                          [resizeable]="false"
                          [sortable]="false"
                          [draggable]="false"
                          [canAutoResize]="false"
                          [flexGrow]="1">
        <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
            <a href="javascript:void(0)"
               [class.datatable-icon-right]="!expanded"
               [class.datatable-icon-down]="expanded"
               (click)="grid.rowDetail.toggleExpandRow(row)">
            </a>
        </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column [name]="'modif_lblModified' | translate" prop="Modified" [flexGrow]="1">
        <ng-template ngx-datatable-cell-template let-row="row">
            {{row.Modified | appDate:'+'}}
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [name]="'modif_lblAction' | translate" prop="ModifAction" [flexGrow]="1">
        <ng-template ngx-datatable-cell-template let-row="row">
            {{'modif_action_' + row.ModifAction | translate}}
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [name]="'modif_lblUser' | translate" prop="ModifiedByName" [flexGrow]="1"></ngx-datatable-column>
    <ngx-datatable-column [name]="'applications_lblName' | translate" prop="Name" [flexGrow]="1"></ngx-datatable-column>
    <ngx-datatable-column [name]="'applications_lblSurname' | translate" prop="Surname" [flexGrow]="1"></ngx-datatable-column>
    <ngx-datatable-column [name]="'applications_lblPersonCode' | translate" prop="PersonCode" [flexGrow]="1"></ngx-datatable-column>
    <ngx-datatable-column [name]="'applications_lblPhone' | translate" prop="Phone" [flexGrow]="1"></ngx-datatable-column>
    <ngx-datatable-column [name]="'applications_lblEmail' | translate" prop="Email" [flexGrow]="1"></ngx-datatable-column>

    <ngx-datatable-row-detail [rowHeight]="'auto'" #detailRow>
        <ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
            <mat-checkbox [ngClass]="{'app-hide-unchanged': !showUnchanged.checked }" #showUnchanged>{{ 'modif_show_unchanged' | translate }}</mat-checkbox>
            <div [ngClass]="{ 'app-differ': row.hasChanged('Birthdate') }"><label [translate]="'applications_lblBirthdate'"></label> {{row.Birthdate | appDate}}</div>
            <div [ngClass]="{ 'app-differ': row.hasChanged('Gender') }"><label [translate]="'applications_lblGender'"></label> {{row.Gender}}</div>
            <div [ngClass]="{ 'app-differ': row.hasChanged('DocType') }"><label [translate]="'applications_lblDocType'"></label> {{row.DocType ? ('applications_docType_' + row.DocType | translate) : ''}}</div>
            <div [ngClass]="{ 'app-differ': row.hasChanged('DocNumber') }"><label [translate]="'applications_lblDocNumber'"></label> {{row.DocNumber}}</div>
            <div [ngClass]="{ 'app-differ': row.hasChanged('DocDate') }"><label [translate]="'applications_lblDocDate'"></label> {{row.DocDate | appDate}}</div>
            <div [ngClass]="{ 'app-differ': row.hasChanged('DocExpiry') }"><label [translate]="'applications_lblDocExpiry'"></label> {{row.DocExpiry | appDate}}</div>
            <div [ngClass]="{ 'app-differ': row.hasChanged('DocBirthPlace') }"><label [translate]="'applications_lblBirthPlace'"></label> {{row.DocBirthPlace}}</div>
            <div [ngClass]="{ 'app-differ': row.hasChanged('AddrCounty','AddrCity','AddrParish','AddrVillage','AddrStreet','AddrHouse','AddrApartment','AddrIndex','AddrCountry') }"><label [translate]="'applications_declaredAddress'" style="font-size: medium; text-decoration: underline;"></label></div>
            <div [ngClass]="{ 'app-differ': row.hasChanged('AddrCity') }"><label [translate]="'applications_lblCity'"></label> {{row.AddrCity}}</div>
            <div [ngClass]="{ 'app-differ': row.hasChanged('AddrCounty') }"><label [translate]="'applications_lblCounty'"></label> {{row.AddrCounty}}</div>
            <div [ngClass]="{ 'app-differ': row.hasChanged('AddrParish') }"><label [translate]="'applications_lblParish'"></label> {{row.AddrParish}}</div>
            <div [ngClass]="{ 'app-differ': row.hasChanged('AddrVillage') }"><label [translate]="'applications_lblVillage'"></label> {{row.AddrVillage}}</div>
            <div [ngClass]="{ 'app-differ': row.hasChanged('AddrStreet') }"><label [translate]="'applications_lblStreet'"></label> {{row.AddrStreet}}</div>
            <div [ngClass]="{ 'app-differ': row.hasChanged('AddrHouse') }"><label [translate]="'applications_lblHouse'"></label> {{row.AddrHouse}}</div>
            <div [ngClass]="{ 'app-differ': row.hasChanged('AddrApartment') }"><label [translate]="'applications_lblApartment'"></label> {{row.AddrApartment}}</div>
            <div [ngClass]="{ 'app-differ': row.hasChanged('AddrIndex') }"><label [translate]="'applications_lblPostalCode'"></label> {{row.AddrIndex}}</div>
            <div [ngClass]="{ 'app-differ': row.hasChanged('AddrCountry') }"><label [translate]="'applications_lblCountry'"></label> {{row.AddrCountry}}</div>
            <div [ngClass]="{ 'app-differ': row.hasChanged('ContCounty','ContCity','ContParish','ContVillage','ContStreet','ContHouse','ContApartment','ContIndex','ContCountry') }"><label [translate]="'applications_contactAddress'" style="font-size: medium; text-decoration: underline;"></label></div>
            <div [ngClass]="{ 'app-differ': row.hasChanged('ContIsSameAsAddr') }"><label [translate]="'applications_contactAddressIsSameAsDeclaredAddress'"></label> {{yesno(row.ContIsSameAsAddr)}}</div>
            <div [ngClass]="{ 'app-differ': row.hasChanged('ContCity') }"><label [translate]="'applications_lblCity'"></label> {{row.ContCity}}</div>
            <div [ngClass]="{ 'app-differ': row.hasChanged('ContCounty') }"><label [translate]="'applications_lblCounty'"></label> {{row.ContCounty}}</div>
            <div [ngClass]="{ 'app-differ': row.hasChanged('ContParish') }"><label [translate]="'applications_lblParish'"></label> {{row.ContParish}}</div>
            <div [ngClass]="{ 'app-differ': row.hasChanged('ContVillage') }"><label [translate]="'applications_lblVillage'"></label> {{row.ContVillage}}</div>
            <div [ngClass]="{ 'app-differ': row.hasChanged('ContStreet') }"><label [translate]="'applications_lblStreet'"></label> {{row.ContStreet}}</div>
            <div [ngClass]="{ 'app-differ': row.hasChanged('ContHouse') }"><label [translate]="'applications_lblHouse'"></label> {{row.ContHouse}}</div>
            <div [ngClass]="{ 'app-differ': row.hasChanged('ContApartment') }"><label [translate]="'applications_lblApartment'"></label> {{row.ContApartment}}</div>
            <div [ngClass]="{ 'app-differ': row.hasChanged('ContIndex') }"><label [translate]="'applications_lblPostalCode'"></label> {{row.ContIndex}}</div>
            <div [ngClass]="{ 'app-differ': row.hasChanged('ContCountry') }"><label [translate]="'applications_lblCountry'"></label> {{row.ContCountry}}</div>
            <div [ngClass]="{ 'app-differ': row.hasChanged('Citizenship') }"><label [translate]="'applications_lblCitizenship'"></label> {{row.Citizenship}}</div>
            <div [ngClass]="{ 'app-differ': row.hasChanged('Nationality') }"><label [translate]="'applications_lblNationality'"></label> {{row.Nationality}}</div>
            <div [ngClass]="{ 'app-differ': row.hasChanged('DocIssuer') || row.hasChanged('DocIssuerText') }"><label [translate]="'applications_lblDocIssuer'"></label> {{row.DocIssuer || row.DocIssuerText}}</div>
            <div [ngClass]="{ 'app-differ': row.hasChanged('Status') }"><label [translate]="'applications_lblStatus'"></label> {{'applicationStatus_' + row.Status | translate}}</div>
            <div [ngClass]="{ 'app-differ': row.hasChanged('PersonVerified') }"><label [translate]="'applications_lblPersonVerified'"></label> {{row.PersonVerified}}</div>
            <div [ngClass]="{ 'app-differ': row.hasChanged('GradesConfirmed','RulesConfirmed','StudiesAtLaterStages','IsGraduate','ApplicationForCreditTransfer','IsIncomplete') }"><label [translate]="'applications_confirmations_lbl'" style="font-size: medium; text-decoration: underline;"></label></div>
            <div [ngClass]="{ 'app-differ': row.hasChanged('GradesConfirmed') }"><label [innerHTML]="applicationConfirmGradesText"></label> {{yesno(row.GradesConfirmed)}}</div>
            <div [ngClass]="{ 'app-differ': row.hasChanged('RulesConfirmed') }"><label [innerHTML]="applicationConfirmRulesLabelText"></label> {{yesno(row.RulesConfirmed)}}</div>
            <div [ngClass]="{ 'app-differ': row.hasChanged('StudiesAtLaterStages') }"><label [innerHTML]="applicationStudiesAtLaterStagesText"></label> {{yesno(row.StudiesAtLaterStages)}}</div>
            <div [ngClass]="{ 'app-differ': row.hasChanged('IsGraduate') }"><label [innerHTML]="applicationIsGraduateText"></label> {{yesno(row.IsGraduate)}}</div>
            <div [ngClass]="{ 'app-differ': row.hasChanged('ApplicationForCreditTransfer') }"><label [innerHTML]="applicationApplicationForCreditTransferText"></label> {{yesno(row.ApplicationForCreditTransfer)}}</div>
            <div [ngClass]="{ 'app-differ': row.hasChanged('IsIncomplete') }"><label [innerHTML]="applicationIsIncompleteText"></label> {{yesno(row.IsIncomplete)}}</div>
            <div [ngClass]="{ 'app-differ': row.hasChanged('IsSISDataChecked','ContractNumber','CrmEnrollmentNumber','UnitedRZStatus','SetRegistrationAsPaid','SetFirstPaymentAsPaid','SubmittedByRepresentative','RepresentativeName','RepresentativeSurname','RepresentativePersonCode') }"><label [translate]="'applications_other_lbl'" style="font-size: medium; text-decoration: underline;"></label></div>
            <div [ngClass]="{ 'app-differ': row.hasChanged('IsSISDataChecked') }"><label [translate]="'applications_personDataChecked'"></label> {{yesno(row.IsSISDataChecked)}}</div>
            <div [ngClass]="{ 'app-differ': row.hasChanged('ContractNumber') }"><label [translate]="'admissionApplicationOrders_lblContractNumber'"></label> {{row.ContractNumber}}</div>
            <div [ngClass]="{ 'app-differ': row.hasChanged('CrmEnrollmentNumber') }"><label [translate]="'applications_lblCrmEnrollmentNumber'"></label> {{row.CrmEnrollmentNumber}}</div>
            <div [ngClass]="{ 'app-differ': row.hasChanged('UnitedRZStatus') }"><label [translate]="'applications_lblUnitedRZStatus'"></label> {{row.UnitedRZStatus && ('applicationUnitedRZStatus_' + row.UnitedRZStatus | translate)}}</div>
            <div [ngClass]="{ 'app-differ': row.hasChanged('SetRegistrationAsPaid') }"><label [translate]="'applications_pay_lblRegistrationPaid'"></label> {{yesno(row.SetRegistrationAsPaid)}}</div>
            <div [ngClass]="{ 'app-differ': row.hasChanged('SetFirstPaymentAsPaid') }"><label [translate]="'applications_pay_lblFirstPaymentPaid'"></label> {{yesno(row.SetFirstPaymentAsPaid)}}</div>
            <div [ngClass]="{ 'app-differ': row.hasChanged('SubmittedByRepresentative') }"><label [translate]="'applications_personSubmittedByRepresentative'"></label> {{yesno(row.SubmittedByRepresentative)}}</div>
            <div [ngClass]="{ 'app-differ': row.hasChanged('RepresentativeName') }"><label [translate]="'applications_personRepresentativeName'"></label> {{row.RepresentativeName}}</div>
            <div [ngClass]="{ 'app-differ': row.hasChanged('RepresentativeSurname') }"><label [translate]="'applications_personRepresentativeSurname'"></label> {{row.RepresentativeSurname}}</div>
            <div [ngClass]="{ 'app-differ': row.hasChanged('RepresentativePersonCode') }"><label [translate]="'applications_personRepresentativePersonCode'"></label> {{row.RepresentativePersonCode}}</div>
        </ng-template>
    </ngx-datatable-row-detail>

</ngx-datatable>
