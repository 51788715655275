<h2 mat-dialog-title>{{'admissionResults_sendEmails_confirm' | translate}}</h2>
<mat-dialog-content>
    <form #form="ngForm">
        <div>
            <mat-form-field>
                <input matInput [(ngModel)]="subject" name="subject" required [placeholder]="'admissionResults_sendEmails_lblSubject' | translate">
            </mat-form-field>
        </div>
        <div *ngIf="!canEditBody" class="send-emails-body" [innerHTML]="body | safeHtml"></div>
        <div *ngIf="canEditBody" class="send-emails-body">
            <mat-form-field>
                <textarea id="persEmailBody" matInput matTextareaAutosize [(ngModel)]="body" name="body" required [placeholder]="'applications_lblPersonalEmailBody' | translate"></textarea>
            </mat-form-field>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-raised-button type="button" [mat-dialog-close]="result" color="primary" [disabled]="!form.valid">
        {{'ok' | translate}}
    </button>
    <button mat-button type="button" mat-dialog-close>{{'cancel' | translate}}</button>
</mat-dialog-actions>