import {  Component, Input } from '@angular/core';

/**
 * Form field display component to match angular material design.
 */
@Component({
    selector: 'app-field-display',
    template: `
    <div class="form-field-display{{cssClass ? ' ' + cssClass : ''}}">
        <label class="form-field-label">
            <ng-container *ngIf="label">{{label | translate}}</ng-container>
            <ng-container *ngIf="!label">&nbsp;</ng-container>
        </label>
        <div class="form-field-value" title="{{value}}">
            <ng-content *ngIf="value === undefined || value === null"></ng-content>
            <ng-container *ngIf="value !== undefined && value !== null">{{value}}</ng-container>
            <div *ngIf="value === undefined || value === null || value == ''" [innerHTML]="ifEmpty | safeHtml"></div>
        </div>
    </div>
    `
})
export class FormFieldDisplayComponent {
    @Input() value: any;
    @Input() label: string;
    @Input() cssClass: string;
    @Input() ifEmpty: string = '&nbsp;';
}