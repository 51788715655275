import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AppService } from '../services/app.service';
import { AuthService } from '../services/auth.service';
import { MessagesService } from '../messages/messages.service';
import { ParameterService } from '../services/parameter.service';
import { RecaptchaLoaderService, RecaptchaComponent } from 'ng-recaptcha';

import { UserInfo } from '../models/UserInfo';

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
    constructor(
        private app: AppService,
        private auth: AuthService,
        private messageService: MessagesService,
        private params: ParameterService,
        private router: Router
    ) { }

    loading = false;
    userToRegister: UserInfo = new UserInfo();
    reCaptchaService: RecaptchaLoaderService;
    reCaptchaResponse: string;
    @ViewChild('recaptchaRef', { static: false }) recaptchaRef: RecaptchaComponent;
    registerError: string;
    registerSuccess: string = '';
    translations: any;
    reCaptchaSiteKey: string;
    emailAuthEnabled: boolean;

    consent: boolean;
    consentBody: string;
    consentCheckbox: string;

    ngOnInit() {
        let loading = this.app.showLoading();
        this.params.getValues().subscribe(values => {
            this.emailAuthEnabled = values.some(t => t.Code === 'EmailAuthEnabled' && t.Value === '1');
            this.app.hideLoading(loading);

            if (!this.emailAuthEnabled) {
                this.router.navigateByUrl('/');
            } else {
                this.reCaptchaSiteKey = this.app.env.reCaptchaSiteKey;
                this.app.translate.get(['nonameandlastname', 'noemail', 'emailalreadyregistered', 'confirmsent', 'unexpectederror', 'confirmsent']).subscribe((result: any) => {
                    this.translations = result;
                });

                const consentBodyCode = 'APPLICANT_CONSENT_BODY';
                const consentCheckboxCode = 'APPLICANT_CONSENT_CB';

                this.messageService.getForApplicantConsent(this.app.translate.currentLang).subscribe(data => {
                    let body = data.find(t => t.Code === consentBodyCode);
                    let checkbox = data.find(t => t.Code === consentCheckboxCode);

                    if (!body)
                        body = { Text: consentBodyCode };

                    if (!checkbox)
                        checkbox = { Text: consentCheckboxCode };

                    this.consentBody = body.Text;
                    this.consentCheckbox = checkbox.Text;
                });
            }
        });
    }

    resolved(captchaResponse: string) {
        this.reCaptchaResponse = captchaResponse;
    }

    register() {
        this.loading = true;
        this.auth.register(this.userToRegister.firstName, this.userToRegister.lastName, this.userToRegister.email.toLowerCase(),
            this.app.translate.currentLang, this.reCaptchaResponse, this.consent)
            .subscribe(response => {
                this.loading = false;
                this.registerSuccess = this.translations['confirmsent'];
            }, error => {
                this.loading = false;
                if (this.translations.hasOwnProperty(error.error)) {
                    this.registerError = this.translations[error.error];
                } else {
                    console.log(error);
                    this.registerError = this.translations['unexpectederror'];
                }
                this.reCaptchaResponse = '';
                this.recaptchaRef.reset();
            });
    }
}
