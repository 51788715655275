<div class="cols">
    <div class="col-6">
        <ng-container *ngFor="let fieldName of ['County', 'City', 'Parish', 'Village']">
            <ng-container *ngTemplateOutlet="territoryLookup;context:{ fieldName: fieldName, field: lookupFields[fieldName] }"></ng-container>
        </ng-container>
    </div>
    <div class="col-6">
        <ng-container *ngFor="let fieldName of ['Street', 'House', 'Apartment']">
            <ng-container *ngTemplateOutlet="territoryLookup;context:{ fieldName: fieldName, field: lookupFields[fieldName] }"></ng-container>
        </ng-container>
        <mat-form-field appearance="standard" [ngClass]="{ 'app-readonly': !!data.PostCode }">
            <mat-label [translate]="'address_lblPostCode'"></mat-label>
            <input type="text" matInput readonly [(ngModel)]="data.PostCode" [disabled]="!data.PostCode">
            <mat-icon matSuffix *ngIf="data.PostCode">check</mat-icon>
        </mat-form-field>
    </div>
</div>
<ng-template #territoryLookup let-name="fieldName" let-field="field">
    <mat-form-field appearance="standard">
        <mat-label [translate]="'address_lbl' + name"></mat-label>
        <input matInput [formControl]="field.control" [matAutocomplete]="auto" #trigger="matAutocompleteTrigger" [required]="isRequired(name)" (click)="onClick($event, trigger)">
        <mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption [displayWith]="getValue" (optionSelected)="onOptionSelected(name,$event)" (closed)="onClosed(name)">
            <mat-option *ngFor="let option of field.filteredSource | async | keyvalue:originalOrder" [value]="option">
                {{ option.value }}
            </mat-option>
        </mat-autocomplete>
        <mat-icon matSuffix *ngIf="field.isSet">check</mat-icon>
    </mat-form-field>
</ng-template>
