import { BaseEntity } from './BaseEntity';

export enum StudyLevel {
    Undergraduate = '54ba54dc-6a24-45b0-ada5-4eb52a554271',
    Masters = '51ecf57d-50a5-464e-a904-795957ac8fa3',
    Doctoral = 'bfeedb2f-91f4-4b0b-b915-a28d398d590e',
    Residency = 'd19c2735-eb7d-44e9-8f16-f283c812b609'
}

export enum AdmissionAnonymiseStatus {
    Requested = 'Requested',
    InProgress = 'InProgress',
    Done = 'Done',
    Failed = 'Failed'
}

export class Admission extends BaseEntity {
    Title: string;
    Level: string;
    LevelId: StudyLevel;
    DateFrom: Date;
    DateTo: Date;
    TimeTo: string;
    ContractFilesAccessibleFrom: Date;
    ContractFilesAccessibleUntil: Date;
    PublishDate: Date;
    PublishTime: string;
    DateExtended: Date;
    TargetAudience: string;
    TargetAudienceId: string;
    PriorityCount: number;
    CrmId: string;
    NotificationCode: string;
    AppSubmitNotificationCode: string;
    AppApproveNotificationCode: string;
    VisibleUntilDate: Date;
    InAdmissionNotificationCode: string;
    ResultBudgetInNotificationCode: string;
    ResultBudgetOutNotificationCode: string;
    ResultNotParticipatedNotificationCode: string;
    ResultFeeInNotificationCode: string;
    ResultFeeOutNotificationCode: string;
    IsHomines: boolean;
    IsUnitedRZ: boolean;
    IsRZ: boolean;
    IsForeign: boolean;
    UnitedRZStatus: string;
    IsExpired: boolean;
    HasInterviews: boolean;
    Anonymise: AdmissionAnonymiseStatus;
    ContractESignEmailBodyCode: string;
    ContractESignEmailSubjectCode: string;
    ContractSignApprovalEmailNotificationSubject: string;
    ContractSignApprovalEmailNotificationBody: string;
    ExtensionPriorityCount: number;
    PdfAvailable: boolean;
    IsLateSubmission: boolean;
}

export class AdmissionCrm  {
    EnrollId: string;
    EnrollTitle: string;
    EnrollUniversity: string;
    EnrollPeriodId: string;
    EnrollPeriodTitle: string;
    EnrollLevel: string;
    EnrollAudience: string;
    EnrollLevelId: number;
    EnrollAudienceId: number;
    EnrollMaxPriorities: number;
    EnrollStart: Date;
    EnrollEnd: Date;
    EnrollApplicationsVisibleTill: Date;
    EnrollEduDocApprovalRequired:boolean;
}

export class AdmissionResult {
	  Id: number;
    Program: string;
    Financing: string;
    Quota: number;
    ApplicantsIn: number;
    ApplicatinsInHigher: number;
    ApplicantsInLower: number;
    ApplicantsOut: number;
    ApplicationCount: number;
}

export class AdmissionApplicant {
    ApplicationId: number;
    ApplicationNumber: string;
    Name: string;
    Surname: string;
    PersonCode: string;
    Phone: string;
    Email: string;
    AdmissionResult: string;
    ResultProgram: string;
    Financing: string;
    ContractStatus: string;
	Status: string;
	PriorityCount: number;
	IsEdited: boolean;
}

export class AdmissionOrder {
  Id: number;
  Code: string;
  Title: string;
  Program: string;
  OrderDate: Date;
  ProtocolNumber: string;
  Status: string;
  DVSDocumentNumber: string;
  OrderCount: number;
}

export class AdmissonOrderApplication {
  Id: number;
  Name: string;
  Surname: string;
  Status: string;
  Number: string;
  ContractNumber: string;
}
