<div class="cols input-row" *ngFor="let f of list">
    <div class="col-10">
        <app-field-file [label]="label | translate"
                        [required]="needsMoreFiles"
                        [file]="f"
                        [accept]="allowedExtensions"
                        [maxSize]="maxUploadSize"
                        [showRemove]="false"
                        (select)="setFile(f, $event)"></app-field-file>
    </div>
    <div class="col-2">
        <button type="button" class="input-row-btn btn-icon" (click)="removeRow(f)" [disabled]="!f.fileName">
            <i class="fa fa-trash-o"></i>
        </button>
    </div>
</div>

<div class="rows-action">
    <button type="button" [disabled]="!canAddRows" mat-raised-button (click)="addRow()">
        <i class="fa fa-plus"></i>
        {{'applications_btnAddFile' | translate}}
    </button>
</div>
