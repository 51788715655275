<header class="content-header">
    <nav class="breadcrumb">
        <a *ngIf="item" [routerLink]="['/applications', item.Number] | localize" class="bc-back">
            {{'applications_edit_titlePrefix' | translate}}
            "{{item.AdmissionTitle}}", {{item.Number}}
        </a>
    </nav>
    <h1 [translate]="'modif_title_ApplicationPrograms'"></h1>
</header>

<ngx-datatable #grid
               class="tight"
               [rows]="gridItems"
               [headerHeight]="'auto'"
               [footerHeight]="50"
               [rowHeight]="'auto'"
               [columnMode]="'flex'"
               [limit]="100"
               [externalSorting]="true"
               [loadingIndicator]="gridLoading"
               [messages]="{ emptyMessage: 'gridEmpty' | translate, totalMessage: 'gridTotal' | translate }"
               (sort)="onGridSort($event)">

    <ngx-datatable-column [width]="20"
                          [resizeable]="false"
                          [sortable]="false"
                          [draggable]="false"
                          [canAutoResize]="false"
                          [flexGrow]="1">
        <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
            <a href="javascript:void(0)"
               [class.datatable-icon-right]="!expanded"
               [class.datatable-icon-down]="expanded"
               (click)="grid.rowDetail.toggleExpandRow(row)">
            </a>
        </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column [name]="'modif_lblModified' | translate" prop="Modified" [flexGrow]="1">
        <ng-template ngx-datatable-cell-template let-row="row">
            {{row.Modified | appDate:'+'}}
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [name]="'modif_lblAction' | translate" prop="ModifAction" [flexGrow]="1">
        <ng-template ngx-datatable-cell-template let-row="row">
            {{'modif_action_' + row.ModifAction | translate}}
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [name]="'modif_lblUser' | translate" prop="ModifiedByName" [flexGrow]="1"></ngx-datatable-column>
    <ngx-datatable-column [name]="'programs_lblCode' | translate" prop="ProgramCode" [flexGrow]="1"></ngx-datatable-column>
    <ngx-datatable-column [name]="'programs_lblFinancing' | translate" prop="ProgramFinancing" [flexGrow]="1"></ngx-datatable-column>
    <ngx-datatable-column [name]="'programs_lblPriority' | translate" prop="Priority" [flexGrow]="1"></ngx-datatable-column>
    <ngx-datatable-column [name]="'programs_lblResult' | translate" prop="Result" [flexGrow]="1">
        <ng-template ngx-datatable-cell-template let-row="row">
            {{'applicationResultType_' + row.Result | translate}}
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [name]="'programs_lblContractStatus' | translate" prop="ContractStatus" [flexGrow]="1">
        <ng-template ngx-datatable-cell-template let-row="row">
            {{row.ContractStatus ? ('contract_' + row.ContractStatus.toLowerCase() | translate) : ''}}
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [name]="'programs_lblAlgorithmResult' | translate" prop="AlgorithmResult" [flexGrow]="1">
        <ng-template ngx-datatable-cell-template let-row="row">
            {{'applicationResultType_' + row.AlgorithmResult | translate}}
        </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-row-detail [rowHeight]="'auto'" #detailRow>
        <ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
            <mat-checkbox [ngClass]="{'app-hide-unchanged': !showUnchanged.checked }" #showUnchanged>{{ 'modif_show_unchanged' | translate }}</mat-checkbox>
            <div [ngClass]="{ 'app-differ': row.hasChanged('Program') }"><label [translate]="'applications_lblProgram'"></label> {{row.Program}}</div>
            <div [ngClass]="{ 'app-differ': row.hasChanged('WeightedResult') }"><label [translate]="'programResults_lblWeightedResult'"></label> {{row.WeightedResult}}</div>
            <div [ngClass]="{ 'app-differ': row.hasChanged('ProgramSpecialization') }"><label [translate]="'programs_lblSpecialization'"></label> {{row.ProgramSpecialization}}</div>
            <div [ngClass]="{ 'app-differ': row.hasChanged('Workplace') }"><label [translate]="'applications_lblHasWorkplace'"></label> {{yesno(row.Workplace)}}</div>
            <div [ngClass]="{ 'app-differ': row.hasChanged('Workplace') }"><label [translate]="'applications_lblWorkplace'"></label> {{row.Workplace}}</div>
            <div [ngClass]="{ 'app-differ': row.hasChanged('DecidingResult') }"><label [translate]="'programResults_lblDecidingResult'"></label> {{row.DecidingResult}}</div>
            <div [ngClass]="{ 'app-differ': row.hasChanged('University') }"><label [translate]="'programs_lblUniversity'"></label> {{row.University}}</div>
            <div [ngClass]="{ 'app-differ': row.hasChanged('EnrollmentStatus') }"><label [translate]="'programResults_lblEnrollmentStatus'"></label> {{row.EnrollmentStatus}}</div>
        </ng-template>
    </ngx-datatable-row-detail>

</ngx-datatable>
