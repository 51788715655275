import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Parameter, ParameterValue } from '../models/Parameter';
import { environment as ENV } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

/**
 * System parameter service
 */
@Injectable()
export class ParameterService {
    constructor(public http: HttpClient) { }

    public get apiUrl(): string { return `${ENV.apiUrl}/parameters` }

    get(): Observable<Parameter[]> {
        return this.http.get<Parameter[]>(this.apiUrl);
    }

    getValues(): Observable<ParameterValue[]> {
        const url = `${this.apiUrl}/values`;
        return this.http.get<ParameterValue[]>(url);
    }

    getById(id: number): Observable<Parameter> {
        const url = `${this.apiUrl}/${id}`;
        return this.http.get<Parameter>(url);
    }

    update(id: number, value: string): Observable<Parameter> {
        const url = `${this.apiUrl}/${id}`;
        return this.http.put<Parameter>(url, {
            Value: value
        });
    }
}
