import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';

import { AppService } from '../services/app.service';
import { ApplicationService } from '../services/application.service';
import { AdmissionService } from '../services/admission.service';
import { ClassifierService } from '../services/classifier.service';
import { ProgramService } from '../services/program.service';

import { Classifier } from '../models/Classifier';
import { Program, ProgramSpecialization } from '../models/Program';
import { ApplicationProgram, ApplicationNote, ApplicationNotePosition, ApplicationStatus } from '../models/Application';

@Component({
    selector: 'app-application-program-add',
    templateUrl: './program-add.component.html',
    styleUrls: ['./program-add.component.css']
})
export class ProgramAddComponent implements OnInit {
    constructor(
        public app: AppService,
        private service: ApplicationService,
        private admissionService: AdmissionService,
        private classifierService: ClassifierService,
        private programService: ProgramService,
        private route: ActivatedRoute
    ) { }

    appId: number;
    appNumber: string;
    appTitle: string;
    programs: Program[] = [];
    addedPrograms: Program[] = [];
    notes: string[] = [];
    limit: number = 0;
    isUnitedRZ: boolean = false;
    isForeign: boolean = false;
    currentFilter = {
        title: '',
        onlyAdded: false
    };
    isLateSubmission: boolean = false;
    applicationStatus: ApplicationStatus;
    statusEnum = ApplicationStatus;

    get filteredPrograms(): Program[] {
        let list = [];

        let title = (this.currentFilter.title || '').toLowerCase();
        list = this.programs.filter(t => !this.currentFilter.onlyAdded || this.addedPrograms.findIndex(p => p.Id == t.Id) > -1);
        list = list.concat(this.addedPrograms.filter(t => this.programs.findIndex(p => p.Id === t.Id) < 0));

        if (title) {
            list = list.filter(t => (t.Title || '').toLowerCase().indexOf(title) > -1);
        }

        return list;
    }

    private appPrograms: ApplicationProgram[] = [];
    private filterTimeout: any;

    ngOnInit() {
        this.route.params.subscribe(params => {
            this.appNumber = params['id'];

            if (this.appNumber) {
                let progLoading = this.app.showLoading();

                this.service.getByNumber(this.appNumber).subscribe(app => {
                    this.appId = app.Id;
                    this.appTitle = app.AdmissionTitle;
                    this.applicationStatus = app.Status;

                    this.admissionService.getById(app.AdmissionId).subscribe(data => {
                        if(data.IsLateSubmission)
                        {
                            this.isLateSubmission = true;
                            this.limit = data.ExtensionPriorityCount ? data.ExtensionPriorityCount : 0;
                        }
                        else
                        {
                            this.limit = data.PriorityCount ? data.PriorityCount : 0;
                        }
                        this.isUnitedRZ = data.IsUnitedRZ;
                        this.isForeign = data.IsForeign;
                    });

                    this.programService.getAvailable(app.AdmissionId).subscribe(data => {
                        this.programs = data;
                        this.app.hideLoading(progLoading);
                    });

                    this.service.getPrograms(app.Id).subscribe(data => {
                        this.appPrograms = data;
                        this.addedPrograms = data.map(t => t.Program);
                    }, err => this.app.showLoadError(err));

                    this.notes = [app.ProgramInfoNote].filter(t => (t || '').length > 0);
                });
            }
        });
    }

    add(program: Program) {
        let loading = this.app.showLoading();
        let specializationId = null;

        if (program.SelectedSpecialization)
            specializationId = program.SelectedSpecialization.Id;

        let universityId = null;

        if (program.SelectedUniversity)
            universityId = program.SelectedUniversity.Id;

        this.service.addProgram(this.appId, program.Id, specializationId, universityId).subscribe(data => {
            Object.assign(program, data);
            this.addedPrograms.push(program);
			this.app.hideLoading(loading);
        }, err => this.app.showLoadError(err));
    }

    remove(program: Program) {
        const statuses = ['Annulled','Canceled','NotSent'];
        if(this.appPrograms.some(x => x.Program.Id === program.Id && 
            (statuses.indexOf(x.ContractStatus) >= 0 || !x.ContractStatus)) || 
            this.appPrograms.every(x => x.Program.Id !== program.Id))
        {
            let loading = this.app.showLoading();
            this.service.removeProgram(this.appId, program.Id).subscribe(result => {
                let index = this.addedPrograms.findIndex(p => p.Id == program.Id);
                this.addedPrograms.splice(index, 1);
                this.app.hideLoading(loading);
            }, err => this.app.showDeleteError(err));
        }
        else {
            this.app.alerts.warning(this.app.translate.instant('application_ContractAlreadyCreatedRemove'));
        }
    }

    canAdd(program: Program): boolean {
        if (this.addedPrograms.length >= this.limit)
            return false;
        else if (program.Specializations && program.Specializations.length > 0)
            return !!program.SelectedSpecialization;
        //else if (program.Universities && program.Universities.length > 0)
        //    return !!program.SelectedUniversity;
        else
            return true;
    }

    isAdded(program: Program) {
        return this.addedPrograms.some(p => p.Id == program.Id);
    }

    selectedSpecialization(program: Program): string {
        let spec = program.SelectedSpecialization;

        if (!spec) {
            let appProgram = this.appPrograms.find(t => t.Program.Id === program.Id);
            spec = appProgram && appProgram.Specialization;
        }

        if (spec)
            return spec.SpecializationName;

        return '';
    }
    selectedUniversity(program: Program): string {
        let uni = program.SelectedUniversity;

        if (!uni) {
            let appProgram = this.appPrograms.find(t => t.Program.Id === program.Id);
            uni = appProgram && appProgram.University;
        }

        if (uni)
            return this.getTranslatedValue(uni);

        return '';
    }
    getTranslatedValue(c: Classifier) {
        if (c) {
            if (this.app.translate.currentLang == 'en' && c.ValueEN)
                return c.ValueEN;
            else
                return c.Value;
        }
        else
            return '';
    }

    checkOnProgramCount() {
        if(this.addedPrograms.length > this.limit)
        {
            let text: string = this.app.translate.instant('applicationPrograms_addedCountIsMoreThanPriority', { availableCount: this.limit, addedCount: this.addedPrograms.length });
            this.app.alerts.warning(text);
        }
        else
        {
            if(this.isProgramAvailabilityCorrect())
            {
                if(this.appNumber)
                {
                    this.app.redirect('/applications/' + this.appNumber);
                }
                else
                {
                    this.app.redirect('/applications');
                }
            }
        }
    }

    isProgramAvailabilityCorrect() : boolean {
        if(this.addedPrograms.some(x => !x.AllowProlongation) && this.isLateSubmission && !this.isForeign)
        {
            let programNames = '';
            this.addedPrograms.forEach(element => {
                if(!element.AllowProlongation)
                {
                    programNames += '<br> &nbsp * ' + element.Title;
                    programNames += ' (' + element.Financing;
                    programNames += ', ' + element.Form + ')';
                }
            });
            programNames += '<br>';
            let text: string = this.app.translate.instant('applicationPrograms_addedProgramIsNotAvailable', {programNames: programNames});
            this.app.alerts.warning(text);
            return false;
        }
        
        return true;
    }
}
