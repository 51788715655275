<header class="content-header">
    <h1 [translate]="'log_title'"></h1>
</header>

<form class="filter-form" #form="ngForm" (submit)="filter()">
    <div class="cols">
        <div class="col-2">
            <mat-form-field>
                <input matInput [matDatepicker]="pickerDate" [(ngModel)]="filterDate" name="filterDate"
                       [placeholder]="'log_lblDate' | translate" [max]="now" required [title]="'dateFormatHint' | translate">
                <mat-datepicker-toggle matSuffix [for]="pickerDate"></mat-datepicker-toggle>
                <mat-datepicker #pickerDate></mat-datepicker>
            </mat-form-field>
        </div>

        <div class="col-1">
            <mat-form-field>
                <input matInput [(ngModel)]="filterTimeFrom" name="filterTimeFrom" pattern="^([01]?[0-9]|2[0-3]):[0-5][0-9]$" 
                       [placeholder]="'log_lblTimeFrom' | translate">
            </mat-form-field>
        </div>

        <div class="col-1">
            <mat-form-field>
                <input matInput [(ngModel)]="filterTimeTo" name="filterTimeTo" pattern="^([01]?[0-9]|2[0-3]):[0-5][0-9]$" 
                       [placeholder]="'log_lblTimeTo' | translate">
            </mat-form-field>
        </div>

        <div class="col-2">
            <mat-form-field>
                <mat-select [placeholder]="'log_lblLevel' | translate" multiple
                            [(ngModel)]="filterLevel" name="filterLevel">
                    <mat-option *ngFor="let o of filterLevelOptions" [value]="o">{{o}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="col-2">
            <mat-form-field>
                <mat-select [placeholder]="'log_lblRequestMethod' | translate" multiple
                            [(ngModel)]="filterRequestMethod" name="filterRequestMethod">
                    <mat-option *ngFor="let o of filterRequestMethodOptions" [value]="o">{{o}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="col-4">
            <mat-form-field>
                <input matInput [(ngModel)]="searchValue" name="searchValue" [placeholder]="'log_lblSearch' | translate" maxlength="255">
            </mat-form-field>
        </div>
    </div>

    <div>
        <button type="submit" mat-raised-button color="primary" [disabled]="!form.valid">
            {{'filter' | translate}}
        </button>
    </div>
</form>

<ngx-datatable #grid
               class="tight"
               [rows]="gridItems"
               [limit]="30"
               [headerHeight]="'auto'"
               [footerHeight]="'auto'"
               [rowHeight]="'auto'"
               [columnMode]="'force'"
               [loadingIndicator]="gridLoading"
               [externalSorting]="true" 
               [messages]="{ emptyMessage: 'gridEmpty' | translate, totalMessage: 'gridTotal' | translate }"
               (sort)="onGridSort($event)">

    <ngx-datatable-row-detail [rowHeight]="'auto'" #detailRow>
        <ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
            {{row.Exception}}
        </ng-template>
    </ngx-datatable-row-detail>

    <ngx-datatable-column [name]="'log_lblDate' | translate" prop="Date">
        <ng-template ngx-datatable-cell-template let-row="row">
            {{row.Date.split('T').join(' ')}}
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [name]="'log_lblMessage' | translate" prop="Message">
        <ng-template ngx-datatable-cell-template let-row="row">
            {{row.Message}}
            <div *ngIf="row.Exception">
                <a href="#" (click)="$event.preventDefault(); toggleRow(row)">{{'log_toggleRow' | translate}}</a>
            </div>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [name]="'log_lblLevel' | translate" prop="Level"></ngx-datatable-column>
    <ngx-datatable-column [name]="'log_lblLogger' | translate" prop="Logger"></ngx-datatable-column>
    <ngx-datatable-column [name]="'log_lblUserName' | translate" prop="UserName"></ngx-datatable-column>
    <ngx-datatable-column [name]="'log_lblRequestUrl' | translate" prop="RequestUrl"></ngx-datatable-column>
    <ngx-datatable-column [name]="'log_lblRequestMethod' | translate" prop="RequestMethod"></ngx-datatable-column>
</ngx-datatable>