import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { UserInfo, UserListItem, UserCrypto, UserType } from '../models/UserInfo';

import { environment as ENV } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

export const userStorageKey: string = 'enroll_user';
export const consentStorageKey: string = 'enroll_consent';

const storageKeys = {
    getAuth: 'rsu_enroll_auth_get_',
    setAuth: 'rsu_enroll_auth_set_',
    endAuth: 'rsu_enroll_auth_end'
};

@Injectable()
export class AuthService {
    constructor(private http: HttpClient) {
        this.storageKey = Date.now().toString();
        this.setupListeners();
    }

    get apiAuthUrl(): string { return `${ENV.apiUrl}/auth` }
    get apiBanklinkUrl(): string { return `${ENV.apiUrl}/banklink` }
    get apiUserUrl(): string { return `${ENV.apiUrl}/users` }

    private userSubject: BehaviorSubject<UserInfo> = new BehaviorSubject<UserInfo>(null);
    user: Observable<UserInfo> = this.userSubject.asObservable();

    get currentUser(): UserInfo {
        return this.userSubject.getValue();
    }

    get sessionKey(): string {
        let user = this.currentUser;
        return user ? user.sessionKey : undefined;
    }

    get isAuthenticated(): boolean {
        return !!this.currentUser;
    }

    private cache: { [key: string]: any } = {};
    private storageKey: string;

    login(email: string, password: string, userCryptoKey: string): Observable<UserInfo> {
        return this.http.post(this.apiAuthUrl + '/actions/login', { email, password, userCryptoKey }).pipe(
            map(response => this.parseUser(response)));
    }

    loginOAuth(token: string, nonce: string, tokenType: string): Observable<{ user?: UserInfo, redirectTo?: string }> {
        return this.http.post(this.apiBanklinkUrl + '/actions/oauth', {
            AccessToken: token,
            State: nonce,
            TokenType: tokenType
        }).pipe(map((data: any) => {
            if (data.RedirectTo) {
                return { redirectTo: data.RedirectTo };
            } else {
                const user = this.parseUser(data);
                return { user };
            }
        }));
    }

    loginAdfs(key: string): Observable<{ user?: UserInfo, redirectTo?: string }> {
        return this.http.post(this.apiAuthUrl + '/actions/adfsconfirm', { ConfirmKey: key })
            .pipe(map((data: any) => {
                if (data.RedirectTo) {
                    return { redirectTo: data.RedirectTo };
                } else {
                    const user = this.parseUser(data);
                    return { user };
                }
            }));
    }

    getCryptoKey(cryptoKey: string): Observable<UserCrypto> {
        return this.http.get<UserCrypto>(this.apiAuthUrl + '/actions/getCryptoKey?cryptoKey=' + cryptoKey);
    }

    restore(email: string, lang: string) {
        return this.http.post(this.apiAuthUrl + '/actions/restore', {
            email,
            language: lang
        });
    }

    getConfirmUserInfo(confirmKey: string): Observable<UserInfo> {
        return this.http.get<UserInfo>(this.apiAuthUrl + '/actions/getconfirm?key=' + confirmKey);
    }

    confirm(password: string, confirmKey: string, userCryptoKey: string): Observable<UserInfo> {
        return this.http.post(this.apiAuthUrl + '/actions/confirm', {
            password,
            confirmKey,
            userCryptoKey
        }).pipe(map((response: any) => {
            const user = this.parseUser(response);
            return user;
        }));
    }

    change(oldPassword: string, newPassword: string, userCryptoKey: string) {
        return this.http.post(this.apiUserUrl + '/actions/update', {
            passwordOld: oldPassword,
            passwordNew: newPassword,
            userCryptoKey
        });
    }

    register(name: string, lastname: string, email: string, lang: string, reCaptchaResponse: string, consent: boolean): any {
        return this.http.post(this.apiUserUrl + '/actions/create', {
            name,
            lastname,
            email,
            accountType: 'Registered',
            language: lang,
            consent: !!consent,
            reCaptchaResponse
        });
    }

    getConsent(): Observable<string> {
        return this.http.get<string>(`${this.apiUserUrl}/consent`);
    }

    checkConsent(): Observable<boolean> {
        return this.http.get<boolean>(`${this.apiUserUrl}/checkConsent`);
    }

    consent(): Observable<any> {
        return this.http.put(`${this.apiUserUrl}/consent`, null);
    }

    parseUser(data: any): UserInfo {
        let user = new UserInfo();

        user.email = data.EMail;
        user.firstName = data.FirstName;
        user.lastName = data.LastName;
        user.personCode = data.PersonCode;
        user.type = data.Type;
        user.accountType = data.AccountType;
        user.sessionKey = data.SessionKey;
        user.universityId = data.UniversityId;
        user.signoutUrl = data.SignoutUrl;

        user.startUrl = this.isDataViewer(user) ? 'applications/search' : 'applications';

        this.setUser(user);

        return user;
    }

    logout() {
        const url = `${this.apiAuthUrl}/actions/logout`;
        return this.http.post(url, {}).pipe(tap(() => {
            this.clearSession();
        }, error => this.clearSession(true)));
    }

    findActiveUsers(options?: any): Observable<UserListItem[]> {
        if (!options)
            options = {};

        if (options.DateFrom)
            options.DateFrom = `${options.DateFrom.getFullYear()}-${options.DateFrom.getMonth() + 1}-${options.DateFrom.getDate()}`;

        if (options.DateTo)
            options.DateTo = `${options.DateTo.getFullYear()}-${options.DateTo.getMonth() + 1}-${options.DateTo.getDate()}`;

        return this.http.get<UserListItem[]>(this.apiUserUrl + '/active', {
            params: options
        });
    }

    update(id: string, user: UserListItem): Observable<UserListItem> {
        return this.http.put<UserListItem>(this.apiUserUrl + '/' + id, user);
    }

    delete(id: string): Observable<boolean> {
        return this.http.delete(this.apiUserUrl + '/' + id).pipe(map((res: Response) => {
            return res.ok;
        }));
    }

    clearSession(clearAllSessions: boolean = false) {
        this.setUser(null);

        if (clearAllSessions) {
            localStorage.setItem(storageKeys.endAuth, '1');
            localStorage.removeItem(storageKeys.endAuth);
        }
    }

    checkLocalContext(key: string): Observable<UserInfo> {
        if (!this.getSession(userStorageKey)) {
            return this.setFromCookie(key);
        } else {
            return of(null);
        }
    }

    setFromCookie(key: string): Observable<UserInfo> {
        return this.checkSession(key).pipe(map(response => {
            let user: UserInfo = null;

            if (response) {
                user = this.parseUser(response);
            } else {
                this.clearSession();
            }

            return user;
        }));
    }

    checkSession(key: string = '') {
        return this.http.get<Response>(this.apiUserUrl + '?key=' + key, { withCredentials: true });
    }

    isApplicant(user?: UserInfo): boolean {
        return this.userIs('applicant', user);
    }

    isAdmin(user?: UserInfo): boolean {
        return this.userIs('administrator', user);
    }

    isPowerUser(user?: UserInfo): boolean {
        return this.userIs('poweruser', user);
    }

    isAdmClerk(user?: UserInfo): boolean {
        return this.userIs('admclerk', user);
    }

    isUnitedEnrollClerk(user?: UserInfo): boolean {
        return this.userIs('unitedenrollmentclerk', user);
    }

    isExamClerk(user?: UserInfo): boolean {
        return this.userIs('examclerk', user);
    }

    isAgent(user?: UserInfo): boolean {
        return this.userIs('agent', user);
    }

    isQuizzUser(user?: UserInfo): boolean {
        return this.userIs('quizzuser', user);
    }

    isAnonymous(user?: UserInfo): boolean {
        return this.userIs('anonymous', user);
    }

    isBankUser(user?: UserInfo): boolean {
        if (!user)
            user = this.currentUser;

        return !user.email;
    }

    isDataViewer(user?: UserInfo): boolean {
        return this.userIs('dataviewer', user);
    }

    userIn(roles: UserType[] | string[] | string, user?: UserInfo): boolean {
        if (typeof roles === 'string')
            roles = roles.split(',');

        for (let i = 0; i < roles.length; i++) {
            if (this.userIs(roles[i], user))
                return true;
        }

        return false;
    }

    userIs(role: UserType | string, user?: UserInfo): boolean {
        if (!user)
            user = this.currentUser;

        if (!user || !role)
            return false;

        return user.type.toLowerCase() === role.toLowerCase();
    }

    removeSession(name) {
        sessionStorage.removeItem(name);
    }

    setSession(name: string, value: string) {
        sessionStorage.setItem(name, value);
    }

    getSession(name: string): string {
        return sessionStorage.getItem(name);
    }

    authenticate(): Observable<UserInfo> {
        if (this.isAuthenticated)
            return of(this.currentUser);

        this.setUser(JSON.parse(this.getSession(userStorageKey)));

        if (this.isAuthenticated)
            return of(this.currentUser);

        return this.requestFromStorage().pipe(map(u => u));
    }

    setUser(user: UserInfo) {
        if (user) {
            this.setSession(userStorageKey, JSON.stringify(user));
            this.removeSession(consentStorageKey);
        } else {
            this.removeSession(userStorageKey);
            this.removeSession(consentStorageKey);
        }

        this.userSubject.next(user);
    }

    isEmployeeThatCanEditGrades(user?: UserInfo): boolean {
        return  this.isUnitedEnrollClerk(user) ||
                this.isAdmin(user) || 
                this.isAgent(user) ||
                this.isAdmClerk(user) ||
                this.isPowerUser(user);

    }

    isEmployeeThatCanSeeContractStatus(user?: UserInfo): boolean {
        return  this.isUnitedEnrollClerk(user) ||
                this.isAdmin(user) || 
                this.isDataViewer(user) ||
                this.isAdmClerk(user) ||
                this.isPowerUser(user);
    }

    private requestFromStorage(): Observable<UserInfo> {
        const subj = new Subject<UserInfo>();
        const getKey = `${storageKeys.getAuth}${this.storageKey}`;

        localStorage.setItem(getKey, '1');
        localStorage.removeItem(getKey);

        setTimeout(() => {
            subj.next(this.isAuthenticated ? this.currentUser : null);
        }, 100);

        return subj.asObservable();
    }

    private setupListeners() {
        window.addEventListener('storage', event => {
            if ((event.key || '').indexOf(storageKeys.getAuth) === 0) {
                const setKey = `${storageKeys.setAuth}${event.key.split('_').pop()}`;
                localStorage.setItem(setKey, this.getSession(userStorageKey));
                localStorage.removeItem(setKey);
            } else if (event.key === `${storageKeys.setAuth}${this.storageKey}`) {
                if (!this.isAuthenticated && event.newValue) {
                    const data = JSON.parse(event.newValue);
                    this.setSession(userStorageKey, event.newValue);
                    this.setUser(data);
                }
            } else if (event.key === storageKeys.endAuth) {
                this.clearSession(false);
            }
        });
    }
}
