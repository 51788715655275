<h2 mat-dialog-title>{{'applications_lblPersonalEmailHistoryTitle' | translate}}</h2>
<mat-dialog-content>
    <table mat-table [dataSource]="emails" multiTemplateDataRows style="width: 100%;">
        <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef class="correctPadding">{{'applications_lblPersonalEmailHistoryTableDate' | translate}}</th>
            <td mat-cell *matCellDef="let email" class="correctPadding">{{email.Date | date: 'dd.MM.yyyy'}}</td>
        </ng-container>
        <ng-container matColumnDef="time">
            <th mat-header-cell *matHeaderCellDef class="correctPadding">{{'applications_lblPersonalEmailHistoryTableTime' | translate}}</th>
            <td mat-cell *matCellDef="let email" class="correctPadding">{{email.Date | date: 'HH:mm'}}</td>
        </ng-container>
        <ng-container matColumnDef="author">
            <th mat-header-cell *matHeaderCellDef class="correctPadding">{{'applications_lblPersonalEmailHistoryTableAuthor' | translate}}</th>
            <td mat-cell *matCellDef="let email" class="correctPadding">{{email.Author}}</td>
        </ng-container>
        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef class="correctPadding">{{'applications_lblPersonalEmailHistoryTableStatus' | translate}}</th>
            <td mat-cell *matCellDef="let email" class="correctPadding">{{'emailStatus_' + email.Status | translate}}</td>
        </ng-container>
        <ng-container matColumnDef="subject">
            <th mat-header-cell *matHeaderCellDef class="correctPadding">{{'applications_lblPersonalEmailHistoryTableSubject' | translate}}</th>
            <td mat-cell *matCellDef="let email" class="correctPadding">{{email.Subject}}</td>
        </ng-container>

        <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let email" [attr.colspan]="columnsToDisplay.length">
                <div class="example-element-detail" [@detailExpand]="email == expandedElement ? 'expanded' : 'collapsed'">
                    <div class="example-element-desctiption" [innerHTML]="email.Body | safeHtml" style="padding:16px;">
                        
                    </div>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let email; columns: columnsToDisplay"
            class="example-element-row"
            [class.example-expanded-row]="expandedElement === email"
            (click)="expandedElement = expandedElement === email ? null : email">
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
    </table>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-raised-button type="button" mat-dialog-close>{{'cancel' | translate}}</button>
</mat-dialog-actions>