<header class="content-header">
    <nav class="breadcrumb">
        <a [routerLink]="['/quizzgroup/'+selectedQuizzQuestion.QuizzGroupId] | localize" class="bc-back">{{'quizzanswer_returnquestion' | translate:selectedQuizzQuestion}}</a>
    </nav>
</header>

<section class="block">
    <header class="block-header">
        <h3>{{'quizzquestionedit_title' | translate}}</h3>
    </header>
    <div class="block-body">
        <form #form="ngForm" (submit)="submitQuizzQuestion()">
            <div class="cols">
                <div class="col-10">
                    <mat-form-field>
                        <input autofocus matInput required name="Text" [placeholder]="'quizzquestion_lblText' | translate" [(ngModel)]="selectedQuizzQuestion.Text" />
                    </mat-form-field>
                </div>

                <div class="col-2">
                    <mat-form-field>
                        <input matInput required name="sortSequence" type="number"
                               min="1"
                               (keydown)="checkNumbers($event)"
                               max="999999"
                               title="{{'valueintervalbeween' | translate:'[1,999999]'}}"
                               step="1" [placeholder]="'quizzquestion_lblNr' | translate" [(ngModel)]="selectedQuizzQuestion.SortSequence" />
                    </mat-form-field>
                </div>
            </div>

            <div class="form-submit">
                <button type="submit" mat-raised-button color="primary"  [disabled]="!form.valid || selectedQuizzQuestion.SortSequence<1 || selectedQuizzQuestion.SortSequence>999999">{{'save' | translate}}</button>
            </div>
        </form>
    </div>
</section>

<section class="block">
    <header class="block-header">
        <h3>{{'quizzquestion_answers' | translate}}</h3>
    </header>
    <div class="block-body">
        <div class="alert alert-info">{{'quizzanswer_notEounghAnswers' | translate}}</div>
        <ngx-datatable #grid
                       [rows]="gridItems"
                       [headerHeight]="'auto'"
                       [footerHeight]="'auto'"
                       [rowHeight]="'auto'"
                       [columnMode]="'force'"
                       [loadingIndicator]="gridLoading"
                       [externalSorting]="true"
                       [messages]="{ emptyMessage: 'gridEmpty' | translate, totalMessage: 'gridTotal' | translate }"
                       (sort)="onGridSort($event)">
            <ngx-datatable-column [name]="'quizzanswer_lblText' | translate" prop="Text">
                <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex" let-value="row.Text">
                    <ng-container *ngIf="!isGridEditing(row)">
                        {{value}}
                    </ng-container>
                    <mat-form-field *ngIf="isGridEditing(row)">
                        <input autofocus
                               matInput
                               required
                               name="Text"
                               [(ngModel)]="row.Text" />
                    </mat-form-field>
                    <mat-error class="text-sm" *ngIf="row.notUnique">{{'quizzquestion_notuniqueanswer' | translate}}</mat-error>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [name]="'quizzanswer_lblPoints' | translate" prop="Points">
                <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex" let-value="row.Points">
                    <ng-container *ngIf="!isGridEditing(row)">
                        {{value}}
                    </ng-container>
                    <mat-form-field *ngIf="isGridEditing(row)">
                        <input required
                               matInput
                               name="Points"
                               type="number"
                               min="0"
                               step=".1"
                               [(ngModel)]="row.Points" />
                    </mat-form-field>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [resizeable]="false">
                <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
                    <button (click)="editGridRow(rowIndex)" class="btn-icon edit-group" *ngIf="!isGridEditing(row)" [title]="'edit' | translate">
                        <i class="fa fa-pencil"></i>
                    </button>
                    <button (click)="saveGridRow(rowIndex)"
                            class="btn-icon"
                            *ngIf="isGridEditing(row)"
                            [disabled]="!isValidGridItem(row)"
                            [title]="'save' | translate">
                        <i class="fa fa-floppy-o"></i>
                    </button>
                    <button (click)="cancelGridRow(rowIndex)" class="btn-icon" *ngIf="isGridEditing(row)" [title]="'cancel' | translate">
                        <i class="fa fa-ban"></i>
                    </button>
                    <button (click)="deleteGridRow(rowIndex)" class="btn-icon" *ngIf="!isNewGridItem(row)" [title]="'delete' | translate">
                        <i class="fa fa-trash"></i>
                    </button>
                </ng-template>
            </ngx-datatable-column>
        </ngx-datatable>
        <div>
            <button mat-raised-button color="primary" (click)="addGridRow()">{{'add' | translate}}</button>
        </div>
    </div>
</section>