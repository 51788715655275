<ng-container *ngIf="!itemType">
    <header class="content-header">
        <h1 [translate]="'classifiers_title'"></h1>
    </header>
    <ul class="bullet-list">
        <li *ngFor="let n of typeList">
            <a [routerLink]="[n.key] | localize">{{n.value}}</a>
        </li>
    </ul>
</ng-container>

<ng-container *ngIf="itemType">
    <header class="content-header">
        <nav class="breadcrumb">
            <a [routerLink]="['/classifiers'] | localize" class="bc-back" [translate]="'classifiers_title'"></a>
        </nav>
        <h1 [translate]="'classifierType_' + itemType"></h1>
    </header>

    <ngx-datatable #grid
                   [rows]="gridItems"
                   [headerHeight]="'auto'"
                   [footerHeight]="'auto'"
                   [rowHeight]="'auto'"
                   [columnMode]="'force'"
                   [loadingIndicator]="gridLoading"
                   [externalSorting]="true"
                   [messages]="{ emptyMessage: 'gridEmpty' | translate, totalMessage: 'gridTotal' | translate }"
                   (sort)="onGridSort($event)">
        <ngx-datatable-column [name]="'classifiers_lblCode' | translate" prop="Code">
            <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex" let-value="row.Code">
                <span *ngIf="!isGridEditing(row)">
                    {{value}}
                </span>
                <mat-form-field *ngIf="isGridEditing(row)">
                    <input autofocus
                           matInput
                           name="code"
                           [(ngModel)]="row.Code"
                           pattern="[a-zA-Z0-9_-]+" />
                </mat-form-field>
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [name]="'classifiers_lblLegacyCode' | translate" prop="LegacyCode">
            <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex" let-value="row.LegacyCode">
                <span *ngIf="!isGridEditing(row)">
                    {{value}}
                </span>
                <mat-form-field *ngIf="isGridEditing(row)">
                    <input matInput
                           name="legacyCode"
                           [(ngModel)]="row.LegacyCode"
                           pattern="[a-zA-Z0-9_-]+" />
                </mat-form-field>
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [name]="'classifiers_lblValue' | translate" prop="ValueLV">
            <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex" let-value="row.ValueLV">
                <span *ngIf="!isGridEditing(row)">
                    {{value}}
                </span>
                <mat-form-field *ngIf="isGridEditing(row)">
                    <input required
                           matInput
                           name="valueLV"
                           [(ngModel)]="row.ValueLV" />
                </mat-form-field>
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [name]="'classifiers_lblValueEN' | translate" prop="ValueEN">
            <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex" let-value="row.ValueEN">
                <span *ngIf="!isGridEditing(row)">
                    {{value}}
                </span>
                <mat-form-field *ngIf="isGridEditing(row)">
                    <input required
                           matInput
                           name="valueEN"
                           [(ngModel)]="row.ValueEN" />
                </mat-form-field>
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [name]="'classifiers_lblPayload' | translate" prop="Payload">
            <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex" let-value="row.Payload">
                <span *ngIf="!isGridEditing(row)">
                    {{value}}
                </span>
                <div *ngIf="isGridEditing(row)">
                    <editor [(ngModel)]="row.Payload" name="Payload" [init]="payloadConfig"></editor>
                </div>
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [resizeable]="false">
            <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
                <button (click)="editGridRow(rowIndex)" class="btn-icon" *ngIf="!isGridEditing(row)" [title]="'edit' | translate">
                    <i class="fa fa-pencil"></i>
                </button>
                <button (click)="saveGridRow(rowIndex)"
                        class="btn-icon"
                        *ngIf="isGridEditing(row)"
                        [disabled]="!isValidGridItem(row)"
                        [title]="'save' | translate">
                    <i class="fa fa-floppy-o"></i>
                </button>
                <button (click)="cancelGridRow(rowIndex)" class="btn-icon" *ngIf="isGridEditing(row)" [title]="'cancel' | translate">
                    <i class="fa fa-ban"></i>
                </button>
                <button (click)="deleteGridRow(rowIndex)" class="btn-icon" *ngIf="!isNewGridItem(row)" [title]="'delete' | translate">
                    <i class="fa fa-trash"></i>
                </button>
            </ng-template>
        </ngx-datatable-column>
    </ngx-datatable>

    <div>
        <button mat-raised-button color="primary" (click)="addGridRow()">
            <i class="fa fa-plus"></i>
            {{'add' | translate}}
        </button>
    </div>
</ng-container>
