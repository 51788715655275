<ng-container *ngIf="showView">
    <header class="content-header">
        <h1 [translate]="'share_listTitle'"></h1>
    </header>

    <form class="filter-form" #form="ngForm" (submit)="filter()">
        <div class="cols">
            <div class="col-2">
                <mat-form-field>
                    <input matInput [(ngModel)]="filterUpn" name="filterUpn"
                           [placeholder]="'share_lblPersonUpn' | translate">
                </mat-form-field>
            </div>

            <div class="col-2">
                <mat-form-field>
                    <input matInput [matDatepicker]="pickerDateFrom" [(ngModel)]="filterDateFrom" name="filterDateFrom"
                           [placeholder]="'share_lblDateFrom' | translate" [title]="'dateFormatHint' | translate">
                    <mat-datepicker-toggle matSuffix [for]="pickerDateFrom"></mat-datepicker-toggle>
                    <mat-datepicker #pickerDateFrom></mat-datepicker>
                </mat-form-field>
            </div>

            <div class="col-2">
                <mat-form-field>
                    <input matInput [matDatepicker]="pickerDateTo" [(ngModel)]="filterDateTo" name="filterDateTo"
                           [placeholder]="'share_lblDateTo' | translate" [title]="'dateFormatHint' | translate">
                    <mat-datepicker-toggle matSuffix [for]="pickerDateTo"></mat-datepicker-toggle>
                    <mat-datepicker #pickerDateTo></mat-datepicker>
                </mat-form-field>
            </div>

            <div class="col-2">
                <div class="mat-align-middle">
                    <button type="submit" mat-raised-button color="primary" [disabled]="!form.valid">
                        {{'filter' | translate}}
                    </button>
                </div>
            </div>
        </div>
    </form>

    <ngx-datatable #grid
                   [rows]="gridItems"
                   [limit]="30"
                   [headerHeight]="'auto'"
                   [footerHeight]="'auto'"
                   [rowHeight]="'auto'"
                   [columnMode]="'force'"
                   [loadingIndicator]="gridLoading"
                   [externalSorting]="true"
                   [messages]="{ emptyMessage: 'gridEmpty' | translate, totalMessage: 'gridTotal' | translate }"
                   (sort)="onGridSort($event)">
        <ngx-datatable-column [name]="'share_lblObjectType' | translate" prop="ObjectType">
            <ng-template ngx-datatable-cell-template let-value="value">
                {{'share_objectType' + value | translate}}
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [name]="'share_lblObjectId' | translate" prop="ObjectId">
            <ng-template ngx-datatable-cell-template let-row="row">
                <a [routerLink]="getObjectUrl(row) | localize">{{row.ObjectId}}</a>
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [name]="'share_lblDateFrom' | translate" prop="DateFrom">
            <ng-template ngx-datatable-cell-template let-value="value">
                {{value | appDate}}
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [name]="'share_lblDateTo' | translate" prop="DateTo">
            <ng-template ngx-datatable-cell-template let-value="value">
                {{value | appDate}}
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [name]="'share_lblPersonUpn' | translate" prop="Upn"></ngx-datatable-column>
        <ngx-datatable-column [name]="'share_lblPersonEmail' | translate" prop="Email"></ngx-datatable-column>
        <ngx-datatable-column [name]="'share_lblPersonFirstName' | translate" prop="FirstName"></ngx-datatable-column>
        <ngx-datatable-column [name]="'share_lblPersonUpLastName' | translate" prop="LastName"></ngx-datatable-column>
        <ngx-datatable-column [resizeable]="false">
            <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
                <button (click)="deleteGridRow(rowIndex)" class="btn-icon" [title]="'delete' | translate">
                    <i class="fa fa-trash"></i>
                </button>
            </ng-template>
        </ngx-datatable-column>
    </ngx-datatable>
</ng-container>