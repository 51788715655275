import { Component, Inject } from '@angular/core';
import { Program } from '../models/Program';
import { ApplicationForContract } from '../models/Application';
import { ClassifierService } from '../services/classifier.service';
import { ProgramService } from '../services/program.service';
import { AppService } from '../services/app.service';
import { Classifier } from '../models/classifier';
import { Utils } from '../Utils';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export class ContractDialogOptions {
    constructor(public program: Program, public applications: ApplicationForContract[]) { }
}

@Component({
    templateUrl: './contract-dialog.component.html'
})
export class ContractDialogComponent {
    constructor(
        private classifierService: ClassifierService,
        private programService: ProgramService,
        public app: AppService,
        public dialogRef: MatDialogRef<ContractDialogOptions>,
        @Inject(MAT_DIALOG_DATA) public data: ContractDialogOptions
    ) {
        this.program = data.program;
        this.applications = data.applications;
        //let appNumbers = data.applications.map(a => a.Number).join(',');
        //this.confirmCreateText = this.app.translate.instant('confirmContractCreate', { appnumbers: appNumbers });
        this.programService.getAdmission(this.program.Id).subscribe(data => {
            if (data.IsRZ || data.IsUnitedRZ)
                this.isRZ = true;

            this.isForeign = data.IsForeign;
        }, err => this.app.showLoadError(err));
        this.classifierService.get('Justification,ContractSigner,ContractDiscount,MedInstitution').subscribe(data => {
            this.contractReasons = data.filter(t => t.Type === 'Justification');
            this.contractSigners = data.filter(t => t.Type === 'ContractSigner');
            this.contractDiscounts = data.filter(t => t.Type === 'ContractDiscount');
            this.medInstitutions = data.filter(t => t.Type === 'MedInstitution');
        });
    }
    medInstitution: Classifier;
    contractSigners: Classifier[] = [];
    contractReasons: Classifier[] = [];
    contractDiscounts: Classifier[] = [];
    contractDiscountValue: Classifier = null;
    specialConditions: string = '';
    specialConditionsEn: string = '';
    medInstitutions: Classifier[] = [];
    isRZ: boolean = false;
    isForeign: boolean;
    program: Program;
    minPaymentDateValue: Date;
    warnings: string = null;

    //confirmCreateText: string = '';
    //appNumbers: string;
    get isBudget(): boolean {
        if (this.program && this.program.FinancingCode
            && this.program.FinancingCode == 'BGT')
            return true;
        return false;
    }
    public onMinPaymentDate(event): void {
        this.minPaymentDateValue = Utils.ensureDate(event.value);
    }
    applications: ApplicationForContract[];
    get applicationsWithStudiesAtLaterStages(): ApplicationForContract[] {
        return this.applications.filter(a => { return a.StudiesAtLaterStages });
    }
    get applicationsWithoutStudiesAtLaterStages(): ApplicationForContract[] {
        return this.applications.filter(a => { return !a.StudiesAtLaterStages });
    }
    get hasForeign(): boolean {
        return (this.applications.filter(a => { return a.IsForeign }).length>0 ? true:false);
    }
    laterStagesConfirmed: boolean = false;
    get hasLaterStages(): boolean {
        return (!this.laterStagesConfirmed && this.applicationsWithStudiesAtLaterStages.length > 0 ? true : false);
    }
    get hasWithoutLaterStages(): boolean {
        return (this.applicationsWithoutStudiesAtLaterStages.length > 0 ? true : false);
    }
    getAppLink(number: string) {
        //['/applications', '${number}'] | localize
        let route = this.app.localize.translateRoute(`/applications/${number}`);
        return `<a href="${route}" target="_blank">${number}</a>`;
    }
    isCreated: boolean = false;
    continueWithContract() {
        this.laterStagesConfirmed = true;
    }

    private _signer : Classifier;
    private _reason : Classifier;

    createContract(signerCtrl?: any, reasonCtrl?: any, force?: boolean) {
        const signer: Classifier = force ? this._signer : (signerCtrl && signerCtrl.value) ? signerCtrl.value : null;
        const reason: Classifier = force ? this._reason : (reasonCtrl && reasonCtrl.value) ? reasonCtrl.value : null;
        const discount: Classifier = this.contractDiscountValue;
        const applicationIds: number[] = this.applications.filter(a => { return !a.StudiesAtLaterStages }).map(a => { return a.Id });
        const loading = this.app.showLoading();

        this.programService.createContracts(
            this.program.Id,
            applicationIds,
            {
                signerId: signer ? signer.Id : undefined,
                reasonId: reason ? reason.Id : undefined,
                discountId: discount ? discount.Id : undefined,
                minPaymentDate: this.minPaymentDateValue,
                medInstitutionId: this.medInstitution ? this.medInstitution.Id : undefined,
                specialConditions: this.specialConditions,
                specialConditionsEn: this.specialConditionsEn
            },
            force
        ).subscribe(response => {
            this.app.hideLoading(loading);
            if (response) {
                this.warnings = response;
                this._signer = signer;
                this._reason = reason;
            }
            else {
                this.isCreated = true;
                this.dialogRef.close(true);
            }
        }, err => {
            this.app.hideLoading(loading);
            this.app.showError(err);
            this.dialogRef.close(false);
        });
    }

    onDiscountChange(event) {
        this.contractDiscountValue = event;
        this.setSpecialConditions();
    }

    onMedInstitutionChange() {
        this.setSpecialConditions();
    }

    getTranslatedValue(c: Classifier) {
        if (c) {
            if (this.app.translate.currentLang == 'en' && c.ValueEN)
                return c.ValueEN;
            else
                return c.Value;
        }
        else
            return '';
    }

    private setSpecialConditions() {
        const workplaceTemplate = this.app.translate.instant('contract_specialConditionsWorkplace');
        this.specialConditions = ((!this.isForeign && this.contractDiscountValue ? this.getTranslatedValue(this.contractDiscountValue) : '')
            + ' ' + (this.medInstitution ? workplaceTemplate.replace('{{workplace}}', this.getTranslatedValue(this.medInstitution)) : '')).trim();
    }
}
