import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { IApplicationBalanceInfo } from "../models/Application";
import { AppService } from "../services/app.service";
import { ApplicationService } from "../services/application.service";

const cache: { [id: number]: { data?: IApplicationBalanceInfo[], error?: string } } = {};

@Component({
    templateUrl: './balance-info.component.html',
    styles: ['.is-bold { font-weight: bold }']
})
export class BalanceInfoDialogComponent implements OnInit {
    constructor(
        private app: AppService,
        private applicationService: ApplicationService,
        @Inject(MAT_DIALOG_DATA) data: {
            applicationId: number
        }
    ) {
        this.applicationId = data?.applicationId;
    }

    data: IApplicationBalanceInfo[] = [];
    noData: boolean;
    error: string;

    get isLoading() {
        return this.app.isLoading();
    }

    private readonly applicationId: number;

    ngOnInit() {
        if (this.applicationId) {
            const cached = cache[this.applicationId];

            if (cached) {
                this.data = cached.data || [];
                this.error = cached.error;
                this.noData = this.data.length == 0;
            } else {
                const loading = this.app.showLoading();
                this.applicationService.getBalance(this.applicationId).subscribe(data => {
                    this.app.hideLoading(loading);
                    this.data = data || [];
                    this.noData = this.data.length == 0;

                    cache[this.applicationId] = { data };
                }, err => {
                    this.app.hideLoading(loading);
                    this.error = this.app.getHttpResponseError(err);

                    cache[this.applicationId] = { error: this.error };
                });
            }
        }
    }
}
