import { Component, OnInit, Input } from '@angular/core';
import { Observable, zip } from 'rxjs';

import { AppService } from '../services/app.service';
import { ApplicationService } from '../services/application.service';

import { Application, ApplicationEducation, ApplicationAuxData, ApplicationPersonDocument } from '../models/Application';

import { Utils } from '../Utils';

@Component({
    selector: 'app-application-person-view',
    templateUrl: './person-view.component.html',
    styleUrls: ['./person.component.css']
})
export class PersonViewComponent implements OnInit {
    constructor(
        public app: AppService,
        private service: ApplicationService
    ) { }

    @Input() item: Application = new Application();

    educations: ApplicationEducation[] = [];
    photoUrl: string;
    isEditor: boolean;
    personDocuments: ApplicationPersonDocument[] = [];
    aux: any = {
        Languages: [],
        ClaimDocs: []
    };

    ngOnInit() {
        if (!this.item || !this.item.Id)
            return;

        if (this.item.PhotoTimestamp) {
            this.photoUrl = this.service.getPhotoUrl(this.item.Id, this.item.PhotoTimestamp);
        }

        if (!this.item.DocType)
            this.item.DocType = 'Passport';

        let loading = this.app.showLoading();

        this.app.auth.user.subscribe(user => {
            this.isEditor = this.app.auth.isAdmin() || this.app.auth.isPowerUser() || this.app.auth.isExamClerk();
        });

        zip(
            this.service.getAuxData(this.item.Id),
            this.service.getEducations(this.item.Id),
            this.service.getPersonDocuments(this.item.Id)
        ).subscribe(data => {
            this.aux = this.parseAuxData(data[0]);
            this.educations = data[1];
            this.personDocuments = data[2];

            this.app.hideLoading(loading);
        }, err => this.app.showLoadError(err));
    }

    private parseAuxData(data: ApplicationAuxData[]): any {
        if (!data)
            data = [];

        let getByType = (type: string): ApplicationAuxData => {
            return data.find(t => t.Type === type);
        };

        let getText = (type: string, textProperty?: number): string => {
            let d = getByType(type);
            return d ? textProperty ? d['Text' + textProperty] : d.Data : undefined;
        };

        let getBool = (type: string, textProperty?: number): boolean => {
            return getText(type, textProperty) === 'true';
        };

        return {
            Languages: data.filter(t => t.Type === 'Language').map(t => {
                return {
                    Language: t.Data,
                    Level: t.Text1,
                    Cert: t.Text2,
                    Score: t.Text3
                }
            }),
            OtherDocs: getText('OtherDocs')
        };
    }
}
