export enum ClassifierType {
    UserRole = 'UserRole',
    UserType = 'UserType',
    StudyLevel = 'StudyLevel',
    StudyForm = 'StudyForm',
    StudyDirection = 'StudyDirection',
    StudyLocation = 'StudyLocation',
    //StudyDegree = 'StudyDegree',
    Faculty = 'Faculty',
    Financing = 'Financing',
    Gender = 'Gender',
    ApplicationStatus = 'ApplicationStatus',
    ProgramResult = 'ProgramResult',
    ApplicantStatus = 'ApplicantStatus',
    TargetAudience = 'TargetAudience',
    ContractStatus = 'ContractStatus',
    Justification = 'Justification',
    ContractSigner = 'ContractSigner',
    TestType = 'TestType',
    Country = 'Country',
    DocIssuer = 'DocIssuer',
    Grade5 = 'Grade5',
    Grade10 = 'Grade10',
    EduInstitution = 'EduInstitution',
    MedInstitution = 'MedInstitution',
    ContractDiscount = 'ContractDiscount',
    EducationLevel = 'EducationLevel',
    LanguageCertificate = 'LanguageCertificate',
    FooterLv1='Footer-lv-1',
    FooterLv2 ='Footer-lv-2',
    FooterLv3 ='Footer-lv-3',
    FooterLv4 ='Footer-lv-4',
    FooterEn1 ='Footer-en-1',
    FooterEn2 ='Footer-en-2',
    FooterEn3 ='Footer-en-3',
    FooterEn4 = 'Footer-en-4',
    OrderDvsStatus = 'OrderDvsStatus',
    ContractDvsStatus = 'ContractDvsStatus',
    OrderAttachmentDvsStatus = 'OrderAttachmentDvsStatus',
    ApplicationFilesDvsConfiguration = 'ApplicationFilesDvsConfiguration',
    ReflectantDataDvs = 'ReflectantDataDvs',
    ReferenceSignatureType = 'ReferenceSignatureType',
    ReferenceSigner = 'ReferenceSigner',
    ASNContractTerminationReason = 'ASNContractTerminationReason',
    CEMapping = 'CEMapping',
    EducationAcquiredIn = 'EducationAcquiredIn',
    IntervierRoom = 'InterviewRoom',
    ImatriculationOrderSigner = 'ImatriculationOrderSigner',
    FileName = 'FileName'
}

export class Classifier {
    Id: string;
    Code: string;
    LegacyCode: string;
    Type: string;
    Value: string;
    ValueEN: string;
	ValueLV: string;
    Payload: string;
}
