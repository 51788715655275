import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';

import { AppService } from '../services/app.service';
import { ApplicationService } from '../services/application.service';

import { Utils } from '../Utils';
import { UserType } from '../models/UserInfo';
import { ParameterService } from '../services/parameter.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    templateUrl: './register.component.html'
})
export class RegisterApplicationComponent implements OnInit {
    constructor(
        public dialogRef: MatDialogRef<RegisterApplicationComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { admissionId: number },
        public app: AppService,
        private service: ApplicationService,
        private paramService : ParameterService,
        private router: Router
    ) {
        this.admissionId = data.admissionId;
    }

    admissionId: number;
    personCode: string;
    personCodeReadOnly: boolean;
    email: string;
    conflicts: any[] = [];
    applicationNumber: string = null;
    isAdmin: boolean;
    showApplicationIdField: boolean;

    @ViewChild('form', { static: false }) form: NgForm;

    ngOnInit(): void {
        this.personCode = this.app.auth.currentUser.personCode;
        this.isAdmin = this.app.auth.currentUser.type === UserType.Administrator;
        this.personCodeReadOnly = this.app.auth.currentUser.accountType === 'OpenAuth';
        this.paramService.getValues().subscribe(values => {
            this.showApplicationIdField = values.some(x => x.Code === 'AllowToTakeData' && x.Value === '1');
        });
    }

    get isValid(): boolean {
        if(this.showApplicationIdField)
            return this.conflicts.length > 0 || (this.form && this.form.valid && !!(this.personCode || this.email || this.applicationNumber));
        else
            return this.conflicts.length > 0 || (this.form && this.form.valid && !!(this.personCode || this.email));
    }

    get personCodeConflicts(): any[] {
        const pcLower = (this.ensuredPersonCode || '').toLowerCase();
        return this.conflicts.filter(t => (t.PersonCode || '').toLowerCase() === pcLower);
    }

    get emailConflicts(): any[] {
        const emailLower = (this.email || '').toLowerCase();
        return this.conflicts.filter(t => (t.Email || '').toLowerCase() === emailLower);
    }

    get ensuredPersonCode(): string {
        return Utils.ensurePersonCode(this.personCode);
    }

    submit() {
        let loading = this.app.showLoading();
        if(this.showApplicationIdField && this.applicationNumber) {
            this.personCode = "";
            this.email = "";
        }
        this.service.create(this.admissionId, this.personCode, this.email, this.conflicts.length > 0, this.applicationNumber).subscribe(data => {
            this.app.hideLoading(loading);
            if (data.Conflicts && data.Conflicts.length) {
                this.conflicts = data.Conflicts;
            } else {
                this.dialogRef.close();
                this.go(data.Number);
            }
        }, err => {
            if(this.showApplicationIdField)
                this.app.showCopyError(err);
            else
                this.app.showSaveError(err);
        } 
        );
    }

    private go(appNumber: string) {
        this.router.navigate([this.app.localize.translateRoute(`/applications/${appNumber}/person`)], {
            queryParams: { returnTo: 'applications' }
        });
    }
}
