import { Directive, Input } from '@angular/core';
import {
    ReactiveFormsModule,
    NG_VALIDATORS,
    FormsModule,
    FormGroup,
    UntypedFormControl,
    ValidatorFn,
    Validator
} from '@angular/forms'; 

@Directive({
    selector: '[yearvalidator][ngModel]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: YearValidator,
            multi: true
        }
    ]
})
export class YearValidator implements Validator {
    constructor() {
        this.validator = this.yearValidator();
    }

    @Input() minYear: number;
    @Input() maxYear: number;

    validator: ValidatorFn;
    
    validate(c: UntypedFormControl) {
        return this.validator(c);
    }

    yearValidator(): ValidatorFn {
        return (c: UntypedFormControl) => {
            if (!c.value)
                return null;

            let valid = false;
            let year = parseInt(c.value);

            if ((c.value + '').length === 4
                && year + '' == c.value
                && (!this.minYear || year >= this.minYear)
                && (!this.maxYear || year <= this.maxYear)
            ) {
                valid = true;
            }

            if (valid) {
                return null;
            } else {
                return {
                    yearvalidator: {
                        valid: false
                    }
                };
            }
        }
    }
}