<header class="content-header">
    <nav class="breadcrumb">
        <a [routerLink]="['/admissions', admissionId] | localize" class="bc-back">
            {{admissionTitle}}
        </a>
    </nav>
    <h1>{{programTitle}}</h1>
</header>

<div class="block">
    <header class="block-header">
        <h3 [translate]="'exams_title'"></h3>
        <aside>
            {{'exams_lblMaxTotalGrade' | translate}}: {{getMaxTotalGrade()}}
        </aside>
    </header>
    <div class="block-body">
        <ngx-datatable #grid
                       class="tight"
                       [rows]="gridItems"
                       [headerHeight]="'auto'"
                       [footerHeight]="50"
                       [rowHeight]="'auto'"
                       [columnMode]="'flex'"
                       [limit]="100"
                       [externalSorting]="true"
                       [loadingIndicator]="gridLoading"
                       [messages]="{ emptyMessage: 'gridEmpty' | translate, totalMessage: 'gridTotal' | translate }"
                       (sort)="onGridSort($event)">
            <ngx-datatable-column [name]="'exams_lblTitle' | translate" prop="Code" [flexGrow]="1"></ngx-datatable-column>
            <ngx-datatable-column [name]="'exams_lblDate' | translate" prop="Date" [flexGrow]="1">
                <ng-template ngx-datatable-cell-template let-row="row">
                    {{row.Date | appDate}}
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [name]="'exams_lblDescription' | translate" prop="Description" [flexGrow]="4"></ngx-datatable-column>
            <ngx-datatable-column [name]="'exams_lblReusable' | translate" prop="Reusable" [flexGrow]="1">
                <ng-template ngx-datatable-cell-template let-row="row">
                    <i class="fa fa-check" *ngIf="row.Reusable"></i>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [name]="'exams_lblIsDeciding' | translate" prop="IsDeciding" [flexGrow]="1">
                <ng-template ngx-datatable-cell-template let-row="row">
                    <i class="fa fa-check" *ngIf="row.IsDeciding"></i>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [name]="'exams_lblType' | translate" prop="Type" [flexGrow]="2"></ngx-datatable-column>
            <ngx-datatable-column [name]="'exams_lblUrl' | translate" prop="Url" [flexGrow]="1">
                <ng-template ngx-datatable-cell-template let-row="row">
                    <a href="{{row.Url}}" target="_blank" [title]="'exams_lblUrl' | translate" *ngIf="row.Url">
                        <i class="fa fa-external-link"></i>
                    </a>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [name]="'exams_lblCodes' | translate" prop="ExamCodes" [flexGrow]="1"></ngx-datatable-column>
            <ngx-datatable-column [name]="'exams_lblGroup' | translate" prop="Group" [flexGrow]="1"></ngx-datatable-column>
            <ngx-datatable-column [name]="'exams_lblMinGrade' | translate" prop="MinGrade" [comparator]="gridNumberComparator" [flexGrow]="1"></ngx-datatable-column>
            <ngx-datatable-column [name]="'exams_lblMaxGrade' | translate" prop="MaxGrade" [comparator]="gridNumberComparator" [flexGrow]="1"></ngx-datatable-column>
            <ngx-datatable-column [name]="'exams_lblSortOrder' | translate" prop="SortOrder" [comparator]="gridNumberComparator" [flexGrow]="1"></ngx-datatable-column>
            <ngx-datatable-column [name]="'exams_lblWeight' | translate" prop="Weight" [comparator]="gridNumberComparator" [flexGrow]="1"></ngx-datatable-column>
            <ngx-datatable-column [name]="'exams_lblGradeRoundPositions' | translate" prop="GradeRoundPositions" [comparator]="gridNumberComparator" [flexGrow]="1"></ngx-datatable-column>
        </ngx-datatable>
    </div>
</div>