import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-autocomplete',
    template: `
    <mat-form-field>
        <input type="text" [placeholder]="placeholder" [value]="text || ''" (keyup)="onTextChange($event, input.value)" matInput
            [matAutocomplete]="auto" #input name="auto" [disabled]="disabled" [required]="required" [attr.maxlength]="maxlength"
            (blur)="onBlur()">
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="onOptionSelect($event)" [displayWith]="display">
            <mat-option *ngFor="let option of options" [value]="option">
                {{display(option)}}
            </mat-option>
        </mat-autocomplete>
        <mat-icon matSuffix *ngIf="isValid">check</mat-icon>
    </mat-form-field>
    `
})
export class AutocompleteComponent {
    @Input() value: any;
    @Input() text: string;
    @Input() options: any[] = [];
    @Input() display: (option: any) => string = option => option;
    @Input() placeholder: string;
    @Input() disabled: boolean;
    @Input() required: boolean;
    @Input() maxlength: number = 512;
    @Input() debounce: number = 250;

    @Output() textChange: EventEmitter<string> = new EventEmitter<string>();
    @Output() selected: EventEmitter<any> = new EventEmitter<any>();
    @Output() blur: EventEmitter<any> = new EventEmitter<any>();

    get isValid(): boolean {
        return !!this.value;
    }

    private timeoutId?: number;

    onTextChange(event, term: string) {
        if (event.keyCode === 13)
            return;

        if (term != this.text) {
            if (this.timeoutId) window.clearTimeout(this.timeoutId);
            this.timeoutId = window.setTimeout(() => {
                this.textChange.emit(term || '');
            }, this.debounce);
        }
    }

    private blurTimeoutId?: number;

    onBlur() {
        if (this.blurTimeoutId) window.clearTimeout(this.blurTimeoutId);
        this.blurTimeoutId = window.setTimeout(() => {
            this.blur.emit();
        }, 500);
    }

    onOptionSelect(event) {
        if (this.blurTimeoutId) window.clearTimeout(this.blurTimeoutId);
        this.selected.emit(event.option.value);
    }
}
