<main #main *ngIf="authDone">
    <div class="body">
        <app-header></app-header>

        <mat-progress-bar mode="query" class="progress-bar" *ngIf="isLoading"></mat-progress-bar>
        <div class="app-curtain" *ngIf="isLoading"></div>

        <div class="page-content">
            <div class="container">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>

    <app-footer></app-footer>
</main>
