<section class="block">
    <header class="block-header">
        <h3>{{'quizzintervals_title' | translate}}</h3>
    </header>
    <div class="block-body">
        <div class="cols">
            <div class="col-5" *ngIf="quizz">
                <mat-radio-group [ngModel]="quizz.IsAvailableInIntervalsOnly" (change)="setIsAvailableInIntervalsOnly($event)">
                    <mat-radio-button [value]="false">{{'quizzintervals_allowwithoutinterval' | translate}}</mat-radio-button>
                    <mat-radio-button [value]="true">{{'quizzintervals_allowininterval' | translate}}</mat-radio-button>
                </mat-radio-group>
            </div>
            <div class="col-5">
                <mat-checkbox [ngModel]="showOnlyActual" (change)="setShowOnlyActual($event)" name="showOnlyActual"
                              [value]="showOnlyActual" class="mat-align-top">
                        {{'quizzintervals_showonlyactual' | translate}}
                </mat-checkbox>
            </div>
        </div>
        <ngx-datatable #grid
                       [rows]="gridItems"
                       [headerHeight]="'auto'"
                       [footerHeight]="'auto'"
                       [rowHeight]="'auto'"
                       [columnMode]="'force'"
                       [loadingIndicator]="gridLoading"
                       [externalSorting]="true"
                       [messages]="{ emptyMessage: 'gridEmpty' | translate, totalMessage: 'gridTotal' | translate }"
                       (sort)="onGridSort($event)">
            <ngx-datatable-column [name]="'quizzinterval_datefromlabel' | translate" prop="DateFrom">
                <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex" let-value="row.DateFrom">
                    <span *ngIf="!isGridEditing(row)">
                        {{value | appDate}}
                    </span>
                    <mat-form-field *ngIf="isGridEditing(row)">
                        <input matInput [matDatepicker]="pickerDateFrom" [(ngModel)]="row.DateFrom" name="DateFrom"
                               required  [max]="row.DateTo" [title]="'quizzinterval_datefromlabel' | translate">
                        <mat-datepicker-toggle matSuffix [for]="pickerDateFrom"></mat-datepicker-toggle>
                        <mat-datepicker #pickerDateFrom></mat-datepicker>
                    </mat-form-field>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [name]="'quizzinterval_timefromlabel' | translate" prop="TimeFrom">
                <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex" let-value="row.TimeFrom">
                    <span *ngIf="!isGridEditing(row)">
                        {{value}}
                    </span>
                    <mat-form-field *ngIf="isGridEditing(row)">
                        <input matInput
                               required
                               name="TimeFrom"
                               [(ngModel)]="row.TimeFrom"
                               pattern="([01]?[0-9]|2[0-3]):[0-5][0-9]" />
                    </mat-form-field>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [name]="'quizzinterval_datetolabel' | translate" prop="DateTo">
                <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex" let-value="row.DateTo">
                    <span *ngIf="!isGridEditing(row)">
                        {{value | appDate}}
                    </span>
                    <mat-form-field *ngIf="isGridEditing(row)">
                        <input matInput [matDatepicker]="pickerDateTo" [(ngModel)]="row.DateTo" name="DateTo"
                                required [min]="row.DateFrom" [title]="'quizzinterval_datetolabel' | translate">
                        <mat-datepicker-toggle matSuffix [for]="pickerDateTo"></mat-datepicker-toggle>
                        <mat-datepicker #pickerDateTo></mat-datepicker>
                    </mat-form-field>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [name]="'quizzinterval_timetolabel' | translate" prop="TimeTo">
                <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex" let-value="row.TimeTo">
                    <span *ngIf="!isGridEditing(row)">
                        {{value}}
                    </span>
                    <mat-form-field *ngIf="isGridEditing(row)">
                        <input matInput
                               required
                               name="TimeTo"
                               [(ngModel)]="row.TimeTo"
                               pattern="([01]?[0-9]|2[0-3]):[0-5][0-9]" />
                    </mat-form-field>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [resizeable]="false">
                <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
                    <button (click)="editGridRow(rowIndex)" class="btn-icon" *ngIf="!isGridEditing(row)" [title]="'edit' | translate">
                        <i class="fa fa-pencil"></i>
                    </button>
                    <button (click)="saveGridRow(rowIndex)"
                            class="btn-icon"
                            *ngIf="isGridEditing(row)"
                            [disabled]="!isValidGridItem(row)"
                            [title]="'save' | translate">
                        <i class="fa fa-floppy-o"></i>
                    </button>
                    <button (click)="cancelGridRow(rowIndex)" class="btn-icon" *ngIf="isGridEditing(row)" [title]="'cancel' | translate">
                        <i class="fa fa-ban"></i>
                    </button>
                    <button (click)="deleteGridRow(rowIndex)" class="btn-icon" *ngIf="!isNewGridItem(row)" [title]="'delete' | translate">
                        <i class="fa fa-trash"></i>
                    </button>
                </ng-template>
            </ngx-datatable-column>
        </ngx-datatable>

        <div>
            <button mat-raised-button color="primary" (click)="addGridRow()">
                <i class="fa fa-plus"></i>
                {{'add' | translate}}
            </button>
        </div>
    </div>
</section>
