<header class="content-header">
    <nav class="breadcrumb">
        <a [routerLink]="['/admissions',admissionId] | localize" class="bc-back">{{admissionTitle}}</a>
    </nav>
    <h1 class="clearfix">
        <span>{{programTitle}}</span>
    </h1>
</header>

<app-interviewersGrid></app-interviewersGrid>

<app-interviewpersonnel></app-interviewpersonnel>
