import { Component, OnInit } from '@angular/core';
import { AppService } from '../../services/app.service';
import { QuizzService } from '../../services//quizz.service';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

import { GridComponentBase } from '../../GridComponentBase';
import { Quizz, QuizzGroup } from '../../models/Quizz';

@Component({
    selector: 'app-quizzeditor-edit',
    templateUrl: './quizzeditor-edit.component.html',
    styleUrls: ['./quizzeditor-edit.component.css']
})
export class QuizzeditorEditComponent extends GridComponentBase<QuizzGroup> implements OnInit {

    selectedQuizz: Quizz=new Quizz();

    constructor(public app: AppService, private service: QuizzService, private route: ActivatedRoute) {
        super(app);
    }

    ngOnInit() {
        if (this.app.auth.isAuthenticated) {
            this.app.setWideLayout();
            this.route.params.subscribe(params => {
                let loading = this.app.showLoading();
                let id = params['id'];
                if (id) {
                    this.service.getById(id).subscribe(data => {
                        if (data) {
                            if (data.QuizzGroups)
                                this.gridItems = data.QuizzGroups;
                            this.selectedQuizz = data;
                            this.app.hideLoading(loading);
                        }
                    }, err => this.app.showLoadError(err));
                }
            });
        }
    }
    submitQuizz() {
        let loading = this.app.showLoading();
        this.service.update(this.selectedQuizz).subscribe(res => {
            this.app.hideLoading(loading);
        }, err => this.app.showSaveError(err));
    }
    checkNumbers(e: any) {
        if (!((e.keyCode > 95 && e.keyCode < 106)
            || (e.keyCode > 47 && e.keyCode < 58)
            || e.keyCode == 8)) {
            return false;
        }
    }
    isValidGridItem(item: QuizzGroup): boolean {
        if (item.QuestionCount && item.QuestionCount>-1 && item.Title) {
            if (this.gridItems.filter(g => g.Title == item.Title).length == 1) {
                item.notUnique = false;
                this.gridItems.filter(g => g.notUnique).forEach((i: QuizzGroup) => {
                    i.notUnique = false;
                });
                return true;
            }
            else {
                item.notUnique = true;
                this.gridItems.filter(g => g.Title == item.Title).forEach((i: QuizzGroup) => {
                    i.notUnique = true;
                });
                return false;
            }
        }
        else
            return false;
    }

    protected createGridItem(item: QuizzGroup): Observable<QuizzGroup> {
        return this.service.addGroup(this.selectedQuizz.Id,item);
    }

    protected updateGridItem(item: QuizzGroup): Observable<QuizzGroup> {
        return this.service.updateGroup(item);
    }
    protected deleteGridItem(item: QuizzGroup): Observable<boolean> {
        return this.service.deleteGroup(item.Id);
    }

    protected createEmptyObject(): QuizzGroup {
        let item = new QuizzGroup();

        return item;
    }

    
}
