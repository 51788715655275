import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthorizeInterceptor implements HttpInterceptor {
    constructor(private auth: AuthService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const selectedLang = location.pathname.indexOf('/en') > -1 ? 'en' : 'lv';
        const sessionKey = this.auth.isAuthenticated ? this.auth.sessionKey : '';

        const req = request.clone({
            setHeaders: {
                AppSelectedLang: selectedLang,
                SessionKey: sessionKey
            },
            withCredentials: true
        });

        return next.handle(req).pipe(
            catchError((error) => {
                return throwError(error);
            })
        );
    }
}
