<header class="content-header">
    <h1 [translate]="'admissions_title'"></h1>
</header>

<p *ngIf="isAdmin">
    <button mat-raised-button (click)="addAdmission()" color="primary">
        <i class="fa fa-plus"></i>
        {{'add' | translate}}
    </button>
</p>

<form class="filter-form" #form="ngForm" (submit)="filter()">
    <div class="cols">
        <div class="col-3">
            <mat-checkbox [(ngModel)]="filterOnlyActual" name="filterOnlyActual" (change)="filter()">
                {{'admissions_lblShowOnlyActual' | translate}}
            </mat-checkbox>
        </div>
    </div>
</form>

<ngx-datatable #grid
               [rows]="gridItems"
               [headerHeight]="'auto'"
               [footerHeight]="50"
               [rowHeight]="'auto'"
               [columnMode]="'force'"
               [externalSorting]="true"
               [loadingIndicator]="gridLoading"
               [sorts]="[{ prop: 'DateFrom', dir: 'desc' }]"
               [messages]="{ emptyMessage: 'gridEmpty' | translate, totalMessage: 'gridTotal' | translate }"
               (sort)="onGridSort($event)">
    <ngx-datatable-column [name]="'admissions_lblTitle' | translate" prop="Title">
        <ng-template ngx-datatable-cell-template let-row="row" let-value="row.Title">
            <span *ngIf="!isGridEditing(row)">
                {{value}}
            </span>
            <input autofocus
                   required
                   *ngIf="isGridEditing(row)"
                   name="title"
                   [(ngModel)]="row.Title" />
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [name]="'admissions_lblLevel' | translate" prop="Level"></ngx-datatable-column>
    <ngx-datatable-column [name]="'admissions_lblDateFrom' | translate" prop="DateFrom">
        <ng-template ngx-datatable-cell-template let-row="row">
            {{row.DateFrom | appDate}}
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [name]="'admissions_lblDateTo' | translate" prop="DateTo">
        <ng-template ngx-datatable-cell-template let-row="row">
            {{row.DateTo | appDate}}
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [name]="'admissions_lblTargetAudience' | translate" prop="TargetAudience"></ngx-datatable-column>
    <ngx-datatable-column>
        <ng-template ngx-datatable-cell-template let-row="row">
            <a [routerLink]="['/admissions/' + row.Id] | localize" class="btn-icon" [title]="'edit' | translate">
                <i class="fa fa-pencil"></i>
            </a>
            &nbsp;
        </ng-template>
    </ngx-datatable-column>
</ngx-datatable>

<p *ngIf="isAdmin">
    <button mat-raised-button (click)="addAdmission()" color="primary">
        <i class="fa fa-plus"></i>
        {{'add' | translate}}
    </button>
</p>