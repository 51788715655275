<header class="content-header">
    <nav class="breadcrumb">
        <a [routerLink]="['/applications', application.Number] | localize" class="bc-back">
            {{'applications_edit_titlePrefix' | translate}}
            "{{application.AdmissionTitle}}", {{application.Number}}
        </a>
    </nav>
</header>

<form #form="ngForm" (submit)="submit()">
    <div class="form-submit form-submit-top">
        <button type="button" (click)="submit()" mat-raised-button color="primary">
            {{'applications_prevUni_save' | translate}}
        </button>
    </div>

    <section class="block">
        <header class="block-header">
            <h1 [translate]="'applications_prevUni'"></h1>
        </header>

        <div class="block-body">
            <label class="group-label">{{'applications_prevUni_lblSelectedPrograms' | translate}}</label>
            <div class="field-group">
                <div class="help-block" [innerHTML]="'applications_prevUni_programCheckboxRequired' | translate | safeHtml"></div>
                <div *ngFor="let n of programs">
                    <mat-checkbox (change)="onSelectProgram(n)" name="selectedPrograms[]" 
                                  [value]="n.Id" [checked]="isProgramSelected(n)">
                        {{n.Program.Title}}
                    </mat-checkbox>
                </div>
            </div>

            <div class="cols">
                <div class="col-6">
                    <mat-form-field>
                        <input matInput [(ngModel)]="item.Name" name="name" [placeholder]="'applications_prevUni_lblName' | translate" required maxlength="250">
                    </mat-form-field>
                </div>

                <div class="col-6">
                    <mat-form-field>
                        <input matInput [(ngModel)]="item.Website" name="website" [placeholder]="'applications_prevUni_lblWebsite' | translate" required maxlength="500">
                    </mat-form-field>
                </div>
            </div>

            <mat-form-field>
                <input matInput [(ngModel)]="item.Program" name="program" [placeholder]="'applications_prevUni_lblProgram' | translate" required maxlength="500">
            </mat-form-field>

            <label class="group-label">{{'applications_prevUni_lblProgramDesc' | translate}}</label>

            <div class="cols">
                <div class="col-3">
                    <div class="field-group">
                        <div class="help-block">{{'applications_prevUni_checkboxRequired' | translate}}</div>
                        <div>
                            <mat-checkbox (change)="toggleInArray($event.source.value, item.DegreeTypes)" name="selectedDegreeTypes[]" 
                                          [value]="'Professional'" [checked]="inArray('Professional', item.DegreeTypes)">
                                {{'applications_prevUni_degreeTypeProfessional' | translate}}
                            </mat-checkbox>
                        </div>
                        <div>
                            <mat-checkbox (change)="toggleInArray($event.source.value, item.DegreeTypes)" name="selectedDegreeTypes[]" 
                                          [value]="'Academic'" [checked]="inArray('Academic', item.DegreeTypes)">
                                {{'applications_prevUni_degreeTypeAcademic' | translate}}
                            </mat-checkbox>
                        </div>
                    </div>
                </div>

                <div class="col-3">
                    <div class="field-group">
                        <div class="help-block">{{'applications_prevUni_checkboxRequired' | translate}}</div>
                        <div>
                            <mat-checkbox (change)="toggleInArray($event.source.value, item.DegreeLevels)" name="selectedDegreeLevels[]" 
                                          [value]="'Bachelor'" [checked]="inArray('Bachelor', item.DegreeLevels)">
                                {{'applications_prevUni_degreeLevelBachelor' | translate}}
                            </mat-checkbox>
                        </div>
                        <div>
                            <mat-checkbox (change)="toggleInArray($event.source.value, item.DegreeLevels)" name="selectedDegreeLevels[]" 
                                          [value]="'Master'" [checked]="inArray('Master', item.DegreeLevels)">
                                {{'applications_prevUni_degreeLevelMaster' | translate}}
                            </mat-checkbox>
                        </div>                        
                    </div>
                </div>
            </div>

            <div class="cols">
                <div class="col-2">
                    <mat-form-field>
                        <input matInput [(ngModel)]="item.DateFrom" name="dateFrom" #dateFromInput [placeholder]="'applications_prevUni_lblDateFrom' | translate"
                               [matDatepicker]="dateFromPicker" [title]="'dateFormatHint' | translate" required readonly
                               [min]="minStudyDate" [max]="item.DateTo || maxStudyDate" (click)="dateToPicker.open()">
                        <mat-datepicker-toggle matSuffix [for]="dateFromPicker"></mat-datepicker-toggle>
                        <mat-datepicker #dateFromPicker></mat-datepicker>
                    </mat-form-field>
                </div>

                <div class="col-2">
                    <mat-form-field>
                        <input matInput [(ngModel)]="item.DateTo" name="dateTo" #dateToInput [placeholder]="'applications_prevUni_lblDateTo' | translate"
                               [matDatepicker]="dateToPicker" [title]="'dateFormatHint' | translate" required readonly
                               [min]="item.DateFrom || minStudyDate" [max]="maxStudyDate" (click)="dateToPicker.open()">
                        <mat-datepicker-toggle matSuffix [for]="dateToPicker"></mat-datepicker-toggle>
                        <mat-datepicker #dateToPicker></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>

            <div class="cols">
                <div class="col-4">
                    <mat-form-field>
                        <input matInput [(ngModel)]="item.CreditPoints" name="creditPoints" [placeholder]="'applications_prevUni_lblCreditPoints' | translate" required maxlength="50">
                    </mat-form-field>
                </div>
            </div>

            <mat-form-field>
                <textarea matInput [(ngModel)]="item.Documents" name="documents" matTextareaAutosize 
                          [placeholder]="'applications_prevUni_lblDocuments' | translate" required maxlength="2000"></textarea>
            </mat-form-field>

            <div>
                <mat-checkbox [(ngModel)]="item.DocumentsConfirmed" name="documentsConfirmed">
                    <span style="white-space: normal">
                        {{'applications_prevUni_lblDocumentsConfirmed' | translate}}
                    </span>
                </mat-checkbox>
            </div>

            <div>
                <mat-checkbox [(ngModel)]="item.TransferRulesConfirmed" name="transferRulesConfirmed">
                    <span style="white-space: normal" [innerHTML]="'applications_prevUni_lblTransferRulesConfirmed' | translate | formatString:transferRulesUrl | safeHtml"></span>
                </mat-checkbox>
            </div>
        </div>
    </section>

    <div class="form-submit">
        <button type="button" (click)="submit()" mat-raised-button color="primary">
            {{'applications_prevUni_save' | translate}}
        </button>
    </div>
</form>
