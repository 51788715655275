<div class="restore-container" *ngIf="emailAuthEnabled">
    <form name="form" class="block form-centered" (ngSubmit)="f.form.valid && processSubmit()" #f="ngForm" novalidate autocomplete="off">
        <header class="block-header">
            <h1 [translate]="'changepassword'" *ngIf="type=='change'"></h1>
            <h1 [translate]="'restorepassword'" *ngIf="type=='restore'"></h1>
            <h1 [translate]="'setpassword'" *ngIf="type=='confirm'"></h1>
        </header>
        <div class="block-body">
            <div class="error-container" *ngIf="restoreError!=''">
                <mat-error>{{restoreError}}</mat-error>
            </div>
            <div class="error-container" *ngIf="wrongConfirmLink">
                <span [innerHTML]="'expiredconfirmlink' | translate | safeHtml"></span>
            </div>
            <div class="success-container" *ngIf="restoreSuccess!=''">
                <span>{{restoreSuccess}}</span>
            </div>
            <div *ngIf="!wrongConfirmLink">
                <div *ngIf="restoreSuccess==''">
                    <div class="form-row" *ngIf="type=='restore'">
                        <mat-form-field>
                            <input matInput type="email" name="email" [(ngModel)]="emailValue" #email="ngModel"
                                   [placeholder]="'emaillabel' | translate" required email />
                            <mat-error *ngIf="f.submitted && email && !email.valid">{{'emailerror' | translate}}</mat-error>
                        </mat-form-field>
                    </div>

                    <div class="form-row" *ngIf="type=='change'">
                        <mat-form-field>
                            <input matInput autocomplete="off" type="password" name="oldpassword" [(ngModel)]="oldPasswordValue" [placeholder]="'oldpassword' | translate" required />
                        </mat-form-field>
                    </div>

                    <div class="form-row" *ngIf="type!='restore'">
                        <mat-form-field>
                            <input matInput autocomplete="off" [type]="newPasswordVisible ? 'text' : 'password'" name="newPassword" [(ngModel)]="newPasswordValue" 
                                   [placeholder]="'newpassword' | translate" #newPassword title="{{'password_requirementsnotmet' | translate}}"  
                                   pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&amp;[\\&quot;\]#>^_%`'{(|)}~+:,;-&lt;.=\/])[A-Za-z\d$@$!%*?&amp;[\\&quot;\]#>^_%`'{(|)}~+:,;-&lt;.=\/]{9,16}$" required />
                            <mat-icon matSuffix class="icon-btn" (click)="newPasswordVisible = !newPasswordVisible">{{newPasswordVisible ? 'visibility_off' : 'visibility'}}</mat-icon>
                            <mat-error *ngIf="newPasswordValue && !newPassword.valid">{{'passworderror' | translate}}</mat-error>
                        </mat-form-field>
                    </div>

                    <div class="form-row" *ngIf="type!='restore'">
                        <mat-form-field>
                            <input matInput autocomplete="off" [type]="newPasswordRepeatVisible ? 'text' : 'password'" name="newPasswordRepeat" [(ngModel)]="newPasswordRepeatValue" 
                                   [placeholder]="'newpasswordagain' | translate" #newPasswordRepeat title="{{'password_requirementsnotmet' | translate}}" required
                                   [pattern]="newPasswordRepeatValue && newPasswordRepeatValue != newPasswordValue ? '$^' : '.*'" />
                            <mat-icon matSuffix class="icon-btn" (click)="newPasswordRepeatVisible = !newPasswordRepeatVisible">{{newPasswordRepeatVisible ? 'visibility_off' : 'visibility'}}</mat-icon>
                            <mat-error *ngIf="newPasswordRepeatValue && !newPasswordRepeat.valid">{{'passwordsDontMatch' | translate}}</mat-error>
                        </mat-form-field>
                    </div>

                    <div class="help-block bg-warning text-left" *ngIf="type === 'change' || type === 'confirm'" [innerHTML]="'passwordrequirements' | translate | safeHtml"></div>
                </div>
                <div class="form-submit">
                    <button mat-raised-button color="primary" type="submit" [disabled]="loading" *ngIf="restoreSuccess=='' && type=='change'">{{'changepassword' | translate}}</button>
                    <button mat-raised-button color="primary" type="submit" [disabled]="loading" *ngIf="restoreSuccess=='' && type=='restore'">{{'restorepassword' | translate}}</button>
                    <button mat-raised-button color="primary" type="submit" [disabled]="loading" *ngIf="restoreSuccess=='' && type=='confirm' && this.confirmKey!=''">{{'setpassword' | translate}}</button>
                    <button mat-raised-button color="primary" type="button" [disabled]="loading" (click)="finish()" *ngIf="restoreSuccess!=''">{{'lblOk' | translate}}</button>
                </div>
            </div>
        </div>
    </form>
</div>