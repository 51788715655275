<p>
    <mat-checkbox [(ngModel)]="notEmpty" (change)="filterEmpty()">{{'applicants_filterEmpty' | translate}}</mat-checkbox>
</p>

<ngx-datatable #grid
               *ngIf="admission"
               class="tight"
               [rows]="gridItems"
               [headerHeight]="'auto'"
               [footerHeight]="50"
               [rowHeight]="'auto'"
               [columnMode]="'force'"
               [limit]="100"
               [externalSorting]="true"
               [loadingIndicator]="gridLoading"
               [messages]="{ emptyMessage: 'gridEmpty' | translate, totalMessage: 'gridTotal' | translate }"
               (sort)="onGridSort($event)">
    <ngx-datatable-column [name]="'applicants_lblApplicationId' | translate" prop="ApplicationNumber">
        <ng-template ngx-datatable-cell-template let-row="row">
            <a [routerLink]="['/applications', row.ApplicationNumber] | localize" [queryParams]="{ returnTo: 'admission' }">{{row.ApplicationNumber}}</a>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [name]="'applicants_lblName' | translate" prop="Name"></ngx-datatable-column>
    <ngx-datatable-column [name]="'applicants_lblSurname' | translate" prop="Surname"></ngx-datatable-column>
    <ngx-datatable-column [name]="' '" [flexGrow]="0.5">
        <ng-template ngx-datatable-cell-template let-row="row">
            <i class="fa fa-info-circle icon-btn" (click)="openContacts(row)"></i>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [name]="'applicants_lblPersonCode' | translate" prop="PersonCode"></ngx-datatable-column>
	<ngx-datatable-column [name]="'applicants_lblPriorityCount' | translate" prop="PriorityCount"></ngx-datatable-column>
    <ngx-datatable-column [name]="'applicants_lblAdmissionResult' | translate" prop="AdmissionResult">
        <ng-template ngx-datatable-cell-template let-row="row">
            {{'admissionResult_' + row.AdmissionResult | translate}}
        </ng-template>
    </ngx-datatable-column>
	<ngx-datatable-column [name]="'applicants_lblResultProgram' | translate" prop="ResultProgram"></ngx-datatable-column>
    <ngx-datatable-column [name]="'applicants_lblFinancing' | translate" prop="Financing"></ngx-datatable-column>
    <ngx-datatable-column [name]="'applicants_lblContractStatus' | translate" prop="ContractStatus">
        <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
            <span *ngIf="!statusesLoaded">{{'applicants_statusesLoading' | translate}}</span>
            <span *ngIf="statusesLoaded">{{value}}</span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [name]="'applicants_lblStatus' | translate" prop="Status">
        <ng-template ngx-datatable-cell-template let-row="row">
            {{'applicationStatus_' + row.Status | translate}}
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [name]="'applicants_lblUnitedRZStatus' | translate" prop="UnitedRZStatus" *ngIf="admission.IsUnitedRZ">
        <ng-template ngx-datatable-cell-template let-row="row">
            {{'applicationUnitedRZStatus_' + row.UnitedRZStatus | translate}}
        </ng-template>
    </ngx-datatable-column>
</ngx-datatable>
