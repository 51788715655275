import { DateTime } from 'luxon'

export enum InterviewStatus {
    NotStarted = 'NotStarted',
    Absent = 'Absent',
    InProgress = 'InProgress',
    Completed = 'Completed',
}

export class ScheduledDate {
    Date: string;
    Count: number;
}

export class ScheduledInterviewItem {
    applicationExamId: number;
    interviewerId?: number;
    scheduledTime: DateTime | string;
    availableFrom: DateTime | string;
    programName: string;
    applicantName: string;
    roomTitle: string;
    meetingUrl: string;
    status: InterviewStatus;
    statusDetails?: { InterviewerName: string, Status: InterviewStatus }[]
}

export class AdmissionInterviewSummary {
    ProgramId: number;
    ProgramTitle: string;
    ExamId: number;
    ExamCode: string;
    Counts: { [status: string]: number };
}

export class ApplicationInterviewSummary {
    ApplicationExamId: number;
    ApplicationNumber: string;
    ApplicationId: number;
    Name: string;
    Surname: string;
    PersonCode: string;
    Phone: string;
    Email: string;
    RoomId: string;
    RoomName?: string;
    InterviewOnlineUrl: string;
    InterviewTime?: DateTime;
    ScheduleConflicts?: InterviewScheduleConflict[];
    AverageGrade?: number;
    InvitationMessageCode: string;
    InterviewResults: {
        [InterviewerId: number]: {
            Status: InterviewStatus,
            TotalGrade?: number,
        }
    };
    isAbsent?: boolean;
    canReschedule?: boolean;
}

export class InterviewScheduleConflict {
    MyId: number;
    OtherId: number;
    ConflictKind: string;
    ConflictingTime: DateTime;
    ConflictingProgramTitle: string;
    ConflictingExamTitle: string;
}

export class AnswerOption {
    Text: string;
    Points: number;
}

export class QuestionnaireEntry {
    Text: string;
    Description?: string;
    IsHighlighted?: boolean;
    Background?: string;

    Code?: string;
    AnswerSet?: string;

    Children: QuestionnaireEntry[];
}

export class Questionnaire {
    Code: string;
    Name: string;

    AnswerSets: {
        [key: string]: AnswerOption[]
    };

    Entries: QuestionnaireEntry[];
}

export class InterviewData {
    ApplicantName: string;
    ExamId: number;
    ExamTitle: string;
    ProgramId: number;
    ProgramTitle: string;
    InterviewOnlineUrl: string;
    QuestionnaireCode: string;
    Status: InterviewStatus;
    SelectedGrades: {
        [entryCode: string]: number
    };
    TotalGrade: number;
    ReadOnly: boolean;
    MotivationLetters: { Title: string, Url: string } [];
}
