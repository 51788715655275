<form #form="ngForm" (ngSubmit)="submit()">
    <h2 mat-dialog-title [translate]="label"></h2>
    <mat-dialog-content>
        <app-application-notes
            [applicationId]="applicationId"
            [position]="position"
            [single]="true"
            [canEdit]="false"
            [notes]="notes">
        </app-application-notes>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button mat-raised-button type="submit" color="primary">{{'ok' | translate}}</button>
    </mat-dialog-actions>
</form>
