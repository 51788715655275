import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Territory } from '../models/Territory';
import { environment as ENV } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

/**
 * Territory service
 */
@Injectable()
export class TerritoryService {
    constructor(public http: HttpClient) { }

    public get apiUrl(): string { return `${ENV.apiUrl}/territories` }

    get(type?: string, parentCode?: string): Observable<Territory[]> {
        return this.http.get<Territory[]>(this.apiUrl, {
            params: {
                type: type,
                parentCode: parentCode
            }
        });
    }

    getById(id: number): Observable<Territory> {
        const url = `${this.apiUrl}/${id}`;
        return this.http.get<Territory>(url);
    }

    getByCode(code: string): Observable<Territory> {
        const url = `${this.apiUrl}/getByCode(${code})`;
        return this.http.get<Territory>(url);
    }
}
