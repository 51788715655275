import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Application } from 'src/app/models/Application';
import { Classifier } from 'src/app/models/Classifier';
import { AppService } from 'src/app/services/app.service';
import { ClassifierService } from 'src/app/services/classifier.service';

@Component({
    selector: 'app-address',
    templateUrl: './address.component.html',
    styleUrls: ['./address.component.css']
})
export class AddressComponent implements OnChanges, OnInit {

    constructor(
        private classifierService: ClassifierService,
        private app: AppService,
    ) { }

    @Input() item: Application
    @Input() prefix: string;
    @Input() lblTitle: string;
    @Input() sameAsLabel: string;
    @Input() sameAsPrefix: string;
    @Input() readonly: boolean

    address: AddressAdapter;

    private countries: Classifier[];
    filteredCountries: Observable<Classifier[]>;
    selectedCountry: Classifier;
    countryControl = new UntypedFormControl();

    get isSameAsOther(): boolean {
        if (!this.item || !this.sameAsPrefix) return false;
        return !!this.item[this.prefix + 'IsSameAs' + this.sameAsPrefix];
    }
    set isSameAsOther(value: boolean) {
        if (!this.item || !this.sameAsPrefix) return;
        if (this.item[this.prefix + 'IsSameAs' + this.sameAsPrefix] !== value) {
            this.item[this.prefix + 'IsSameAs' + this.sameAsPrefix] = value;
            if (!value)
                this._setInitialCountry();
        }
    }

    isAnyAddress: boolean = true;
    showDetails: boolean = false;

    displayClassifier = this.app.displayClassifier;

    private get _prefix(): string {
        return this.isSameAsOther ? this.sameAsPrefix : this.prefix;
    }

    private _get(fieldName: string): string {
        return this.item[this._prefix + fieldName];
    }

    private _set(fieldName: string, value: string): void {
        this.item[this._prefix + fieldName] = value;
    }


    ngOnChanges(changes: SimpleChanges) {

        if (changes['item'] && this.item) {

            this.showDetails = !this._get('CountryId') && !this.readonly;

            if (!this.countries) {
                this.classifierService.get('Country').subscribe(
                    countries => {
                        this.countries = countries;
                        this._setInitialCountry();
                    },
                    err => this.app.showLoadError(err));
            }
            else {
                this._setInitialCountry();
            }

        }
        if (changes['readonly'] && this.item) {
            this.showDetails = !this.readonly
        }
    }

    private _setInitialCountry() {
        const countryId = this._get('CountryId');
        const country = !!countryId
            ? this.countries.find(c => c.Id === countryId)
            : this.item.IsForeign
                ? null
                : this.countries.find(c => c.Code === 'LV');
        this._setSelectedCountry(country);
        this.countryControl.setValue(country);
    }

    ngOnInit() {
        this.filteredCountries = this.countryControl.valueChanges
            .pipe(
                startWith(''),
                map((value: any) => (!value || typeof value === 'string') ? value : value.value),
                map((filter: string) => this._filterCountries(filter)),
            );
    }

    private _filterCountries(filter: string): Classifier[] {
        if (filter) filter = filter.toLowerCase();
        return this.countries ? this.countries.filter(c => !filter || this.displayClassifier(c).toLowerCase().startsWith(filter)) : [];
    }

    onCountrySelected(event: MatAutocompleteSelectedEvent) {
        this._setSelectedCountry(event.option.value);
    }

    private _setSelectedCountry(country: Classifier) {
        this.selectedCountry = country;
        this.isAnyAddress = this._isAnyAddress();
        this.address = new AddressAdapter(this.prefix, this.item, this.isAnyAddress);
        if (!country || this._get('CountryId') !== country.Id) {
            this._set('CountryId', country ? country.Id : null);
            this._set('Country',  country ? country.Value : null);
            this.address.clear();
        }
    }

    private _isAnyAddress(): boolean {
        return !this.item || this.item.IsForeign || !this.selectedCountry || this.selectedCountry.Code !== 'LV';
    }

    get shortAddress(): string {
        const country = this._get('Country');
        const addrText = !!this.address ? this.address.AddressText : '';
        return [addrText, country].filter(s => !!s).join(", ");
    }
}

/** Adresses inside country */
export interface Address {
    County: string;
    CountyId: string;
    City: string;
    CityId: string;
    Parish: string;
    ParishId: string;
    Village: string;
    VillageId: string;
    Street: string;
    StreetId: string;
    House: string;
    HouseId: string;
    Apartment: string;
    ApartmentId: string;
    PostCode: string;
    PostCodeId: string;
    AddressText: string;
}

class AddressAdapter implements Address {

    constructor(
        private prefix: string,
        private app: Application,
        private isAny: boolean,
    ){}

    protected _get = (fieldName: string): string => this.app[this.prefix + fieldName];
    protected _set = (fieldName: string, value: string) : void => { this.app[this.prefix + fieldName] = value; };

    get County(): string { return this._get('County') };
    set County(value: string) { this._set('County', value) };

    get CountyId(): string { return this._get('CountyId') };
    set CountyId(value: string) { this._set('CountyId', value) };

    get City(): string { return this._get('City') };
    set City(value: string) { this._set('City', value) };

    get CityId(): string { return this._get('CityId') };
    set CityId(value: string) { this._set('CityId', value) };

    get Parish(): string { return this._get('Parish') };
    set Parish(value: string) { this._set('Parish', value) };

    get ParishId(): string { return this._get('ParishId') };
    set ParishId(value: string) { this._set('ParishId', value) };

    get Village(): string { return this._get('Village') };
    set Village(value: string) { this._set('Village', value) };

    get VillageId(): string { return this._get('VillageId') };
    set VillageId(value: string) { this._set('VillageId', value) };

    get Street(): string { return this._get('Street') };
    set Street(value: string) { this._set('Street', value) };

    get StreetId(): string { return this._get('StreetId') };
    set StreetId(value: string) { this._set('StreetId', value) };

    get House(): string { return this._get('House') };
    set House(value: string) { this._set('House', value) };

    get HouseId(): string { return this._get('HouseId') };
    set HouseId(value: string) { this._set('HouseId', value) };

    get Apartment(): string { return this._get('Apartment') };
    set Apartment(value: string) { this._set('Apartment', value) };

    get ApartmentId(): string { return this._get('ApartmentId') };
    set ApartmentId(value: string) { this._set('ApartmentId', value) };

    get PostCode(): string { return this._get('Index') };
    set PostCode(value: string) { this._set('Index', value) };

    get PostCodeId(): string { return this._get('IndexId') };
    set PostCodeId(value: string) { this._set('IndexId', value) };

    get AddressText(): string {
        let result = this._get('Text');
        if (!result)
            result = this.buldAddressText();
        return result;
    }
    set AddressText(value: string) { this._set('Text', value) };

    protected buldAddressText() : string {
        if (this.isAny)
            return [this.County, this.City, this.Parish, this.Village, this.Street, this.House, this.Apartment, this.PostCode].filter(s => !!s).join(', ')
        let streetHouseAndApartment = '';
        if (this.Street)
            streetHouseAndApartment = [this.Street, this.House].filter(s => !!s).join(' ');
        else if (this.House)
            streetHouseAndApartment = `"${this.House}"`;
        if (this.Apartment)
            streetHouseAndApartment = `${streetHouseAndApartment} - ${this.Apartment}`;
        return [streetHouseAndApartment, this.Village, this.Parish, this.City, this.County, this.PostCode].filter(s => !!s).join(', ');
    }

    clear() : void {
        this.County = this.CountyId =
        this.City = this.CityId =
        this.Parish = this.ParishId =
        this.Village = this.VillageId =
        this.Street = this.StreetId =
        this.House = this.HouseId =
        this.Apartment = this.ApartmentId =
        this.PostCode = this.PostCodeId = null;
    }
}
