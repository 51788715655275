import { Component, Input } from '@angular/core';
import { PersonalEmailToApplicantModel } from '../models/Application';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { AppService } from '../services/app.service';

export interface PersonalEmail {
    date: Date;
    time: Date;
    author: string;
    status: string;
    subject: string;
}

@Component({
    selector: 'personal-email-history-table',
    templateUrl: 'personal-email-history-table.component.html',
    styleUrls: ['personal-email-history-table.css'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0', borderStyle: 'none' })),
            state('expanded', style({ height: '*', marginBottom: '2%' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class PersonalEmailHistoryTableComponent {
    @Input() emails: PersonalEmailToApplicantModel[];
    constructor(public app: AppService) { }
    readonly columnsToDisplay: string[] = ['date', 'time', 'author', 'status', 'subject'];
    expandedElement: PersonalEmail | null;

}
