import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    templateUrl: './send-emails-confirm.component.html',
    styleUrls: ['./admissions.component.css']
})
export class SendEmailsConfirmDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<SendEmailsConfirmDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.body = data.body;
        this.subject = data.subject;
        this.canEditBody = data.canEditBody;
    }

    body: string;
    subject: string;
    canEditBody: boolean = false;

    get result() {
        return {
            subject: this.subject,
            body: this.body
        };
    }
}
