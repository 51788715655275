import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler, LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RecaptchaModule, RECAPTCHA_LANGUAGE } from 'ng-recaptcha';
import { ImageCropperModule } from 'ngx-image-cropper';
import { environment } from '../environments/environment';

import localeLv from '@angular/common/locales/lv';
registerLocaleData(localeLv, environment.defaultLanguage);

import { Location } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LocalizeRouterModule, LocalizeParser, LocalizeRouterSettings } from '@gilsdav/ngx-translate-router';
import { LocalizeRouterHttpLoader } from '@gilsdav/ngx-translate-router-http-loader';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';

import { MaterialModule } from './material.module';
import { EditorModule } from '@tinymce/tinymce-angular';

import { MomentUtcDateAdapter } from './MomentUtcDateAdapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

const MY_DATE_FORMATS = {
    parse: {
        dateInput: 'DD.MM.YYYY'
    },
    display: {
        dateInput: 'DD.MM.YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY'
    },
};

import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { RouterModule } from '@angular/router';

import { AppErrorHandler } from './AppErrorHandler';
import { AuthorizeInterceptor } from './core/AuthorizeInterceptor';
import { routes } from './app.routes';

import { SafeHtmlPipe } from './shared/safe-html.pipe';
import { AppDatePipe, AppTimePipe } from './shared/date.pipe';
import { FormatStringPipe } from './shared/format-string.pipe';

import { YearValidator } from './shared/year.validator';
import { NgxDataTableColumnFlexGrowModifier } from './shared/ngx-datatable-column-flexGrow.directive';

import { LogService } from './services/log.service';
import { AlertService } from './services/alert.service';
import { ParameterService } from './services/parameter.service';
import { ClassifierService } from './services/classifier.service';
import { TerritoryService } from './services/territory.service';
import { AuthService } from './services/auth.service';
import { AdmissionService } from './services/admission.service';
import { ProgramService } from './services/program.service';
import { ExamService } from './services/exam.service';
import { AppService } from './services/app.service';
import { ReportService } from './services/report.service';
import { ApplicationService } from './services/application.service';
import { BanklinkService } from './banklink/banklink.service';
import { ShareService } from './services/share.service';
import { InterviewService } from './services/interview.service';

import { FormFieldDisplayComponent } from './shared/form-field-display.component';
import { FormFieldFileComponent } from './shared/form-field-file.component';
import { AutocompleteComponent } from './shared/autocomplete.component';
import { MessageAutocompleteComponent } from './shared/message-autocomplete.component';

import { DeactivateGuardService } from './CanDeactivateGuard';
import { AppComponent } from './app.component';
import { AlertDialogComponent } from './shared/alert-dialog.component';
import { ConfirmDialogComponent } from './shared/confirm-dialog.component';
import { ContractDialogComponent } from './shared/contract-dialog.component';
import { EnrollmentDialogComponent } from './shared/enrollment-dialog.component';

import { LoginComponent } from './login/login.component';
import { ClassifiersComponent } from './classifiers/classifiers.component';
import { ParametersComponent } from './parameters/parameters.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { ApplicationsComponent } from './applications/applications.component';
import { RegisterApplicationComponent } from './applications/register.component';
import { ApplicationEditComponent } from './applications/application-edit.component';
import { ConfirmPersonLinkDialogComponent } from './applications/confirm-person-link.component';
import { PhotoEditDialogComponent as AppPhotoEditDialogComponent } from './applications/person-edit.component';
import { CamPhotoDialogComponent as AppCamPhotoDialogComponent } from './applications/person-edit.component';
import { ApplicationNotesComponent } from './applications/notes.component';
import { ProgramAddComponent as AppProgramAddComponent } from './applications/program-add.component';
import { PersonComponent as AppPersonComponent } from './applications/person.component';
import { PersonEditComponent as AppPersonEditComponent } from './applications/person-edit.component';
import { PersonViewComponent as AppPersonViewComponent } from './applications/person-view.component';
import { ApplicationSearchComponent } from './applications/search.component';
import { SignEdocDialogComponent } from './applications/sign-edoc.component';
import { IndexComponent as AppPreviousUniversityComponent } from './applications/previous-university/index.component';
import { ViewComponent as AppPreviousUniversityViewComponent } from './applications/previous-university/view.component';
import { EditComponent as AppPreviousUniversityEditComponent } from './applications/previous-university/edit.component';
import { AuxFilesComponent } from './applications/aux-files.component';
import { RegisterComponent } from './register/register.component';
import { AdmissionsComponent } from './admissions/admissions.component';
import { AdmissionAddComponent } from './admissions/admission-add.component';
import { AdmissionEditComponent } from './admissions/admission-edit.component';
import { MyInterviewsComponent } from './interviews/my-interviews.component';
import { ProgramsComponent as AdmissionProgramsComponent } from './admissions/programs.component';
import { ProgramEditComponent as AdmissionProgramEditComponent } from './admissions/program-edit.component';
import { ResultsComponent as AdmissionResultsComponent } from './admissions/results.component';
import { AdmissionInterviewsComponent } from './interviews/admission-interviews.component';
import { ResultAlgorithmComponent as AdmissionResultsAlgorithmComponent } from './admissions/result-algorithm.component';
import { ApplicantsComponent as AdmissionApplicantsComponent } from './admissions/applicants.component';
import { SendEmailsConfirmDialogComponent as AdmissionSendEmailsConfirmDialogComponent } from './admissions/send-emails-confirm.component';
import { OrdersComponent as AdmissionOrdersComponent } from './admissions/orders.component';
import { AccountComponent } from './account/account.component';
import { TerritoriesComponent } from './territories/territories.component';
import { BanklinkComponent } from './banklink/banklink.component';
import { RegisterComponent as BankLinkRegisterComponent } from './banklink/register.component';
import { ProgramResultsComponent } from './programs/results.component';
import { ResultNoteComponent } from './programs/result-note.component';
import { ProgramExamsComponent } from './programs/exams.component';
import { QuizzeditorComponent } from './quizzeditor/list/quizzeditor-list.component';
import { QuizzeditorEditComponent } from './quizzeditor/editquizz/quizzeditor-edit.component';
import { QuizzService } from './services/quizz.service';
import { QuizzgroupComponent } from './quizzgroup/quizzgroup.component';
import { QuizzquestionComponent } from './quizzquestion/quizzquestion.component';
import { MessagesComponent } from './messages/messages.component';
import { MessagesService } from './messages/messages.service';
import { UsersComponent } from './users/users.component';
import { LogComponent } from './log/log.component';
import { IntervalsComponent } from './quizzeditor/editquizz/intervals/intervals.component';
import { ApplicationSchedulesComponent } from './applications/application-schedules/application-schedules.component';
import { ApplicationSchedulesPaymentsComponent } from './applications/application-schedules/application-schedules-payments.component';
import { CamPhotoComponent } from './shared/cam-photo/cam-photo.component';
import { TemplatesComponent } from './templates/templates.component';
import { TemplateComponent } from './templates/template.component';
import { TemplateService } from './services/template.service';
import { ContactsDialogComponent } from './shared/contacts-dialog.component';
import { ShareComponent } from './share/share.component';
import { SharedItemsComponent } from './share/shared-items.component';
import { SharedGoComponent } from './share/shared-go.component';
import { ShareButtonDirective } from './share/share-button.directive';
import { MessageCodeValidator } from './shared/message-autocomplete.component';
import { InterviewersComponent } from './programs/interviewers/interviewers.component';
import { InterviewersGridComponent } from './programs/interviewerGrid/interviewersgrid.component';
import { ProgramContentWrapperComponent } from './programs/program-content-wrapper.component';

import { ApplicationHistoryComponent } from './applications/history/application.component';
import { ApplicationEducationsHistoryComponent } from './applications/history/educations.component';
import { ApplicationExamsHistoryComponent } from './applications/history/exams.component';
import { InterviewHistoryComponent } from './interviews/interview-history.component';
import { ApplicationProgramsHistoryComponent } from './applications/history/programs.component';
import { ApplicationPersonDocumentsHistoryComponent } from './applications/history/person-documents.component';
import { ProgramInterviewsComponent } from './interviews/program-interviews.component';
import { InterviewPersonnelComponent } from './programs/interviewpersonnel/interviewpersonnel.component';
import { InterviewEditComponent } from './interviews/interview-edit.component';
import { ApplicationNoteComponent } from './applications/application-note-universal/app-note-universal.component';
import { AddressComponent } from './applications/address/address.component';
import { LvAddressFormComponent } from './applications/address/lv-address-form.component';
import { PersonalEmailHistoryComponent } from './applications/personal-email-history.component';
import { PersonalEmailHistoryTableComponent } from './applications/personal-email-history-table.component';
import { ExamGradeDialogComponent } from './applications/grade-edit.component';
import { BalanceInfoDialogComponent } from './programs/balance-info.component';

export function HttpLoaderFactory(translate: TranslateService, location: Location, settings: LocalizeRouterSettings, http: HttpClient) {
    return new LocalizeRouterHttpLoader(translate, location, settings, http);
}

export function getDefaultLanguage(languages: string[], cachedLang?: string, browserLang?: string) {
    return environment.defaultLanguage;
}

@NgModule({
    declarations: [
        SafeHtmlPipe,
        AppDatePipe,
        AppTimePipe,
        FormatStringPipe,
        YearValidator,
        AppComponent,
        LoginComponent,
        ClassifiersComponent,
        ParametersComponent,
        HeaderComponent,
        FooterComponent,
        ApplicationsComponent,
        RegisterComponent,
        AdmissionsComponent,
        AdmissionAddComponent,
        AdmissionEditComponent,
        AdmissionProgramsComponent,
        AdmissionApplicantsComponent,
        AdmissionOrdersComponent,
        MyInterviewsComponent,
        FormFieldDisplayComponent,
        AdmissionProgramEditComponent,
        ProgramExamsComponent,
        AdmissionResultsComponent,
        AdmissionInterviewsComponent,
        AdmissionResultsAlgorithmComponent,
        AccountComponent,
        TerritoriesComponent,
        BanklinkComponent,
        BankLinkRegisterComponent,
        AlertDialogComponent,
        ConfirmDialogComponent,
        ContractDialogComponent,
        EnrollmentDialogComponent,
        ProgramResultsComponent,
        ResultNoteComponent,
        ApplicationEditComponent,
        ApplicationNotesComponent,
        ApplicationSearchComponent,
        AppPhotoEditDialogComponent,
        AppProgramAddComponent,
        AppPersonComponent,
        AppPersonEditComponent,
        AppPersonViewComponent,
        AppPreviousUniversityComponent,
        AppPreviousUniversityViewComponent,
        AppPreviousUniversityEditComponent,
        QuizzeditorComponent,
        QuizzeditorEditComponent,
        QuizzgroupComponent,
        QuizzquestionComponent,
        MessagesComponent,
        FormFieldFileComponent,
        AutocompleteComponent,
        UsersComponent,
        LogComponent,
        IntervalsComponent,
        ApplicationSchedulesComponent,
        ApplicationSchedulesPaymentsComponent,
        CamPhotoComponent,
        AppCamPhotoDialogComponent,
        RegisterApplicationComponent,
        MessageAutocompleteComponent,
        TemplatesComponent,
        TemplateComponent,
        ContactsDialogComponent,
        ShareComponent,
        SharedItemsComponent,
        SharedGoComponent,
        ShareButtonDirective,
        MessageCodeValidator,
        AuxFilesComponent,
        AddressComponent,
        LvAddressFormComponent,
        ConfirmPersonLinkDialogComponent,
        AdmissionSendEmailsConfirmDialogComponent,
        InterviewersComponent,
        InterviewPersonnelComponent,
        InterviewersGridComponent,
        ApplicationHistoryComponent,
        ApplicationEducationsHistoryComponent,
        InterviewHistoryComponent,
        ApplicationPersonDocumentsHistoryComponent,
        ApplicationProgramsHistoryComponent,
        ApplicationExamsHistoryComponent,
        NgxDataTableColumnFlexGrowModifier,
        SignEdocDialogComponent,
        ProgramContentWrapperComponent,
        ProgramInterviewsComponent,
        InterviewEditComponent,
        ApplicationNoteComponent,
        PersonalEmailHistoryTableComponent,
        PersonalEmailHistoryComponent,
        ExamGradeDialogComponent,
        BalanceInfoDialogComponent
        ],
    imports: [
        BrowserModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        RecaptchaModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: function (http: HttpClient) {
                    return new TranslateHttpLoader(http);
                },
                deps: [HttpClient]
            }
        }),
        LocalizeRouterModule.forRoot(routes, {
            parser: {
                provide: LocalizeParser,
                useFactory: HttpLoaderFactory,
                deps: [TranslateService, Location, LocalizeRouterSettings, HttpClient]
            },
            defaultLangFunction: getDefaultLanguage
        }),
        RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
        BrowserAnimationsModule,
        NgxDatatableModule,
        MaterialModule,
        EditorModule,
        ImageCropperModule,
        NgxMaterialTimepickerModule
    ],
    exports: [
        FormsModule,
        SafeHtmlPipe,
        AppDatePipe,
        AppTimePipe,
        FormFieldDisplayComponent,
        MaterialModule
    ],
    providers: [
        {
            provide: ErrorHandler,
            useClass: AppErrorHandler
        },
        {
            provide: LOCALE_ID,
            deps: [TranslateService],
            useFactory: (translateService) => {
                return translateService.currentLang || environment.defaultLanguage;
            }
        },
        { provide: DateAdapter, useClass: MomentUtcDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
        {
            provide: RECAPTCHA_LANGUAGE,
            useValue: location.href.indexOf('/en/') > -1 ? 'en' : location.href.indexOf('/lv/') > -1 ? 'lv' : environment.defaultLanguage // language from url, or default
        },
        { provide: HTTP_INTERCEPTORS, useClass: AuthorizeInterceptor, multi: true },
        AlertService,
        ParameterService,
        ClassifierService,
        AuthService,
        AdmissionService,
        ProgramService,
        TerritoryService,
        BanklinkService,
        ExamService,
        AppService,
        ApplicationService,
        QuizzService,
        MessagesService,
        DeactivateGuardService,
        LogService,
        ReportService,
        TemplateService,
        ShareService,
        InterviewService,
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
