<header class="content-header">
    <nav class="breadcrumb">
        <a [routerLink]="['/quizzeditor'] | localize" class="bc-back" [translate]="'quizzeditorlist_title'"></a>
    </nav>
</header>

<section class="block">
    <header class="block-header">
        <h3>{{'quizzeditoredit_title' | translate:selectedQuizz}}</h3>
    </header>
    <div class="block-body">
        <form #form="ngForm" (submit)="submitQuizz()">
            
            <div class="cols">
                <div class="col-4">
                    <mat-form-field>
                        <input autofocus matInput required name="Title" [placeholder]="'quizzeditor_lblTitle' | translate" [(ngModel)]="selectedQuizz.Title" />
                    </mat-form-field>
                </div>
                <div class="col-4">
                    <mat-form-field>
                        <input autofocus matInput required name="Code" [placeholder]="'quizzeditor_lblCode' | translate" [(ngModel)]="selectedQuizz.Code" />
                    </mat-form-field>
                </div>
                <div class="col-4">
                    <mat-form-field>
                        <input matInput required name="TimeToFinish" type="number" [(ngModel)]="selectedQuizz.TimeToFinish"
                               [placeholder]="'quizzeditoredit_lblTimeEdit' | translate" min="0" step="1" (keydown)="checkNumbers($event)" />
                    </mat-form-field>
                </div>
            </div>

            <div class="form-submit">
                <button type="submit" mat-raised-button color="primary" [disabled]="!form.valid">{{'save' | translate}}</button>
            </div>
        </form>
    </div>
</section>

<app-intervals [quizz]="selectedQuizz"></app-intervals>

<section class="block">
    <header class="block-header">
        <h3>{{'quizzeditoredit_groups' | translate}}</h3>
    </header>
    <div class="block-body">
        <ngx-datatable #grid
                       [rows]="gridItems"
                       [headerHeight]="'auto'"
                       [footerHeight]="'auto'"
                       [rowHeight]="'auto'"
                       [columnMode]="'flex'"
                       [loadingIndicator]="gridLoading"
                       [externalSorting]="true"
                       [messages]="{ emptyMessage: 'gridEmpty' | translate, totalMessage: 'gridTotal' | translate }"
                       (sort)="onGridSort($event)">
            <ngx-datatable-column [name]="''" [flexGrow]="0.5">
                <ng-template ngx-datatable-cell-template let-row="row">
                    <i class="fa fa-exclamation-triangle text-warning help" *ngIf="row.ActualQuestionCount<row.QuestionCount"
                       [title]="'quizzeditor_notEounghQuestions' | translate"></i>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [name]="'quizzeditor_lblGroup' | translate" prop="Title" [flexGrow]="10">
                <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex" let-value="row.Title">
                    <ng-container *ngIf="!isGridEditing(row)">
                        {{value}}
                    </ng-container>
                    <mat-form-field *ngIf="isGridEditing(row)">
                        <input autofocus
                               matInput
                               required
                               name="Title"
                               [(ngModel)]="row.Title" />
                    </mat-form-field>
                    <mat-error *ngIf="row.notUnique">{{'quizzeditor_notuniquegroup' | translate}}</mat-error>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [name]="'quizzeditor_lblQuestionCount' | translate" prop="QuestionCount" [flexGrow]="1.5">
                <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex" let-value="row.QuestionCount">
                    <ng-container *ngIf="!isGridEditing(row)">
                        {{value}}
                    </ng-container>
                    <mat-form-field *ngIf="isGridEditing(row)">
                        <input required
                               matInput
                               name="QuestionCount"
                               type="number"
                               min="1"
                               max="1000"
                               step="1"
                               title="{{'valueintervalbeween' | translate:'[1,1000]'}}"
                                (keydown)="checkNumbers($event)"
                               [(ngModel)]="row.QuestionCount" />
                    </mat-form-field>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [name]="'quizzeditor_lblActualQuestionCount' | translate" prop="ActualQuestionCount" [flexGrow]="1.5">
                <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex" let-value="row.ActualQuestionCount">
                    {{value}}
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [resizeable]="false" [flexGrow]="2">
                <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
                    <a [routerLink]="['/quizzgroup', row.Id] | localize" class="btn-icon" *ngIf="!isGridEditing(row)">
                        <i class="fa fa-pencil"></i>
                    </a>
                    <button (click)="saveGridRow(rowIndex)"
                            class="btn-icon"
                            *ngIf="isGridEditing(row)"
                            [disabled]="!isValidGridItem(row)"
                            [title]="'save' | translate">
                        <i class="fa fa-floppy-o"></i>
                    </button>
                    <button (click)="cancelGridRow(rowIndex)" class="btn-icon" *ngIf="isGridEditing(row)" [title]="'cancel' | translate">
                        <i class="fa fa-ban"></i>
                    </button>
                    <button (click)="deleteGridRow(rowIndex)" class="btn-icon" *ngIf="!isNewGridItem(row)" [title]="'delete' | translate">
                        <i class="fa fa-trash"></i>
                    </button>
                </ng-template>
            </ngx-datatable-column>
        </ngx-datatable>

        <div>
            <button mat-raised-button color="primary" (click)="addGridRow()">{{'add' | translate}}</button>
        </div>
    </div>
</section>