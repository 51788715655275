<div class="banklink-container block form-centered">
    <p *ngIf="bankname!='error'">{{'pleasewait' | translate}}</p>
    <div class="alert alert-danger" *ngIf="bankname=='error'">
        <span>{{'banklinkerror' | translate}}</span>
        <br/>
        <a [routerLink]="['/login'] | localize" [translate]="'returntologin'"></a>
    </div>
    <form #swedbankform method="post" id="swedbankform" action="https://www.swedbank.lv/banklink/" accept-charset="UTF-8" *ngIf="bankname=='swedbank'">
        <h3>{{'redirectingtobank' | translate}}</h3>
        <input type="hidden" name="VK_SERVICE" value="{{swedbank.VK_SERVICE}}" />
        <input type="hidden" name="VK_VERSION" value="{{swedbank.VK_VERSION}}" />
        <input type="hidden" name="VK_SND_ID" value="{{swedbank.VK_SND_ID}}" />
        <input type="hidden" name="VK_REC_ID" value="{{swedbank.VK_REC_ID}}" />
        <input type="hidden" name="VK_NONCE" value="{{swedbank.VK_NONCE}}" />
        <input type="hidden" name="VK_RETURN" value="{{swedbank.VK_RETURN}}" />
        <input type="hidden" name="VK_MAC" value="{{swedbank.VK_MAC}}" />
        <input type="hidden" name="VK_ENCODING" value="{{swedbank.VK_ENCODING}}" />
    </form>
    <form #sebform method="post" name="sebform" id="sebform" action="https://ibanka.seb.lv/ipc/epakindex.jsp" accept-charset="UTF-8" *ngIf="bankname=='seb'">
        <input type="hidden" name="IB_SND_ID" value="{{seb.IB_SND_ID}}" />
        <input type="hidden" name="IB_SERVICE" value="{{seb.IB_SERVICE}}" />
        <input type="hidden" name="IB_LANG" value="{{seb.IB_LANG}}" />
    </form>
    <form #citadeleform method="post" id="citadeleform" action="https://online.citadele.lv/amai/start.htm" accept-charset="UTF-8" *ngIf="bankname=='citadele'">
        <h3>{{'redirectingtobank' | translate}}</h3>
        <input type="hidden" name="xmldata" id="xmldata" value="{{citadele.xmldata}}" />
    </form>
    <form #nordeaform method="post" action="https://netbank.nordea.com/pnbeid/eid.jsp" *ngIf="bankname=='nordea'">
        <input name="A01Y_ACTION_ID" type="hidden" value="{{nordea.A01Y_ACTION_ID}}" />
        <input name="A01Y_VERS" type="hidden" value="{{nordea.A01Y_VERS}}" />
        <input name="A01Y_RCVID" type="hidden" value="{{nordea.A01Y_RCVID}}" />
        <input name="A01Y_LANGCODE" type="hidden" value="{{nordea.A01Y_LANGCODE}}" />
        <input name="A01Y_STAMP" type="hidden" value="{{nordea.A01Y_STAMP}}" />
        <input name="A01Y_IDTYPE" type="hidden" value="{{nordea.A01Y_IDTYPE}}" />
        <input name="A01Y_RETLINK" type="hidden" value="{{nordea.A01Y_RETLINK}}" />
        <input name="A01Y_CANLINK" type="hidden" value="{{nordea.A01Y_CANLINK}}" />
        <input name="A01Y_REJLINK" type="hidden" value="{{nordea.A01Y_REJLINK}}" />
        <input name="A01Y_KEYVERS" type="hidden" value="{{nordea.A01Y_KEYVERS}}" />
        <input name="A01Y_ALG" type="hidden" value="{{nordea.A01Y_ALG}}" />
        <input name="A01Y_MAC" type="hidden" value="{{nordea.A01Y_MAC}}" />
    </form>
</div>