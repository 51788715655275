import { Component, OnInit } from '@angular/core';

import { AuthService } from '../services/auth.service';
import { AppService } from '../services/app.service';

import { UserInfo } from '../models/UserInfo';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { InterviewService } from '../services/interview.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
    constructor(
        private auth: AuthService,
        private app: AppService,
        private router: Router,
        private interviews: InterviewService,
    ) { }

    navItems = [
        {
            titleRes: 'nav_admissions',
            isHidden: () => !this.auth.isAdmin()
                        && !this.auth.isPowerUser()
                        && !this.auth.isAdmClerk()
                        && !this.auth.isExamClerk()
                        && !this.auth.isUnitedEnrollClerk(),
            children: [
                { titleRes: 'nav_admissionList', isHidden: () => (!this.auth.isAdmin() && !this.auth.isPowerUser()), route: '/admissions' }
            ]
        },
        {
            titleRes: 'nav_applications',
            isHidden: () => !this.auth.isAdmin()
                && !this.auth.isPowerUser()
                && !this.auth.isAdmClerk()
                && !this.auth.isUnitedEnrollClerk()
                && !this.auth.isAgent()
                && !this.auth.isDataViewer(),
            children: [
                {
                    titleRes: 'nav_createApplication', route: '/applications', isHidden: () => !this.auth.isAdmin()
                        && !this.auth.isPowerUser()
                        && !this.auth.isAdmClerk()
                        && !this.auth.isUnitedEnrollClerk()
                        && !this.auth.isAgent()
                },
                {
                    titleRes: 'nav_applicantSearch', route: '/applications/search', isHidden: () => !this.auth.isAdmin()
                        && !this.auth.isPowerUser()
                        && !this.auth.isAdmClerk()
                        && !this.auth.isUnitedEnrollClerk()
                        && !this.auth.isAgent()
                        && !this.auth.isDataViewer()
                }
            ]
        },
        {
            titleRes: 'nav_exams',
            isHidden: () => !this.auth.isAdmin() && !this.auth.isPowerUser(),
            children: [
                { titleRes: 'nav_examList', route: '/quizzeditor' }
            ]
        },
        {
            titleRes: 'nav_interviews',
            isHidden: () => ['Interviewer', 'InterviewPersonnel'].indexOf(this.interviews.currentRole) === -1,
            children: [
                { titleRes: 'nav_interviews_schedule', route: '/interviews' }
            ]
        },
        {
            titleRes: 'nav_admin',
            isHidden: () => !this.auth.isAdmin() && !this.auth.isPowerUser(),
            children: [
                { titleRes: 'nav_audit', route: '/messages' },
                { titleRes: 'nav_userList', isHidden: () => !this.auth.isAdmin(), route: '/users'  },
                { titleRes: 'nav_classifiers', route: '/classifiers', isHidden: () => !this.auth.isAdmin() },
                { titleRes: 'nav_config', route: '/parameters', isHidden: () => !this.auth.isAdmin() },
                { titleRes: 'nav_log', route: '/log', isHidden: () => !this.auth.isAdmin() },
                { titleRes: 'nav_templates', route: '/templates', isHidden: () => !this.auth.isAdmin() },
                { titleRes: 'nav_shared', route: '/shared', isHidden: () => !this.auth.isAdmin() && !this.auth.isPowerUser() }
            ]
        },
        {
            titleRes: 'nav_myApplications',
            isHidden: () => !this.auth.isApplicant(),
            route: '/applications'
        },
        {
            titleRes: 'nav_changePassword',
            isHidden: () => !this.auth.isApplicant()
                || this.auth.isBankUser(),
            route: '/account/change'
        }
    ];
    env = environment;
    currentUser: UserInfo;
    isUnitedRZ: boolean = false;
    userName: any = {};
    menuOpened: boolean;
    locale: string;
    logoUrl: string = '/';

    private openedMenuItem: any;

    setUser(user: UserInfo) {
        if (user && user.lastName) {
            this.currentUser = user;
            this.userName = { value: this.currentUser.firstName + ' ' + this.currentUser.lastName };
        }
        else {
            this.currentUser = null;
            this.userName = '';
        }
    }
    ngOnInit() {
        this.setUser(this.auth.currentUser);
        this.auth.user.subscribe(user => {
            this.setUser(user);

            if (this.app.auth.isDataViewer())
                this.logoUrl = '/applications/search';
        });

        if (!this.app.translate.currentLang || this.app.translate.currentLang == '')
            this.locale = 'lv';
        else
            this.locale = this.app.translate.currentLang;

        this.isUnitedRZ = this.env.isUnitedRZ;
    }

    openMenuItem(menuItem: any) {
        if (this.openedMenuItem)
            this.openedMenuItem.isOpened = false;

        if (menuItem.children && menuItem.children.length) {
            menuItem.isOpened = true;
            this.openedMenuItem = menuItem;
        }
    }

    closeMenuItem(menuItem: any) {
        menuItem.isOpened = false;
    }

    toggleMenu() {
        this.menuOpened = !this.menuOpened;
    }

    logout() {
        this.app.logout();
    }

    changeLocale(event: Event, localeto: string) {
        if (event)
            event.preventDefault();

        let route = this.router.url.split('/');

        if (!route.length) {
            route.push(localeto);
        } else {
            route[1] = localeto;
        }

        // must reload because of pipes and LOCALE_ID
        location.href = route.join('/');
    }

    getVisibleNavItems(items: any[]): any[] {
        return items.filter(t => !t.isHidden || (t.isHidden && !t.isHidden()));
    }
}
