<footer class="footer">
    <div class="container">
		<div class="cols columns">
			<div *ngFor="let column of columns" class="col-3 column">
                <ul>
                    <li *ngFor="let classifier of column.items">
                        <a class="item" href="{{classifier.LegacyCode}}" target="_blank">
                            {{classifier.Value || classifier.ValueEN}}
                        </a>
                    </li>
                </ul>
			</div>
		</div>

        <div class="bottom">
            {{'footerInfo' | translate:params }}
        </div>
    </div>
</footer>
