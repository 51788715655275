import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatTabChangeEvent } from '@angular/material/tabs';

import { Admission } from '../models/Admission';
import { ApplicationUnitedRZStatus } from '../models/Application';
import { AdmissionService } from '../services/admission.service';
import { AppService } from '../services/app.service';
import { ProgramsComponent } from './programs.component';
import { ResultsComponent } from './results.component';
import { AdmissionInterviewsComponent } from '../interviews/admission-interviews.component';
import { ApplicantsComponent } from './applicants.component';
import { OrdersComponent } from './orders.component';

import { environment } from '../../environments/environment';
import { MessageCodeStateMatcher } from '../shared/message-autocomplete.component';
import { Utils } from '../Utils';

@Component({
    selector: 'app-admission-edit',
    templateUrl: './admission-edit.component.html',
    styleUrls: ['./admission-edit.component.css']
})
export class AdmissionEditComponent implements OnInit {
    constructor(
        public app: AppService,
        private service: AdmissionService,
        private route: ActivatedRoute
    ) { }

    env = environment;
    translations: any;
    item: Admission;
    title: string;
    loadResults: boolean;
    loadInterviews: boolean;
    loadApplicants: boolean;
    unitedRZStatusList: ApplicationUnitedRZStatus[] = [
        ApplicationUnitedRZStatus.SpecialitySelection,
        ApplicationUnitedRZStatus.SpecialityApproval,
        ApplicationUnitedRZStatus.UniversitySelection
    ];
    isAdmin: boolean;
    isUnitedRZ: boolean;
    maxDateForDateTo: Date;
    minDateForVisibleUntilDate: Date;

    messageCodeStateMatcher = new MessageCodeStateMatcher();

    get showUnitedRZStatusMenu(): boolean {
        return this.isAdmin && this.item && this.item.IsUnitedRZ;
    }

    canAnonymise: boolean;
    loadOrders: boolean;

    @ViewChild(ProgramsComponent, { static: false }) programs: ProgramsComponent;
    @ViewChild(ResultsComponent, { static: false }) results: ResultsComponent;
    @ViewChild(AdmissionInterviewsComponent, { static: false }) interviews: AdmissionInterviewsComponent;
    @ViewChild(ApplicantsComponent, { static: false }) applicants: ApplicantsComponent;
    @ViewChild(OrdersComponent, { static: false }) orders: OrdersComponent;

    ngOnInit() {
        this.app.setWideLayout();

        this.route.params.subscribe(para => {
            let id = +para['id'];
            if (id)
                this.load(id);
        });

        this.app.translate.get(['applicationsalreadyexist', 'refreshfromcrmsuccess']).subscribe((result: any) => {
            this.translations = result;
        });

        this.app.auth.user.subscribe(u => {
            this.isAdmin = this.app.auth.isAdmin();
        });
    }

    submit() {
        const loading = this.app.showLoading();
        this.service.update(this.item).subscribe(() => {
            this.app.hideLoading(loading);
            this.load(this.item.Id);
        }, err => this.app.showSaveError(err));
    }

    refreshFromCrm() {
        let callback = () => {
            let loading = this.app.showLoading();
            this.service.refreshFromCrm(this.item).subscribe(result => {
                this.app.hideLoading(loading);

                if (result) {
                    this.refreshAll(this.item.Id);
                    
                    let text = this.translations['refreshfromcrmsuccess'];

                    const programs: any[] = (result || {}).AffectedPrograms || [];

                    if (programs.length)
                        text += `<p><strong>${this.app.translate.instant('admissions_crmRefreshAffectedPrograms')}</strong></p>
                        <ul>${programs.map(t => `<li>${t.Title}</li>`).join('')}</ul>`;

                    this.app.alerts.success(text);
                }
            }, err => {
                this.app.hideLoading(loading);
                this.app.showError(err);
            });
        };

        this.app.confirm({
            text: this.app.translate.instant('confirmrefreshDialogText'),
            title: this.app.translate.instant('confirmrefreshDialogTitle')
        }, result => result && callback());
    }

    refreshFormsFromCrm() {
        let callback = () => {
            let loading = this.app.showLoading();
            this.service.refreshFormsFromCrm(this.item).subscribe(result => {
                this.app.hideLoading(loading);

                if (result) {
                    this.refreshAll(this.item.Id);
                    
                    const text = this.translations['refreshfromcrmsuccess'];

                    this.app.alerts.success(text);
                }
            }, err => {
                this.app.hideLoading(loading);
                this.app.showError(err);
            });
        };

        this.app.confirm({
            text: this.app.translate.instant('confirmrefreshDialogText'),
            title: this.app.translate.instant('confirmrefreshDialogTitle')
        }, result => result && callback());
    }

    onTabChange(event: MatTabChangeEvent) {
        if (event.tab.ariaLabel === 'Results' && !this.loadResults) {
            this.loadResults = true;
        }

        if (event.tab.ariaLabel === 'Interviews' && !this.loadInterviews) {
            this.loadInterviews = true;
        }

        if (event.tab.ariaLabel === 'Applicants' && !this.loadApplicants) {
            this.loadApplicants = true;
        }
        if (event.tab.ariaLabel === 'Orders' && !this.loadOrders) {
            this.loadOrders = true;
        }
    }
    refreshAll(id: number) {
        if (this.programs)
            this.programs.load();
        if (this.results)
            this.results.load();
        if (this.interviews)
            this.interviews.load();
        if (this.applicants)
            this.applicants.reload();
        if (this.item)
            this.load(this.item.Id);
        if (this.orders)
            this.orders.load();
    }

    load(id: number) {
        const loading = this.app.showLoading();

        this.service.getById(id).subscribe(data => {
            this.app.hideLoading(loading);
            this.item = JSON.parse(JSON.stringify(data));
            this.title = this.item.Title;
            this.setMaxDateTo();
            this.setMinVisibleUntilDate();
            if (this.item.IsUnitedRZ && !this.item.UnitedRZStatus)
                this.item.UnitedRZStatus = ApplicationUnitedRZStatus.SpecialitySelection;

            this.setCanAnonymise();
        }, err => this.app.showLoadError(err));
    }

    setMaxDateTo() {
        if(this.item.PublishDate && this.item.DateExtended)
        {
            if (Date.parse(this.item.DateExtended.toString()) < Date.parse(this.item.PublishDate.toString()))
            {
                this.maxDateForDateTo = this.item.DateExtended;
            }
            else
            {
                this.maxDateForDateTo = this.item.PublishDate;
            }
        }
        else if(this.item.PublishDate)
        {
            this.maxDateForDateTo = this.item.PublishDate;
        }
        else if(this.item.DateExtended)
        {
            this.maxDateForDateTo = this.item.DateExtended;
        }
        else
        {
            this.maxDateForDateTo = this.env.maxDate;
        }
    }

    setMinVisibleUntilDate() {
        if(this.item.DateExtended && this.item.DateTo)
        {
            if (Date.parse(this.item.DateExtended.toString()) > Date.parse(this.item.DateTo.toString()))
            {
                this.minDateForVisibleUntilDate = this.item.DateExtended;
            }
            else
            {
                this.minDateForVisibleUntilDate = this.item.DateTo;
            }
        }
        else if(this.item.DateTo)
        {
            this.minDateForVisibleUntilDate = this.item.DateTo;
        }
        else if(this.item.DateExtended)
        {
            this.minDateForVisibleUntilDate = this.item.DateExtended;
        }
        else
        {
            this.minDateForVisibleUntilDate = this.env.minDate;
        }
    }

    validateMessageCode(code: string): string {
        return '';//return !code || messageCodes.indexOf(code) !== -1 ? '.*' : '^$';
    }

    setApplicationUnitedRZStatus(status: string) {
        let loading = this.app.showLoading();
        this.service.setApplicationUnitedRZStatus(this.item.Id, status).subscribe(res => {
            this.item.UnitedRZStatus = status;
            this.app.hideLoading(loading);
        }, err => this.app.showSaveError(err));
    }

    anonymise() {
        this.app.confirm(this.app.translate.instant('admissions_confirmAnonymise'), res => {
            if (res) {
                let loading = this.app.showLoading();
                this.service.anonymise(this.item.Id).subscribe(() => {
                    this.app.hideLoading(loading);
                    this.app.notify('admissions_anonymiseRequested');

                    //if (this.loadApplicants) {
                    //    this.loadApplicants = false;
                    //    setTimeout(() => this.loadApplicants = true, 100);
                    //}
                }, err => this.app.showSaveError(err));
            }
        });
    }

    private setCanAnonymise() {
        let today = new Date();

        this.canAnonymise =
            (!this.item.DateExtended || Utils.ensureDate(this.item.DateExtended) < today)
            && (!this.item.DateFrom || Utils.ensureDate(this.item.DateFrom) < today)
            && (!this.item.DateTo || Utils.ensureDate(this.item.DateTo) < today)
            && (!this.item.PublishDate || Utils.ensureDate(this.item.PublishDate) < today)
            && (!this.item.VisibleUntilDate || Utils.ensureDate(this.item.VisibleUntilDate) < today)
            && !this.item.Anonymise;
    }
}
