<form #form="ngForm" (ngSubmit)="submit()">
    <h2 mat-dialog-title [translate]="'admissionResults_launchAlgorithm'"></h2>
    <mat-dialog-content>
        {{'admissionResults_launchAlgorithmConfirm' | translate}}
    </mat-dialog-content>
    <mat-dialog-actions>
        <button mat-raised-button type="submit" color="primary">{{'continue' | translate}}</button>
        <button mat-button mat-dialog-close>{{'cancel' | translate}}</button>
    </mat-dialog-actions>
</form>
