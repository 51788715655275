import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ApplicationPersonToLink } from './common';

@Component({
    templateUrl: './confirm-person-link.component.html',
    styleUrls: ['./application-edit.component.css']
})
export class ConfirmPersonLinkDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<ConfirmPersonLinkDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.persons = data.persons;
    }

    persons: ApplicationPersonToLink[];
}
