import { Directive, Input, EventEmitter, Output, HostListener } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { ShareComponent } from './share.component';

@Directive({
    selector: '[appShareButton]'
})
export class ShareButtonDirective {
    constructor(private dialog: MatDialog) { }

    @Input('appShareButton') params: { objectIds: string[], objectType: string };
    @Output() shared: EventEmitter<any> = new EventEmitter<any>();

    @HostListener('click') 
    click() {
        let dlg = this.dialog.open(ShareComponent, {
            disableClose: true,
            autoFocus: true,
            width: '400px',
            data: {
                objectIds: this.params.objectIds,
                objectType: this.params.objectType
            }
        });

        dlg.afterClosed().subscribe(result => {
            if (result)
                this.shared.emit(result);
        });
    }
}
