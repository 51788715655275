import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject, zip } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { DatatableComponent } from '@swimlane/ngx-datatable';

import { Program, ProgramResult, ApplicationResultType } from '../models/Program';
import { ProgramService } from '../services/program.service';
import { AdmissionService } from '../services/admission.service';
import { ApplicationService } from '../services/application.service';
import { ClassifierService } from '../services/classifier.service';
import { Classifier } from '../models/classifier';
import { AppService } from '../services/app.service';
import { MessagesService } from '../messages/messages.service';

import { ApplicationForContract, ApplicationForEnrollment } from '../models/Application';
import { Admission } from '../models/Admission';

import { AlertMessage, AlertMessageType } from '../services/alert.service';
import { GridComponentBase } from '../GridComponentBase';
import { ResultNoteComponent } from './result-note.component';
import { AlertDialogComponent } from '../shared/alert-dialog.component';
import { ContractDialogOptions, ContractDialogComponent } from '../shared/contract-dialog.component';
import { EnrollmentDialogOptions, EnrollmentDialogComponent } from '../shared/enrollment-dialog.component';
import { SchedulesDialogOptions, ApplicationSchedulesComponent } from '../applications/application-schedules/application-schedules.component';
import { ContactsDialogComponent } from '../shared/contacts-dialog.component';
import { ShareComponent } from '../share/share.component';
import { Utils } from '../Utils';
import { MatDialog } from '@angular/material/dialog';
import { BalanceInfoDialogComponent } from './balance-info.component';

class ContractOptions {
    signer: Classifier;
    reason: Classifier;
    discount: Classifier;
    target: string;
    action: string;
}

class NotificationOptions {
    action: string;
    code: string;
    codeExternal: string;
    target: string;
}

class Filter {
    result?: ApplicationResultType;
    hasNotificationCode?: boolean;
    text?: string;
    notificationCode?: string;
    contractStatus?: string;
    enrollmentStatus?: string;
    applicationSubbmitedByRepresentative?: boolean;
    isIncomplete?: boolean;
    isLateSubmission?: boolean;
    onlyGraduates?: boolean;

    get isEmpty(): boolean {
        return !this.result && !this.hasNotificationCode && !this.text && !this.notificationCode
            && !this.contractStatus && !this.enrollmentStatus && !this.isIncomplete && !this.isLateSubmission
            && !this.onlyGraduates;
    }
}

@Component({
    selector: 'app-program-results',
    templateUrl: './results.component.html'
})
export class ProgramResultsComponent extends GridComponentBase<ProgramResult> implements OnInit {
    constructor(
        public app: AppService,
        private service: ProgramService,
        private admissionService: AdmissionService,
        private applicationService: ApplicationService,
        private classifierService: ClassifierService,
        private messageService: MessagesService,
        private route: ActivatedRoute,
        public dialog: MatDialog
    ) {
        super(app);
    }

    program: Program = new Program();
    admissionTitle: string;
    totalStudentsIn: number;
    notificationActions = ['create', 'delete'];
    notificationTargets = ['selected', 'filtered'];
    contractSigners: Classifier[] = [];
    contractDiscounts: Classifier[] = [];
    contractReasons: Classifier[] = [];
    contractTargets = ['selected', 'allIn', 'filtered'];
    contractActions = ['create', 'sign', 'enroll', 'share'];
    filterResultOptions = Object.keys(ApplicationResultType);
    filterContractStatusOptions = ['contract_notsent', 'contract_sent', 'contract_applicantcreated', 'contract_canceled', 'contract_signed', 'contract_created', 'contract_nocontract', 'contract_notcreatedindvs', 'contract_availabletosignindvs', 'contract_annulled'];
    filterEnrollmentStatusOptions = [
        'enrollmentStatus_readytosend',
        'enrollmentStatus_checkingpayment',
        'enrollmentStatus_readyforenrollment',
        'enrollmentStatus_enrollmentordercreated',
        'enrollmentStatus_done'
    ];
    availableResults = [ApplicationResultType.InManual, ApplicationResultType.OutManual];
    selectedRows: ProgramResult[] = [];
    resultEditing = {};
    translations: any;
    isHomines: boolean = false;
    isForeign: boolean = false;
    notificationOpts: NotificationOptions = new NotificationOptions();
    contractOpts: ContractOptions = new ContractOptions();

    filterResult: ApplicationResultType;
    filterHasNotificationCode: boolean;
    filterIsIncomplete: boolean;
    /** Iesniegts pagarinājuma laikā */
    filterIsLateSubmission: boolean;
    filterText: string;
    filterContractStatus: string;
    filterEnrollmentStatus: string;
    filterNotificationCode: string;
    filterApplicationsSubmittedByRepresentative: boolean;
    filterOnlyGraduates: boolean;
    //contractInfo: string;

    @ViewChild(DatatableComponent, { static: false }) grid: DatatableComponent;

    private admission: Admission;
    private filterTerms = new Subject<Filter>();
    private tempItems: ProgramResult[] = [];
    private translatedResultStrings: { [key: string]: string } = {};
    private contactsEmailSubject: string = '';
    ngOnInit() {
        this.app.setWideLayout();
        this.route.params.subscribe(para => {
            let id = +para['id'];
            if (id) {
                let loading = this.app.showLoading();
                zip(
                    this.service.getById(id),
                    this.service.getResults(id),
                    this.messageService.getByCodes(['CONTACTS_EMAIL_SUBJECT'])
                ).subscribe(data => {
                    this.program = data[0];
                    if (this.program.IsHomines)
                        this.isHomines = true;
                    this.gridItems = data[1];
                    this.gridItems.sort((a, b) => (a.Place || 9999) - (b.Place || 9999));

                    this.totalStudentsIn = this.gridItems.filter(p => p.Result == ApplicationResultType.In || p.Result == ApplicationResultType.InManual).length;
                    this.tempItems = [...data[1]];

                    this.admissionService.getById(this.program.AdmissionId).subscribe(data => {
                        this.admission = data;
                        this.admissionTitle = data.Title;
                        if (data.IsForeign)
                            this.isForeign = true;
                            this.contractActions = ['createapplicant','create', 'enroll', 'share'];
                        if (data.PublishDate && data.PublishTime) {
                            let h = +data.PublishTime.split(':')[0];
                            let min = +data.PublishTime.split(':')[1] + h * 60;
                            let dateTime = new Date(new Date(data.PublishDate).getTime() + min * 60000);

                            let current = new Date();
                            if (dateTime > current) {
                                if (data.IsForeign)
                                    this.contractActions = ['createapplicant','create', 'share'];
                                else
                                    this.contractActions = ['create', 'share'];
                            }
                            else {
                                this.notificationActions = ['create'];
                                this.notificationOpts.action = 'create';
                            }
                        }
                    });

                    let subjParam = data[2].find(t => t.Code === 'CONTACTS_EMAIL_SUBJECT');
                    this.contactsEmailSubject = subjParam
                        ? this.app.translate.currentLang === 'en' ? subjParam.TextEN : subjParam.TextLV
                        : '';

                    this.app.hideLoading(loading);

                    //this.service.getContractStatuses(id).subscribe(data => {

                    //    data.forEach((value, index) => {
                    //        let gridItem = this.gridItems.find(i => i.Id == value.ProgramApplicationId);
                    //        if(gridItem)
                    //            gridItem.ContractStatus = value.ContractStatus;
                    //        let tempItem = this.tempItems.find(i => i.Id == value.ProgramApplicationId);
                    //        if (tempItem)
                    //            tempItem.ContractStatus = value.ContractStatus;
                    //    });
                    //    this.gridItems.forEach((value, index) => {
                    //        if (value.ContractStatus == '')
                    //            value.ContractStatus = this.translations['contract_notsent'];
                    //    });
                    //    this.tempItems.forEach((value, index) => {
                    //        if (value.ContractStatus == '')
                    //            value.ContractStatus = this.translations['contract_notsent'];
                    //    });
                    //});
                }, err => this.app.showLoadError(err));

                this.classifierService.get('Justification').subscribe(data => {
                    this.contractReasons = data;
                });
                this.classifierService.get('ContractSigner').subscribe(data => {
                    this.contractSigners = data;
                });
                this.classifierService.get('ContractDiscount').subscribe(data => {
                    this.contractDiscounts = data;
                });
            }
        });

        this.app.translate.get(['contractssent', 'crmIdmissing', 'contract_pleasewait','contract_applicantnotcreated', 'contract_notsent', 'contract_nocontract',
            'contract_sent', 'programmissing', 'classifierValueNotValid',
            'wrongapplicationresult', 'contract_done', 'contract_applicantcreated', 'contract_created', 'contract_signed','contract_canceled', 'contract_annulled',
            'contract_availabletosignindvs', 'contract_notcreatedindvs']).subscribe((result: any) => {
            this.translations = result;
        });

        Object.keys(ApplicationResultType).forEach(t => {
            this.translatedResultStrings[t] = this.app.translate.instant(`applicationResultType_${t}`);
        });

        this.filterTerms.pipe(
            debounceTime(300),
            distinctUntilChanged()
        ).subscribe(filter => this.filterGrid(filter));
    }

    editResult(item: ProgramResult) {
        this.resultEditing[item.Id] = true;
    }

    onResultChange(event, item: ProgramResult) {
        if(!item.IsContractCreated) {
            if (event.value != item.Result) {
                let loading = this.app.showLoading();
                this.service.setResult(this.program.Id, item.ApplicationId, event.value).subscribe(() => {
                    item.Result = event.value;
                    this.app.hideLoading(loading);
                }, err => this.app.showSaveError(err));
            }
            this.resultEditing[item.Id] = false;
        }
        else {
            this.resultEditing[item.Id] = false;
            this.app.alerts.warning(this.app.translate.instant('application_ContractAlreadyCreatedResult'));
        }
    }

    selectRow(item: ProgramResult) {
        let row = this.selectedRows.find(s => s.Id == item.Id);

        if (row)
            this.selectedRows=this.selectedRows.filter(r => r.Id != row.Id);
        else
            this.selectedRows.push(item);
    }

    selectRows(event) {
        if (event.checked)
            this.selectedRows = [...this.gridItems];
        else
            this.selectedRows = [];
    }

    rowSelected(item: ProgramResult): boolean {
        return this.selectedRows.find(s=>s.Id==item.Id)?true:false;
    }

    allRowsSelected(): boolean {
        return this.selectedRows.length === this.gridItems.length;
    }

    createOrDeleteNotification() {
        let rows = this.notificationOpts.target === 'selected' ? this.selectedRows : this.gridItems;

        if (rows.length) {
            const format = this.app.translate.instant('programResults_notificationAction_confirm');
            const action = this.app.translate.instant('programResults_notificationAction_' + this.notificationOpts.action).toLowerCase();
            const target = this.app.translate.instant('programResults_notificationTarget_' + this.notificationOpts.target).toLowerCase();
            this.app.confirm(Utils.formatString(format, [action, rows.length, target]), result => {
                if (!result) return;
                let action = this.notificationOpts.action === 'delete'
                    ? this.service.deleteNotification(rows.map(k => k.Id))
                    : this.service.createNotification({
                            local: this.notificationOpts.code,
                            external: this.notificationOpts.codeExternal
                        }, rows.map(k => k.Id));
                action.subscribe(() => {
                    this.app.notify(this.app.translate.instant('programResults_notificationSuccess_' + this.notificationOpts.action));
                    rows.forEach(r => r.NotificationCode = this.notificationOpts.action === 'create' ? this.notificationOpts.code : '');
                }, err => this.app.showSaveError(err));
            });
        } else {
            this.app.alerts.warning(this.app.translate.instant('programResults_noAppSelected'));
        }
    }

    getRows(): ProgramResult[] {

        if (this.contractOpts.target === 'selected')
            return this.selectedRows;
        else if (this.contractOpts.target === 'filtered')
            return this.gridItems
        else
            return this.tempItems;
    }

    canModifyContractInStatuses: string[] = ['contract_canceled', 'contract_annulled', 'contract_applicantcreated', 'contract_notsent'];
    processContracts() {
        let rows: ProgramResult[] = this.getRows();
        //this.contractInfo = '';
        switch (this.contractOpts.action) {
            case 'enroll': {
                //let allowedEnrollStatuses = ['enrollmentStatus_readyforenrollment'];
                let skipRows = rows.filter(t => !t.ContractStatus || t.ContractStatus.toLowerCase() != 'contract_applicantcreated' || (t.EnrollmentStatus != 'enrollmentStatus_readyforenrollment' && t.EnrollmentStatus != 'enrollmentStatus_checkingpayment'));
                let skipIds = skipRows.map(t => t.ApplicationNumber);

                if (rows.filter(t => skipIds.indexOf(t.ApplicationNumber) === -1).length > 0) {
                    if (skipRows.length > 0) {
                        let appNumbers = skipRows.map(a => this.getAppLink(a.ApplicationNumber)).join(',');
                        let dialogRef = this.dialog.open(AlertDialogComponent, {
                            data: new AlertMessage(this.app.translate.instant('programResults_skippingEnroll', { appnumbers: appNumbers }), AlertMessageType.Warning),
                            disableClose: true
                        });
                        dialogRef.beforeClosed().subscribe(() => {
                            let rows: ProgramResult[] = this.getRows();
                            let skipRows = rows.filter(t => !t.ContractStatus || t.ContractStatus.toLowerCase() != 'contract_applicantcreated' || (t.EnrollmentStatus != 'enrollmentStatus_readyforenrollment' && t.EnrollmentStatus != 'enrollmentStatus_checkingpayment'));
                            let skipIds = skipRows.map(t => t.ApplicationNumber);
                            let apps = rows.map(t => new ApplicationForEnrollment(t)).filter(t => skipIds.indexOf(t.Number) === -1);
                            this.openEnrollDialog(apps);
                        });
                    }
                    else {
                        let appsForEnrolment = rows.map(t => new ApplicationForEnrollment(t)).filter(t => skipIds.indexOf(t.Number) === -1);
                        this.openEnrollDialog(appsForEnrolment);
                    }
                } else {
                    this.app.alerts.warning(this.app.translate.instant('programResults_noAppSelectedForEnroll'));
                }

                break;
            }
            case 'createapplicant': {
                let skipRows = rows.filter(t => t.Result === ApplicationResultType.Out || t.Result === ApplicationResultType.OutManual
                    || t.Result === ApplicationResultType.InHigher || t.Result === ApplicationResultType.InLower || !t.Result
                    || !t.ApplicationStatus || t.ApplicationStatus == 'New' || (t.ContractStatus !='contract_applicantnotcreated'));

                let skipIds = skipRows.map(t => t.ApplicationNumber);
                let useRows = rows.filter(t => skipIds.indexOf(t.ApplicationNumber) === -1);
                if (useRows.length > 0) {

                    if (skipRows.length > 0) {
                        let appNumbers = skipRows.map(a => this.getAppLink(a.ApplicationNumber)).join(',');
                        let dialogRef = this.dialog.open(AlertDialogComponent, {
                            data: new AlertMessage(this.app.translate.instant('programResults_skippingApplicantCreation', { appnumbers: appNumbers }), AlertMessageType.Warning),
                            disableClose: true
                        });
                        dialogRef.beforeClosed().subscribe(() => {
                            this.callApplicantCreate(useRows);
                            //this.openContractDialog(apps, rows[0]);
                        });
                    }
                    else {
                        this.callApplicantCreate(useRows);
                    }


                } else {
                    this.app.alerts.warning(this.app.translate.instant('programResults_noAppSelected'));
                }

                break;
            }
            case 'create': {
                const skipResults = [ApplicationResultType.Out, ApplicationResultType.OutManual, ApplicationResultType.InHigher, ApplicationResultType.InLower];
                if (!rows.length) {
                    this.app.alerts.warning(this.app.translate.instant('programResults_noAppSelected'));
                } else {
                    let skipRows = rows.filter(t => skipResults.some(x => x === t.Result) || !t.Result || !t.ApplicationStatus || t.ApplicationStatus == 'New'
                        || (t.ContractStatus && this.canModifyContractInStatuses.indexOf(t.ContractStatus) < 0));
                    let skipUnitedRzRows = rows.filter(t => this.admission.IsUnitedRZ && !t.ApplicationPersonVerified
                        && !skipRows.some(x => x.ApplicationId === t.ApplicationId));

                    let skipMsg = [];
                    let validRowCount = rows.length - skipRows.length - skipUnitedRzRows.length;

                    const proceed = () => {
                        if (validRowCount > 0) {
                            let skipIds = skipRows.concat(skipUnitedRzRows).map(t => t.ApplicationId);
                            let apps = rows.filter(t => !skipIds.some(x => x === t.ApplicationId)).map(t => new ApplicationForContract(t));
                            this.openContractDialog(apps, rows[0]);
                        }
                    };

                    if (skipRows.length)
                        skipMsg.push(this.app.translate.instant('programResults_skippingApps', {
                            appnumbers: skipRows.map(t => this.getAppLink(t.ApplicationNumber)).join(',')
                        }));

                    if (skipUnitedRzRows.length)
                        skipMsg.push(this.app.translate.instant('programResults_skipAppsPersonNotVerified', {
                            appnumbers: skipUnitedRzRows.map(t => this.getAppLink(t.ApplicationNumber)).join(',')
                        }));

                    if (skipMsg.length) {
                        let dialogRef = this.dialog.open(AlertDialogComponent, {
                            data: new AlertMessage(skipMsg.join('<br>'), AlertMessageType.Warning),
                            disableClose: true
                        });

                        dialogRef.beforeClosed().subscribe(() => proceed());
                    } else {
                        proceed();
                    }
                }

                break;
            }

            case 'share': {
                this.dialog.open(ShareComponent, {
                    disableClose: true,
                    autoFocus: true,
                    width: '400px',
                    data: {
                        objectIds: this.getRows().map(t => t.ApplicationNumber),
                        objectType: 'Application'
                    }
                });

                break;
            }

            case 'sign': {
                let skipRows = rows.filter(t => !t.ContractStatus || t.ContractStatus.toLowerCase() != 'contract_created');
                let skipIds = skipRows.map(t => t.ApplicationNumber);
                let userows = rows.filter(t => skipIds.indexOf(t.ApplicationNumber) === -1);
                if (userows.length > 0) {
                    if (skipRows.length > 0) {
                        let appNumbers = skipRows.map(a => this.getAppLink(a.ApplicationNumber)).join(',');
                        let dialogRef = this.dialog.open(AlertDialogComponent, {
                            data: new AlertMessage(this.app.translate.instant('programResults_skippingSign', { appnumbers: appNumbers }), AlertMessageType.Warning),
                            disableClose: true
                        });
                        dialogRef.beforeClosed().subscribe(() => {
                            this.callSign(userows);
                        });
                    }
                    else {
                        this.callSign(userows);
                    }
                } else {
                    this.app.alerts.warning(this.app.translate.instant('programResults_noAppSelectedToSign'));
                }

                break;
            }

            default: console.error('Contract action not supported.');
        }
    }
    callApplicantCreate(apps: ProgramResult[]) {
        let appNumbers = apps.map(t => t.ApplicationNumber);
        let numberLinks = apps.map(a => this.getAppLink(a.ApplicationNumber)).join(',');
        let msg = this.app.translate.instant('contract_confirmApplicantCreateMultiple', { appnumbers: numberLinks });
        if (appNumbers.length == 1)
            msg = this.app.translate.instant('contract_confirmApplicantCreate', { appnumber: this.getAppLink(appNumbers[0]) });
        this.app.confirm(msg, result => {
            if (!result)
                return;

            this.service.createApplicant(this.program.Id, appNumbers).subscribe(r => {
                let missing = appNumbers.filter(n => r.indexOf(n) == -1);
                this.service.getResults(this.program.Id).subscribe(r => {
                    this.gridItems = r;
                    if (missing.length > 0) {
                        let missingLinks = missing.map(a => this.getAppLink(a)).join(',');
                        this.app.alerts.warning(this.app.translate.instant('contract_applicantsNotSentForCreation', { appnumbers: missingLinks }));
                    }
                    else {
                        if (appNumbers.length == 1)
                            this.app.notify('contract_applicantSentForCreation');
                        else
                            this.app.notify('contract_applicantsSentForCreation');
                    }
                    //this.app.alerts.success(this.app.translate.instant('programResults_appsSigned'));
                }, err => this.app.showError(err));
            }, err => this.app.showError(err));
        });
    }
    callSign(results: ProgramResult[]) {
        let appNumbers = results.map(t => t.ApplicationNumber);
        let numberLinks = results.map(a => this.getAppLink(a.ApplicationNumber)).join(',');
        let msg = this.app.translate.instant('contract_confirmSignMultiple', { appnumbers: numberLinks });
        if (appNumbers.length == 1)
            msg = this.app.translate.instant('contract_confirmSignSingle', { appnumber: this.getAppLink(appNumbers[0]) });
        this.app.confirm(msg, result => {
            if (!result)
                return;

            this.service.signContract(this.program.Id, appNumbers).subscribe(r => {
                this.service.getResults(this.program.Id).subscribe(r => {
                    this.gridItems = r;
                    if (appNumbers.length == 1)
                        this.app.notify('programResults_appSigned');
                    else
                        this.app.notify('programResults_appsSigned');
                    //this.app.alerts.success(this.app.translate.instant('programResults_appsSigned'));
                }, err => this.app.showError(err));
            }, err => this.app.showError(err));
        });
    }
    getAppLink(number: string) {
        //['/applications', '${number}'] | localize
        let route = this.app.localize.translateRoute(`/applications/${number}`);
        return `<a href="${route}" target="_blank">${number}</a>`;
    }
    openContractDialog(apps: ApplicationForContract[], programResult:ProgramResult) {
        if (apps.length == 1 && apps[0].StudiesAtLaterStages) {
            let opts = new SchedulesDialogOptions(programResult.ProgramId, programResult.ApplicationId);
            let dialogRef = this.dialog.open(ApplicationSchedulesComponent, {
                data: opts,
                disableClose: true,
                autoFocus: true,
                //height: '800px',
                width: '800px'
            });
            dialogRef.beforeClosed().subscribe((res) => {
                this.reloadGrid();
                if (res) {
                    this.service.getContract(this.program.Id, programResult.ApplicationId).subscribe(data => {
                        if (data.ContractStatus == 'NotReady')
                            this.app.notify('contract_dataSaved');
                        else
                            this.app.notify('contract_sentToCreate');
                    });
                }
            });
        }
        else {
            let opts = new ContractDialogOptions(this.program, apps);
            let dialogRef = this.dialog.open(ContractDialogComponent, {
                data: opts,
                disableClose: true,
                autoFocus: true,
                width: '600px'
            });
            dialogRef.beforeClosed().subscribe((res) => {
                this.reloadGrid();
                if (res) {
                    //contract_sentToCreateMultiple
                    if (apps.length==1)
                        this.app.notify('contract_sentToCreate');
                    else
                        this.app.notify('contract_sentToCreateMultiple');
                }
            });
        }
    }
    openEnrollDialog(apps: ApplicationForEnrollment[]) {
        let opts = new EnrollmentDialogOptions(this.program, apps);
        let dialogRef = this.dialog.open(EnrollmentDialogComponent, {
            data: opts,
            disableClose: true,
            autoFocus: true,
            width: '800px'
        });
        dialogRef.beforeClosed().subscribe((res) => {
            this.reloadGrid();
            if (res)
                this.app.notify('contract_enrollOrderSent');
        });
    }
    reloadGrid() {
        this.service.getResults(this.program.Id).subscribe(r => {
            this.gridItems = r;
        });
    }

    filter() {
        this.filterTerms.next(<Filter>{
            result: this.filterResult,
            hasNotificationCode: this.filterHasNotificationCode,
            isIncomplete: this.filterIsIncomplete,
            isLateSubmission: this.filterIsLateSubmission,
            text: this.filterText,
            notificationCode: this.filterNotificationCode,
            contractStatus: this.filterContractStatus,
            enrollmentStatus: this.filterEnrollmentStatus,
            applicationSubbmitedByRepresentative: this.filterApplicationsSubmittedByRepresentative,
            onlyGraduates: this.filterOnlyGraduates
        });
    }

    filterGrid(filter: Filter) {
        if (!filter || filter.isEmpty) {
            this.gridItems = this.tempItems;
            this.grid.offset = 0;
        } else {
            const val = (filter.text || '').toLowerCase();
            const notifCode = (filter.notificationCode || '').toLowerCase();
            const temp = this.tempItems.filter(t => {
                return (!filter.result || t.Result === filter.result)
                    && (!filter.hasNotificationCode || t.NotificationCode)
                    && (!filter.isIncomplete || t.ApplicationIsIncomplete)
                    && (!filter.isLateSubmission || t.IsLateSubmission)
                    && (!notifCode || (t.NotificationCode || '').toLowerCase().indexOf(notifCode) > -1)
                    && (!filter.contractStatus || t.ContractStatus === filter.contractStatus)
                    && (!filter.enrollmentStatus || t.EnrollmentStatus === filter.enrollmentStatus)
                    && (!filter.applicationSubbmitedByRepresentative || t.SubmittedByRepresentative)
                    && (!filter.onlyGraduates || t.IsGraduate)
                    && (!val
                        || (t.ApplicationNumber || '').indexOf(val) > -1
                        || (t.Name || '').toLowerCase().indexOf(val) > -1
                        || (t.Surname || '').toLowerCase().indexOf(val) > -1
                        || (t.PersonCode || '').toLowerCase().indexOf(val) > -1
                        || (t.ContractStatus || '').toLowerCase().indexOf(val) > -1
                        || (t.Place + '').toLowerCase().indexOf(val) > -1
                        || (t.Priority + '').toLowerCase().indexOf(val) > -1
                        || (this.translatedResultStrings[t.Result] || '').toLowerCase().indexOf(val) > -1
                        || (t.StartYear + '').toLowerCase().indexOf(val) > -1
                        || (t.WeightedResult + '').toLowerCase().indexOf(val) > -1
                    );
            });

            this.gridItems = temp;
            this.grid.offset = 0;
        }
    }

    openNotification(applicationId: number) {
        this.applicationService.getNotes(applicationId, 'ProgramResult', 'ProgramResults').subscribe((data) => {
            if(data.length > 0) {
                this.dialog.open(ResultNoteComponent, {
                    disableClose: true,
                    data: data
                });
            }
        });
    }
    getTranslatedValue(c: Classifier) {
        if (c) {
            if (this.app.translate.currentLang == 'en' && c.ValueEN)
                return c.ValueEN;
            else
                return c.Value;
        }
        else
            return '';
    }
    getContractStatus(status) {
        if (status) {
            if (this.translations.hasOwnProperty(status)) {
                return this.translations[status];
            }
            else
                return status;
        }
    }

    gridPlaceComparator(valueA, valueB, isDesc: boolean) {
        if (!valueA && valueB)
            valueA = valueB + 1;
        if (!valueB && valueA)
            valueB = valueA + 1;
        return isDesc ? (+valueB) - (+valueA) : (+valueA) - (+valueB);
    }

    openContacts(item: ProgramResult) {
        this.dialog.open(ContactsDialogComponent, {
            disableClose: true,
            width: '250px',
            data: {
                phone: item.Phone,
                email: item.Email,
                emailSubject: this.contactsEmailSubject
            }
        });
    }

    openBalance(item: ProgramResult) {
        this.dialog.open(BalanceInfoDialogComponent, {
            disableClose: true,
            width: '600px',
            data: {
                applicationId: item.ApplicationId
            }
        });
    }
}
