import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { AppService } from '../../services/app.service';
import { ClassifierService } from '../../services/classifier.service';
import { ApplicationService } from '../../services/application.service';
import { ProgramService } from '../../services/program.service';
import { ApplicationPaymentSchedule, ProgramApplicationContract } from '../../models/Application';
import { ApplicationSchedulesPaymentsComponent } from './application-schedules-payments.component';
import { GridComponentBase } from '../../GridComponentBase';
import { Classifier } from '../../models/classifier';
import { Admission } from '../../models/Admission';
import { Program } from '../../models/Program';
import { MomentUtcDateAdapter } from '../../MomentUtcDateAdapter';
import { Utils } from '../../Utils';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export class SchedulesDialogOptions {
    constructor(public programId: number, public applicationId: number) { }
}

@Component({
    selector: 'app-application-schedules',
    templateUrl: './application-schedules.component.html',
    styleUrls: ['./application-schedules.component.css']
})
export class ApplicationSchedulesComponent extends GridComponentBase<ApplicationPaymentSchedule> implements OnInit {
    constructor(public app: AppService,
        private classifierService: ClassifierService,
        private service: ApplicationService,
        private programService: ProgramService,
        public dialogRef: MatDialogRef<SchedulesDialogOptions>,
        @Inject(MAT_DIALOG_DATA) public data: SchedulesDialogOptions
    ) {
        super(app);
    }

    itemType: string;
    programId: number;
    applicationId: number;

    @ViewChild('payments', { static: false }) payments: ApplicationSchedulesPaymentsComponent;

    showStatusValue(statusClassifer: ApplicationPaymentSchedule) {
        return statusClassifer.CP;
    }

    semesterList: ApplicationPaymentSchedule[];
    selectedSemester: ApplicationPaymentSchedule;
    SemesterPayments: ApplicationPaymentSchedule[];
    admission: Admission = new Admission();
    program: Program = new Program();

    contractSigners: Classifier[] = [];
    contractReasons: Classifier[] = [];
    contractDiscounts: Classifier[] = [];
    medInstitutions: Classifier[] = [];

    contract: ProgramApplicationContract = new ProgramApplicationContract();
    isCreated: boolean = false;

    warnings: string;

    ngOnInit() {
        this.classifierService.get('Justification,ContractSigner,ContractDiscount,MedInstitution').subscribe(data => {
            this.contractReasons = data.filter(t => t.Type === 'Justification');
            this.contractSigners = data.filter(t => t.Type === 'ContractSigner');
            this.contractDiscounts = data.filter(t => t.Type === 'ContractDiscount');
            this.medInstitutions = data.filter(t => t.Type === 'MedInstitution');
        });


        this.programId = this.data.programId;
        this.applicationId = this.data.applicationId;
        this.loadData();
    }

    checkNumbers(e: any) {
        if (!((e.keyCode > 0 && e.keyCode < 106))) {
            return false;
        }
    }
    get isBudget(): boolean {
        if (this.program && this.program.FinancingCode
            && this.program.FinancingCode == 'BGT')
            return true;
        return false;
    }
    loadData(loadContractData: boolean = true) {
        let loading = this.app.showLoading();

        this.service.ensurePaymentSchedules(this.applicationId, this.programId).subscribe(() => {
            this.service.getPaymentSchedule(this.programId, this.applicationId).subscribe(data => {
                this.gridItems = data;
            }, err => this.app.showLoadError(err));

            if (loadContractData) {
                this.programService.getContract(this.programId, this.applicationId).subscribe(data => {
                    this.contract = data;
                    this.loadSemesterPaylist(loading);
                }, err => this.app.showLoadError(err));

                this.programService.getAdmission(this.programId).subscribe(data => {
                    this.admission = data;
                }, err => this.app.showLoadError(err));

                this.programService.getById(this.programId).subscribe(data => {
                    this.program = data;
                }, err => this.app.showLoadError(err));
            }  else {
                this.loadSemesterPaylist(loading);
            }
        }, err => this.app.showLoadError(err));
    }

    loadSemesterPaylist(loading: number) {
        this.service.getPaymentSemesterList(this.programId, this.applicationId).subscribe(data => {
            this.semesterList = data;
            this.semesterList.forEach(s => {
                s.SemesterName = this.app.translate.instant('contract_SemesterName', { semester: s.Semester, year: s.Year });
            });
            this.selectedSemester = data.find(s => s.IsStart);
            this.service.getPayments(this.programId, this.applicationId).subscribe(data => {
                this.payments.gridItems = data;
                if (!this.contract.SpecialConditions)
                    this.setConditions();
                this.app.hideLoading(loading);
            }, err => this.app.showLoadError(err));
        }, err => this.app.showLoadError(err));
    }

    setConditions(args?: {
        protocolDate?: any,
        protocolNr?: string,
        schedulePaymentStartDate?: any,
        schedulePaymentEndDate?: any,
        medInstitutionId?: string,
        discountId?: string
    }) {
        if (this.admission.IsForeign)
            return;

        if (!args)
            args = {};

        let protocolDateTimeOb: Date = args.protocolDate ? new Date(args.protocolDate) : this.contract.ProtocolDate;
        let protocolNrObj: string = args.protocolNr || this.contract.ProtocolNr;
        let medInstitutionIdObj: string = args.medInstitutionId || this.contract.MedInstitutionId;
        let discountIdObj: string = args.discountId || this.contract.DiscountId;
        let schedulePaymentStartDateOb: Date = args.schedulePaymentStartDate ? new Date(args.schedulePaymentStartDate) : this.contract.SchedulePaymentStartDate;
        let schedulePaymentEndDateOb: Date = args.schedulePaymentEndDate ? new Date(args.schedulePaymentEndDate) : this.contract.SchedulePaymentEndDate;

        let textTemplate = this.app.translate.instant('applicationSchedule_ConditionsTemplate');

        if (this.admission.IsRZ && args.medInstitutionId)
            textTemplate += ' ' + this.app.translate.instant('applicationSchedule_ConditionsTemplateRZ');

        this.service.getSpecialCondition({
            programId: this.programId,
            applicationid: this.applicationId,
            protocolDate: protocolDateTimeOb,
            protocolNr: protocolNrObj,
            totalCP: this.contract.TotalCP,
            schedulePaymentStartDate: schedulePaymentStartDateOb,
            schedulePaymentEndDate: schedulePaymentEndDateOb,
            medInstitutionId: medInstitutionIdObj,
            discountId: discountIdObj,
            templateText: textTemplate,
            startYear: this.selectedSemester ? this.selectedSemester.Year : ((this.gridItems && this.gridItems.length > 0) ? this.gridItems[0].Year : null),
            startSemester: this.selectedSemester ? this.selectedSemester.Semester : ((this.gridItems && this.gridItems.length > 0) ? this.gridItems[0].Semester : null)
        }).subscribe(data => {
            this.contract.SpecialConditions = data;
        }, err => this.app.showLoadError(err));
    }

    studyLength() {
        return (this.gridItems[this.gridItems.length - 1].Semester - this.gridItems[0].Semester + 1) / 2;
    }

    countCP(gridItems: any) {
        let sum;
        for (let i = 0; i < gridItems.length; i++)
            sum += gridItems[i].CP;
        return sum;
    }

    onSemesterChange(event) {
        this.createPaymentSchedules();
    }

    onDiscountChange(event) {
        this.setConditions({ discountId: event.value });
        this.createPaymentSchedules();
    }

    updateCP(payment: ApplicationPaymentSchedule, rowIndex: number) {
        if (this.contract)
            payment.DiscountId = this.contract.DiscountId;

        let loading = this.app.showLoading();

        this.service.setCreditPoints(this.programId, this.applicationId, payment, this.contract.MinPaymentDate).subscribe(response => {
            this.gridItems.find(i => i.Id == payment.Id).Payment = response.SemesterPayments.find(i => i.Id == payment.Id).Payment;
            this.endGridRowEditing(rowIndex);
            this.refreshGrid();
            this.payments.gridItems = response.MonthPayments;
            this.app.hideLoading(loading);
        }, err => {
            this.app.showError(JSON.parse(err.text()).toString());
        });
    }

    createContract(status?: string, close: boolean = true, force: boolean = false) {
        let applicationIds: number[] = [this.applicationId];
        let loading = this.app.showLoading();

        const options = {
            signerId: this.contract.SignerId,
            reasonId: this.contract.ReasonId,
            discountId: this.contract.DiscountId,
            minPaymentDate: this.contract.MinPaymentDate,
            medInstitutionId: this.contract.MedInstitutionId,
            specialConditions: this.contract.SpecialConditions,
            specialConditionsEn: this.contract.SpecialConditionsEn,
            protocolNr: this.contract.ProtocolNr,
            protocolDate: this.contract.ProtocolDate,
            status: status,
            totalCP: this.contract.TotalCP,
            startYear: this.selectedSemester ? this.selectedSemester.Year : ((this.gridItems && this.gridItems.length > 0) ? this.gridItems[0].Year : null),
            startSemester: this.selectedSemester ? this.selectedSemester.Semester : ((this.gridItems && this.gridItems.length > 0) ? this.gridItems[0].Semester : null)
        };

        this.programService.createContracts(this.programId, applicationIds, options, force).subscribe(response => {
            this.app.hideLoading(loading);
            if (response) {
                this.warnings = response;
            }
            else {
                this.warnings = null;
                if (close) {
                    this.isCreated = true;
                    this.dialogRef.close(true);
                }
                else
                    this.loadData(false);
            }
        }, err => {
            this.app.hideLoading(loading);
            this.warnings = null;
            this.app.showError(err);
            if (close)
                this.dialogRef.close(false);
        });
    }

    save() {
        this.createContract('NotReady', false);
    }

    saveAndClose() {
        this.createContract('NotReady');
    }

    public onMinPaymentDate(event): void {
        let minDate = event;
        if (event.target && event.target.value) {
            let adapter = new MomentUtcDateAdapter(this.app.translate.currentLang);
            minDate = adapter.parse(event.target.value, 'DD.MM.YYYY');
        }
        let discount: Classifier = null;
        if (this.contract && this.contract.DiscountId)
            discount = this.contractDiscounts.find(c => c.Id == this.contract.DiscountId);
        this.calculatePrice(minDate, discount);
    }

    calculatePrice(minDateObj: any, discount: Classifier) {
        let minDate = new Date(minDateObj);
        let discountValue: number = 0;
        if (discount && discount.Payload) {
            discountValue = parseInt(discount.Payload.trim());
        }
        this.service.getPayments(this.programId, this.applicationId).subscribe(data => {
            this.payments.gridItems = data;
            this.payments.gridItems.forEach((v, index) => {
                if (new Date(v.PaymentDueDate) < minDate)
                    v.PaymentDueDate = minDate;
                if (discountValue > 0) {
                    if (discountValue == 100 && index == 0)
                        v.PaymentAmount = 0;
                    else if (discountValue < 100)
                        v.PaymentAmount = v.PaymentAmount - (discountValue * v.PaymentAmount / 100)
                }
            });
        });
    }

    onProtocolDate(event): void {
        this.setConditions({ protocolDate: Utils.ensureDate(event.value) });
    }
    onScheduleStartDate(event): void {
        this.setConditions({ schedulePaymentStartDate: Utils.ensureDate(event.value) });
    }
    onScheduleEndDate(event): void {
        this.setConditions({ schedulePaymentEndDate: Utils.ensureDate(event.value) });
    }
    onProtocolNumber(event) {
        this.setConditions({ protocolNr: event.value });
    }
    onMedInstitution(event) {
        this.setConditions({ medInstitutionId: event.value });
    }
    getTranslatedValue(c: Classifier) {
        if (c) {
            if (this.app.translate.currentLang == 'en' && c.ValueEN)
                return c.ValueEN;
            else
                return c.Value;
        }
        else
            return '';
    }
    formatDate(dateValue: any) {
        let dateTime: Date = new Date(dateValue);
        let day = dateTime.getDate().toString();
        if (dateTime.getDate() < 10)
            day = '0' + day;
        let month = (dateTime.getMonth() + 1).toString();
        if (dateTime.getMonth() < 9)
            month = '0' + month;
        let year = dateTime.getFullYear().toString();

        if (this.app.translate.currentLang == 'lv')
            return day + '.' + month + '.' + year;
        else
            return year + '-' + month + '-' + day;
    }

    private setSchedulePaymentDates() {
        let startDate = this.contract.SchedulePaymentStartDate = Utils.ensureDate(this.contract.MinPaymentDate || this.program.StudyStartDate);

        if (this.selectedSemester) {
            let len = this.semesterList.length;
            let semestersLeft = len - this.semesterList.indexOf(this.selectedSemester);
            let yearsLeft = Math.floor(semestersLeft / 2);

            let semStartMonth = startDate.getMonth();
            let startInAutumn = semStartMonth > 6;

            let endMonth: number;

            if (semestersLeft % 2 === 0)
                endMonth = startInAutumn ? 6 : 1;
            else
                endMonth = startInAutumn ? 1 : 6;

            let endYear = startDate.getFullYear() + yearsLeft;

            if (semStartMonth > 6 && endMonth === 1)
                endYear++;

            this.contract.SchedulePaymentEndDate = new Date(endYear, endMonth, 1);
        }
    }

    private createPaymentSchedules() {
        let loading = this.app.showLoading();

        const semester = this.selectedSemester ? this.selectedSemester.Semester : null;

        this.service.createPaymentSchedules(this.applicationId, this.programId, semester, this.contract.DiscountId).subscribe(response => {
            this.gridItems = response.SemesterPayments;
            this.payments.gridItems = response.MonthPayments;
            this.setSchedulePaymentDates();
            this.setConditions();
            this.app.hideLoading(loading);
        }, err => this.app.showError(err));
    }
}
