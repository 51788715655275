import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export class ConfirmDialogOptions {
    text: string;
    title?: string;
    okText?: string;
    cancelText?: string;
}

@Component({
    template: `
        <div class="alert-dialog confirm-dialog">
            <h2 mat-dialog-title *ngIf="title">{{title}}</h2>
            <mat-dialog-content><div [innerHTML]="text | safeHtml"></div></mat-dialog-content>
            <mat-dialog-actions>
                <button mat-raised-button [mat-dialog-close]="true" color="primary">
                    <ng-container *ngIf="!okText">{{'confirmDialog_ok' | translate}}</ng-container>
                    <ng-container *ngIf="okText">{{okText}}</ng-container>
                </button>
                <button mat-raised-button mat-dialog-close *ngIf="cancelText !== ''">
                    <ng-container *ngIf="!cancelText">{{'confirmDialog_cancel' | translate}}</ng-container>
                    <ng-container *ngIf="cancelText">{{cancelText}}</ng-container>
                </button>
            </mat-dialog-actions>
        </div>`
})
export class ConfirmDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<ConfirmDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogOptions
    ) {
        this.title = data.title;
        this.text = data.text;
        this.okText = data.okText;
        this.cancelText = data.cancelText;
    }

    title: string;
    text: string;
    okText: string;
    cancelText: string;
}
