<div *ngIf="gridItems && gridItems.length>0">
    <header class="block-header">
        <h2 [translate]="'paymentmonths'"></h2>
    </header>
    <ngx-datatable #grid
                   [rows]="gridItems"
                   [headerHeight]="'auto'"
                   [footerHeight]="'auto'"
                   [rowHeight]="'auto'"
                   [limit]="pageSize"
                   [columnMode]="'force'"
                   [loadingIndicator]="gridLoading"
                   [externalSorting]="true"
                   [messages]="{ emptyMessage: 'gridEmpty' | translate, totalMessage: 'gridTotal' | translate }"
                   (sort)="onGridSort($event)">
        <ngx-datatable-column [name]="'paymentyear' | translate" prop="Year" [width]="15" [flexGrow]="0.25">
            <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex" let-value="row.Year">
                {{value}}.
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [name]="'paymentsemestername' | translate" prop="SemesterName" [width]="200" [flexGrow]="0.25">
            <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex" let-value="row.SemesterName">
                {{value}}
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [name]="'paymentsemesteramoount' | translate" prop="PaymentAmount" [minWidth]="10" [flexGrow]="0.25">
            <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex" let-value="row.PaymentAmount">
                {{value | number:'1.2-2'}}
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [name]="'applicationSchedule_LabelPaymentDate' | translate" prop="PaymentDueDate" [minWidth]="10" [flexGrow]="0.25">
            <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex" let-value="row.PaymentDueDate">
                {{value | appDate}}
            </ng-template>
        </ngx-datatable-column>
    </ngx-datatable>
</div>