export class BankRequest {
}


export class SwedbankRequest extends BankRequest {
    VK_SERVICE: string;
    VK_VERSION: string;
    VK_SND_ID: string;
    VK_REC_ID: string;
    VK_NONCE: string;
    VK_RETURN: string;
    VK_MAC: string;
    VK_ENCODING: string
}
export class SebRequest extends BankRequest {
    IB_SND_ID: string;
    IB_SERVICE: string;
    IB_LANG: string;
}
export class CitadeleRequest extends BankRequest {
    xmldata: string;
}
export class NordeaRequest extends BankRequest {
    A01Y_ACTION_ID: string;
    A01Y_VERS: string;
    A01Y_RCVID: string;
    A01Y_LANGCODE: string;
    A01Y_STAMP: string;
    A01Y_IDTYPE: string;
    A01Y_RETLINK: string;
    A01Y_CANLINK: string;
    A01Y_REJLINK: string;
    A01Y_KEYVERS: string;
    A01Y_ALG: string;                         
    A01Y_MAC: string;
}
