import { Component, OnInit, Input } from '@angular/core';

import { Admission, AdmissionApplicant } from '../models/Admission';

import { AdmissionService } from '../services/admission.service';
import { AppService } from '../services/app.service';
import { MessagesService } from '../messages/messages.service';

import { GridComponentBase } from '../GridComponentBase';
import { ContactsDialogComponent } from '../shared/contacts-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'app-admission-applicants',
    templateUrl: './applicants.component.html'
})
export class ApplicantsComponent extends GridComponentBase<AdmissionApplicant> implements OnInit {
    constructor(
        public app: AppService,
        private service: AdmissionService,
        private messageService: MessagesService,
        private dialog: MatDialog
    ) {
        super(app);
    }

    @Input() admission: Admission;
    data: AdmissionApplicant[];
    notEmpty: boolean = true;
    statusesLoaded: boolean = false;

    private contactsEmailSubject: string = '';

    ngOnInit() {
        if (this.admission)
            this.reload();
    }
    
    reload() {
        let loading = this.app.showLoading();
        this.service.getApplicants(this.admission.Id).subscribe(data => {
			this.data = [...data];
            this.filterEmpty();
            this.app.hideLoading(loading);
            this.service.getContractStatuses(this.admission.Id).subscribe(results => {
                results.forEach((value, index) => {
                    let gridItem = this.gridItems.find(i => i.ApplicationId == value.ApplicationId);
                    if (gridItem)
                        gridItem.ContractStatus = value.ContractStatus;
                });
                this.statusesLoaded = true;
            });
        }, err => this.app.showLoadError(err));

        this.messageService.getByCodes(['CONTACTS_EMAIL_SUBJECT']).subscribe(data => {
            let subjParam = data.find(t => t.Code === 'CONTACTS_EMAIL_SUBJECT');
            this.contactsEmailSubject = subjParam
                ? this.app.translate.currentLang === 'en' ? subjParam.TextEN : subjParam.TextLV
                : '';
        });
	}

	filterEmpty() {
		if (this.notEmpty) {
			this.gridItems = this.data.filter(row => row.PriorityCount > 0 && row.IsEdited);
		}
		else {
			this.gridItems = this.data;
		}
    }

    openContacts(item: AdmissionApplicant) {
        let dialogRef = this.dialog.open(ContactsDialogComponent, {
            disableClose: true,
            width: '250px',
            data: {
                phone: item.Phone,
                email: item.Email,
                emailSubject: this.contactsEmailSubject
            }
        });
    }
}
