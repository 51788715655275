import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Log } from '../models/Log';
import { environment as ENV } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

/**
 * Log service
 */
@Injectable()
export class LogService {
    constructor(public http: HttpClient) { }

    public get apiUrl(): string { return `${ENV.apiUrl}/log` }

    get(options?: any): Observable<Log[]> {
        if (!options)
            options = {};

        if (!options.date)
            options.date = new Date();

        options.date = `${options.date.getFullYear()}-${options.date.getMonth() + 1}-${options.date.getDate()}`;

        return this.http.get<Log[]>(this.apiUrl, {
            params: options
        });
    }

    logError(error: string): Observable<any> {
        return this.http.post(this.apiUrl, {
            level: 'error',
            content: error
        }).pipe(map(res => res));
    }
}
