import { Component, OnInit, ViewChild, Directive } from '@angular/core';
import { UntypedFormControl, FormGroupDirective, NgForm, NG_VALIDATORS } from '@angular/forms';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { ErrorStateMatcher } from '@angular/material/core';
import { Subject } from 'rxjs';

import { MessagesService } from '../messages/messages.service';

let values: string[] = undefined;

let getCodesSubject: Subject<string[]> = new Subject<string[]>();
let singleCall: any;

export function getCodes(service: MessagesService, fn: (data: string[]) => void) {
    if (values) {
        fn(values);
    } else {
        if (!singleCall) {
            singleCall = service.getCodes().subscribe(data => {
                values = data;
                fn(data);
                getCodesSubject.next(data);
            });
        } else {
            getCodesSubject.asObservable().subscribe(fn);
        }
    }
}

export function validateMessageCode(control: UntypedFormControl) {
    return !control.value || !values || values.indexOf(control.value) > -1 ? null : {
        validateMessageCode: {
            valid: false
        }
    };
}

export class MessageCodeStateMatcher implements ErrorStateMatcher {
    isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        return control.value && values && values.indexOf(control.value) === -1;
    }
}

@Directive({
    selector: '[validateMessageCode][ngModel]',
    providers: [
        { provide: NG_VALIDATORS, useValue: validateMessageCode, multi: true }
    ]
})
export class MessageCodeValidator { }

@Component({
    selector: 'app-message-autocomplete',
    template: `
        <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let option of filteredOptions" [value]="option">
                {{option}}
            </mat-option>
        </mat-autocomplete>`
})
export class MessageAutocompleteComponent implements OnInit {
    constructor(private service: MessagesService) { }

    element: any;
    options: string[] = [];
    filteredOptions: string[] = [];
    ready: boolean;

    @ViewChild(MatAutocomplete, { static: true }) autocomplete: MatAutocomplete;

    ngOnInit() {
        getCodes(this.service, data => {
            this.options = this.filteredOptions = data;
            this.ready = true;
        });
    }

    bind(element: any): MatAutocomplete {
        if (!this.element) {
            this.element = element;

            this.element.onkeyup = e => {
                if (e.keyCode === 13)
                    return;

                this.filteredOptions = this.filter(this.element.value);
            };           
        }

        return this.autocomplete;
    }

    private filter(value: string): string[] {
        const val = value.toLowerCase();
        return this.options.filter(t => t.toLowerCase().indexOf(val) > -1);
    }
}
