import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { SharedObject } from '../models/Share';

import { AppService } from '../services/app.service';
import { ShareService } from '../services/share.service';

@Component({
    selector: 'app-shared-go',
    template: ''
})
export class SharedGoComponent implements OnInit {
    constructor(public app: AppService, private service: ShareService, private route: ActivatedRoute) { }

    ngOnInit() {
        this.route.params.subscribe(para => {
            let id = para['id'];

            if (id) {
                let loading = this.app.showLoading();
                this.service.getObject(id).subscribe(data => {
                    let url = this.service.getObjectRoute(data).join('/');

                    this.app.hideLoading(loading);

                    if (this.app.auth.isAuthenticated) {
                        this.app.redirect(url);
                    } else {
                        sessionStorage.setItem('returnUrl', url);
                        location.href = this.app.env.adfsUrl;
                    }
                }, err => this.app.showLoadError(err));
            }
        });
    }
}
