import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { Program } from '../models/Program';

import { ProgramService } from '../services/program.service';
import { AppService } from '../services/app.service';

import { GridComponentBase } from '../GridComponentBase';
import { ProgramEditComponent } from './program-edit.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'app-admission-programs',
    templateUrl: './programs.component.html'
})
export class ProgramsComponent extends GridComponentBase<Program> implements OnInit {
    constructor(public app: AppService, private service: ProgramService, public dialog: MatDialog) {
        super(app);
    }

    @Input() admissionId: number;
    @ViewChild('grid', { static: true }) grid: any;

    expanded: any = {};

    private tempItems: Program[] = [];
    private filterCodeTerms = new Subject<string>();
    private filterTitleTerms = new Subject<string>();

    ngOnInit() {
        this.load();

        this.filterCodeTerms.pipe(
            debounceTime(300),
            distinctUntilChanged()
        ).subscribe(term => this.filterGrid(term));

        this.filterTitleTerms.pipe(
            debounceTime(300),
            distinctUntilChanged()
        ).subscribe(term => this.filterGrid(term));
    }

    load() {
        let loading = this.app.showLoading();
        if (this.admissionId) {
            this.service.get(this.admissionId).subscribe(data => {
                this.gridItems = data;
                this.tempItems = [...data];
                this.app.hideLoading(loading);
            }, err => this.app.showLoadError(err));
        }
    }

    isValidGridItem(item: Program): boolean {
        return item.Title && item.Quota > -1;
    }

    toggleGridRow(row) {
        this.grid.rowDetail.toggleExpandRow(row);
    }

    edit(item: Program) {
        let dialogRef = this.dialog.open(ProgramEditComponent, {
            disableClose: true,
            width: '600px',
            data: Object.assign(new Program(), item)
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                let loading = this.app.showLoading();
                this.service.update(result).subscribe(data => {
                    Object.assign(item, data);
                    this.app.hideLoading(loading);
                }, err => this.app.showSaveError(err));
            }
        });
    }

    filter(term: string, field: string) {
        switch (field) {
            case 'Code': this.filterCodeTerms.next(term); break;
            case 'Title': this.filterTitleTerms.next(term); break;
        }
    }

    filterGrid(term: string) {
        if (!term) {
            this.gridItems = this.tempItems;
            this.grid.offset = 0;
        } else {
            const val = term.toLowerCase();
            let temp = this.tempItems.filter(t => {
                return (t.Code || '').toLowerCase().indexOf(val) > -1 || (t.Title || '').toLowerCase().indexOf(val) > -1;
            });

            this.gridItems = temp;
            this.grid.offset = 0;
        }
    }
}
