import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { Parameter } from '../models/Parameter';
import { ParameterService } from '../services/parameter.service';
import { AppService } from '../services/app.service';

import { GridComponentBase } from '../GridComponentBase';

@Component({
  selector: 'app-parameters',
  templateUrl: './parameters.component.html',
  styleUrls: ['./parameters.component.css']
})
export class ParametersComponent extends GridComponentBase<Parameter> implements OnInit {
    constructor(public app: AppService, private service: ParameterService) {
        super(app);
    }
    
    ngOnInit() {
        if (this.app.auth.isAuthenticated) {
            let loading = this.app.showLoading();
            this.service.get().subscribe(data => {
                this.gridItems = data;
                this.app.hideLoading(loading);                                
            }, err => this.app.showLoadError(err));
        }                   
    }
    
    isValidGridItem(item: Parameter): boolean {
        return !!item.Value;
    }
    
    isNullOrWhitespace = (item: string) : boolean => !item || !item.trim();
    
    checkDataURL = (item: Parameter) : boolean => {        
        let allowToTakeDataPresent = this.gridItems.find(item => item.Code === "AllowToTakeData");
        let DataURLMandatory = allowToTakeDataPresent && allowToTakeDataPresent.Value === "1" ? true : false;
        if (item.Code === "DataURL" && DataURLMandatory && this.isNullOrWhitespace(item.Value))
            return false;        
        else 
            return true;
    }
    
    protected updateGridItem(item: Parameter): Observable<Parameter> {     
        if (item.Code)
            return this.service.update(item.Id, item.Value);
    }
}
