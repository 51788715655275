<header class="content-header">
    <nav class="breadcrumb">
        <a [routerLink]="['/applications', application.Number] | localize" class="bc-back">
            {{'applications_edit_titlePrefix' | translate}}
            "{{application.AdmissionTitle}}", {{application.Number}}
        </a>
    </nav>
</header>

<section class="block">
    <header class="block-header">
        <h1 [translate]="'applications_prevUni'"></h1>
    </header>

    <div class="block-body">
        <label class="group-label">{{'applications_prevUni_lblSelectedPrograms' | translate}}</label>
        <div class="field-group">
            <div *ngFor="let n of item.SelectedPrograms">
                <mat-checkbox [checked]="true" disabled>
                    {{n.Title}}
                </mat-checkbox>
            </div>
        </div>

        <div class="cols">
            <div class="col-6">
                <app-field-display [label]="'applications_prevUni_lblName' | translate" [value]="item.Name"></app-field-display>
            </div>

            <div class="col-6">
                <app-field-display [label]="'applications_prevUni_lblWebsite' | translate" [value]="item.Website"></app-field-display>
            </div>
        </div>

        <app-field-display [label]="'applications_prevUni_lblProgram' | translate" [value]="item.Program"></app-field-display>

        <label class="group-label">{{'applications_prevUni_lblProgramDesc' | translate}}</label>

        <div class="cols">
            <div class="col-3">
                <div class="field-group">
                    <div *ngFor="let n of item.DegreeTypes">
                        <mat-checkbox [checked]="true" disabled>
                            {{n}}
                        </mat-checkbox>
                    </div>
                </div>
            </div>

            <div class="col-3">
                <div class="field-group">
                    <div *ngFor="let n of item.DegreeLevels">
                        <mat-checkbox [checked]="true" disabled>
                            {{n}}
                        </mat-checkbox>
                    </div>
                </div>
            </div>
        </div>

        <div class="cols">
            <div class="col-2">
                <app-field-display [label]="'applications_prevUni_lblDateFrom' | translate" [value]="item.DateFrom | appDate"></app-field-display>
            </div>

            <div class="col-2">
                <app-field-display [label]="'applications_prevUni_lblDateTo' | translate" [value]="item.DateTo | appDate"></app-field-display>
            </div>
        </div>

        <div class="cols">
            <div class="col-4">
                <app-field-display [label]="'applications_prevUni_lblCreditPoints' | translate" [value]="item.CreditPoints"></app-field-display>
            </div>
        </div>

        <app-field-display [label]="'applications_prevUni_lblDocuments' | translate" [value]="item.Documents"></app-field-display>

        <div>
            <mat-checkbox [ngModel]="item.DocumentsConfirmed" disabled>
                <span style="white-space: normal">
                    {{'applications_prevUni_lblDocumentsConfirmed' | translate}}
                </span>
            </mat-checkbox>
        </div>

        <div>
            <mat-checkbox [ngModel]="item.TransferRulesConfirmed" disabled>
                <span style="white-space: normal" [innerHTML]="'applications_prevUni_lblTransferRulesConfirmed' | translate | formatString:transferRulesUrl | safeHtml"></span>
            </mat-checkbox>
        </div>
    </div>
</section>
