import { Component, OnInit } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';

import { UserInfo, UserCrypto } from '../models/UserInfo';
import { AES, mode, pad, enc } from 'crypto-ts';
import { AuthService } from '../services/auth.service';
import { AppService } from '../services/app.service';
import { ParameterService } from '../services/parameter.service';

import { environment } from '../../environments/environment';

@Component({
    selector: 'app-account',
    templateUrl: './account.component.html',
    styleUrls: ['./account.component.css']
})
export class AccountComponent implements OnInit {
    constructor(
        private app: AppService,
        private auth: AuthService,
        private params: ParameterService,
        private route: ActivatedRoute,
        private router: Router
    ) { }

    env = environment;
    type: string;//possible values restore, confirm, change
    confirmKey: string;
    oldPasswordValue: string;
    newPasswordValue: string;
    newPasswordRepeatValue: string;
    emailValue: string;
    restoreError: string;
    restoreSuccess: string = '';
    translations: any;
    user: UserInfo;
    wrongConfirmLink: boolean = false;
    emailAuthEnabled: boolean;

    cryptoKey: string = '';
    userCryptoKey: string;
    crypto: UserCrypto;

    newPasswordVisible: boolean;
    newPasswordRepeatVisible: false;

    ngOnInit() {
        let loading = this.app.showLoading();
        this.params.getValues().subscribe(values => {
            this.emailAuthEnabled = values.some(t => t.Code === 'EmailAuthEnabled' && t.Value === '1');
            this.app.hideLoading(loading);

            if (!this.emailAuthEnabled) {
                this.router.navigateByUrl('/');
            } else {
                this.route.params.subscribe(params => {
                    this.user = this.auth.currentUser;

                    if (params['type']) {
                        this.type = params['type'];
                        if (params['confirmKey']) {
                            this.confirmKey = params['confirmKey'];
                            this.app.auth.getConfirmUserInfo(this.confirmKey).subscribe(userValue => {
                                if (!this.user)
                                    this.user = userValue;
                            },
                                error => {
                                    if (error.text() == 'expiredconfirmlink') {
                                        this.wrongConfirmLink = true;
                                        //this.restoreError = this.translations['expiredconfirmlink'];
                                    }
                                });
                        }
                    }
                    else {
                        this.type = 'change';
                    }

                    if (this.type == 'logout')
                        this.logout();
                    else if (this.type == 'change' || this.type == 'confirm') {
                        this.userCryptoKey = new Date().getTime() + '_' + Math.floor(Math.random() * 100);
                        this.auth.getCryptoKey(this.userCryptoKey).subscribe(responseObj => {
                            this.crypto = responseObj;
                        });
                    }
                });
                this.app.translate.get(['passwordrestoresent', 'passwordtooshort', 'passwordmissingsymbols', 'passwordcontainsname', 'expiredconfirmlink',
                    'passwordsdontmatch', 'passwordchangesuccess', 'passwordrestoresent', 'wrongemail', 'wrongoldpassword', 'passwordrequirements',
                    'unexpectederror', 'passwordchangesuccess', 'notauthenticated', 'alreadyauthenticated', 'oldpasswordisthesame']).subscribe((result: any) => {
                        this.translations = result;
                    });
            }
        });
    }

    processSubmit() {
        if ((this.type == 'change' || this.type == 'confirm') && this.newPasswordValue.length < this.env.minPasswordLength) {
            this.restoreError = this.translations['passwordtooshort'].replace('{minPasswordLength}', String(this.env.minPasswordLength));
        }
        else if ((this.type == 'change' || this.type == 'confirm') && this.newPasswordValue.length > this.env.maxPasswordLength) {
            this.restoreError = this.translations['passwordtoolong'].replace('{maxPasswordLength}', String(this.env.maxPasswordLength));
        }
        else if ((this.type == 'change' || this.type == 'confirm') && this.containsName(this.newPasswordValue)) {
            this.restoreError = this.translations['passwordcontainsname'];
        }
        else if ((this.type == 'change' || this.type == 'confirm') && this.newPasswordValue != this.newPasswordRepeatValue) {
            this.restoreError = this.translations['passwordsdontmatch'];
        }
        else if ((this.type == 'change') && this.newPasswordValue == this.oldPasswordValue) {
            this.restoreError = this.translations['oldpasswordisthesame'];
        }
        else if (this.type == 'change' && !this.app.auth.isAuthenticated) {
            this.restoreError = this.translations['notauthenticated'];
        }
        else {
            switch (this.type) {
                case 'change':
                    this.change();
                    break;
                case 'confirm':
                    this.confirm();
                    break;
                case 'restore':
                    this.restore();
                    break;
            }
        }
    }
    containsName(value: string): boolean {
        if (this.user &&
            (value.indexOf(this.user.firstName) > -1 || value.indexOf(this.user.lastName) > -1 || value.indexOf(this.user.email) > -1)) {
            return true;
        }
        else
            return false;
    }
    confirm() {
        let key = enc.Utf8.parse(this.crypto.key);
        let iv = enc.Utf8.parse(this.crypto.iv);
        let crypted = AES.encrypt(this.newPasswordValue, key, {
            iv: iv,
            mode: mode.CBC,
            padding: pad.PKCS7
        });

        const loading = this.app.showLoading();

        this.app.auth.confirm(enc.Hex.stringify(crypted.ciphertext), this.confirmKey, this.userCryptoKey).subscribe((response: any) => {
            this.app.hideLoading(loading);
            this.restoreError = '';
            this.router.navigate([this.app.localize.translateRoute('/applications')]);
        },
            error => {
                this.app.hideLoading(loading);

                if (this.translations.hasOwnProperty(error.error)) {
                    this.restoreError = this.translations[error.error];
                }
                else {
                    console.log(error);
                    this.restoreError = this.translations['unexpectederror'];
                }
            });
    }

    restore() {
        const loading = this.app.showLoading();

        this.app.auth.restore(this.emailValue.toLowerCase(), this.app.translate.currentLang).subscribe((response: any) => {
            this.app.hideLoading(loading);
            this.restoreError = '';
            this.restoreSuccess = this.translations['passwordrestoresent'];
        },
            error => {
                this.app.hideLoading(loading);

                if (this.translations.hasOwnProperty(error.error)) {
                    this.restoreError = this.translations[error.error];
                }
                else {
                    console.log(error);
                    this.restoreError = this.translations['unexpectederror'];
                }
            });
    }

    change() {
        let key = enc.Utf8.parse(this.crypto.key);
        let iv = enc.Utf8.parse(this.crypto.iv);
        let cryptedObjOld = AES.encrypt(this.oldPasswordValue, key, {
            iv: iv,
            mode: mode.CBC,
            padding: pad.PKCS7
        });
        let cryptedObjNew = AES.encrypt(this.newPasswordValue, key, {
            iv: iv,
            mode: mode.CBC,
            padding: pad.PKCS7
        });

        const loading = this.app.showLoading();

        this.app.auth.change(enc.Hex.stringify(cryptedObjOld.ciphertext), enc.Hex.stringify(cryptedObjNew.ciphertext), this.userCryptoKey).subscribe((response: any) => {
            this.app.hideLoading(loading);

            if (response) {
                if (this.app.auth.currentUser.startUrl)
                    this.app.redirect("/" + this.app.auth.currentUser.startUrl);
                else
                    this.app.redirect("/applications");
            }
        },
            error => {
                this.app.hideLoading(loading);

                if (this.translations.hasOwnProperty(error.error)) {
                    this.restoreError = this.translations[error.error];
                }
                else {
                    console.log(error);
                    this.restoreError = this.translations['unexpectederror'];
                }
            });
    }

    finish() {
        this.app.redirect('/');
    }
    logout() {
        this.app.auth.clearSession();
        this.router.navigate([this.app.localize.translateRoute('/login')]);
    }
}
