import { BaseEntity } from './BaseEntity';

export class Quizz extends BaseEntity {
    Title: string;
    Code: string;
    TimeToFinish: number;
    HasQuestions: boolean;
    IsAvailableInIntervalsOnly: boolean;
    QuizzGroups: QuizzGroup[];
    notUnique: boolean;
}

export class QuizzGroup extends BaseEntity {
    Title: string;
    QuestionCount: number;
    QuizzId: number;
    QuizzTitle: string;
    QuizzQuestions: QuizzQuestion[];
    ActualQuestionCount: number;
    notUnique: boolean;
}

export class QuizzInterval extends BaseEntity {
    DateFrom: Date;
    TimeFrom: string;
    DateTo:Date;
    TimeTo: string;
}

export class QuizzQuestion extends BaseEntity {
    QuizzGroupId: number;
    QuizzAnswers: QuizzAnswer[];
    ActualAnswerCount: number;
    Title: string;
    Text: string;
    SortSequence: number;
    notUnique: boolean;
}
export class QuizzAnswer extends BaseEntity {
    QuizzQuestionId: number;
    QuizzUserAnswers: QuizzUserAnswer[];
    Text: string;
    Points: number;
    notUnique: boolean;
}
export class QuizzUserAnswer extends BaseEntity {
    QuizzAnswerId: number;
    QuizzAnswerText: string;
    FinishAll: boolean;
    QuizzQuestionText: string;
    PossibleQuizzAnswers: QuizzAnswer[];
}