<header class="content-header">
    <h1 [translate]="'applications_search_title'"></h1>
</header>

<p [translate]="'applications_search_description'"></p>

<form class="filter-form" #form="ngForm" (submit)="filter()">
    <div class="cols">
        <div class="col-6">
            <mat-form-field>
                <mat-select [placeholder]="'applications_lblAdmission' | translate"
                            [(ngModel)]="filterAdmissionId" name="filterAdmissionId" (selectionChange)="changeAdmission()">
                    <mat-option *ngFor="let o of filteredAdmissions" [value]="o.Id">{{o.Title}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="col-2">
            <mat-form-field>
                <input matInput [(ngModel)]="filterNumber" name="filterNumber"
                       [placeholder]="'applications_lblNumberShort' | translate">
            </mat-form-field>
        </div>

        <div class="col-2" *ngIf="showIncludeAll">
            <div class="mat-align-bottom">
                <mat-checkbox [(ngModel)]="filterIncludeAll" name="filterIncludeAll" (change)="setFilterIncludeAll()">
                    {{'applications_search_lblIncludeAll' | translate}}
                </mat-checkbox>
            </div>
        </div>
    </div>
    <div class="cols">
        <div class="col-3">
            <mat-form-field>
                <input matInput [(ngModel)]="filterName" name="filterName" [placeholder]="'applications_lblName' | translate">
            </mat-form-field>
        </div>

        <div class="col-3">
            <mat-form-field>
                <input matInput [(ngModel)]="filterSurname" name="filterSurname" [placeholder]="'applications_lblSurname' | translate">
            </mat-form-field>
        </div>

        <div class="col-3">
            <mat-form-field>
                <input matInput [(ngModel)]="filterPersonCode" name="filterPersonCode" [placeholder]="'applications_lblPersonCode' | translate"
                       pattern="[a-zA-Z0-9\-]*">
            </mat-form-field>
        </div>

        <div class="col-3">
            <mat-form-field>
                <input matInput [(ngModel)]="filterEmail" name="filterEmail" [placeholder]="'applications_lblEmail' | translate">
            </mat-form-field>
        </div>
    </div>
    <div class="cols">
        <div class="col-6">
            <mat-form-field>
                <mat-select [placeholder]="'applications_lblStatus' | translate"
                            [(ngModel)]="filterStatus" name="filterStatus">
                    <mat-option>{{'optionNone' | translate}}</mat-option>
                    <mat-option *ngFor="let s of Statuses" [value]="s.Status">{{s.Title}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-6">
            <mat-form-field>
                <mat-select [placeholder]="'applications_lblResult' | translate"
                            [(ngModel)]="filterResult" name="filterResult">
                    <mat-option>{{'optionNone' | translate}}</mat-option>
                    <mat-option *ngFor="let r of Results" [value]="r.Result">{{r.Title}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="cols" *ngIf="isUnitedRZ">
        <div class="col-6">
            <mat-form-field>
                <mat-select [placeholder]="'programs_lblUniversity' | translate"
                            [(ngModel)]="filterUniversity" name="filterUniversity">
                    <mat-option>{{'optionNone' | translate}}</mat-option>
                    <mat-option [value]="'notselected'">{{'applications_NotSelectedOption' | translate}}</mat-option>
                    <mat-option *ngFor="let u of Universities" [value]="u.LegacyCode">{{getTranslatedValue(u)}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    
    <div>
        <button type="submit" mat-raised-button color="primary" [disabled]="!form.valid">
            {{'search' | translate}}
        </button>
		<a [routerLink]="['/applications'] | localize" mat-raised-button color="accent" *ngIf="canCreate">
			<i class="fa fa-plus"></i>
			{{'applications_createNew' | translate}}
		</a>
        <button *ngIf="isUnitedRZ && (isUnitedEnrollmentClerk || isAdmin)" (click)="getReport()"  mat-raised-button color="primary" [disabled]="!gridItems || gridItems.length==0">
            <i class="fa fa-download"></i>&nbsp;{{'applications_makeUnitedRZReport' | translate}}
        </button>
    </div>
</form>

<ngx-datatable #grid
               class="tight"
               [rows]="gridItems"
               [limit]="30"
               [headerHeight]="'auto'"
               [footerHeight]="'auto'"
               [rowHeight]="'auto'"
               [columnMode]="'force'"
               [loadingIndicator]="gridLoading"
               [externalSorting]="true"
               [messages]="{ emptyMessage: 'gridEmpty' | translate, totalMessage: 'gridTotal' | translate }"
               (sort)="onGridSort($event)">
    <ngx-datatable-column [name]="'applications_lblAdmission' | translate" prop="Admission"></ngx-datatable-column>
    <ngx-datatable-column [name]="'applications_lblNumber' | translate" prop="Number">
        <ng-template ngx-datatable-cell-template let-row="row">
            <a *ngIf="row.Number" [routerLink]="['/applications/', row.Number] | localize" [queryParams]="{ returnTo: 'search' }">{{row.Number}}</a>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [name]="'applications_lblStatus' | translate" prop="Status">
        <ng-template ngx-datatable-cell-template let-row="row">
            <span *ngIf="row.Status">{{'applicationStatus_' + row.Status | translate}}</span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [name]="'applications_lblName' | translate" prop="Name"></ngx-datatable-column>
    <ngx-datatable-column [name]="'applications_lblSurname' | translate" prop="Surname"></ngx-datatable-column>
    <ngx-datatable-column [name]="'applications_lblPersonCode' | translate" prop="PersonCode"></ngx-datatable-column>
    <ngx-datatable-column [name]="'applications_lblEmail' | translate" prop="Email"></ngx-datatable-column>
    <ngx-datatable-column [name]="'applications_lblResult' | translate" prop="Result">
        <ng-template ngx-datatable-cell-template let-row="row">
            <span *ngIf="row.Result">{{'applicationResultType_' + row.Result | translate}}</span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [name]="'programs_lblUniversityCol' | translate" *ngIf="isUnitedRZ" prop="universityName"></ngx-datatable-column>
    <ngx-datatable-column [name]="'applications_lblCreatedBy' | translate" prop="createdByFullName"></ngx-datatable-column>
</ngx-datatable>
