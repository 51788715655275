import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { InterviewData, InterviewStatus, Questionnaire } from "../models/Interview";
import { AppService } from "../services/app.service";
import { InterviewService } from "../services/interview.service";
import { MatRadioChange } from '@angular/material/radio';

@Component({
    selector: 'app-interview-edit',
    templateUrl: './interview-edit.component.html',
    styleUrls: ['./interview-edit.component.css'],
})
export class InterviewEditComponent implements OnInit {

    constructor(
        private app: AppService,
        private service: InterviewService,
        private route: ActivatedRoute,
        private router: Router
    ) { }

    applicationExamId: number;
    interviewerId: number;

    sessionKey: string;
    isAdmin: boolean;
    isPowerUser: boolean;

    data: InterviewData;
    questionnaire: Questionnaire;

    backLink: any[];

    InterviewStatus = InterviewStatus;

    ngOnInit(): void {
        this.sessionKey = this.app.auth.sessionKey;
        this.isAdmin = this.app.auth.isAdmin();
        this.isPowerUser = this.isAdmin || this.app.auth.isPowerUser();
        this.app.setWideLayout();
        this.route.params.subscribe(para => {
            this.applicationExamId = +para['applicationExamId'];
            this.interviewerId = +para['interviewerId'];
            this.load();
        });
    }

    private async load(): Promise<void> {
        const loading = this.app.showLoading();
        try {
            this.data = await this.service.getInterviewData(this.applicationExamId, this.interviewerId);
            this.backLink = <any[]>this.app.localize.translateRoute(
                this.isPowerUser ? ['/programs', this.data.ProgramId, 'interviews', this.data.ExamId] : ['/interviews']);
            this.questionnaire = await this.service.getQuestionnaire(this.data.QuestionnaireCode);
        }
        catch (err) {
            this.app.showLoadError(err);
        }
        finally {
            this.app.hideLoading(loading);
        }
    }

    async answerChanged(event: MatRadioChange, entryCode: string): Promise<void> {
        const prev = this.data.SelectedGrades[entryCode];
        this.data.SelectedGrades[entryCode] = event.value;
        const loading = this.app.showLoading();
        try {
            this.data.TotalGrade = await this.service.updateSelectedGrades(this.applicationExamId, this.interviewerId, this.data.SelectedGrades);
        }
        catch (err) {
            this.data.SelectedGrades[entryCode] = prev;
        }
        finally {
            this.app.hideLoading(loading);
        }
    }

    async finish(): Promise<void> {
        this.app.confirm({
            title: this.app.translate.instant('interview_finish'),
            text: this.app.translate.instant('interview_confirm_finish'),
        }, async result => {
            if (result) {
                const loading = this.app.showLoading();
                try {
                    await this.service.updateSelectedGrades(this.applicationExamId, this.interviewerId, this.data.SelectedGrades);
                    await this.service.setStatus(this.applicationExamId, this.interviewerId, InterviewStatus.Completed);
                    await this.router.navigate(this.backLink);
                }
                catch (err) {
                    this.app.showLoadError(err);
                }
                finally {
                    this.app.hideLoading(loading);
                }
            }
        });
    }

    async amend(): Promise<void> {
        this.app.confirm({
            title: this.app.translate.instant('interview_amend'),
            text: this.app.translate.instant('interview_confirm_amend'),
        }, async result => {
            if (result) {
                const loading = this.app.showLoading();
                try {
                    await this.service.setStatus(this.applicationExamId, this.interviewerId, InterviewStatus.InProgress);
                    await this.load();
                }
                catch (err) {
                    this.app.showLoadError(err);
                }
                finally {
                    this.app.hideLoading(loading);
                }
            }
        });
    }

    async reset(): Promise<void> {
        this.app.confirm({
            title: this.app.translate.instant('interview_reset'),
            text: this.app.translate.instant('interview_confirm_reset'),
        }, async result => {
            if (result) {
                const loading = this.app.showLoading();
                try {
                    await this.service.setStatus(this.applicationExamId, this.interviewerId, InterviewStatus.NotStarted);
                    await this.load();
                }
                catch (err) {
                    this.app.showLoadError(err);
                }
                finally {
                    this.app.hideLoading(loading);
                }
            }
        });
    }

    showDescription(content: string) {
        this.app.alerts.info(content);
    }

}
