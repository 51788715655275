<div class="alert-dialog confirm-dialog">
    <h3 mat-dialog-title>{{'enrollment_dialogTitle' | translate}}</h3>
    <div>
        <mat-dialog-content>
            <form #enrollmentForm="ngForm">
                <div class="cols">
                    <div class="col-4">
                        <mat-form-field>
                            <input matInput [matDatepicker]="pickerOrderDate" [(ngModel)]="enrollmentOrder.OrderDate" name="orderDate" required
                                   [placeholder]="'enrollment_lblOrderDate' | translate" [min]="minDate" [max]="maxDate"
                                   [title]="'dateFormatHint' | translate" (click)="pickerOrderDate.open()">
                            <mat-datepicker-toggle matSuffix [for]="pickerOrderDate"></mat-datepicker-toggle>
                            <mat-datepicker #pickerOrderDate></mat-datepicker>
                            <mat-error>{{'valueInvalid' | translate}}</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-8">
                        <mat-form-field>
                            <input matInput [(ngModel)]="enrollmentOrder.ProtocolNumber" name="protocolNumber" required [placeholder]="'enrollment_lblProtocolNumber' | translate">
                        </mat-form-field>
                    </div>
                </div>
                <hr>
                <ng-container  *ngIf="!hasForeign">
                    <div class="cols">
                        <div class="col-12">
                            <mat-checkbox [(ngModel)]="doNotGenerateReference" name="doNotGenerateReference">{{'enrollment_lblDoNotGenerateReference' | translate }}</mat-checkbox>
                        </div>
                    </div>
                    <div class="cols" *ngIf="!doNotGenerateReference">
                        <div class="col-4">
                            <mat-form-field>
                                <mat-select [placeholder]="'order_lblReferenceSignatureType' | translate" required name="referenceSignatureType" [(ngModel)]="enrollmentOrder.ReferenceSignatureTypeId">
                                    <mat-option *ngFor="let t of signatureTypes" [value]="t.Id">
                                        {{getTranslatedValue(t)}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-8" *ngIf="showSignerField">
                            <mat-form-field>
                                <mat-select [placeholder]="'order_lblReferenceSigner' | translate" required name="referenceSigner" [(ngModel)]="enrollmentOrder.ReferenceSignerId">
                                    <mat-option *ngFor="let t of signers" [value]="t.Id">
                                        {{getTranslatedValue(t)}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </ng-container>
                <hr>
                <div class="cols">
                    <div class="col-4">{{'enrollment_lblStartDate' | translate}}</div>
                    <div class="col-8">
                        {{program.StudyStartDate | appDate}}
                    </div>
                </div>
                <div class="cols">
                    <div class="col-4">{{'enrollment_lblProgram' | translate}}</div>
                    <div class="col-8">
                        {{program.Title}}
                    </div>
                </div>
                <hr>
                <div class="cols">
                    <div class="col-4" ><b>{{'enrollment_dialogSignerTitle' | translate}}</b></div>
                </div>
                <div class="cols">
                    <div class="col-4">
                        <mat-radio-button [checked]="!isManualSignerSelected" (change)="isManualSignerSelected = false">
                            {{'enrollment_dialogFillAutomatically' | translate}} - <b> {{ signerName }} </b>
                        </mat-radio-button>
                    </div>
                </div>
                <div class="cols">
                    <div class="col-4">
                        <mat-radio-button (change)="isManualSignerSelected = true">
                            {{'enrollment_dialogFillManually' | translate}}
                        </mat-radio-button>
                    </div> 
                    <div class="col-8" *ngIf="isManualSignerSelected">
                        <mat-form-field>
                            <mat-select [placeholder]="'enrollment_dialogFillPlaceholder' | translate" required name="imatriculationOrderSigner" [(ngModel)]="enrollmentOrder.DvsSignerId">
                                <mat-option *ngFor="let t of imatriculationOrderSigners" [value]="t.LegacyCode">
                                    {{getTranslatedValue(t)}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <hr>
                <ul class="block-list">
                    <li>
                        <mat-checkbox (change)="selectAll($event)" name="selectApp_all" class="mat-align-top">
                            <b>{{'enrollment_AllApplications' | translate}}</b>
                        </mat-checkbox>
                    </li>
                    <li class="applicants" *ngFor="let app of applications">
                        <div class="cols">
                            <div class="col-8" *ngIf="app.EnrollmentStatus == 'enrollmentStatus_readyforenrollment' || canForce">
                                <mat-checkbox [(ngModel)]="app.IsSelected" [name]="'selectApp_'+app.Number" class="mat-align-top">
                                    {{app.Name+' '+app.Surname}}
                                </mat-checkbox>
                            </div>
                            <div class="col-8" *ngIf="app.EnrollmentStatus != 'enrollmentStatus_readyforenrollment' && !canForce">
                                <a [routerLink]="'/applications/'+app.Number | localize" target="_blank">{{app.Name+' '+app.Surname}}</a>
                            </div>
                            <div [className]="(app.EnrollmentStatus != 'enrollmentStatus_readyforenrollment' && canForce) ? 'col-4 status warning-status' : (app.EnrollmentStatus != 'enrollmentStatus_readyforenrollment')?'col-4 warning-status':'col-4 status'">{{app.EnrollmentStatus | translate}}</div>
                        </div>
                    </li>
                </ul>
            </form>
        </mat-dialog-content>
        <mat-dialog-actions>
            <button mat-raised-button color="primary"
                    [disabled]="!enrollmentForm.valid || !enrollmentOrder || !enrollmentOrder.OrderDate || !enrollmentOrder.ProtocolNumber || !isSelected"
                    (click)="createEnrollOrder()">
                <ng-container>{{'confirmDialog_ok' | translate}}</ng-container>
            </button>
            <button mat-raised-button mat-dialog-close>
                <ng-container>{{'confirmDialog_cancel' | translate}}</ng-container>
            </button>
        </mat-dialog-actions>
    </div>
</div>
