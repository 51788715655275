import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DocumentTemplate, DocumentTemplateType } from '../models/Template';

import { AppService } from '../services/app.service';
import { TemplateService } from '../services/template.service';

@Component({
  selector: 'app-template',
  templateUrl: './template.component.html',
  styleUrls: ['./templates.component.css']
})
export class TemplateComponent implements OnInit {
    constructor(
        public app: AppService,
        private service: TemplateService,
        private route: ActivatedRoute
    ) { }

    @ViewChild('fileLvInput', { static: false }) private fileLvInput: ElementRef;
    @ViewChild('fileEnInput', { static: false }) private fileEnInput: ElementRef;

    item: DocumentTemplate = new DocumentTemplate();
    title: string;
    fileUploadedNameEn: string = '';
    fileUploadedNameLv: string = '';
    minDate: Date = this.app.env.minDate;
    maxDate: Date = this.app.env.maxDate;
    types: DocumentTemplateType[] = [DocumentTemplateType.Order, DocumentTemplateType.Suborder, DocumentTemplateType.Interview, DocumentTemplateType.Reports];
    isLoaded: boolean;

    ngOnInit() {
        this.route.params.subscribe(para => {
            let id = para['id'];
            
            if (+id == id) {
                let loading = this.app.showLoading();
                this.service.get(id).subscribe(data => {
                    this.item = data;
                    this.title = data.Title;

                    let filename = data.FileName;

                    if (filename.indexOf('.docx') < 0)
                        filename = data.FileName + '.docx';

                    this.fileUploadedNameLv = filename;
                    this.fileUploadedNameEn = filename;

                    this.app.hideLoading(loading);

                    this.isLoaded = true;
                }, err => this.app.showLoadError(err));
            } else {
                this.isLoaded = true;
            }
        });

        //this.app.auth.CurrentUser.subscribe((user) => {
        //    if (user) {
        //        let rights = [];//user.rights || [];

        //        if (rights.indexOf('ES_MAIN.TEMPLATES.APPLICATION'))
        //            this.types.push(DocumentTemplateType.Application);

        //        if (rights.indexOf('ES_MAIN.TEMPLATES.PURCHASE'))
        //            this.types.push(DocumentTemplateType.Purchase);

        //        if (rights.indexOf('ES_MAIN.TEMPLATES.CERT_REQUEST'))
        //            this.types.push(DocumentTemplateType.CertificateRequest);
        //    } else {
        //        this.types = [];
        //    }
        //});
    }

    setFile(event, lang: string) {
        if (lang === 'en') {
            this.fileUploadedNameEn = '';

            if (this.fileEnInput.nativeElement.files.length > 0) {
                this.fileUploadedNameEn = this.fileEnInput.nativeElement.files[0].name;
                let reader = new FileReader();
                reader.readAsDataURL(this.fileEnInput.nativeElement.files[0]);
                reader.onload = (e) => {
                    const res = <string>reader.result;
                    this.item.FileEn = <any>res.substring(res.lastIndexOf(',') + 1);
                };
            }
        } else {
            this.fileUploadedNameLv = '';

            if (this.fileLvInput.nativeElement.files.length > 0) {
                this.fileUploadedNameLv = this.fileLvInput.nativeElement.files[0].name;
                let reader = new FileReader();
                reader.readAsDataURL(this.fileLvInput.nativeElement.files[0]);
                reader.onload = (e) => {
                    const res = <string>reader.result;
                    this.item.FileLv = <any>res.substring(res.lastIndexOf(',')+1);
                };
            }
        }
    }

    getFileUrl(item: DocumentTemplate, language: string) {
        return this.service.getFileUrl(item.Id, language);
    }

    saveTemplate() {
        let call = this.item.Id
            ? this.service.update(this.item)
            : this.service.add(this.item);

        let loading = this.app.showLoading();

        call.subscribe(template => {
            this.app.hideLoading(loading);
            this.app.redirect('/templates');
        }, err => this.app.showSaveError(err));
    }
}
