import { Routes } from '@angular/router';

import { DeactivateGuardService } from './CanDeactivateGuard';

import { LoginComponent } from './login/login.component';
import { AccountComponent } from './account/account.component';
import { RegisterComponent } from './register/register.component';
import { ParametersComponent } from './parameters/parameters.component';
import { ClassifiersComponent } from './classifiers/classifiers.component';
import { AdmissionsComponent } from './admissions/admissions.component';
import { ApplicationsComponent } from './applications/applications.component';
import { ApplicationEditComponent } from './applications/application-edit.component';
import { ApplicationSearchComponent } from './applications/search.component';
import { ProgramAddComponent as AppProgramAddComponent } from './applications/program-add.component';
import { PersonComponent as AppPersonComponent } from './applications/person.component';
import { IndexComponent as PreviousUniversityComponent } from './applications/previous-university/index.component';
import { AdmissionEditComponent } from './admissions/admission-edit.component';
import { TerritoriesComponent } from './territories/territories.component';
import { BanklinkComponent } from './banklink/banklink.component';
import { RegisterComponent as BankLinkRegisterComponent } from './banklink/register.component';
import { ProgramResultsComponent } from './programs/results.component';
import { ProgramInterviewsComponent } from './interviews/program-interviews.component'
import { ProgramExamsComponent } from './programs/exams.component';
import { QuizzeditorComponent } from './quizzeditor/list/quizzeditor-list.component';
import { QuizzeditorEditComponent } from './quizzeditor/editquizz/quizzeditor-edit.component';
import { QuizzgroupComponent } from './quizzgroup/quizzgroup.component';
import { QuizzquestionComponent } from './quizzquestion/quizzquestion.component';
import { MessagesComponent } from './messages/messages.component';
import { UsersComponent } from './users/users.component';
import { LogComponent } from './log/log.component';
import { TemplatesComponent } from './templates/templates.component';
import { TemplateComponent } from './templates/template.component';
import { ApplicationSchedulesComponent } from './applications/application-schedules/application-schedules.component';
import { InterviewersComponent } from './programs/interviewers/interviewers.component';
import { InterviewersGridComponent } from './programs/interviewerGrid/interviewersgrid.component';
import { InterviewPersonnelComponent } from './programs/interviewpersonnel/interviewpersonnel.component';
import { SharedItemsComponent } from './share/shared-items.component';
import { SharedGoComponent } from './share/shared-go.component';

import { ApplicationHistoryComponent } from './applications/history/application.component';
import { ApplicationEducationsHistoryComponent } from './applications/history/educations.component';
import { ApplicationExamsHistoryComponent } from './applications/history/exams.component';
import { InterviewHistoryComponent } from './interviews/interview-history.component';
import { ApplicationProgramsHistoryComponent } from './applications/history/programs.component';
import { ApplicationPersonDocumentsHistoryComponent } from './applications/history/person-documents.component';
import { MyInterviewsComponent } from './interviews/my-interviews.component';
import { InterviewPersonnel } from './models/Program';
import { InterviewEditComponent } from './interviews/interview-edit.component';

export const routes: Routes = [
    {
        path: '',
        component: LoginComponent
    },
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: 'account/:type',
        component: AccountComponent
    },
    {
        path: 'account/:type/:confirmKey',
        component: AccountComponent
    },
    {
        path: 'banklink/register/:nonce',
        component: BankLinkRegisterComponent
    },
    {
        path: 'banklink/:bankname',
        component: BanklinkComponent
    },
    {
        path: 'banklink/:bankname/:key',
        component: BanklinkComponent
    },
    {
        path: 'register',
        component: RegisterComponent
    },
    {
        path: 'applications',
        component: ApplicationsComponent
    },
    {
        path: 'applications/search',
        component: ApplicationSearchComponent
    },
    {
        path: 'applications/:id',
        component: ApplicationEditComponent,
        canDeactivate: [DeactivateGuardService]
    },
    {
        path: 'applications/:id/programs/add',
        component: AppProgramAddComponent
    },
    {
        path: 'applications/:id/person',
        component: AppPersonComponent,
        canDeactivate: [DeactivateGuardService]
    },
    {
        path: 'applications/:id/previousuniversity',
        component: PreviousUniversityComponent,
        canDeactivate: [DeactivateGuardService]
    },
    {
        path: 'applications/:id/history',
        component: ApplicationHistoryComponent
    },
    {
        path: 'applications/:id/educationsHistory',
        component: ApplicationEducationsHistoryComponent
    },
    {
        path: 'applications/:id/personDocumentsHistory',
        component: ApplicationPersonDocumentsHistoryComponent
    },
    {
        path: 'applications/:id/programsHistory',
        component: ApplicationProgramsHistoryComponent
    },
    {
        path: 'applications/:id/examsHistory',
        component: ApplicationExamsHistoryComponent
    },
    {
        path: 'interviews/:applicationExamId/:interviewerId/history',
        component: InterviewHistoryComponent
    },
    {
      path: 'application-schedules/:id',
      component: ApplicationSchedulesComponent
    },
    {
        path: 'classifiers',
        component: ClassifiersComponent
    },
    {
        path: 'classifiers/:type',
        component: ClassifiersComponent
    },
    {
        path: 'territories',
        component: TerritoriesComponent
    },
    {
        path: 'parameters',
        component: ParametersComponent
    },
    {
        path: 'admissions',
        component: AdmissionsComponent
    },
    {
        path: 'admissions/:id',
        component: AdmissionEditComponent
    },
    {
        path: 'programs/:id/exams',
        component: ProgramExamsComponent
    },
    {
        path: 'programs/:id/results',
        component: ProgramResultsComponent
    },
    {
        path: 'programs/:id/interviewers',
        component: InterviewersComponent
    },
    {
        path: 'programs/:id/interviewpersonnel',
        component: InterviewPersonnelComponent
    },
    {
        path: 'programs/:id/interviewersgrid',
        component: InterviewersGridComponent
    },
    {
        path: 'programs/:id/interviews/:examId',
        component: ProgramInterviewsComponent
    },
    {
        path: 'interviews',
        component: MyInterviewsComponent
    },
    {
        path: 'interviews/:applicationExamId/:interviewerId',
        component: InterviewEditComponent
    },
    {
        path: 'quizzeditor',
        component: QuizzeditorComponent
    },
    {
        path: 'quizzeditor/:id',
        component: QuizzeditorEditComponent
    },
    {
        path: 'quizzgroup/:id',
        component: QuizzgroupComponent
    },
    {
        path: 'quizzquestion/:id',
        component: QuizzquestionComponent
    },
    {
        path: 'messages',
        component: MessagesComponent
    },
    {
        path: 'messages/:id',
        component: MessagesComponent
    },
    {
        path: 'log',
        component: LogComponent
    },
    {
        path: 'users',
        component: UsersComponent
    },
    {
        path: 'templates',
        component: TemplatesComponent
    },
    {
        path: 'template/new',
        component: TemplateComponent
    },
    {
        path: 'templates/:id',
        component: TemplateComponent
    },
    {
        path: 'shared',
        component: SharedItemsComponent
    },
    {
        path: 'shared/go/:id',
        component: SharedGoComponent
    }
];
