import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';

import { Classifier, ClassifierType } from '../models/Classifier';

import { AppService } from '../services/app.service';
import { ClassifierService } from '../services/classifier.service';

import { GridComponentBase } from '../GridComponentBase';

@Component({
  selector: 'app-classifiers',
  templateUrl: './classifiers.component.html',
  styleUrls: ['./classifiers.component.css']
})
export class ClassifiersComponent extends GridComponentBase<Classifier> implements OnInit {
    itemType: string;
    typeList: any[] = [];

    constructor(public app: AppService, private route: ActivatedRoute, private service: ClassifierService) {
        super(app);
    } 

    payloadConfig = {
        menubar: false,
        convert_urls: false,
        forced_root_block: '',
        entity_encoding: 'raw',
        plugins: 'code',
        toolbar:
            'undo redo | code',
        paste_data_images: false,
        image_title: false,
        image_description: false,
        image_dimensions: false,
        image_caption: false,
    };
    

    ngOnInit() {
        const params = this.route.snapshot.params;

        this.itemType = params['type'];
        
        if (this.itemType) {
            let loading = this.app.showLoading();
            this.service.get(this.itemType).subscribe(data => {
                this.gridItems = data;
                this.app.hideLoading(loading);
            }, err => this.app.showLoadError(err));
        } else {
            this.typeList = Object.keys(ClassifierType).map(t => ClassifierType[t])
                .map(t => {
                    return {
                        key: t,
                        value: this.app.translate.instant(`classifierType_${t}`)
                    };
                }).concat([{ key: '/territories', value: this.app.translate.instant('territories_title') }])
                .sort((a, b) => a.value.localeCompare(b.value));
        }
    }

    isValidGridItem(item: Classifier): boolean {
        return !!(item.ValueLV && item.ValueEN);
    }

    protected createGridItem(item: Classifier): Observable<Classifier> {
        return this.service.create(item);
    }

    protected updateGridItem(item: Classifier): Observable<Classifier> {        
        return this.service.update(item);
    }

    protected deleteGridItem(item: Classifier): Observable<boolean> {                
        if(this.gridItems.filter(element => element.Code === "LV").length == 1 && item.Code === "LV" && item.Type === ClassifierType.EducationAcquiredIn) {
            this.app.notify('classifiers_cannotDeleteLV');
            return;
        }
        else             
            return this.service.delete(item.Id);

    }

    protected createEmptyObject(): Classifier {
        let item = new Classifier();
        item.Type = this.itemType;
        return item;
    }
}
