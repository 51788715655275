import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

export class ContactsDialogOptions {
    phone?: string;
    email?: string;
    emailSubject?: string;
}

@Component({
    template: `
        <div class="contacts-dialog">
            <h2 mat-dialog-title>{{'contactsDialog_title' | translate}}</h2>
            <mat-dialog-content>
                <div *ngIf="phone"><a [href]="skypeUrl">{{phone}}</a></div>
                <div *ngIf="email"><a href="mailto:{{email}}?subject={{emailSubject || ''}}">{{email}}</a></div>
            </mat-dialog-content>
            <mat-dialog-actions>
                <button mat-raised-button [mat-dialog-close]="true" color="primary">
                    <ng-container>{{'contactsDialog_ok' | translate}}</ng-container>
                </button>
            </mat-dialog-actions>
        </div>`
})
export class ContactsDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<ContactsDialogComponent>,
        private sanitizer: DomSanitizer,
        @Inject(MAT_DIALOG_DATA) public data: ContactsDialogOptions
    ) {
        this.phone = data.phone;
        this.email = data.email;
        this.emailSubject = data.emailSubject;
    }

    phone: string;
    email: string;
    emailSubject: string;

    get skypeUrl(): any {
        return this.sanitizer.bypassSecurityTrustUrl(`callto:tel:+${this.phone}`);
    }
}
