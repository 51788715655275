<div class="login-container" *ngIf="!isAuthenticated">
    <form name="form" class="block form-centered" (ngSubmit)="f.form.valid && login()" #f="ngForm" novalidate>
        <header class="block-header" *ngIf="!isHomines">
            <h1 [translate]="'eenrollmenttitle'"></h1>
        </header>
        <header class="block-header" *ngIf="isHomines">
            <h1 [translate]="'loginlabel'"></h1>
        </header>
        <div class="block-body">
            <div class="email-login" *ngIf="emailAuthEnabled">
                <div class="alert alert-danger" *ngIf="loginError">
                    {{'loginincorrect' | translate}}
                </div>
                <div class="form-row">
                    <mat-form-field>
                        <input matInput type="email" name="email" [(ngModel)]="emailValue" #email="ngModel"
                               [placeholder]="'emaillabel' | translate" required autofocus email />
                        <mat-error *ngIf="f.submitted && email && !email.valid">{{'emailerror' | translate}}</mat-error>
                    </mat-form-field>
                </div>
                <div class="form-row">
                    <mat-form-field>
                        <input matInput type="password" name="password" [(ngModel)]="passwordValue" [placeholder]="'password' | translate" #password="ngModel" required />
                    </mat-form-field>
                </div>
                <div class="form-submit">
                    <button mat-raised-button color="primary" type="submit" [disabled]="loading">
                        {{'loginlabel' | translate}}
                    </button>

                    <div class="form-more">
                        <p>
                            <a [routerLink]="['/account','restore'] | localize" [translate]="'restorepassword'"></a>
                        </p>
                        <p>
                            <a [routerLink]="['/register'] | localize" [translate]="'registerlabel'"></a>
                        </p>
                    </div>
                </div>
            </div>
            <div class="oauth-login" *ngIf="oAuthEnabled">
                <h3 class="login-title oauth-login-title" [translate]="'uselatvijalv'"></h3>
                <h3 class="login-title oauth-login-subtitle" [translate]="'uselatvijalvsub'"></h3>
                <div class="oauth-list">
                    <a [routerLink]="['/banklink','oauth'] | localize" class="login-btn">
                        <img src="/assets/img/latvija-logo.png" />
                    </a>
                </div>
            </div>
            <div class="bank-login" *ngIf="bankAuthEnabled">
                <h3 class="login-title" [translate]="'usebankauth'"></h3>

                <ul class="bank-list clearfix">
                    <li>
                        <a [routerLink]="['/banklink','swedbank'] | localize" class="login-btn">
                            <img src="/assets/img/swedbank-logo.png" />
                        </a>
                    </li>
                    <li>
                        <a [routerLink]="['/banklink','citadele'] | localize" class="login-btn">
                            <img src="/assets/img/citadele-logo.png" />
                        </a>
                    </li>
                    <li>
                        <a [routerLink]="['/banklink','seb'] | localize" class="login-btn">
                            <img src="/assets/img/seb-logo.png" />
                        </a>
                    </li>
                    <li>
                        <a [routerLink]="['/banklink','nordea'] | localize" class="login-btn">
                            <img src="/assets/img/Luminor_Nordea_burgundy.png" class="luminor" />
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </form>
 </div>