import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProgramApplicationStatus } from '../models/Program';
import { Admission, AdmissionResult, AdmissionApplicant, AdmissionOrder, AdmissionCrm } from '../models/Admission';
import { environment as ENV } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

/**
 * Admission service
 */
@Injectable()
export class AdmissionService {
    constructor(public http: HttpClient) { }

    public get apiUrl(): string { return `${ENV.apiUrl}/admissions` }
    public get apiCrmUrl(): string { return `${ENV.apiUrl}/crm` }
    public get apiselectionAlgorithmUrl(): string { return `${ENV.apiUrl}/selectionalgorithm` }

    getFromCrm(): Observable<AdmissionCrm[]> {
        return this.http.get<AdmissionCrm[]>(this.apiCrmUrl + '/actions/admissions');
    }

    refreshFromCrm(admission: Admission) {
        return this.http.post<{
            Message: string,
            AffectedPrograms: { Id: string, Title: string }[]
        }>(this.apiCrmUrl + '/actions/refresh', { admissionId: admission.Id });
    }

    refreshFormsFromCrm(admission: Admission) {
        return this.http.post<{
            Message: string,
            AffectedPrograms: { Id: string, Title: string }[]
        }>(this.apiCrmUrl + '/actions/refreshForms', { admissionId: admission.Id });
    }

    get(): Observable<Admission[]> {
        return this.http.get<Admission[]>(this.apiUrl);
    }

    getById(id: number): Observable<Admission> {
        const url = `${this.apiUrl}/${id}`;
        return this.http.get<Admission>(url).pipe(map(data =>  this.prepareItem(data)));
    }

    getContractStatuses(id: number): Observable<ProgramApplicationStatus[]> {
        const url = `${this.apiUrl}/${id}/contracts`;
        return this.http.get<ProgramApplicationStatus[]>(url);
    }

    getApplicationCount(id: number): Observable<number> {
        const url = `${this.apiUrl}/${id}/applicationCount`;
        return this.http.get<number>(url);
    }

    add(admissionCrm: AdmissionCrm): Observable<Admission> {
        return this.http.post<Admission>(this.apiUrl, admissionCrm).pipe(map(res => this.prepareItem(res)));
    }

    update(item: Admission): Observable<any> {
        const url = `${this.apiUrl}/${item.Id}`;
        return this.http.put<Admission>(url, {
            Title: item.Title,
            DateFrom: item.DateFrom,
            DateTo: item.DateTo,
            TimeTo: item.TimeTo,
            ContractFilesAccessibleFrom: item.ContractFilesAccessibleFrom,
            ContractFilesAccessibleUntil: item.ContractFilesAccessibleUntil,
            PublishDate: item.PublishDate,
            PublishTime: item.PublishTime,
            DateExtended: item.DateExtended,
            VisibleUntilDate: item.VisibleUntilDate,
            PriorityCount: item.PriorityCount,
            NotificationCode: item.NotificationCode,
            AppSubmitNotificationCode: item.AppSubmitNotificationCode,
            AppApproveNotificationCode: item.AppApproveNotificationCode,
            InAdmissionNotificationCode: item.InAdmissionNotificationCode,
            ResultBudgetInNotificationCode: item.ResultBudgetInNotificationCode,
            ResultBudgetOutNotificationCode: item.ResultBudgetOutNotificationCode,
            ResultNotParticipatedNotificationCode: item.ResultNotParticipatedNotificationCode,
            ResultFeeInNotificationCode: item.ResultFeeInNotificationCode,
            ResultFeeOutNotificationCode: item.ResultFeeOutNotificationCode,
            ContractESignEmailBodyCode: item.ContractESignEmailBodyCode,
            ContractESignEmailSubjectCode: item.ContractESignEmailSubjectCode,
            ContractSignApprovalEmailNotificationBody : item.ContractSignApprovalEmailNotificationBody,
            ContractSignApprovalEmailNotificationSubject : item.ContractSignApprovalEmailNotificationSubject,
            ExtensionPriorityCount: item.ExtensionPriorityCount,
            PdfAvailable: item.PdfAvailable
        });
    }

    getResults(id: number): Observable<AdmissionResult[]> {
        const url = `${this.apiUrl}/${id}/results`;
        return this.http.get<AdmissionResult[]>(url);
    }

    getApplicants(id: number): Observable<AdmissionApplicant[]> {
        const url = `${this.apiUrl}/${id}/applicants`;
        return this.http.get<AdmissionApplicant[]>(url);
    }

    getOrders(id: number, options?: {
        dateFrom?: string,
        dateTo?: string
    }): Observable<AdmissionOrder[]> {
        const url = `${this.apiUrl}/${id}/orders`;
        return this.http.get<AdmissionOrder[]>(url, { params: options });
    }

    getAvailable(): Observable<any[]> {
        const url = `${this.apiUrl}/available`;
        return this.http.get<any[]>(url);
    }

    launchAlgorithm(admissionId: number): Observable<Admission> {
        const url = `${this.apiselectionAlgorithmUrl}/${admissionId}`;
        return this.http.post<Admission>(url, null);
    }

    setApplicationUnitedRZStatus(id: number, status: string): Observable<any> {
        const url = `${this.apiUrl}/${id}/applicationUnitedRZStatus`;
        return this.http.post(url, { Status: status });
    }

    sendApplicantEmails(id: number, args: { programIds: number[], subject: string, notificationCode: string, applicantType: string }): Observable<any> {
        const url = `${this.apiUrl}/${id}/applicantEmails`;
        return this.http.post(url, args);
    }

    anonymise(id: number): Observable<any> {
        const url = `${this.apiUrl}/${id}/anonymise`;
        return this.http.post(url, null);
    }

    private prepareItem(item: any): any {
        if (item.PublishTime) {
            item.PublishTime = (item.PublishTime || '').split(':').slice(0, 2).join(':');
        }
        if (item.TimeTo) {
            item.TimeTo = (item.TimeTo || '').split(':').slice(0, 2).join(':');
        }

        return item;
    }
}
