import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AppService } from '../services/app.service';
import { MessagesService } from '../messages/messages.service';
import { BanklinkService } from './banklink.service';

@Component({
    selector: 'app-banklink-register',
    templateUrl: './register.component.html'
})
export class RegisterComponent implements OnInit {
    constructor(
        public app: AppService,
        public router: Router,
        public route: ActivatedRoute,
        private service: BanklinkService,
        private messageService: MessagesService
    ) {}

    nonce: string;
    consent: boolean;
    consentBody: string;
    consentCheckbox: string;

    ngOnInit() {
        const consentBodyCode = 'APPLICANT_CONSENT_BODY';
        const consentCheckboxCode = 'APPLICANT_CONSENT_CB';

        if (this.app.auth.isAuthenticated)
            this.router.navigate([this.app.localize.translateRoute('/')]);

        const params = this.route.snapshot.params;

        this.nonce = params['nonce'];

        if (!this.nonce)
            this.router.navigate([this.app.localize.translateRoute('/')]);

        this.messageService.getForApplicantConsent(this.app.translate.currentLang).subscribe(data => {
            let body = data.find(t => t.Code === 'APPLICANT_CONSENT_BODY');
            let checkbox = data.find(t => t.Code === 'APPLICANT_CONSENT_CB');

            if (!body)
                body = { Text: 'APPLICANT_CONSENT_BODY' };

            if (!checkbox)
                checkbox = { Text: 'APPLICANT_CONSENT_CB' };

            this.consentBody = body.Text;
            this.consentCheckbox = checkbox.Text;
        }); 
    }

    submit() {
        const loading = this.app.showLoading();
        this.service.register(this.nonce, this.app.translate.currentLang, this.consent).subscribe(result => {
            this.app.hideLoading(loading);
            this.app.auth.checkLocalContext(result).subscribe(response => {
                this.router.navigate([this.app.localize.translateRoute('/')]);
            });
        }, err => this.app.showError(err));
    }
}
