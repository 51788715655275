import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AppService } from '../services/app.service';
import { ApplicationService } from '../services/application.service';

import { Application, ApplicationStatus } from '../models/Application';
import { setReturnTo } from './application-edit.component';

import { ICanDeactivateGuard } from '../CanDeactivateGuard';

@Component({
    selector: 'app-application-person',
    template: `
        <app-application-person-edit *ngIf="item && canEdit" [item]="item" #editCmp></app-application-person-edit>
        <app-application-person-view *ngIf="item && !canEdit" [item]="item"></app-application-person-view>`
})
export class PersonComponent implements OnInit, ICanDeactivateGuard {
    constructor(
        public app: AppService,
        private service: ApplicationService,
        private route: ActivatedRoute
    ) { }

    item: Application;
    canEdit: boolean;

    @ViewChild('editCmp', { static: false }) private editCmp: ICanDeactivateGuard;

    ngOnInit() {
        this.route.params.subscribe(params => {
            let appNumber = params['id'];

            if (!appNumber)
                return;

            let returnTo = this.route.snapshot.queryParams['returnTo'];

            if (returnTo)
                setReturnTo(returnTo);

            let loading = this.app.showLoading();

            this.app.auth.user.subscribe(user => {
                this.canEdit = this.app.auth.isAdmin() || this.app.auth.isPowerUser() || this.app.auth.isAdmClerk() || this.app.auth.isUnitedEnrollClerk();
            });

            this.service.getByNumber(appNumber).subscribe(data => {
                this.item = data;

                if (!this.canEdit)
                    this.canEdit = data.Status !== ApplicationStatus.Approved;

                this.app.hideLoading(loading);
            });
        });
    }

    canDeactivate() {
        return !this.editCmp ? true : this.editCmp.canDeactivate();
    }
}
