import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { NgForm, UntypedFormControl } from '@angular/forms';
import { switchMap, debounceTime, filter } from 'rxjs/operators';

import { AppService } from '../services/app.service';
import { ParameterService } from '../services/parameter.service';
import { ShareService } from '../services/share.service';

import { PersonSearchResult } from '../models/share';

import { Utils } from '../Utils';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-share',
    templateUrl: './share.component.html',
    styleUrls: ['./share.component.css']
})
export class ShareComponent implements OnInit {
    constructor(
        public dialogRef: MatDialogRef<ShareComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { objectType: string, objectIds: string[] },
        private app: AppService,
        private service: ShareService,
        private paramService: ParameterService
    ) {
        this.objectIds = data.objectIds;
        this.objectType = data.objectType;
    }

    objectIds: string[] = [];
    objectType: string;

    today = new Date();
    dateFrom: any;
    dateTo: any;
    personsControl = new UntypedFormControl();
    filteredPersons: PersonSearchResult[];
    selectedPersons: PersonSearchResult[] = [];
    isLoadingPersons: boolean;

    @ViewChild('form', { static: true }) form: NgForm;

    get isValid(): boolean {
        return this.form.valid && this.selectedPersons.length > 0;
    }

    ngOnInit() {
        this.dateFrom = this.today;
        
        this.personsControl.valueChanges.pipe(
            debounceTime(200),
            filter(value => value.length > 2),
            switchMap(value => {
                this.isLoadingPersons = true;
                return this.service.searchPersons(value);
            })
        ).subscribe((res: any) => {
            this.isLoadingPersons = false;
            this.filteredPersons = res;
        }, err => {
            this.isLoadingPersons = false;
        });

        this.paramService.getValues().subscribe(data => {
            let para = data.find(t => t.Code === 'ShareDefaultPeriodDays');
            this.dateTo = new Date();
            this.dateTo.setDate(this.today.getDate() + +para.Value);
        });
    }

    personSelected(event) {
        this.selectedPersons.push(event.option.value);
        this.personsControl.setValue('');
    }

    isPersonSelected(person: PersonSearchResult): boolean {
        return this.selectedPersons.some(t => t.UPN === person.UPN);
    }

    removePerson(person: PersonSearchResult) {
        let i = this.selectedPersons.indexOf(person);
        i > -1 && this.selectedPersons.splice(i, 1);
    }

    submit() {
        let loading = this.app.showLoading();

        this.service.create({
            dateFrom: Utils.ensureDate(this.dateFrom),
            dateTo: Utils.ensureDate(this.dateTo),
            objectIds: this.objectIds,
            objectType: this.objectType,
            persons: this.selectedPersons
        }).subscribe(res => {
            this.app.hideLoading(loading);
            this.app.notify(this.app.translate.instant('share_success'));
            this.dialogRef.close(res);
        }, err => this.app.showSaveError(err));
    }
}
