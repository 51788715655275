import { Component, Inject, OnInit } from '@angular/core';
import { Program } from '../models/Program';
import { ApplicationForEnrollment,EnrollmentOrder } from '../models/Application';
import { ClassifierService } from '../services/classifier.service';
import { ProgramService } from '../services/program.service';
import { AppService } from '../services/app.service';
import { Classifier } from '../models/classifier';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export class EnrollmentDialogOptions {
    constructor(public program: Program, public applications: ApplicationForEnrollment[]) { }
}

@Component({
    styleUrls: ['./enrollment-dialog.component.css'],
    templateUrl: './enrollment-dialog.component.html'
})
export class EnrollmentDialogComponent implements OnInit {
    constructor(
        private classifierService: ClassifierService,
        private programService: ProgramService,
        private app: AppService,
        public dialogRef: MatDialogRef<EnrollmentDialogOptions>,
        @Inject(MAT_DIALOG_DATA) public data: EnrollmentDialogOptions
    ) {
        this.program = data.program;
        this.canForce = this.app.auth.isAdmin();
        this.applications = data.applications.sort((one, two) => (one.Surname + ' ' + one.Name < two.Surname + ' ' + two.Name ? -1 : 1));
        this.minDate = this.app.env.minDate;
        this.maxDate = this.app.env.maxDate;
        this.enrollmentOrder = new EnrollmentOrder();
        this.enrollmentOrder.ProtocolNumber = '';
    }

    program: Program;
    applications: ApplicationForEnrollment[] = [];
    signatureTypes: Classifier[] = [];
    signers: Classifier[] = [];
    imatriculationOrderSigners: Classifier[] = [];
    enrollmentOrder: EnrollmentOrder;
    minDate: Date;
    maxDate: Date;
    canForce: boolean;
    doNotGenerateReference: boolean = false;
    isManualSignerSelected: boolean = false;
    signerName: string;

    get hasForeign(): boolean {
        return (this.applications.filter(a => { return a.IsForeign }).length > 0 ? true : false);
    }

    get isSelected():boolean {
        return this.applications.filter(a => a.IsSelected).length > 0;
    }

    get showSignerField(): boolean {
        return this.enrollmentOrder.ReferenceSignatureTypeId && this.signatureTypes.some(t => t.Id === this.enrollmentOrder.ReferenceSignatureTypeId && t.Code === 'DigitalSignature');
    }

    ngOnInit() {
        this.classifierService.get('ReferenceSignatureType,ReferenceSigner,ImatriculationOrderSigner').subscribe(res => {
            this.signatureTypes = res.filter(t => t.Type === 'ReferenceSignatureType');
            this.signers = res.filter(t => t.Type === 'ReferenceSigner');
            this.imatriculationOrderSigners = res.filter(t => t.Type === 'ImatriculationOrderSigner');
        });
        this.programService.getSignerName(this.program.Id).subscribe(
            t => this.signerName = t
        );
    }

    selectAll(event) {
        this.applications.forEach(a => {
            if (a.EnrollmentStatus == 'enrollmentStatus_readyforenrollment' || this.canForce)
                a.IsSelected = (event && event.checked);
        });
    }

    createEnrollOrder() {
        let notReady = this.applications.filter(a => a.IsSelected && a.EnrollmentStatus != 'enrollmentStatus_readyforenrollment');
        this.enrollmentOrder.IsSelectedAutomatically = !this.isManualSignerSelected;
        if (notReady.length > 0) {
            let appNumbers = notReady.map(a => this.getAppLink(a.Number)).join(',');
            this.app.confirm({
                title: this.app.translate.instant('enrollment_confirmForce'),
                okText: this.app.translate.instant('enrollment_doEnroll'),
                cancelText: this.app.translate.instant('cancel'),
                text: this.app.translate.instant('enrollment_notReadyList', { appnumbers: appNumbers }),
            }, (result) => {
                if (result) {
                    this.create();
                }
            });
        }
        else
            this.create();

    }

    getAppLink(number: string) {
        let route = this.app.localize.translateRoute(`/applications/${number}`);
        return `<a href="${route}" target="_blank">${number}</a>`;
    }

    create() {
        this.enrollmentOrder.ApplicationNumbers = this.applications.filter(a => a.IsSelected).map(a => a.Number);
        if (this.doNotGenerateReference) {
            this.enrollmentOrder.ReferenceSignatureTypeId = null;
            this.enrollmentOrder.ReferenceSignerId = null;
        }
        let loading = this.app.showLoading();
        this.programService.createEnrollOrder(this.program.Id, this.enrollmentOrder).subscribe(r => {
            this.app.hideLoading(loading);
            this.dialogRef.close(true);
        }, err => {
            this.app.hideLoading(loading);
            this.app.showError(err);

            this.dialogRef.close(false);
        });
    }

    getTranslatedValue(c: Classifier) {
        if (c) {
            if (this.app.translate.currentLang == 'en' && c.ValueEN)
                return c.ValueEN;
            else
                return c.Value;
        }
        else
            return '';
    }
}
