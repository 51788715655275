import { Component, OnInit, Input } from '@angular/core';

import { AppService } from '../../services/app.service';
import { ApplicationService } from '../../services/application.service';
import { ParameterService } from '../../services/parameter.service';

import { Application, ApplicationPreviousUniversity } from '../../models/Application';

@Component({
    selector: 'app-application-prevuniversity-view',
    templateUrl: './view.component.html'
})
export class ViewComponent implements OnInit {
    constructor(
        public app: AppService,
        private service: ApplicationService,
        private params: ParameterService
    ) { }

    @Input() item: ApplicationPreviousUniversity = new ApplicationPreviousUniversity();

    application: Application = new Application();
    transferRulesUrl: string;

    ngOnInit() {
        this.service.getById(this.item.ApplicationId).subscribe(data => {
            this.application = data;
        });

        this.params.getValues().subscribe(values => {
            let para = values.find(t => t.Code === 'TransferRulesUrl');

            if (para)
                this.transferRulesUrl = para.Value;
        });
    }

    inArray<T>(value: T, array: T[]) {
        return array ? array.indexOf(value) > -1 : false;
    }
}