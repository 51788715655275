<h2 mat-dialog-title>{{'applications_editGradeTitle' | translate}}</h2>
<mat-dialog-content>
    <div *ngIf="gradeRecalcWarning" class="alert alert-warning" [innerHTML]="gradeRecalcWarning | safeHtml"></div>
    <form #form="ngForm">
        <label>{{'applications_lblGradeSystem' | translate}}</label>
        <mat-radio-group [(ngModel)]="system" name="system" required class="radio-rows">
            <mat-radio-button *ngFor="let s of systems" [value]="s">{{s.label | translate}}</mat-radio-button>
        </mat-radio-group>
        <div *ngIf="auxTypes.length">
            <mat-form-field>
                <mat-select [placeholder]="'applications_lblExamAuxType' | translate" name="auxType" autofocus required
                            [errorStateMatcher]="gradeErrorMatcher" [ngModel]="auxType" (ngModelChange)="onAuxTypeChange($event)">
                    <mat-option *ngFor="let t of auxTypes" [value]="t">
                        {{t}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div>
            <mat-form-field>
                <input matInput [placeholder]="'applications_lblGradeFromDocument' | translate" [ngModel]="grade"
                       [errorStateMatcher]="gradeErrorMatcher"
                       name="grade" autofocus required [pattern]="system?.validation" (ngModelChange)="onGradeChange($event)">
            </mat-form-field>
        </div>
        <div>
            <mat-form-field>
                <input matInput [placeholder]="'applications_lblPointsEarned' | translate" [value]="finalGrade || '-'" disabled>
            </mat-form-field>
        </div>
    </form>
    <div class="alert alert-info" *ngIf="isEditor && viisGrade" [innerHTML]="'applications_viisGrade_message' | translate | formatString:[viisGrade.substring(1)] | safeHtml"></div>
    <div class="alert alert-info" *ngIf="isEditor && crmGrade" [innerHTML]="'applications_crmGrade_message' | translate | formatString:[crmGrade.substring(1)] | safeHtml"></div>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-raised-button type="button" [mat-dialog-close]="result" color="primary" [disabled]="!form.valid">{{'ok' | translate}}</button>
    <button mat-button type="button" mat-dialog-close>{{'cancel' | translate}}</button>
</mat-dialog-actions>
