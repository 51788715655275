<header class="content-header">
    <h1 [translate]="'userslist_title'"></h1>
</header>

<form class="filter-form" #form="ngForm">
    <div class="cols">
        <div class="col-2">
            <mat-form-field>
                <mat-select [placeholder]="'userslist_lblAccountType' | translate"
                            [(ngModel)]="filterAccountType" name="filterAccountType">
                    <mat-option *ngFor="let at of AccountTypes" [value]="at.value">{{at.text | translate}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="col-2">
            <mat-form-field>
                <mat-select [placeholder]="'userslist_lblRole' | translate"
                            [(ngModel)]="filterRole" name="filterRole">
                    <mat-option *ngFor="let ut of UserTypes" [value]="ut.value">{{ut.text | translate}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="col-4">
            <mat-form-field>
                <input matInput [(ngModel)]="searchValue" name="searchValue" [placeholder]="'log_lblSearch' | translate" maxlength="255">
            </mat-form-field>
        </div>
    </div>

    <div>
        <button (click)="filter()" mat-raised-button color="primary" [disabled]="!form.valid">
            {{'filter' | translate}}
        </button>
    </div>
</form>

<ngx-datatable #grid
                [rows]="gridItems"
                [headerHeight]="'auto'"
                [limit]="20"
                [footerHeight]="'auto'"
                [rowHeight]="'auto'"
                [columnMode]="'force'"
                [loadingIndicator]="gridLoading"
                [externalSorting]="true"
                [messages]="{ emptyMessage: 'gridEmpty' | translate, totalMessage: 'gridTotal' | translate }"
                (sort)="onGridSort($event)">
    <ngx-datatable-column [name]="'userslist_lblUserName' | translate" prop="Username">
        <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex" let-value="row.Username">
            <span>
                {{value}}
            </span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [name]="'userslist_lblAccountType' | translate" prop="AccountType">
        <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex" let-value="row.AccountType">
            <span>
                {{'accountType_'+value | translate}}
            </span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [name]="'userslist_lblName' | translate" prop="Name">
        <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex" let-value="row.Name">
            <span *ngIf="!isGridEditing(row)">
                {{value}}
            </span>
            <mat-form-field *ngIf="isGridEditing(row)">
                <input required
                        matInput
                        name="Name"
                        type="text"
                        [(ngModel)]="row.Name" />
            </mat-form-field>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [name]="'userslist_lblLastName' | translate" prop="LastName">
        <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex" let-value="row.LastName">
            <span *ngIf="!isGridEditing(row)">
                {{value}}
            </span>
            <mat-form-field *ngIf="isGridEditing(row)">
                <input required
                        matInput
                        name="LastName"
                        type="text"
                        [(ngModel)]="row.LastName" />
            </mat-form-field>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [name]="'userslist_lblPersonCode' | translate" prop="PersonCode">
        <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex" let-value="row.PersonCode">
            <span *ngIf="!isGridEditing(row)">
                {{value}}
            </span>
            <mat-form-field *ngIf="isGridEditing(row)">
                <input required
                        matInput
                        name="PersonCode"
                        type="text"
                        [(ngModel)]="row.PersonCode" />
            </mat-form-field>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [name]="'userslist_lblRole' | translate" prop="UserType">
        <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex" let-value="row.UserType">
            <span *ngIf="!isGridEditing(row)">
                {{'userType_'+value | translate}}
            </span>
            <mat-form-field *ngIf="isGridEditing(row)" [floatLabel]="'never'">
                <mat-select [placeholder]="'userslist_lblRole' | translate" [required]="true"
                            [(ngModel)]="row.UserType" name="UserType">
                    <mat-option *ngFor="let ut of UserTypes" [value]="ut.value">{{ut.text | translate}}</mat-option>
                </mat-select>
            </mat-form-field>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [name]="'userslist_lblUniversity' | translate" prop="UniversityId">
        <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex" let-value="row.UniversityId">
            <span *ngIf="!isGridEditing(row)">
                {{getTranslatedValue(getUniversity(value))}}
            </span>
            <mat-form-field *ngIf="isGridEditing(row)" [floatLabel]="'never'">
                <mat-select [(ngModel)]="row.UniversityId" name="University">
                    <mat-option *ngFor="let ut of Universities" [value]="ut.Id">{{getTranslatedValue(ut)}}</mat-option>
                </mat-select>
            </mat-form-field>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [name]="'userslist_lblCreatedDate' | translate" prop="Created">
        <ng-template ngx-datatable-cell-template let-row="row">
            {{row.Created | appDate}}
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [name]="'userslist_lblLastLoginDate' | translate" prop="LastLogin">
        <ng-template ngx-datatable-cell-template let-row="row">
            {{row.LastLogin | appDate}}
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [resizeable]="false">
        <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
            <button (click)="editGridRow(rowIndex)" class="btn-icon edit-group" *ngIf="!isGridEditing(row)" [title]="'edit' | translate">
                <i class="fa fa-pencil"></i>
            </button>
            <button (click)="saveGridRow(rowIndex)"
                    class="btn-icon"
                    *ngIf="isGridEditing(row)"
                    [disabled]="!isValidGridItem(row)"
                    [title]="'save' | translate">
                <i class="fa fa-floppy-o"></i>
            </button>
            <button (click)="cancelGridRow(rowIndex)" class="btn-icon" *ngIf="isGridEditing(row)" [title]="'cancel' | translate">
                <i class="fa fa-ban"></i>
            </button>
            <button (click)="deleteGridRow(rowIndex)" class="btn-icon" *ngIf="!isNewGridItem(row)" [title]="'delete' | translate">
                <i class="fa fa-trash"></i>
            </button>
        </ng-template>
    </ngx-datatable-column>
</ngx-datatable>

