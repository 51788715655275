<ngx-datatable class="tight admission-results-grid"
               [rows]="gridItems"
               [headerHeight]="'auto'"
               [footerHeight]="50"
               [rowHeight]="'auto'"
               [columnMode]="'flex'"
               [limit]="100"
               [externalSorting]="true"
               [loadingIndicator]="gridLoading"
               [messages]="{ emptyMessage: 'gridEmpty' | translate, totalMessage: 'gridTotal' | translate }"
               (sort)="onGridSort($event)">
    <ngx-datatable-column cellClass="check-cell" headerClass="check-cell" [minWidth]="32" [flexGrow]="0.25">
        <ng-template ngx-datatable-header-template>
            <mat-checkbox (change)="selectRows($event)" value="true" [checked]="allRowsSelected()"></mat-checkbox>
        </ng-template>
        <ng-template ngx-datatable-cell-template let-row="row">
            <mat-checkbox [value]="row.Id" (change)="selectRow(row)" [checked]="rowSelected(row)"></mat-checkbox>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [name]="'admissionResults_lblCode' | translate" prop="Code" [flexGrow]="1"></ngx-datatable-column>
    <ngx-datatable-column [name]="'admissionResults_lblProgram' | translate" prop="Program" [flexGrow]="5">
        <ng-template ngx-datatable-cell-template let-row="row">
            <a [routerLink]="['/programs', row.Id, 'results'] | localize">
                {{row.Program}}
            </a>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [name]="'admissionResults_lblFinancing' | translate" prop="Financing" [flexGrow]="1"></ngx-datatable-column>
    <ngx-datatable-column [name]="'admissionResults_lblApplicationCount' | translate" prop="ApplicationCount" [flexGrow]="1" [comparator]="gridNumberComparator"></ngx-datatable-column>
    <ngx-datatable-column [name]="'admissionResults_lblApplicantsIn' | translate" prop="ApplicantsIn" [flexGrow]="1" [comparator]="gridNumberComparator"></ngx-datatable-column>
    <ngx-datatable-column [name]="'admissionResults_lblApplicantsInHigher' | translate" prop="ApplicantsInHigher" [flexGrow]="1" [comparator]="gridNumberComparator"></ngx-datatable-column>
    <ngx-datatable-column [name]="'admissionResults_lblApplicantsInLower' | translate" prop="ApplicantsInLower" [flexGrow]="1" [comparator]="gridNumberComparator"></ngx-datatable-column>
    <ngx-datatable-column [name]="'admissionResults_lblApplicantsOut' | translate" prop="ApplicantsOut" [flexGrow]="1" [comparator]="gridNumberComparator"></ngx-datatable-column>
    <ngx-datatable-column [name]="'admissionResults_lblQuota' | translate" prop="Quota" [flexGrow]="1" [comparator]="gridNumberComparator"></ngx-datatable-column>
</ngx-datatable>

<div class="cols-wrapper" *ngIf="!isHomines">
    <div class="cols">
        <div class="col-6" *ngIf="gridItems.length">
            <form #reportForm="ngForm" class="block form-inline">
                <header class="block-header">
                    <h3 [translate]="'admissionResults_makeReport'"></h3>
                </header>

                <div class="block-body">
                    <mat-form-field>
                        <mat-select [placeholder]="'admissionResults_lblReportTarget' | translate" required name="ReportTarget" [(value)]="reportTarget">
                            <mat-option *ngFor="let t of targetRowTypes" [value]="t">
                                {{'admissionResults_targetRows' + t | translate}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field>
                        <mat-select [placeholder]="'admissionResults_lblReportType' | translate" required name="ReportType" [(value)]="reportType">
                            <mat-option [value]="'WithCodes'">
                                {{'admissionResults_reportTypeWithCodes' | translate}}
                            </mat-option>
                            <mat-option [value]="'WithoutCodes'">
                                {{'admissionResults_reportTypeWithoutCodes' | translate}}
                            </mat-option>
                            <mat-option [value]="'ResidentExams'">
                                {{'admissionResults_reportTypeResidentExams' | translate}}
                            </mat-option>
                            <mat-option [value]="'ResidentExamsWithSelfControl'">
                                {{'admissionResults_reportTypeResidentExamsWithSelfControl' | translate}}
                            </mat-option>
                            <mat-option [value]="'InterviewPagesDocx'">
                                {{'admissionResults_reportTypeInterviewPagesDocx' | translate}}
                            </mat-option>
                            <mat-option [value]="'InterviewPagesXlsx'">
                                {{'admissionResults_reportTypeInterviewPagesXlsx' | translate}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <a href="{{getReportUrl()}}" target="_blank" mat-raised-button color="primary" class="btn-inline"
                       [disabled]="!(reportTarget && reportType && (reportTarget=='All' || (selectedRows && selectedRows.length)))">
                        <i class="fa fa-download"></i>
                        {{'admissionResults_makeReport' | translate}}
                    </a>
                </div>
            </form>
        </div>

        <div class="col-{{gridItems.length ? 6 : 12}} text-right">
            <button mat-raised-button type="button" (click)="launchAlgorithm()">
                <i class="fa fa-calculator"></i>
                {{'admissionResults_launchAlgorithm' | translate}}
            </button>
        </div>
    </div>

    <div class="cols">
        <div class="col-9">
            <form #sendEmailsForm="ngForm" class="block form-in form-inline">
                <header class="block-header">
                    <h3 [translate]="'admissionResults_sendEmails'" class="form-title"></h3>
                </header>

                <div class="block-body">
                    <mat-form-field style="min-width: 250px">
                        <mat-select [placeholder]="'admissionResults_sendEmails_lblTarget' | translate" [(ngModel)]="sendEmailsOpts.target"
                                    name="sendEmailsTarget" required #sendEmailsTarget
                                    [errorStateMatcher]="targetRowsStateMatcher">
                            <mat-option *ngFor="let t of targetRowTypes" [value]="t">
                                {{'admissionResults_targetRows' + t | translate}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="!sendEmailsTarget.valid">
                            {{'admissionResults_noRowSelected' | translate}}
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field style="min-width: 250px">
                        <mat-select [placeholder]="'admissionResults_sendEmails_lblApplicantType' | translate" [(ngModel)]="sendEmailsOpts.applicantType" name="sendEmailsApplicantType" required>
                            <mat-option *ngFor="let t of sendEmailsApplicantTypes" [value]="t">
                                {{'admissionResults_sendEmails_applicantType' + t | translate}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field style="min-width: 300px">
                        <input matInput [(ngModel)]="sendEmailsOpts.notificationCode" name="sendEmailsNotificationCode" required
                               [placeholder]="'admissionResults_sendEmails_lblNotificationCode' | translate" #sendEmailsNotifCode
                               [matAutocomplete]="sendEmailsNotifCodeAuto.bind(sendEmailsNotifCode)" validateMessageCode
                               [errorStateMatcher]="messageCodeStateMatcher">
                        <app-message-autocomplete #sendEmailsNotifCodeAuto></app-message-autocomplete>
                    </mat-form-field>

                    <button type="button" class="btn-inline" mat-raised-button color="primary" (click)="sendEmails()" [disabled]="!sendEmailsForm.valid">
                        <i class="fa fa-paper-plane"></i>
                        {{'admissionResults_btnSendEmails' | translate}}
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
