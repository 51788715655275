<header class="content-header">
    <nav class="breadcrumb">
        <a [routerLink]="['/classifiers'] | localize" class="bc-back" [translate]="'classifiers_title'"></a>
    </nav>
    <aside>
        <form #form="ngForm" class="search-form">
            <mat-form-field [floatLabel]="'never'">
                <input #search
                       matInput
                       (keyup)="filter(search.value)"
                       spellcheck="false"
                       [placeholder]="'lblSearch' | translate">
                <button mat-button *ngIf="search.value" matSuffix mat-icon-button [title]="'lblClear' | translate"
                        (click)="search.value = ''; filter()">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>
        </form>
    </aside>
    <h1 [translate]="'territories_title'"></h1>
</header>

<ngx-datatable #grid
               [rows]="gridItems"
               [columns]="[
                { prop: 'Code', name: 'territories_lblCode' | translate },
                { prop: 'ParentCode', name: 'territories_lblParentCode' | translate },
                { prop: 'Name', name: 'territories_lblName' | translate },
                { prop: 'Level', name: 'territories_lblLevel' | translate }
               ]"
               [limit]="30"
               [headerHeight]="'auto'"
               [footerHeight]="'auto'"
               [rowHeight]="'auto'"
               [columnMode]="'force'"
               [loadingIndicator]="gridLoading"
               [externalSorting]="true" 
               (sort)="onGridSort($event)">
</ngx-datatable>