<ngx-datatable class="tight app-scrollable-grid"
               [rows]="gridItems"
               [headerHeight]="30"
               [footerHeight]="50"
               [rowHeight]="30"
               [scrollbarV]="true"
               [columnMode]="'force'"
               [externalSorting]="true"
               [loadingIndicator]="gridLoading"
               [messages]="{ emptyMessage: 'gridEmpty' | translate, totalMessage: 'gridTotal' | translate }"
               (sort)="onGridSort($event)">
    <ngx-datatable-column cellClass="check-cell" headerClass="check-cell" [width]="32" [maxWidth]="32">
        <ng-template ngx-datatable-header-template>
            <mat-checkbox (change)="selectRows($event)" value="true" [checked]="allRowsSelected()"></mat-checkbox>
        </ng-template>
        <ng-template ngx-datatable-cell-template let-row="row">
            <mat-checkbox [value]="row.Id" (change)="selectRow(row)" [checked]="rowSelected(row)"></mat-checkbox>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [name]="'admissionInterviews_lblExamCode' | translate" prop="ExamCode" [width]="100"></ngx-datatable-column>
    <ngx-datatable-column [name]="'admissionInterviews_lblProgram' | translate" prop="ProgramTitle" [width]="200">
        <ng-template ngx-datatable-cell-template let-row="row">
            <a [routerLink]="['/programs', row.ProgramId, 'interviews', row.ExamId] | localize">
                {{row.ProgramTitle}}
            </a>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [name]="'admissionInterviews_lblNotStartedCount' | translate" prop="NotStarted" [width]="60" [comparator]="gridNumberComparator"></ngx-datatable-column>
    <ngx-datatable-column [name]="'admissionInterviews_lblUnfinishedCount' | translate" prop="Unfinished" [width]="60" [comparator]="gridNumberComparator"></ngx-datatable-column>
    <ngx-datatable-column [name]="'admissionInterviews_lblFinishedCount' | translate" prop="Finished" [width]="60" [comparator]="gridNumberComparator"></ngx-datatable-column>
    <ngx-datatable-column [name]="'admissionInterviews_lblTotalCount' | translate" prop="Total" [width]="60" [comparator]="gridNumberComparator"></ngx-datatable-column>
    <ngx-datatable-footer>
        <ng-template ngx-datatable-footer-template let-rowCount="rowCount">
            <div>
                <strong>{{rowCount || 0}}</strong> {{'gridTotal' | translate}}
            </div>
        </ng-template>
    </ngx-datatable-footer>
</ngx-datatable>

<div class="cols-wrapper">
    <div class="cols">
        <div class="col-6" *ngIf="gridItems.length">
            <form #publishForm="ngForm" class="block form-inline">
                <header class="block-header">
                    <h3 [translate]="'admissionInterviews_publish'"></h3>
                </header>

                <div class="block-body">
                    <mat-form-field [style.width.rem]=18>
                        <mat-select [placeholder]="'admissionInterviews_lblPublishTarget' | translate" required name="PublishTarget" [(value)]="publishTarget">
                            <mat-option *ngFor="let t of targetRowTypes" [value]="t">
                                {{'admissionInterviews_targetRows_' + t | translate}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <button type="button" class="btn-inline" mat-raised-button color="primary" (click)="publish()"
                        [disabled]="!(publishTarget && (publishTarget=='All' || (selectedRows && selectedRows.length)))">
                        {{'admissionInterviews_btnPublish' | translate}}
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
