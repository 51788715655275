import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { AppService } from '../services/app.service';

import { InterviewService } from '../services/interview.service';
import { GridComponentBase } from '../GridComponentBase';

@Component({
    selector: 'app-interview-history',
    templateUrl: './interview-history.component.html'
})
export class InterviewHistoryComponent extends GridComponentBase<any> implements OnInit {
    constructor(
        public app: AppService,
        protected service: InterviewService,
        protected route: ActivatedRoute
    ) {
        super(app);
    }

    applicationExamId: number;
    interviewerId: number;

    applicationNumber: string;
    examTitle: string;
    interviewer: string;

    ngOnInit(): void {
        this.app.setWideLayout();
        this.route.params.subscribe(async para => {
            this.applicationExamId = para['applicationExamId'];
            this.interviewerId = para['interviewerId'];
            if (this.applicationExamId && this.interviewerId) {
                const loading = this.app.showLoading();
                try {
                    this.gridItems = await this.service.getHistory(this.applicationExamId, this.interviewerId);
                    if (this.gridItems.length > 0) {
                        this.applicationNumber = this.gridItems[0].Application;
                        this.examTitle = this.gridItems[0].Exam;
                        this.interviewer = this.gridItems[0].Interviewer;
                        this.gridItems.forEach(gi => {
                            gi.Grades = new Map<string, number>();
                            if (gi.IndividualGrades) {
                                const individualGrades = JSON.parse(gi.IndividualGrades);
                                for (let key in individualGrades)
                                    gi.Grades.set(key, individualGrades[key]);
                            }
                            gi.Status = this.app.translate.instant('interviewStatus_' + gi.Status);
                        });
                    }
                }
                catch (err) {
                    this.app.showLoadError(err);
                }
                finally {
                    this.app.hideLoading(loading);
                }
            }
        });
    }

    getDetailsHeight(row: any, index: number): number {
        if (!row) return undefined;
        return row.Grades.size * 30;
    }
}
