import { Component, OnInit,Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppService } from '../../services/app.service';
import { ApplicationService } from '../../services/application.service';
import { ApplicationSchedule } from '../../models/Application';
import { GridComponentBase } from '../../GridComponentBase';


@Component({
  selector: 'app-application-schedules-payments',
  templateUrl: './application-schedules-payments.component.html',
  styleUrls: ['./application-schedules-payments.component.css']
})
export class ApplicationSchedulesPaymentsComponent extends GridComponentBase<ApplicationSchedule> implements OnInit {
  itemType: string;
  pageSize: number = 10;



  constructor(public app: AppService, private route: ActivatedRoute, private service: ApplicationService) {
    super(app);
  }
  //private _schedules: ApplicationSchedule[];
  //get schedules(): ApplicationSchedule[] {
  //  return this._schedules;
  //}
  //@Input() set schedules(value: ApplicationSchedule[]) {
  //  this._schedules = value;
  //}
  private _programId:number;
  get programId(): number{
      return this._programId;
  }
  @Input() set programId(value: number) {
      this._programId = value;
  }

  private _applicationId: number;
  get applicationId(): number {
      return this._applicationId;
  }
  @Input() set applicationId(value: number) {
      this._applicationId = value;
  }


  ngOnInit() {
  }

}
