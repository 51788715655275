import { Component, OnInit } from '@angular/core';
import { DocumentTemplate } from '../models/Template';
import { Observable } from 'rxjs';
import { UserInfo } from '../models/UserInfo';

import { AppService } from '../services/app.service';
import { TemplateService } from '../services/template.service';

import { GridComponentBase } from '../GridComponentBase';

@Component({
  selector: 'app-templates',
  templateUrl: './templates.component.html',
  styleUrls: ['./templates.component.css']
})
export class TemplatesComponent extends GridComponentBase<DocumentTemplate> implements OnInit {
    constructor(public app: AppService, private template: TemplateService) {
        super(app);
    }

    documentTemplate: DocumentTemplate = new DocumentTemplate();
    currentUser: UserInfo;
    canEdit: boolean = false;
    canDelete: boolean = false;
    fileUploadedNameEn: string = '';
    fileUploadedNameLv: string = '';
    minDate: Date = this.app.env.minDate;
    maxDate: Date = this.app.env.maxDate;

    ngOnInit() {
        this.currentUser = this.app.auth.currentUser;
        if (this.currentUser) {
            this.canEdit = this.app.auth.isPowerUser() || this.app.auth.isAdmin();
            this.canDelete = this.app.auth.isPowerUser() || this.app.auth.isAdmin();
        }
        let loading = this.app.showLoading();
        this.template.getAll().subscribe(data => {
            this.gridItems = data;
            this.app.hideLoading(loading);
        }, err => this.app.showLoadError(err));
    }
    
    getFileUrl(item: DocumentTemplate, language: string) {
        return this.template.getFileUrl(item.Id, language);
    }

    protected deleteGridItem(item: DocumentTemplate): Observable<boolean> {
        return this.template.delete(item.Id);
    }
}
