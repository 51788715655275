import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment as ENV } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

export enum InterviewPageReportFormat {
    Docx = 'docx',
    Xlsx = 'xlsx'
}

/**
 * Report service
 */
@Injectable()
export class ReportService {
    constructor(public http: HttpClient, public auth: AuthService) { }

    public get apiUrl(): string { return `${ENV.apiUrl}/reports` }

    getApplicationReportUrl(programIds: number[], withPersonCodes?: boolean): string {
        return `${this.apiUrl}/${withPersonCodes ? 'applicationswithcodes' : 'applications'}`
            + `?SessionKey=${this.auth.sessionKey}&programIds=${programIds.join(',')}`;
    }

    getInterviewPagesReportUrl(programIds: number[], format: InterviewPageReportFormat) {
        return `${this.apiUrl}/interviewPages?SessionKey=${this.auth.sessionKey}&programIds=${programIds.join(',')}&format=${format}`;
    }

    //united rz report
    getUnitedRZReportUrl(applicationIds: number[]): Observable<string> {
        const url = `${this.apiUrl}/unitedRZ`;
        return this.http.post<string>(url, { ApplicationIds: applicationIds, ReturnType:'url'}).pipe(map(data => {
            data += '&SessionKey=' + this.auth.sessionKey;
            return data;
        }));
    }

    getUnitedRZReport(applicationIds: number[]): Observable<any> {
        const url = `${this.apiUrl}/unitedRZ`;
        return this.http.post(url, { ApplicationIds: applicationIds, ReturnType: 'file' }, { responseType: 'blob' });
    }

    getResidentExamReportUrl(programIds: number[],gradeType?:string): string {
        return `${this.apiUrl}/residentExams?SessionKey=${this.auth.sessionKey}&programIds=${programIds.join(',')}`
            + `${gradeType ? '&gradeType=' + gradeType:''}`;
    }

    getExamReportUrl(admissionId: number, examId: number, programId: number, withSignatures?: boolean): string {
        return `${this.apiUrl}/examreport`
            + `?SessionKey=${this.auth.sessionKey}&admissionId=${admissionId}&examId=${examId}&programId=${programId}`;
    }
}
