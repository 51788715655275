<div *ngIf="!actionPay && itemExists">
    <header class="content-header">
        <nav class="breadcrumb">
            <a [routerLink]="returnUrl" class="bc-back">
                <ng-container *ngIf="returnTo === 'search'">{{'applications_search_title' | translate}}</ng-container>
                <ng-container *ngIf="returnTo === 'admission'">{{item.AdmissionTitle}}</ng-container>
                <ng-container *ngIf="returnTo === 'applications'">{{'applications_title' | translate}}</ng-container>
            </a>
        </nav>
        <h1 class="clearfix">
            <span class="app-number">{{'applications_numberPrefix' | translate}}{{item.Number}}</span>
            {{'applications_edit_titlePrefix' | translate}} "{{item.AdmissionTitle}}"
            <span class="badge status-{{item.Status}}" *ngIf="item.Status">{{'applicationStatus_' + item.Status | translate}}</span>
            <span class="badge status-IsLateSubmission" *ngIf="item.IsLateSubmission && !item.IsForeign">
                {{'applicationStatus_IsLateSubmission' | translate}}
                <i *ngIf="isPowerUser || isAdmin" class="fa fa-trash-o" (click)="removeLateSubmissionTag()" style="color:white"></i>
            </span>
            <span class="badge sign" *ngIf="signRequired && !isHomines && item.Status === 'Approved'">
                <i class="fa fa-pencil-square{{item.IsSigned ? '' : '-o'}}"></i>
                {{(item.IsSigned ? 'applications_signed' : 'applications_notSigned') | translate}}
            </span>
            <span class="badge status-SignedByeDoc" *ngIf="contractStatusRead &&
             (contractStatusRead.ESignedByApplicant || contractStatusRead.ESignedByEmployee)">
                {{'applications_signedByeDoc' | translate}}
            </span>
        </h1>
    </header>

    <div class="alert alert-danger bg-danger" *ngIf="isPowerUser && item.IsIncomplete">
        <div><i class="fa fa-exclamation-triangle text-danger"></i> {{ 'applications_isIncompleteNotification' | translate }}</div>
    </div>
    <div class="alert alert-info" *ngIf="isEditor && item.IsGraduate">{{'applications_isGraduateNotification' | translate}}</div>
    <div class="alert alert-{{programResult === 'in' ? 'success' : programResult === 'out' ? 'danger' : 'info'}}"
         *ngIf="programResultNotes.length" [innerHTML]="programResultNotes.join('<br>') | safeHtml"></div>

    <!-- Personas dati (kopsavilkums ar iespēju iet uz detaļām/labošanu) -->
    <section class="block">
        <header class="block-header">
            <h2>
                <i class="fa fa-exclamation-triangle text-danger" *ngIf="personDataErrors.length"></i>
                {{'applications_personData' | translate}}
                <a [routerLink]="['/applications', item.Number, 'history'] | localize" *ngIf="isPowerUser">
                    <i class="fa fa-history" aria-hidden="true"></i>
                </a>
            </h2>
            <aside>
                <a [routerLink]="['person'] | localize" mat-raised-button color="accent">
                    <i class="fa fa-pencil" *ngIf="canEdit"></i>
                    {{(canEdit ? 'applications_editPersonData' : 'open') | translate}}
                </a>
            </aside>
        </header>
        <div class="block-body">
            <div class="alert bg-warning" *ngIf="personDataErrors.length" [innerHTML]="personDataErrorAlert | safeHtml"></div>
            <div *ngIf="isUnder18 && under18Message" class="alert alert-warning bg-warning">
                <i class="fa fa-exclamation-triangle text-danger"></i>
                <div [innerHTML]="under18Message | safeHtml" class="under18-text"></div>
            </div>
            <div class="cols">
                <div class="col-3">
                    <label>{{'applications_lblName' | translate}}</label>
                </div>
                <div class="col-7">{{item.Name}}</div>
            </div>

            <div class="cols">
                <div class="col-3">
                    <label>{{'applications_lblSurname' | translate}}</label>
                </div>
                <div class="col-7">{{item.Surname}}</div>
            </div>

            <div class="cols" *ngIf="!personCodeHidden && !isHomines">
                <div class="col-3">
                    <label>{{'applications_lblPersonCode' | translate}}</label>
                </div>
                <div class="col-7">{{item.PersonCode}}</div>
            </div>
            <div class="cols">
                <div class="col-3">
                    <label>{{'applications_lblContactInfo' | translate}}</label>
                </div>
                <div class="col-8">
                    <a [href]="sanitize('callto:tel:'+item.Phone)" *ngIf="item.Phone">{{item.Phone}}</a>{{ item.Phone && item.Email ? ', ' : ''}}
                    <a role="button" (click)="sendPersonalApplicantEmail()" style="cursor: pointer;" *ngIf="item.Email && (isEditor || isAdmin); else emailWithoutHref">
                        {{item.Email}}
                    </a>
                    <ng-template #emailWithoutHref>{{item.Email}}</ng-template>
                    <a role="button" (click)="getPersonalEmailHistory()" *ngIf="item.Email && (isEditor || isAdmin)" style="padding-left: 1%; cursor: pointer;">
                        <i class="fa fa-history" aria-hidden="true"></i>
                    </a>
                </div>
            </div>
        </div>
        <div class="block-footer">
            <app-application-notes *ngIf="item.Id"
                                   [applicationId]="item.Id"
                                   [position]="'PersonData'"
                                   [single]="true"
                                   [canEdit]="isEditor"
                                   [notes]="notes">
            </app-application-notes>
        </div>
    </section>

    <!-- Personas datu pārbaude SIS (ar izvēršamiem rezultātiem) -->
    <section class="block" *ngIf="isEditor && !isUnitedEnrollClerk">
        <header class="block-header">
            <h2>{{'applications_personDataCheck' | translate}}</h2>
            <aside>
                <button *ngIf="item.IsSISDataChecked" mat-raised-button type="button" (click)="showPersonGrid = !showPersonGrid">
                    <i class="fa fa-eye{{showPersonGrid ? '-slash' : ''}}"></i>
                    {{'applications_checkPersonShowGrid' | translate}}
                </button>
                <button mat-raised-button color="accent" type="button" [disabled]="!selectedPersons.length" (click)="checkBalance()">
                    <i class="fa fa-refresh"></i>
                    {{'applications_checkPersonBalance' | translate}}
                </button>
                <button mat-raised-button color="accent" type="button" (click)="checkPerson()">
                    <i class="fa fa-refresh"></i>
                    {{'applications_checkPersonData' | translate}}
                </button>
            </aside>
        </header>
        <div class="block-body">
            <ng-container *ngIf="!item.ContractIsBudget && item.CrmContractId">
                <div class="cols">
                    <div class="col-2">
                        <label>{{'applications_lblContractTotal' | translate}}</label>
                    </div>
                    <div class="col-8">{{item.ContractTotal}}</div>
                </div>
                <div class="cols">
                    <div class="col-2">
                        <label>{{'applications_lblContractPaid' | translate}}</label>
                    </div>
                    <div class="col-8">{{item.ContractPaid}}</div>
                </div>
                <div class="cols">
                    <div class="col-2">
                        <label>{{'applications_lblContractBalanceRefreshDate' | translate}}</label>
                    </div>
                    <div class="col-8">{{item.ContractBalanceRefreshDate | appDate}}</div>
                </div>
            </ng-container>

            <p class="text-{{item.IsSISDataChecked ? 'info' : 'danger'}}">
                {{(item.IsSISDataChecked ? 'applications_personDataChecked' : 'applications_personDataNotChecked') | translate}}
            </p>

            <ngx-datatable #grid
                           *ngIf="showPersonGrid"
                           class="tight"
                           [rows]="persons"
                           [limit]="30"
                           [headerHeight]="'auto'"
                           [footerHeight]="'auto'"
                           [rowHeight]="'auto'"
                           [columnMode]="'standard'"
                           [messages]="{ emptyMessage: 'gridEmpty' | translate, totalMessage: 'gridTotal' | translate }">
                <ngx-datatable-column cellClass="check-cell" headerClass="check-cell" [width]="30">
                    <ng-template ngx-datatable-header-template>
                        <mat-checkbox (change)="selectRows($event)" value="true" [checked]="allRowsSelected()"></mat-checkbox>
                    </ng-template>
                    <ng-template ngx-datatable-cell-template let-row="row">
                        <mat-checkbox [value]="row.Id" (change)="selectRow(row)" [checked]="rowSelected(row)"></mat-checkbox>
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column [name]="'persons_lblName' | translate" prop="Name" [width]="125"></ngx-datatable-column>
                <ngx-datatable-column [name]="'persons_lblSurname' | translate" prop="Surname" [width]="125"></ngx-datatable-column>
                <ngx-datatable-column [name]="'persons_lblPersonCode' | translate" prop="PersonCode" [width]="125"></ngx-datatable-column>
                <ngx-datatable-column [name]="'persons_lblPhone' | translate" prop="Phone" [width]="100"></ngx-datatable-column>
                <ngx-datatable-column [name]="'persons_lblEmail' | translate" prop="Email" [width]="130"></ngx-datatable-column>
                <ngx-datatable-column [name]="'persons_lblCrmMatchType' | translate" [width]="200">
                    <ng-template ngx-datatable-cell-template let-row="row">
                        <ng-container *ngIf="row.LinkedApplications.length">
                            {{'persons_lblFromEnr' | translate}}:
                            <a *ngFor="let a of row.LinkedApplications" [routerLink]="['/applications', a.Number]" class="match-app-link">{{a.Number}}</a>
                        </ng-container>
                        <ng-container *ngIf="row.CrmId">
                            {{'persons_lblFromSys' | translate}}:
                            {{prettifyCrmMatchType(row)}}
                        </ng-container>
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column [name]="''" prop="CrmId" [width]="100">
                    <ng-template ngx-datatable-cell-template let-row="row">
                        <a href="#" (click)="$event.preventDefault(); linkPerson(row);" *ngIf="(row.CrmId && row.CrmId != item.CrmPersonId) || (!row.CrmId && !row.IsLocalLinked)">
                            <i class="fa fa-link"></i>
                            {{'applications_linkPerson' | translate}}
                        </a>
                        <a href="#" (click)="$event.preventDefault(); unlinkPerson();" *ngIf="(row.CrmId && row.CrmId == item.CrmPersonId) || row.IsLocalLinked">
                            <i class="fa fa-chain-broken"></i>
                            {{'applications_unlinkPerson' | translate}}
                        </a>
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column [name]="'persons_lblDebt' | translate" prop="Debt" [width]="100">
                    <ng-template ngx-datatable-cell-template let-row="row">
                        {{row.Debt | number:'1.2-2'}}
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column [name]="'persons_lblDebtInspectionDate' | translate" prop="DebtInspectionDate" [width]="100">
                    <ng-template ngx-datatable-cell-template let-row="row">
                        {{row.DebtInspectionDate | appDate}}
                    </ng-template>
                </ngx-datatable-column>
            </ngx-datatable>
        </div>
    </section>

    <!-- Pieteikšanās metode (ārzemju) -->
    <section class="block" *ngIf="item.IsForeign" [class.collapsed]="!showWayOfApp">
        <header class="block-header">
            <h3 [translate]="'applications_wayOfApp'"></h3>
            <aside>
                <button mat-raised-button type="button" (click)="showWayOfApp = !showWayOfApp">
                    <i class="fa fa-eye{{showWayOfApp ? '-slash' : ''}}"></i>
                    {{(showWayOfApp ? 'applications_hideDetails' : 'applications_showDetails') | translate}}
                </button>
            </aside>
        </header>
        <div class="block-body" *ngIf="showWayOfApp">
            <div>
                <mat-checkbox [(ngModel)]="aux.WayOfApp.ViaRepresentative" (change)="onAuxChange()" name="woaViaRepresentative" [value]="true">
                    {{'applications_lblWayOfApp_ViaRepresentative' | translate}}
                </mat-checkbox>
                <mat-form-field *ngIf="aux.WayOfApp.ViaRepresentative">
                    <input matInput [(ngModel)]="aux.WayOfApp.ViaRepresentativeText" (change)="onAuxChange()" name="woaRepresentativeText"
                           [placeholder]="'applications_lblIndicateNameOrCompany' | translate" required>
                </mat-form-field>
            </div>
            <div>
                <mat-checkbox [(ngModel)]="aux.WayOfApp.OnMyOwn" (change)="onAuxChange()" name="woaOnMyOwn" [value]="true">
                    {{'applications_lblWayOfApp_OnMyOwn' | translate}}
                </mat-checkbox>
            </div>
        </div>
    </section>

    <!-- Kur atradis informāciju par studiju programmām (ārzemju) -->
    <section class="block" *ngIf="item.IsForeign" [class.collapsed]="!showWhereFoundInfo">
        <header class="block-header">
            <h3 [translate]="'applications_whereFoundInfo'"></h3>
            <aside>
                <button mat-raised-button type="button" (click)="showWhereFoundInfo = !showWhereFoundInfo">
                    <i class="fa fa-eye{{showWhereFoundInfo ? '-slash' : ''}}"></i>
                    {{(showWhereFoundInfo ? 'applications_hideDetails' : 'applications_showDetails') | translate}}
                </button>
            </aside>
        </header>

        <div class="block-body" *ngIf="showWhereFoundInfo">
            <div>
                <mat-checkbox [(ngModel)]="aux.WhereFoundInfo.ViaRepresentative" (change)="onAuxChange()" name="wfiViaRepresentative" [value]="true">
                    {{'applications_lblWhereFoundInfo_ViaRepresentative' | translate}}
                </mat-checkbox>
                <mat-form-field *ngIf="aux.WhereFoundInfo.ViaRepresentative">
                    <input matInput [(ngModel)]="aux.WhereFoundInfo.ViaRepresentativeText" (change)="onAuxChange()" name="wfiRepresentativeText"
                           [placeholder]="'applications_lblIndicateNameOrCompany' | translate" required>
                </mat-form-field>
            </div>
            <div>
                <mat-checkbox [(ngModel)]="aux.WhereFoundInfo.CurrentStudent" (change)="onAuxChange()" name="wfiCurrentStudent" [value]="true">
                    {{'applications_lblWhereFoundInfo_CurrentStudent' | translate}}
                </mat-checkbox>
            </div>
            <div>
                <mat-checkbox [(ngModel)]="aux.WhereFoundInfo.Alumni" (change)="onAuxChange()" name="wfiAlumni" [value]="true">
                    {{'applications_lblWhereFoundInfo_Alumni' | translate}}
                </mat-checkbox>
            </div>
            <div>
                <mat-checkbox [(ngModel)]="aux.WhereFoundInfo.Internet" (change)="onAuxChange()" name="wfiInternet" [value]="true">
                    {{'applications_lblWhereFoundInfo_Internet' | translate}}
                </mat-checkbox>
            </div>
            <div>
                <mat-checkbox [(ngModel)]="aux.WhereFoundInfo.Exhibition" (change)="onAuxChange()" name="wfiExhibition" [value]="true">
                    {{'applications_lblWhereFoundInfo_Exhibition' | translate}}
                </mat-checkbox>
                <mat-form-field *ngIf="aux.WhereFoundInfo.Exhibition">
                    <input matInput [(ngModel)]="aux.WhereFoundInfo.ExhibitionText" (change)="onAuxChange()" name="wfiExhibitionText"
                           [placeholder]="'applications_lblIndicateExhibition' | translate" required>
                </mat-form-field>
            </div>
            <div>
                <mat-checkbox [(ngModel)]="aux.WhereFoundInfo.OpenDays" (change)="onAuxChange()" name="wfiOpenDays" [value]="true">
                    {{'applications_lblWhereFoundInfo_OpenDays' | translate}}
                </mat-checkbox>
            </div>
            <div>
                <mat-form-field>
                    <input matInput [(ngModel)]="aux.WhereFoundInfo.Other" (change)="onAuxChange()" name="wfiOther"
                           [placeholder]="'applications_lblWhereFoundInfo_Other' | translate">
                </mat-form-field>
            </div>
        </div>
    </section>

    <div class="alert alert-info" *ngIf="item.ProgramInfoNote" [innerHTML]="item.ProgramInfoNote | safeHtml"></div>
    <app-application-notes *ngIf="item.Id && isEditor"
                           [applicationId]="item.Id"
                           [position]="'Programs'"
                           [single]="true"
                           [canEdit]="isEditor"
                           [notes]="notes">
    </app-application-notes>

    <!-- Studijas (izvēlētās programmas) -->
    <section class="block" *ngIf="!isHomines">
        <header class="block-header">
            <h2>
                <i class="fa fa-exclamation-triangle text-danger" *ngIf="dataLoaded && !programs.length"></i>
                {{'applications_studies' | translate}}
                <a [routerLink]="['/applications', item.Number, 'programsHistory'] | localize" *ngIf="isPowerUser">
                    <i class="fa fa-history" aria-hidden="true"></i>
                </a>
            </h2>
            <aside>
                <button type="button" (click)="addProgram()" mat-raised-button color="accent" [disabled]="!canAddPrograms" *ngIf="canEdit">
                    <i class="fa fa-plus"></i>
                    {{'applications_addProgram' | translate}}
                </button>
            </aside>
        </header>
        <div class="block-body">
            <div class="alert bg-warning" *ngIf="dataLoaded && !programs.length">
                {{'applications_noProgramAdded' | translate}}
            </div>

            <ul class="block-list">
                <li *ngFor="let p of programs; let i = index;" class="program" [class.program-ordered]="showProgramOrder">
                    <div class="program-container">
                        <aside class="program-actions aside-right text-right">
                            <div>
                                <button type="button" mat-mini-fab color="primary" (click)="moveProgramUp(p)" *ngIf="i > 0 && canOrderPrograms && (p.AllowProlongation || !item.isLateSubmission || item.IsForeign)"
                                        [title]="'applicationPrograms_moveUp' | translate">
                                    <i class="fa fa-caret-up"></i>
                                </button>
                                <button type="button" mat-mini-fab color="primary" (click)="moveProgramDown(p)" *ngIf="i < programs.length - 1 && canOrderPrograms && (p.AllowProlongation || !item.isLateSubmission || item.IsForeign)"
                                        [title]="'applicationPrograms_moveDown' | translate">
                                    <i class="fa fa-caret-down"></i>
                                </button>
                                <button *ngIf="canRemovePrograms" type="button" mat-mini-fab color="accent" (click)="removeProgram(p)"
                                        [title]="'applicationPrograms_remove' | translate">
                                    <i class="fa fa-minus"></i>
                                </button>
                            </div>
                            <div *ngIf="p.Rank"><em>{{getProgramRankString(p)}}</em></div>
                            <div class="uni-select" *ngIf="showProgramUniversitySelection(p)">
                                <mat-form-field [floatLabel]="'never'" class="mat-em">
                                    <mat-select [placeholder]="'programs_selectUniversity' | translate" required
                                                [disabled]="item.UnitedRZStatus == 'UniversitySelected'"
                                                [(ngModel)]="p.University" name="university" (selectionChange)="saveUniversity(p)">
                                        <mat-option *ngFor="let u of p.Program.Universities" [value]="u">{{getTranslatedValue(u)}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div *ngIf="canEdit && item.Status == 'Approved'">
                                <button type="button" mat-raised-button (click)="enroll(p)" [disabled]="isUnitedRZ && p.Program.Universities && !p.University" *ngIf="isEditor && p.Result !== 'In'">
                                    <i class="fa fa-check-circle"></i>
                                    {{'applicationPrograms_enroll' | translate}}
                                </button>
                            </div>
                        </aside>
                        <div class="program-body">
                            <span class="program-index" *ngIf="showProgramOrder">{{i + 1}}.</span>
                            <div class="program-data">
                                <span *ngIf="!p.Program.AllowProlongation && isLateSubmission && !isForeign && item.Status === statusEnum.New" class="not-available-program"> {{'applicationPrograms_programNotAvailable' | translate}} </span>
                                <h4>
                                    {{p.Program.Title}}
                                    <span *ngIf="p.Result" class="badge"
                                          [class.bg-success]="p.Result === 'In'"
                                          [class.bg-danger]="p.Result === 'Out'"
                                          [class.bg-info]="p.Result !== 'In' && p.Result !== 'Out'">{{'applicationResultType_' + p.Result | translate}}</span>
                                </h4>
                                <div class="meta meta-1">
                                    {{p.Program.Financing ? p.Program.Financing + ', ' : ''}}{{isUnitedRZ ? '' : p.Program.Faculty + ', '}} {{p.Program.Form}}
                                </div>
                                <div class="meta meta-2">
                                    <span><i class="fa fa-graduation-cap"></i> {{p.Program.Degree}}</span>
                                    <span *ngIf="p.Program.Speciality"><i class="fa fa-star"></i> {{p.Program.Speciality}}</span>
                                    <span *ngIf="p.Specialization"><i class="fa fa-star-o"></i> {{p.Specialization.SpecializationName}}</span>
                                    <!--<span *ngIf="isUnitedRZ && p.University"><i class="fa fa-star-o"></i> {{getTranslatedValue(p.University)}}</span>-->
                                </div>
                                <div class="meta meta-3 text-sm">
                                    <span><i class="fa fa-map-marker"></i> {{p.Program.Location}}</span>
                                    <span><i class="fa fa-eur"></i> {{p.Program.PricePerYear}}</span>
                                    <span><i class="fa fa-clock-o"></i> {{p.Program.LengthInYears}}</span>
                                    <span><i class="fa fa-globe"></i> {{p.Program.Language}}</span>
                                </div>
                                <div class="meta meta-4" *ngIf="p.Program.SelectedSpecialization">
                                    <div class="cols">
                                        <div class="col-4">
                                            {{p.Program.SelectedSpecialization.SpecializationName}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <app-application-notes *ngIf="item.Id"
                                               [applicationId]="item.Id"
                                               [position]="applicationNotePosition.Program + p.Program.Id"
                                               [single]="true"
                                               [notes]="notes">
                        </app-application-notes>
                    </div>
                </li>
            </ul>
        </div>
    </section>

    <!-- Pieteikums konkursam -->
    <section class="block" *ngIf="isHomines">
        <header class="block-header">
            <h2>
                {{'applications_applicationForAdmission' | translate}}
            </h2>
        </header>
        <div class="block-body">

            <ul class="block-list">
                <li *ngFor="let p of programs; let i = index;">
                    <div class="program-container">
                        <div class="program-body">
                            <div class="program-data">
                                <h4>
                                    {{p.Program.Title}}
                                </h4>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </section>

    <!-- Plānotais zinātniskais darbs -->
    <section class="block" *ngIf="isDoctor && !isHomines" [class.collapsed]="!showThesis">
        <header class="block-header">
            <h2>{{'applications_thesis' | translate}}</h2>
            <aside>
                <button mat-raised-button type="button" (click)="showThesis = !showThesis">
                    <i class="fa fa-eye{{showThesis ? '-slash' : ''}}"></i>
                    {{(showThesis ? 'applications_hideDetails' : 'applications_showDetails') | translate}}
                </button>
            </aside>
        </header>
        <div class="block-body" *ngIf="showThesis">
            <div>
                <mat-form-field>
                    <input matInput [(ngModel)]="thesis.Text1" name="thesisTitle" (blur)="saveThesis()"
                           [placeholder]="'applications_thesisTitle' | translate" required>
                </mat-form-field>
            </div>
            <div>
                <mat-form-field>
                    <input matInput [(ngModel)]="thesis.Text2" name="thesisSupervisor" (blur)="saveThesis()"
                           [placeholder]="'applications_thesisSupervisor' | translate" required>
                </mat-form-field>
            </div>
            <div>
                <mat-form-field>
                    <input matInput [(ngModel)]="thesis.Text3" name="thesisSecondSupervisor" (blur)="saveThesis()"
                           [placeholder]="'applications_thesisSecondSupervisor' | translate">
                </mat-form-field>
            </div>
            <div>
                <mat-form-field>
                    <input matInput [(ngModel)]="thesis.Text4" name="thesisConsultant" (blur)="saveThesis()"
                           [placeholder]="'applications_thesisConsultant' | translate">
                </mat-form-field>
            </div>
        </div>
    </section>

    <!-- Eksāmeni un iestājpārbaudījumi (savāžams bloks) -->
    <section class="block exams" [class.collapsed]="!showExams">
        <header class="block-header">
            <h2>
                <ng-container *ngIf="!isHomines">
                    <i class="fa fa-exclamation-triangle text-danger" *ngIf="dataLoaded && !examTree.length"></i>
                    {{'applications_examsAndTests' | translate}}
                </ng-container>
                <ng-container *ngIf="isHomines">
                    {{'applications_hominesTests' | translate}}
                </ng-container>
                <a [routerLink]="['/applications', item.Number, 'examsHistory'] | localize" *ngIf="isPowerUser">
                    <i class="fa fa-history" aria-hidden="true"></i>
                </a>
            </h2>
            <aside>
                <button *ngIf="isEditor && hasVIISExams" type="button" (click)="refreshViisGrades()" mat-raised-button color="accent">
                    <i class="fa fa-refresh"></i>
                    {{'applications_refreshViisGrades' | translate}}
                </button>
                <button *ngIf="isEditor && hasDVAExams" type="button" (click)="refreshCrmGrades()" mat-raised-button color="accent">
                    <i class="fa fa-refresh"></i>
                    {{'applications_refreshCrmGrades' | translate}}
                </button>
                <button mat-raised-button type="button" (click)="showExams = !showExams">
                    <i class="fa fa-eye{{showExams ? '-slash' : ''}}"></i>
                    {{ (showExams ? 'applications_hideDetails' : 'applications_showDetails') | translate}}
                </button>
            </aside>
        </header>
        <div class="block-body" *ngIf="showExams">
            <div class="alert bg-warning" *ngIf="dataLoaded && !examTree.length && !isHomines">
                {{'applications_noExamToShow' | translate}}
            </div>

            <div *ngFor="let p of examTree" [class.exam-program]="p.name">
                <h5 class="exam-program-title" *ngIf="p.name">{{p.name}}</h5>
                <div *ngFor="let g of p.groups" [class.exam-group]="g.name">
                    <h5 class="exam-group-title" *ngIf="g.name">{{g.name}}</h5>
                    <div class="alert alert-danger" *ngIf="g.name && !validateExamGroup(g)">
                        {{'applications_groupTotalExceedsMaxGrade' | translate | formatString:[getExamGroupTotal(g),g.maxGrade]}}
                    </div>
                    <ul class="block-list">
                        <li *ngFor="let e of g.exams">
                            <div class="cols">
                                <div class="col-{{e.AuxTypes.length ? 6 : (isHomines ? 9 : 10)}}">
                                    <h4>
                                        {{e.Title}}
                                        <ng-container *ngIf="isEditor && getViisGradeString(e) as viisGrade">
                                            <span style="margin-left: 8px;" class="badge"
                                                  [ngClass]="viisGrade.charAt(0) === '?' ? 'bg-warning' : viisGrade.charAt(0) === '!' ? 'bg-danger' : 'bg-success'"
                                                  [innerHTML]="'applications_viisGrade_badge' | translate | formatString:[getViisRawGradeString(e).substring(1)] | safeHtml"></span>
                                        </ng-container>
                                        <ng-container *ngIf="isEditor && getCrmGradeString(e) as crmGrade">
                                            <span style="margin-left: 8px;" class="badge"
                                                  [ngClass]="crmGrade.charAt(0) === '?' ? 'bg-warning' : crmGrade.charAt(0) === '!' ? 'bg-danger' : 'bg-success'"
                                                  [innerHTML]="'applications_crmGrade_badge' | translate | formatString:[crmGrade.substring(1)] | safeHtml"></span>
                                        </ng-container>
                                    </h4>
                                    <app-application-notes *ngIf="item.Id"
                                                           [applicationId]="item.Id"
                                                           [position]="e.Position"
                                                           [single]="true"
                                                           [canEdit]="isEditor"
                                                           [notes]="notes"
                                                           class="exam-notes">
                                    </app-application-notes>
                                </div>

                                <ng-container *ngIf="hasAdvancedGradeEditor(e); else simpleGradeEditor">
                                    <div class="col-2">
                                        <app-field-display *ngIf="e.AuxType" [value]="e.AuxType" [label]="'applications_lblExamAuxType' | translate"></app-field-display>
                                    </div>

                                    <div class="col-2">
                                        <app-field-display [value]="e.Grade" [label]="'applications_lblGrade' | translate"></app-field-display>
                                        <div *ngIf="getGradeHints(e) as gradeHints" class="mat-hint mat-hint-standalone text-right">
                                            <div *ngFor="let gh of gradeHints" [innerHTML]="gh"></div>
                                        </div>
                                    </div>

                                    <div class="col-2 mat-align-middle text-right" *ngIf="canEditGrade(e)">
                                        <button mat-stroked-button type="button" (click)="setGrade(e, g)">
                                            <i class="fa fa-pencil"></i>
                                            {{'applications_editGrade' | translate}}
                                        </button>
                                    </div>
                                </ng-container>

                                <ng-template #simpleGradeEditor>
                                    <div class="col-2"></div>
                                    <div class="col-2">
                                        <ng-template [ngIf]="canEditGrade(e)" [ngIfElse]="cantEditGrade">
                                            <div [matTooltip]="getGradeTooltip(e)" [matTooltipPosition]="'above'" #gradeTooltip="matTooltip"></div>
                                            <div [matTooltip]="getPreApprovalGrade(e)" [matTooltipPosition]="'before'" #preApprovalGradeTooltip="matTooltip"></div>
                                            <mat-form-field>
                                                <input matInput #gradeInput [ngModel]="e.Grade" [required]="e.ExamMandatory"
                                                        (ngModelChange)="onGradeChange($event, e, gradeInput)" name="examGrade" type="text"
                                                        [placeholder]="'applications_lblGrade' | translate" [pattern]="!validateGrade(e) ? '^$' : '[0-9]+([\.,][0-9]+)?'"
                                                        [disabled]="!isEditor && e.TypeCode !== 'CE'" (mouseover)="preApprovalGradeTooltip.show()"
                                                        (mouseleave)="preApprovalGradeTooltip.hide()" (focus)="gradeTooltip.show()" (blur)="saveGrade(e, g)">
                                                <mat-error *ngIf="!validateGrade(e)">{{'applicationExams_invalidGrade' | translate}}</mat-error>
                                                <mat-hint *ngIf="e.Weight" align="end">
                                                    <strong>({{e.Weight}}) * {{e.Grade}} = {{getExamFinalGrade(e)}}</strong>
                                                </mat-hint>
                                            </mat-form-field>
                                        </ng-template>
                                        <ng-template #cantEditGrade>
                                            <app-field-display [value]="e.Grade" [label]="'applications_lblGrade' | translate"></app-field-display>
                                            <div *ngIf="e.Weight" class="mat-hint mat-hint-standalone text-right">
                                                <strong>({{e.Weight}}) * {{e.Grade}} = {{getExamFinalGrade(e)}}</strong>
                                            </div>
                                        </ng-template>
                                    </div>
                                </ng-template>

                                <div class="col-2 mat-align-middle text-right" *ngIf="isHomines && (item.Status=='Approved' || item.Status=='Finished')">
                                    <a mat-raised-button color="primary" [href]="quizzRedirectUrl">
                                        {{'quizz_openlink' | translate}}
                                    </a>
                                </div>
                            </div>
                            <div class="cols">
                                <div class="col-4" *ngIf="e.IsHybridDoc">
                                    <h5>{{'applicationFileTypeExamFile' | translate}}</h5>
                                    <app-application-aux-files *ngIf="dataLoaded"
                                                               [label]="'addApplicationFileTypeExamFile'"
                                                               [files]="aux.examFiles[e.Id]"
                                                               [auxType]="'ExamFile_' + e.Id"
                                                               [maxUploadSize]="maxFileUploadSize"
                                                               [allowedExtensions]="fileUploadExtensions"
                                                               (select)="saveExamFile(e.Id, $event)"
                                                               (remove)="examFileRemoved(e.Id, $event)"></app-application-aux-files>
                                </div>
                                <div class="col-4" *ngIf="isEditor">
                                    <mat-checkbox [checked]="e.SendToDvs" (change)="examSendToDvsChange($event, e)" class="mat-align-bottom">
                                        <span style="white-space: normal;">{{'applications_lblExamSendToDvs' | translate}}</span>
                                    </mat-checkbox>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>

                <div class="workplace" *ngIf="isResidentAdmission && p.item">
                    <p>{{ 'applications_workplaceIntro' | translate : { program: p.name } }}</p>
                    <div class="cols">
                        <div class="col-1">
                            <mat-form-field floatLabel="never">
                                <mat-select #hasWorkplace [placeholder]="'choose_value' | translate" required [value]="p.item.HasWorkplace" (selectionChange)="hasWorkplaceSelected(p.item, $event.value)" [disabled]="!canEdit">
                                    <mat-option>--</mat-option>
                                    <mat-option [value]="true">{{ 'value_yes' | translate }}</mat-option>
                                    <mat-option [value]="false">{{ 'value_no' | translate }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <ng-container *ngIf="p.item.HasWorkplace">
                            <div class="col-7">
                                <app-autocomplete *ngIf="canEdit"
                                                  [value]="p.item.WorkplaceId"
                                                  [text]="p.item.Workplace"
                                                  [options]="workplaces"
                                                  [display]="app.displayClassifier"
                                                  [placeholder]="'applications_lblWorkplaceEdit' | translate"
                                                  (selected)="workplaceSelected($event, p.item)"
                                                  (textChange)="onWorkplaceChange($event, p.item)"
                                                  [debounce]="500"
                                                  (blur)="onWorkplaceBlur(p.item)"></app-autocomplete>
                                <app-field-display *ngIf="!canEdit" [value]="p.item.Workplace" [label]="'applications_lblWorkplace' | translate"></app-field-display>
                            </div>
                            <div class="col-4">
                                <app-application-aux-files [label]="'addApplicationFileTypeWorkplaceFile'"
                                                           [files]="aux.workplaceFiles[p.item.Id]"
                                                           [auxType]="'WorkplaceFile_' + p.item.Id"
                                                           [maxUploadSize]="maxFileUploadSize"
                                                           [allowedExtensions]="fileUploadExtensions"
                                                           (select)="saveWorkplaceFile(p.item.Id, $event)"
                                                           (remove)="workplaceFileRemoved(p.item.Id, $event)">

                                </app-application-aux-files>
                            </div>
                        </ng-container>
                    </div>
                </div>

                <div *ngIf="isResidentAdmission && p.name" class="exams-total">
                    {{'applications_total' | translate}}: {{getProgramExamsTotal(p)}}
                </div>
            </div>
        </div>
    </section>

    <ng-container *ngIf="!isHomines">
        <!-- Iepriekšējās studijas (iesniegumiem par kredītpunktu pārcelšanu) (kopsavilkums ar iespēju iet uz detaļām/labošanu) -->
        <section class="block" *ngIf="item.ApplicationForCreditTransfer">
            <header class="block-header">
                <h2>{{'applications_prevUni' | translate}}</h2>
                <a [routerLink]="['previousuniversity'] | localize" mat-raised-button color="accent">
                    <i class="fa fa-pencil" *ngIf="canEdit"></i>
                    {{(canEdit ? 'applications_editPrevUni' : 'open') | translate}}
                </a>
            </header>
            <div class="block-body">
                <div class="cols">
                    <div class="col-3">
                        <label>{{'applications_prevUni_lblName' | translate}}</label>
                    </div>
                    <div class="col-8" *ngIf="prevUni">{{prevUni.Name}}</div>
                </div>

                <div class="cols">
                    <div class="col-3">
                        <label>{{'applications_prevUni_lblSelectedPrograms' | translate}}</label>
                    </div>
                    <div class="col-8" *ngIf="prevUni">{{prevUniSelectedPrograms}}</div>
                </div>
            </div>
        </section>

        <!-- Apliecinājumu bloks -->
        <section class="block" [class.collapsed]="!showConfirmations">
            <header class="block-header">
                <h2>{{'applications_confirmations_lbl' | translate}}</h2>
                <aside>
                    <button mat-raised-button type="button" (click)="showConfirmations = !showConfirmations">
                        <i class="fa fa-eye{{showConfirmations ? '-slash' : ''}}"></i>
                        {{(showConfirmations ? 'applications_hideDetails' : 'applications_showDetails') | translate}}
                    </button>
                </aside>
            </header>
            <div class="block-body" *ngIf="showConfirmations">
                <div *ngIf="item.EduDocApprovalRequired && !isHomines">
                    <mat-checkbox [(ngModel)]="item.GradesConfirmed" name="gradesConfirmed" (change)="confirm('grades', $event)" [disabled]="!canEdit">
                        <span [innerHTML]="applicationConfirmGradesText | safeHtml" style="white-space: normal"></span>
                    </mat-checkbox>
                </div>
                <div *ngIf="!isHomines">
                    <mat-checkbox [(ngModel)]="item.RulesConfirmed" name="rulesConfirmed" (change)="confirm('rules', $event)" [disabled]="!canEdit">
                        <span [innerHTML]="confirmRulesText | safeHtml" style="white-space: normal"></span>
                    </mat-checkbox>
                </div>
                <div *ngIf="isHomines">
                    <mat-checkbox [(ngModel)]="item.RulesConfirmed" name="rulesConfirmed" (change)="confirm('rules', $event)" [disabled]="!canEdit">
                        <span [innerHTML]="applicationConfirmHominesRulesText | formatString:admissionRulesUrl | safeHtml" style="white-space: normal"></span>
                    </mat-checkbox>
                </div>
                <div *ngIf="(isApplicant || isEditor || isAgent) && !isHomines">
                    <mat-checkbox [(ngModel)]="item.StudiesAtLaterStages" name="studiesAtLaterStages" (change)="studiesAtLaterStagesChange($event, item)" [disabled]="!canEdit">
                        <span [innerHTML]="applicationStudiesAtLaterStagesText | safeHtml" style="white-space: normal"></span>
                    </mat-checkbox>
                </div>
                <div *ngIf="(isEditor || isAgent) && !isHomines && !item.IsForeign">
                    <mat-checkbox [(ngModel)]="item.IsGraduate" name="isGraduate" (change)="isGraduateChange($event)" [disabled]="!canEdit">
                        <span [innerHTML]="applicationIsGraduateText | safeHtml" style="white-space: normal"></span>
                    </mat-checkbox>
                </div>
                <div *ngIf="item.IsForeign && !isHomines">
                    <mat-checkbox [(ngModel)]="item.ApplicationForCreditTransfer" name="applicationForCreditTransfer"
                                  (change)="applicationForCreditTransferChange($event)" [disabled]="!canEdit">
                        <span [innerHTML]="applicationApplicationForCreditTransferText | safeHtml" style="white-space: normal"></span>
                    </mat-checkbox>
                </div>
                <div *ngIf="!item.IsForeign && isPowerUser">
                    <mat-checkbox [(ngModel)]="item.IsIncomplete" name="isIncomplete" (change)="isIncompleteChange($event)" [disabled]="!canEdit">
                        <span [innerHTML]="applicationIsIncompleteText | safeHtml" style="white-space: normal"></span>
                    </mat-checkbox>
                </div>
            </div>
        </section>

        <!-- Reģistrēšanās apmaksas info -->
        <section class="block" *ngIf="showBanklink" [class.collapsed]="!showPay">
            <header class="block-header">
                <h2>{{'applications_pay_lbl' | translate}}</h2>
                <aside>
                    <button mat-raised-button type="button" (click)="showPay = !showPay">
                        <i class="fa fa-eye{{showPay ? '-slash' : ''}}"></i>
                        {{(showPay ? 'applications_hideDetails' : 'applications_showDetails') | translate}}
                    </button>
                </aside>
            </header>
            <div class="block-body" *ngIf="showPay">
                <div class="alert alert-info" *ngIf="payInfo" [innerHTML]="payInfo | safeHtml"></div>
                <div class="cols" *ngIf="studentPayStatusModel">
                    <div class="col-12">
                        <p [translate]="'applications_pay_status'" [translateParams]="{ paid: studentPayStatusModel.PaidRegAmount, needToPay: studentPayStatusModel.NeedTopPayRegAmount}"></p>
                    </div>
                </div>
                <div class="cols">
                    <div class="col-4">
                        <a *ngIf="!paidReg"
                           [disabled]="!item.PersonCode && !item.Name && !item.Surname"
                           [routerLink]="['/applications/pay'] | localize"
                           [queryParams]="{ type: 'RegistraionPayment', AplicationId: item.Id, ApplicationProgramId: null }"
                           (click)="startPay()"
                           target="_blank"
                           mat-raised-button
                           color="primary">
                            {{'applications_pay_register_lbl' | translate}}
                        </a>
                    </div>
                    <div class="col-4" *ngIf="!paidReg">
                        <h5>{{'applications_pay_addFile_lbl' | translate}}</h5>
                        <app-application-aux-files [label]="'applications_pay_addFile_lbl'"
                                                   [files]="aux.paymentFiles"
                                                   [auxType]="'Payment'"
                                                   [maxUploadSize]="maxFileUploadSize"
                                                   (select)="savePaymentFile($event)"
                                                   (remove)="paymentFileRemoved($event)"
                                                   [allowedExtensions]="fileUploadExtensions"></app-application-aux-files>
                    </div>
                    <div class="col-4">
                        <div>
                            <mat-checkbox [(ngModel)]="paidReg" name="paidReg" [disabled]="!canSetPayment" (change)="setPayment()">
                                <span [innerHTML]="'applications_pay_register_check_lbl' | translate"></span>
                            </mat-checkbox>
                        </div>
                    </div>
                </div>
                <div class="cols" *ngIf="paidReg && acceptedInProgram">
                    <div class="col-4">
                        <a *ngIf="!paidFirstPart && acceptedInProgram"
                           [disabled]="!isPayButtonEnabled"
                           [routerLink]="['/applications/pay'] | localize"
                           [queryParams]="{ type: 'FirstPartPayment', AplicationId: item.Id, ApplicationProgramId: acceptedInProgram.Id }"
                           target="_blank"
                           mat-raised-button
                           color="primary">
                            {{'applications_pay_first_lbl' | translate}}
                        </a>
                    </div>
                    <div class="col-4">
                        <div *ngIf="acceptedInProgram && !acceptedInProgram.IsBudget">
                            <mat-checkbox [(ngModel)]="paidFirstPart" name="paidFirstPart" [disabled]="!isPowerUser" (change)="setPayment()">
                                <span [innerHTML]="'applications_pay_first_check_lbl' | translate"></span>
                            </mat-checkbox>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- Dokumenti (rāda tikai vietējās uzņemšanas reflektantiem) -->
        <section class="block" *ngIf="showApplicantDocuments else nonApplicantDocuments" [class.collapsed]="!showDocuments">
            <header class="block-header">
                <h2>{{'applications_documents' | translate}}</h2>
                <aside>
                    <button mat-raised-button type="button" (click)="showDocuments = !showDocuments">
                        <i class="fa fa-eye{{showDocuments ? '-slash' : ''}}"></i>
                        {{(showDocuments ? 'applications_hideDetails' : 'applications_showDetails') | translate}}
                    </button>
                </aside>
            </header>
            <div class="block-body" *ngIf="showDocuments">
                <div *ngIf="shouldSignContract" class="div-buttons">
                    <div *ngIf="canUploadEdoc">
                        <div *ngIf="esignHelpText" [innerHTML]="esignHelpText | safeHtml" class="alert alert-info"></div>
                        <div>
                            <a href="#" mat-raised-button color="primary" (click)="$event.preventDefault(); uploadEdoc();">
                                <i class="fa fa-pencil"></i>
                                {{'applications_btnUploadEdoc' | translate}}
                            </a>
                        </div>
                    </div>
                </div>

                <ngx-datatable [rows]="applicantFiles"
                               [headerHeight]="'auto'"
                               [footerHeight]="'auto'"
                               [rowHeight]="'auto'"
                               [columnMode]="'force'"
                               [messages]="{ emptyMessage: 'gridEmpty' | translate, totalMessage: 'gridTotal' | translate }">
                    <ngx-datatable-column [name]="'applications_files_thType' | translate" prop="type">
                        <ng-template ngx-datatable-cell-template let-value="value">
                            {{'applicationFileType' + value | translate}}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column [name]="'applications_files_thName' | translate" prop="name">
                        <ng-template ngx-datatable-cell-template let-row="row">
                            <a *ngIf="row.name" href="{{row.url}}" target="_blank">{{row.name}}</a>
                            <a *ngIf="row.pdfUrl" href="{{row.pdfUrl}}" target="_blank" style="margin-left: 4px">
                                <span class="badge">{{'applications_downloadAsPdf_lbl' | translate}}</span>
                            </a>
                            <a *ngIf="row.type === 'Contract' && row.status.toLowerCase() !== 'canceled' && row.status.toLowerCase() !== 'annulled' && !!contractDVSId && isEmployeeThatCanSeeContractStatus" href="{{DVSlink}}{{contractDVSId}}" target="_blank" style="margin-left: 4px">
                                <span class="badge status-Finished">
                                    {{'applications_openInDVS_lbl' | translate}}
                                    <i class="fa fa-external-link"></i>
                                </span>
                            </a>
                            <span *ngIf="row.status && row.status!=''" style="margin-left: 4px" class="badge" [ngClass]="{
                                'status-Approved': row.status.toLowerCase() === 'signed',
                                'status-Cancelled' : row.status.toLowerCase() === 'canceled' || row.status.toLowerCase() === 'annulled',
                                'status-ApplicationCreated' : row.status.toLowerCase() === 'created' || row.status.toLowerCase() === 'availabletosignindvs' || row.status.toLowerCase() === 'waitingapprovalfromrsu',
                                'status-New' : row.status.toLowerCase() === 'notcreatedindvs'}">
                                {{getContractStatusText(row.status)}}
                            </span>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column [name]="'applications_files_thAdditionalInformation' | translate" prop="institution">
                        <ng-template ngx-datatable-cell-template let-row="row">
                            <span>{{row.institution}}</span>
                        </ng-template>
                    </ngx-datatable-column>
                </ngx-datatable>
            </div>
        </section>
        <ng-template #nonApplicantDocuments>
            <!-- Dokumenti (nerāda vietējās uzņemšanas reflektantiem) -->
            <section class="block" [class.collapsed]="!showDocuments">
                <header class="block-header">
                    <h2>{{'applications_documents' | translate}}</h2>
                    <aside>
                        <a href="{{personDocumentsDownloadUrl}}" mat-raised-button color="accent" *ngIf="isPowerUser && aux.personDocuments?.length">
                            <i class="fa fa-download"></i>
                            {{'applications_btnDownloadPersonFiles' | translate}}
                        </a>
                        <button mat-raised-button color="accent" type="button" (click)="generatePdf()" *ngIf="canGeneratePdf">
                            <i class="fa fa-file-text"></i>
                            {{'applications_generatePdf' | translate}}
                        </button>
                        <button mat-raised-button color="accent" type="button" [appShareButton]="{ objectIds: [item.Number], objectType: 'Application'}"
                                *ngIf="canShare">
                            <i class="fa fa-share"></i>
                            {{'share_btn' | translate}}
                        </button>
                        <button mat-raised-button type="button" (click)="showDocuments = !showDocuments">
                            <i class="fa fa-eye{{showDocuments ? '-slash' : ''}}"></i>
                            {{(showDocuments ? 'applications_hideDetails' : 'applications_showDetails') | translate}}
                        </button>
                    </aside>
                </header>
                <div class="block-body" *ngIf="showDocuments">
                    <div *ngIf="isEmployeeThatCanSeeContractStatus && (item.Status === statusEnum.Approved) && acceptedInProgram && acceptedInProgram.Program && acceptedInProgram.ContractStatus">
                        <div class="info" *ngIf="isAnyContractCreated || contractCreated || contractSigned || orderCreated">
                            <ng-container *ngIf="isAnyContractCreated || contractCreated">
                                {{'applications_contractStatus' | translate}}&nbsp;<b>{{getLastContractDisplayStatus()}}</b>
                            </ng-container>
                            <ng-container *ngIf="acceptedInProgram.EnrollmentStatus">
                                {{'applications_enrollmentStatus' | translate}}&nbsp;<b>{{'enrollmentStatus_'+acceptedInProgram.EnrollmentStatus.toLowerCase() | translate}}</b>
                            </ng-container>
                        </div>
                    </div>
                    <div class="div-buttons" *ngIf="isEditor && !item.CrmContractId && (item.Status === statusEnum.Approved) && acceptedInProgram && acceptedInProgram.Program
                        && ((!item.IsForeign && ((!item.CrmReflectantId && !acceptedInProgram.ContractStatus) || canCreateContract))
                            || (item.IsForeign && !!item.CrmReflectantId && (acceptedInProgram.ContractStatus=='ApplicantCreated' || acceptedInProgram.ContractStatus=='NotSent')))">
                        <a *ngIf="canCreateContract || item.IsForeign" href="#" mat-raised-button color="primary" class="div-buttons" (click)="$event.preventDefault(); createContract(acceptedInProgram.Program);">
                            <i class="fa fa-file-text"></i>
                            {{'applications_createContractForProgram' | translate | formatString:(acceptedInProgram.Program.Title+ ' ('+acceptedInProgram.Program.Code+')')}}
                        </a>
                        <div class="result-message" *ngIf="contractCreated">
                            <ng-container>
                                {{'applications_contractForProgramInProcess' | translate | formatString:(acceptedInProgram.Program.Title+ ' ('+acceptedInProgram.Program.Code+')')}}
                            </ng-container>
                        </div>
                    </div>
                    <div class="div-buttons" *ngIf="item.IsForeign && isEditor && (item.Status === statusEnum.Approved) && acceptedInProgram && acceptedInProgram.Program">
                        <a *ngIf="!item.CrmReflectantId || (!!item.CrmReflectantId && (!acceptedInProgram.ContractStatus || acceptedInProgram.ContractStatus=='Annulled' || acceptedInProgram.ContractStatus=='Canceled'))" href="#" mat-raised-button color="primary" class="div-buttons" (click)="$event.preventDefault();createSISApplicant(acceptedInProgram.Program);">
                            <i class="fa fa-file-text"></i> {{'applications_createNewContractForProgram' | translate}}
                        </a>
                        <div class="alert alert-success" *ngIf="applicantCreatedButtonClicked">
                            {{'contract_applicantSentForCreation' | translate}}
                        </div>
                    </div>
                    <div *ngIf="isEditor && !item.CrmContractId && (item.Status === statusEnum.Approved) && acceptedInProgram && acceptedInProgram.ContractStatus=='Created' && !acceptedInProgram.EnrollmentStatus"
                         style="display: inline; flex-direction: row; flex-wrap: wrap; justify-content: flex-start;">
                        <div *ngIf="!contractSigned" class="div-buttons">
                            <a href="#" mat-raised-button color="primary" (click)="$event.preventDefault();signContract(acceptedInProgram.Program);">
                                <i class="fa fa-pencil-square-o"></i>
                                {{'applications_signContractForProgram' | translate}}
                            </a>
                        </div>
                        <div *ngIf="canUploadEdoc" class="div-buttons">
                            <div>
                                <a href="#" mat-raised-button color="primary" (click)="$event.preventDefault(); uploadEdoc();">
                                    <i class="fa fa-pencil"></i>
                                    {{'applications_btnUploadEdoc' | translate}}
                                </a>
                            </div>
                        </div>
                        <div *ngIf="canApproveContractESign" class="div-buttons">
                            <a href="#" mat-raised-button color="primary" (click)="$event.preventDefault();signContract(acceptedInProgram.Program, true);">
                                <i class="fa fa-pencil-square-o"></i>
                                {{'applications_btnApproveContractESign' | translate}}
                            </a>
                        </div>
                        <div class="alert alert-success" *ngIf="contractSigned">
                            {{'programResults_appSigned' | translate}}
                        </div>
                    </div>
                    <div class="div-buttons" *ngIf="isEditor && item.CrmContractId && item.CrmReflectantId && (item.Status === statusEnum.Approved) && acceptedInProgram &&
                        acceptedInProgram.ContractStatus=='ApplicantCreated' && acceptedInProgram.EnrollmentStatus=='ReadyForEnrollment'">
                        <a *ngIf="!orderCreated" href="#" mat-raised-button color="primary" (click)="$event.preventDefault(); createOrder(acceptedInProgram.Program);">
                            <i class="fa fa-file-text"></i>
                            <ng-container>
                                {{'applications_createEnrollmentForProgram' | translate}}
                            </ng-container>
                        </a>
                        <div class="alert alert-success" *ngIf="orderCreated">
                            <ng-container>
                                {{'applications_orderCreatedInProcess' | translate}}
                            </ng-container>
                        </div>
                    </div>
                    <div class="div-buttons" *ngIf="canTerminateOrAnnulContract && (item.Status === statusEnum.Approved) && acceptedInProgram && acceptedInProgram.Program
                    && (canAnnulContract || (!item.IsForeign && !item.CrmReflectantId && !acceptedInProgram.ContractStatus))">
                        <a *ngIf="canAnnulContract" href="#" mat-raised-button color="accent" (click)="$event.preventDefault(); annulContract(acceptedInProgram.Program);">
                            <i class="fa fa-times-circle"></i>
                            {{'applications_annulContract' | translate}}
                        </a>
                    </div>
                    <div class="div-buttons" *ngIf="canTerminateOrAnnulContract && item.CrmContractId && (item.Status === statusEnum.Approved) && acceptedInProgram && acceptedInProgram.Program
                    && (canTerminateContract || (item.IsForeign && item.CrmReflectantId && acceptedInProgram.ContractStatus=='ApplicantCreated'))">
                        <a *ngIf="canTerminateContract" href="#" mat-raised-button color="accent" (click)="$event.preventDefault(); terminateContract(acceptedInProgram.Program);">
                            <i class="fa fa-times-circle"></i>
                            {{'applications_terminateContract' | translate}}
                        </a>
                    </div>
                    <div class="alert alert-warning" *ngIf="item.Status !== statusEnum.Approved && pdfAvailable">
                        {{'applications_pdfAvailableOnlyForApproved' | translate}}
                    </div>

                    <ngx-datatable [rows]="allFiles"
                                   [headerHeight]="'auto'"
                                   [footerHeight]="'auto'"
                                   [rowHeight]="'auto'"
                                   [columnMode]="'force'"
                                   [messages]="{ emptyMessage: 'gridEmpty' | translate, totalMessage: 'gridTotal' | translate }">
                        <ngx-datatable-column [name]="'applications_files_thType' | translate" prop="type">
                            <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
                                {{'applicationFileType' + value | translate}}
                                <span *ngIf="value === 'Contract' && !!contractFilesAccessibleFrom && row.status.toLowerCase() !== 'canceled' && row.status.toLowerCase() !== 'annulled'" style="margin-left: 2%;" class="badge" [ngClass]="'contract-file-not-accessible'">
                                    {{'applications_contractFileAccessibleFrom' | translate | formatString:(contractFilesAccessibleFrom | appDate)}}
                                </span>
                                <span *ngIf="value === 'Contract' && !contractFilesAccessibleFrom && !!contractFilesAccessibleUntil && row.status.toLowerCase() !== 'canceled' && row.status.toLowerCase() !== 'annulled'" style="margin-left: 2%;" class="badge"
                                      [ngClass]="areContractFilesAccessible ? 'contract-file-accessible' : 'contract-file-not-accessible'">
                                    {{'applications_contractFileAccessibleUntil' | translate | formatString:(contractFilesAccessibleUntil | appDate)}}
                                </span>
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column [name]="'applications_files_thName' | translate" prop="name">
                            <ng-template ngx-datatable-cell-template let-row="row">
                                <a *ngIf="!!row.name" href="{{row.url}}" target="_blank">{{row.name}}</a>
                                <a *ngIf="row.pdfUrl" href="{{row.pdfUrl}}" target="_blank" style="margin-left: 4px">
                                    <span class="badge">{{'applications_downloadAsPdf_lbl' | translate}}</span>
                                </a>
                                <a *ngIf="row.type === 'Contract' && row.status.toLowerCase() !== 'canceled' && row.status.toLowerCase() !== 'annulled' && !!contractDVSId && isEmployeeThatCanSeeContractStatus" href="{{DVSlink}}{{contractDVSId}}" target="_blank" style="margin-left: 4px">
                                    <span class="badge status-Finished">
                                        {{'applications_openInDVS_lbl' | translate}}
                                        <i class="fa fa-external-link"></i>
                                    </span>
                                </a>
                                <span *ngIf="row.status && row.status!=''" style="margin-left: 4px" class="badge" [ngClass]="{
                                    'status-Approved': row.status.toLowerCase() === 'signed',
                                    'status-Cancelled' : row.status.toLowerCase() === 'canceled' || row.status.toLowerCase() === 'annulled',
                                    'status-ApplicationCreated' : row.status.toLowerCase() === 'created' || row.status.toLowerCase() === 'availabletosignindvs' || row.status.toLowerCase() === 'waitingapprovalfromrsu',
                                    'status-New' : row.status.toLowerCase() === 'notcreatedindvs'
                                    }">
                                    {{getContractStatusText(row.status)}}
                                </span>
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column [name]="'applications_files_thAdditionalInformation' | translate" prop="institution">
                            <ng-template ngx-datatable-cell-template let-row="row">
                                <span>{{row.institution}}</span>
                            </ng-template>
                        </ngx-datatable-column>
                    </ngx-datatable>
                </div>
            </section>
        </ng-template>

        <!-- Līgums no CRM, ar iespēju mainīt statusu. Iespējams, nav pieejams reflektantam  -->
        <!-- <section class="block" *ngIf="item.CrmContractId && contractStatusToSet && contractStatusRead && acceptedInProgram && acceptedInProgram.Program">
            <header class="block-header">
                <h2>{{'applications_contractsForProgram' | translate | formatString:(acceptedInProgram.Program.Title+ ' ('+acceptedInProgram.Program.Code+')')}}</h2>
            </header>
            <div class="block-body">
                <div class="alert alert-danger" *ngIf="contractStatusRead && contractStatusRead.IsBeingProcessed">
                    {{'applications_contractInProgress' | translate}}
                </div>
                <div class="form-inline" *ngIf="contractStatusRead && !contractStatusRead.IsBeingProcessed
                    && (!contractStatusRead.currentStatusCode || contractStatusRead.currentStatusCode!='Canceled' || contractStatusRead.currentStatusCode!='Annulled')">
                    <mat-form-field>
                        <mat-select [placeholder]="'applications_lblContractStatus' | translate" name="ContractStatus"
                                    [(value)]="contractStatusToSet" (selectionChange)="statusChange($event)">
                            <mat-option *ngFor="let cs of getContractStatuses()" [value]="cs.Id">
                                {{showStatusValue(cs)}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field *ngIf="reasonNeeded">
                        <mat-select [placeholder]="'applications_lblContractTermReason' | translate" name="TerminationReason" [(value)]="terminationReasonId">
                            <mat-option *ngFor="let r of terminationReasons" [value]="r.Id">
                                {{classifierDisplay(r)}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <button type="button" class="btn-inline" mat-raised-button color="accent"
                            [disabled]="!contractStatusToSet || (reasonNeeded && !terminationReasonId)"
                            (click)="setContractStatus()">
                        <i class="fa fa-floppy-o"></i>
                        {{'applications_updatecontractstatus' | translate}}
                    </button>
                </div>
            </div>
        </section> -->
    </ng-container>
    <div class="form-submit">
        <button type="button" mat-raised-button color="primary" (click)="submit()" *ngIf="canSubmit || canSubmitAgain">
            <i class="fa fa-paper-plane"></i>
            {{(canSubmitAgain ? 'applications_submitagain' : 'applications_submit') | translate}}
        </button>

        <button type="button" mat-raised-button color="primary" (click)="sign()" *ngIf="canSign">
            <i class="fa fa-pencil-square-o"></i>
            {{'applications_sign' | translate}}
        </button>

        <button type="button" mat-raised-button color="primary" (click)="setStatus('OnHold')" *ngIf="canSetOnHold">
            <i class="fa fa-ban"></i>
            {{'applications_setOnHold' | translate}}
        </button>

        <button type="button" mat-raised-button color="primary" (click)="setStatus('Approved')" *ngIf="canApprove" [disabled]="!personIsChecked"
                [title]="personIsChecked ? '' : 'applications_personNotVerified' | translate">
            <i class="fa fa-thumbs-up"></i>
            {{'applications_setApproved' | translate}}
        </button>

        <button type="button" mat-raised-button color="primary" (click)="setStatus('Cancelled')" *ngIf="canCancel">
            <i class="fa fa-thumbs-down"></i>
            {{'applications_setCancelled' | translate}}
        </button>

        <button type="button" mat-raised-button color="primary" (click)="setUnitedRZStatus('SpecialityApproved')" *ngIf="canApproveSpeciality">
            <i class="fa fa-check"></i>
            {{'applications_setRZStatusSpecialityApproved' | translate}}
        </button>

        <button type="button" mat-raised-button color="primary" (click)="setUnitedRZStatus('UniversitySelected')" *ngIf="canApproveUniversity"
                [disabled]="!universitySelected">
            <i class="fa fa-check"></i>
            {{'applications_setRZStatusUniversitySelected' | translate}}
        </button>

        <button type="button" mat-raised-button color="primary" (click)="setStatus('Recalled')" *ngIf="canRecall">
            <i class="fa fa-step-backward"></i>
            {{'applications_setRecalled' | translate}}
        </button>

        <button type="button" mat-raised-button color="accent" (click)="delete()" *ngIf="canDelete">
            <i class="fa fa-trash-o"></i>
            {{'applications_delete' | translate}}
        </button>
    </div>
</div>

<div *ngIf="!itemExists" style="margin-top: 3%;">
    <section class="block">
        <header class="block-header">
            <h2>{{'applications_lblNotAvailableMessageHeader' | translate}}</h2>
        </header>
        <div class="block-body" [innerHtml]="applicationIsNotAvailableMsgText | safeHtml"></div>
    </section>
</div>

<form [action]="payFormUrl" method="post" #payForm>
    <input name="payment_data" type="hidden" [value]="payData">
</form>
