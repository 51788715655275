import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ApplicationNote } from '../models/Application';

@Component({
    selector: 'app-program-result-note',
    templateUrl: './result-note.component.html'
})
export class ResultNoteComponent {
    constructor(public dialogRef: MatDialogRef<ResultNoteComponent>, @Inject(MAT_DIALOG_DATA) public data: ApplicationNote[]) {
        this.applicationId = data[0].ApplicationId;
        this.notes = data;
    }

    applicationId: number;
    notes: ApplicationNote[];

    submit() {
        this.dialogRef.close();
    }
}
