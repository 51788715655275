import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Program } from '../models/Program';

@Component({
    selector: 'app-admission-program-edit',
    templateUrl: './program-edit.component.html'
})
export class ProgramEditComponent {
    constructor(public dialogRef: MatDialogRef<ProgramEditComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
        this.item = data;
    }

    item: Program;

    submit() {
        this.dialogRef.close(this.item);
    }
}
