<form #form="ngForm" (submit)="submit()">
    <h2 mat-dialog-title [translate]="'admissions_addAdmission'"></h2>
    <mat-dialog-content>
        <mat-form-field class="full-width">
            <mat-select [placeholder]="'admissions_lblAdmission' | translate" [required]="true"
                        [(ngModel)]="selectedAdmission" name="selectedAdmission">
                <mat-option *ngFor="let a of admissions" [value]="a">{{getAdmissionDisplayText(a)}}</mat-option>
            </mat-select>
        </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button mat-raised-button type="submit" color="primary" [disabled]="!form.valid">{{'continue' | translate}}</button>
        <button mat-button mat-dialog-close>{{'cancel' | translate}}</button>
    </mat-dialog-actions>
</form>